import * as THREE from 'three'
import { OrbitControls } from 'three/addons/controls/OrbitControls.js'
import { getViewFillData } from './3DUtils.mjs'

class CameraController {
  _camera = null
  _controls = null
  _domElement = null

  get camera() {
    return this._camera
  }
  set camera(newCamera) {
    if (newCamera === this._camera) {
      return
    }

    if (!(newCamera instanceof THREE.Camera)) {
      throw new Error('Parameter is not a camera.')
    }

    this._camera = newCamera
    this.resetControls()
  }
  set panEnabled(value) {
    this._controls.enablePan = value
  }

  get minDistance() {
    return this._controls.minDistance
  }
  get maxDistance() {
    return this._controls.maxDistance
  }

  constructor(camera, domElement) {
    this._domElement = domElement

    if (camera !== undefined) {
      this.camera = camera
    }
  }

  resetControls() {
    if (this._controls) {
      this._controls.dispose()
    }

    this._controls = new OrbitControls(this._camera, this._domElement)
    this._controls.autoRotate = false
  }

  setControlsEnabled(enabled) {
    this._controls.enabled = enabled
  }

  dispose() {
    this._controls.dispose()
    this._controls = null
  }

  setTarget({ x, y, z }) {
    this._controls.target.set(x, y, z)
  }

  setDistanceLimits({ minDistance, maxDistance }) {
    if (minDistance !== undefined) {
      this._controls.minDistance = minDistance
    }

    if (maxDistance !== undefined) {
      this._controls.maxDistance = maxDistance
    }
  }

  setMouseControls(newMouseControls) {
    this._controls.mouseButtons = newMouseControls
  }

  fitViewToObjects(root, horizontalViewRatio, verticalViewRatio) {
    const { boundingSphere, startDistance } = getViewFillData(
      root,
      this.camera,
      horizontalViewRatio,
      verticalViewRatio,
    )

    this._controls.target = boundingSphere.center
    this.camera.position.copy(boundingSphere.center)
    this.camera.translateZ(startDistance)

    this._controls.saveState()
    this._controls.minDistance = boundingSphere.radius
    this._controls.maxDistance = startDistance
  }

  update(deltaTime, timestamp) {
    deltaTime = timestamp
    if (this._controls) {
      this._controls.update()
    }
  }
}

export default CameraController
