import { Pagination } from "@mui/material"
import { css, StyleSheet } from "aphrodite"
import React, { useCallback, useMemo } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import { useTheme } from "../../components/Provider/ThemeProvider"
import { LogoutHeader } from "../../components/Widget/LogoutHeader"
import { useHomePage } from "../../hooks/useHomePage"
import { WidgetBrandCard } from "./WidgetBrandCard"

export const HomePage = () => {
  // others hooks --------------------------------------------------------------
  const { t } = useTranslation("homePage")
  const { themeStyles } = useTheme()
  const navigate = useNavigate()
  const { brandsDb, paginationState, first, changeCurrentPage } = useHomePage()

  // init style ----------------------------------------------------------------
  const styles = useMemo(() => getStyles({ theme: themeStyles }), [themeStyles])

  const redirect = useCallback(
    brand => {
      navigate(`/manuals?brand=${brand.id}&page=1`)
    },
    [navigate],
  )

  if (!brandsDb || !paginationState) {
    return <div>{t("error")}</div>
  }

  return (
    <section className={css(styles.container)}>
      <header className={css(styles.header)}>
        <h1 className={css(styles.title)}>{t("title")}</h1>
        <LogoutHeader themeStyles={themeStyles} />
      </header>
      <main className={css(styles.grid)}>
        {brandsDb?.map(brand => (
          <WidgetBrandCard
            key={brand?.id}
            onClick={() => redirect(brand)}
            img={brand.imgSrc}
            id={brand?.id}
          />
        ))}
      </main>
      <footer className={css(styles.paginationContainer)}>
        {paginationState && brandsDb?.length > 0 && (
          <Pagination
            size="small"
            page={paginationState?.currentPage}
            count={Math.ceil(paginationState?.totalCount / first)}
            onChange={(e, value) => changeCurrentPage(value)}
            color="primary"
            shape="rounded"
          />
        )}
      </footer>
    </section>
  )
}

const getStyles = ({ theme }) =>
  StyleSheet.create({
    container: {
      background: theme.BACKGROUND_COLOR,
      width: "100%",
      padding: "30px",
      boxSizing: "border-box",
      fontFamily: "Roboto",
      height: "100dvh",
      overflow: "auto",
      "::-webkit-scrollbar": {
        width: 4,
      },
    },
    title: {
      fontWeight: "600",
      fontSize: 20,
      color: theme.PRIMARY_COLOR,
      margin: 0,
      "@media (max-width: 1100px)": {
        fontSize: 16,
      },
    },
    header: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      marginBottom: 50,
      gap: 40,
    },
    grid: {
      display: "grid",
      gridAutoRows: "minmax(100px, auto)",
      gridTemplateColumns: "repeat(auto-fill, minmax(200px, 1fr))",
      gap: 15,
      boxSizing: "border-box",
    },
    paginationContainer: {
      display: "flex",
      justifyContent: "flex-end",
      paddingLeft: "10px",
      paddingTop: 15,
      marginBottom: 50,
    },
    elementNumber: {
      fontSize: "12px",
      color: "rgba(0, 0, 0, 0.5)",
      margin: 0,
    },
  })
