import { useLazyQuery } from "@apollo/client"
import * as Sentry from "@sentry/react"
import { jwtDecode } from "jwt-decode"
import PropTypes from "prop-types"
import React, { useState } from "react"
import { useNavigate } from "react-router-dom"
import api from "../../api"
import { AuthContext } from "../../context"
import { GET_TOKEN_USER } from "../../graphql/user"

export const AuthProvider = ({ children }) => {
  // states --------------------------------------------------------------------
  const [userLog, setUserLog] = useState()
  const navigate = useNavigate()
  const [errorLogin, setErrorLogin] = useState(null)
  const [loading, setLoading] = useState(false)

  // graphql -------------------------------------------------------------------
  const [logUser] = useLazyQuery(GET_TOKEN_USER, {
    fetchPolicy: "no-cache",
    onError: () => {
      setErrorLogin("error_signin")
      setLoading(false)
    },
    onCompleted: data => {
      const userInfos = jwtDecode(data.getTokenWithCredentials.accessToken)
      setUserLog({
        id: userInfos.sub,
        firstname: userInfos.firstname,
        lastname: userInfos.lastname,
        role: userInfos.role,
        email: userInfos.email,
      })

      // add user to sentry, for capture error
      if (userInfos.email) {
        Sentry.withScope(scope => {
          scope.setUser({ email: userInfos.email, id: userInfos.sub })
        })
      }

      localStorage.setItem("access_token", data.getTokenWithCredentials.accessToken)
      localStorage.setItem("refresh_token", data.getTokenWithCredentials.refreshToken)

      setLoading(false)
      navigate("/brands")
    },
  })

  const refreshUserData = () => {
    const token = jwtDecode(localStorage.getItem("access_token"))
    setUserLog({
      id: token.sub,
      firstname: token.firstname,
      lastname: token.lastname,
      role: token.role,
    })
  }

  const signinUserFunc = async (login, password) => {
    await logUser({ variables: { login, password } })
  }

  const refreshTokenFunc = async () => {
    await api
      .refreshAccessToken()
      .then(response => {
        localStorage.setItem("access_token", response.accessToken)
        localStorage.setItem("refresh_token", response.refreshToken)

        setUserLog({
          id: jwtDecode(response.accessToken).sub,
          firstname: jwtDecode(response.accessToken).firstname,
          lastname: jwtDecode(response.accessToken).lastname,
          role: jwtDecode(response.accessToken).role,
        })
      })
      .catch(() => {
        navigate("/signin")
      })
  }

  const value = {
    setUserLog,
    userLog,
    refreshUserData,
    signinUserFunc,
    refreshTokenFunc,
    loading,
    setLoading,
    errorLogin,
  }

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}

AuthProvider.propTypes = {
  children: PropTypes.node,
}
