import * as React from "react"

const Trash = () => (
  <svg width={11} height={15} fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8.35 5.465v7.5H2.34v-7.5H8.35ZM7.223.965H3.466l-.752.75H.084v1.5h10.52v-1.5h-2.63l-.75-.75Zm2.63 3H.836v9c0 .825.676 1.5 1.503 1.5H8.35c.827 0 1.503-.675 1.503-1.5v-9Z"
      fill="#FFF"
    />
  </svg>
)

export default Trash
