import { gql } from "@apollo/client"

export const BRAND_DATA = gql`
  fragment BrandData on Brand {
    id
    description
    label
    nbManuals
  }
`

export const ASSET_TYPE_DATA = gql`
  fragment AssetTypeData on AssetType {
    id
    label
  }
`

export const MANUAL_DATA = gql`
  fragment ManualData on Manual {
    id
    description
    numberOfPeopleNeeded
    idProduct
    isPublished
    manualVersion
  }
`

export const MANUAL_STEP_DATA = gql`
  fragment ManualStepData on ManualStep {
    id
    text
    pos
  }
`

export const CHILD_STEP_DATA = gql`
  fragment ChildStepData on ChildStep {
    id
    pos
    text
    status
    nbRepeat
  }
`

export const PART_DATA = gql`
  fragment PartData on Part {
    id
    filename
    updatedAt
    createdAt
  }
`

export const TOOL_DATA = gql`
  fragment ToolData on Tool {
    id
    filename
    updatedAt
    createdAt
  }
`

export const POI_DATA = gql`
  fragment PoiData on Poi {
    id
    filename
  }
`

export const MODEL_DATA = gql`
  fragment ModelData on Model {
    id
    description
    filename
    updatedAt
    createdAt
  }
`

export const CHILD_STEP_ANIMATION_DATA = gql`
  fragment ChildStepAnimationData on ChildStepAnimation {
    id
    name
    idAnimationMode
    repetitions
  }
`

export const CHILD_STEP_CAMERA_DATA = gql`
  fragment ChildStepCameraData on ChildStepCamera {
    camPositionX
    camPositionY
    camPositionZ
    id
    maxZoom
    minZoom
    targetPositionX
    targetPositionY
    targetPositionZ
  }
`
