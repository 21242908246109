import PropTypes from "prop-types"
import * as React from "react"

const Lock = ({ color }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={16} height={17} fill="none">
    <g transform="translate(2, 2)">
      <path
        fill={color}
        d="M13.716 7.648h-1.214v-4a2.286 2.286 0 0 0-2.286-2.286H5.788a2.286 2.286 0 0 0-2.286 2.286v4H2.288a.57.57 0 0 0-.572.571v6.858a.57.57 0 0 0 .572.571h11.428a.57.57 0 0 0 .572-.571V8.219a.57.57 0 0 0-.572-.571Zm-8.928-4a1 1 0 0 1 1-1h4.428a1 1 0 0 1 1 1v4H4.788v-4Zm8.214 10.714h-10V8.934h10v5.428Zm-5.5-2.482v.947c0 .078.064.142.143.142h.714a.143.143 0 0 0 .143-.142v-.947a.858.858 0 1 0-1 0Z"
      />
    </g>
  </svg>
)

Lock.propTypes = {
  color: PropTypes.string,
}

export default Lock
