import { css, StyleSheet } from "aphrodite"
import PropTypes from "prop-types"
import { forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState } from "react"
import { use3DAnimation } from "../../hooks/use3DAnimation"
import { use3DViewer } from "../../hooks/use3DViewer"
import { useTheme } from "../Provider/ThemeProvider"
import UIPlayerBlue from "../UI/UIPlayerBlue"

export const Model3DViewer = forwardRef(function Model3DViewer(
  { modelURL, wireframe = true, playerSize = "medium", setAnimationCount },
  forwardedRef,
) {
  const styles = getStyles()
  const canvasRef = useRef(null)
  const { viewerRef, modelRoot, animationsData } = use3DViewer(canvasRef, modelURL, wireframe, true)
  const [playerAnimations, setPlayerAnimations] = useState([])
  const [selectedAnimation, setSelectedAnimation] = useState(null)
  const [animationState, setAnimationState] = useState("paused")
  const [animationTime, setAnimationTime] = useState(0)
  const { themeStyles } = useTheme()

  use3DAnimation(viewerRef, modelRoot, selectedAnimation, "normal", Infinity, animationState === "paused")

  useEffect(updateAnimationCount, [animationsData, setAnimationCount])
  useEffect(updatePlayerAnimations, [animationsData])
  useEffect(addAnimationEventListeners, [viewerRef.current])

  useImperativeHandle(
    forwardedRef,
    () => {
      return {
        async takeScreenshot() {
          try {
            const screenshotBlob = await viewerRef.current.takeScreenshot()
            return screenshotBlob
          } catch (error) {
            console.error(error)
            return null
          }
        },
      }
    },
    [],
  )

  const handleAnimation = useCallback(
    animationName => {
      setSelectedAnimation(animationName)
      setAnimationState("paused")
      setAnimationTime(0)
    },
    [playerAnimations],
  )

  const handlePlay = () => {
    const toggleAnimationState = () => {
      if (animationState === "paused") {
        setAnimationState("playing")
      } else {
        setAnimationState("paused")
      }
    }
    if (selectedAnimation) {
      toggleAnimationState()
    }
  }

  const handleSeek = useCallback(
    time => {
      viewerRef.current.setAnimationTime(time)
      setAnimationTime(time)
    },
    [viewerRef.current],
  )

  return (
    <div className={css(styles.viewerContainer)}>
      <canvas ref={canvasRef} className={css(styles.viewerCanvas)}></canvas>
      <UIPlayerBlue
        animations={playerAnimations}
        onAnimationChanged={handleAnimation}
        handlePlay={handlePlay}
        handleSeek={handleSeek}
        animationState={animationState}
        animationTime={animationTime}
        disabled={!selectedAnimation}
        primaryColor={themeStyles?.PRIMARY_COLOR}
        secondaryColor={themeStyles?.SECONDARY_COLOR}
        size={playerSize}
      />
    </div>
  )

  function updateAnimationCount() {
    if (typeof setAnimationCount === "function" && animationsData) {
      setAnimationCount(animationsData.length)
    }
  }

  function updatePlayerAnimations() {
    setPlayerAnimations(
      animationsData.map(animationData => {
        return {
          name: animationData.name,
          value: animationData.name,
          duration: animationData.duration,
        }
      }),
    )
  }

  function addAnimationEventListeners() {
    viewerRef.current.canvas.addEventListener("animationUpdated", onAnimationUpdated)

    return () => viewerRef.current.canvas.removeEventListener("animationUpdated", onAnimationUpdated)
  }

  function onAnimationUpdated(event) {
    setAnimationTime(event.detail.time)
  }
})

const getStyles = () =>
  StyleSheet.create({
    viewerContainer: {
      width: "100%",
      height: "100%",
    },
    viewerCanvas: {
      width: "100%",
      height: "100%",
    },
  })

Model3DViewer.propTypes = {
  modelURL: PropTypes.string,
  wireframe: PropTypes.bool,
  playerSize: PropTypes.string,
  setAnimationCount: PropTypes.func,
}
