import { css, StyleSheet } from "aphrodite"
import PropTypes from "prop-types"
import React, { useMemo } from "react"
import { Lock, User } from "../../assets/svgr"
import { BLACK_COLOR, ERROR_COLOR, WHITE } from "../../styles/colors"
import { useTheme } from "../Provider/ThemeProvider"

export const UIInput = ({
  type,
  onKeyDown = null,
  colorIcon = BLACK_COLOR,
  disabled = false,
  placeholder,
  autofocus = false,
  min,
  iconDirection = "right",
  icon = false,
  label,
  value,
  labelDynamicStyle,
  dynamicStyle,
  maxLength,
  keyInput,
  isError,
  camera = null,
  ...props
}) => {
  const { themeStyles } = useTheme()

  const styles = useMemo(
    () => getStyles({ isError, iconDirection, colorIcon, themeStyles }),
    [isError, iconDirection, colorIcon, themeStyles],
  )

  const iconChoice = choiceIcon => {
    switch (choiceIcon) {
      case "lock":
        return <Lock color={colorIcon} />
      case "user":
        return <User color={colorIcon} />
      default:
        break
    }
  }

  return (
    <>
      {!icon && (
        <>
          {label && (
            <label className={css(styles.label, labelDynamicStyle)} htmlFor={keyInput}>
              {label}
            </label>
          )}
          <div className={css(styles.containerInput)}>
            <input
              placeholder={placeholder}
              value={value}
              type={type}
              min={min}
              id={keyInput ?? ""}
              maxLength={maxLength}
              onKeyDown={onKeyDown}
              style={{ marginTop: label ? 5 : 0 }}
              autoFocus={autofocus}
              className={css(styles.input, dynamicStyle)}
              disabled={disabled}
              {...props}
            />
            {camera && <p className={css(styles.camera)}>{camera}</p>}
          </div>
        </>
      )}
      {icon && (
        <>
          {label && (
            <label className={css(styles.label, labelDynamicStyle)} htmlFor={keyInput}>
              {label}
            </label>
          )}
          <div className={css(styles.containerInput)}>
            <input
              placeholder={placeholder}
              value={value}
              type={type}
              onKeyDown={onKeyDown}
              min={min}
              id={keyInput ?? ""}
              autoFocus={autofocus}
              className={css(styles.inputWithIcon, dynamicStyle)}
              disabled={disabled}
              {...props}
            />

            <div
              className={css(styles.containerIcon)}
              style={{
                right: iconDirection === "right" ? 10 : null,
                left: iconDirection === "left" ? 10 : null,
              }}>
              {iconChoice(icon)}
            </div>
          </div>
        </>
      )}
    </>
  )
}

const getStyles = ({ isError, iconDirection, colorIcon, themeStyles }) =>
  StyleSheet.create({
    containerInput: {
      position: "relative",
      boxSizing: "border-box",
    },
    label: {
      fontSize: 10,
      fontWeight: 300,
      margin: 0,
    },
    input: {
      width: "100%",
      height: 40,
      fontFamily: "Roboto",
      boxSizing: "border-box",
      position: "relative",
      backgroundColor: WHITE,
      paddingLeft: 10,
      paddingRight: 10,
      border: isError
        ? `1px solid ${themeStyles.ERROR_COLOR || ERROR_COLOR}`
        : `1px solid ${themeStyles?.BORDER_INPUT_COLOR}`,
      borderRadius: 5,
      color: isError ? themeStyles.ERROR_COLOR : themeStyles?.PRIMARY_COLOR,
      "::placeholder": {
        fontWeight: "400",
        opacity: 0.6,
      },
      ":focus": {
        outline: `1.5px solid ${themeStyles?.PRIMARY_COLOR}`,
      },
      ":active": {
        outline: `1.5px solid ${themeStyles?.PRIMARY_COLOR}`,
      },
      ":disabled": {
        opacity: "0.6",
      },
    },
    inputWithIcon: {
      width: "100%",
      boxSizing: "border-box",
      height: 40,
      backgroundColor: WHITE,
      fontFamily: "Roboto",
      border: isError
        ? `0.5px solid ${themeStyles?.ERROR_COLOR}`
        : `1px solid ${themeStyles?.BORDER_INPUT_COLOR}`,
      padding: iconDirection === "right" ? "0px 35px 0px 15px" : "0px 15px 0px 35px",
      borderRadius: 5,
      color: isError ? themeStyles?.ERROR_COLOR : themeStyles?.PRIMARY_COLOR,
      "::placeholder": {
        fontWeight: "600",
        opacity: 0.25,
        textAlign: "center",
      },
      ":focus": {
        outline: `1.5px solid ${themeStyles?.PRIMARY_COLOR}`,
      },
      ":active": {
        outline: `1.5px solid ${themeStyles?.PRIMARY_COLOR}`,
      },
      ":disabled": {
        opacity: "0.6",
      },
    },
    containerIcon: {
      position: "absolute",
      margin: 0,
      top: 0,
      right: 0,
      display: "flex",
      height: 40,
      borderRadius: 5,
      zIndex: 2,
      width: "fit-content",
      justifyContent: "center",
      alignItems: "center",
    },
    btnIcon: {
      background: "transparent",
      border: "none",
      marginRight: 5,
    },
    btnClose: {
      border: "none",
      background: "transparent",
      position: "absolute",
      top: 11,
      right: 5,
      width: "fit-content",
    },
    camera: {
      position: "absolute",
      top: 0,
      right: 5,
      margin: 0,
      height: "100%",
      display: "flex",
      fontSize: 12,
      fontWeight: "600",
      alignItems: "center",
      color: colorIcon,
    },
  })

UIInput.propTypes = {
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  type: PropTypes.oneOf(["text", "password", "email", "tel", "date", "number", "textarea"]),
  placeholder: PropTypes.string,
  disabled: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  valid: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  isError: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  textBtn: PropTypes.string,
  enterkeyhint: PropTypes.oneOf(["enter", "done", "go", "next", "previous", "search", "send"]),
  onKeyDown: PropTypes.func,
  autofocus: PropTypes.bool,
  icon: PropTypes.string,
  button: PropTypes.bool,
  keyInput: PropTypes.string,
  kindBtn: PropTypes.string,
  typeBtn: PropTypes.func,
  iconDirection: PropTypes.string,
  inputId: PropTypes.string,
  fullHeight: PropTypes.bool,
  onClickIcon: PropTypes.func,
  eyeActive: PropTypes.bool,
  labelDynamicStyle: PropTypes.array,
  dynamicStyle: PropTypes.array,
  maxLength: PropTypes.number,
  close: PropTypes.bool,
  onClose: PropTypes.func,
  colorIcon: PropTypes.string,
  min: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  camera: PropTypes.string,
}
