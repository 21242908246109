import * as React from "react"

const Save = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="none">
    <path
      fill="#fff"
      d="M4 0v3.333a2 2 0 0 0 2 2h2.667a2 2 0 0 0 2-2V0h.505c.707 0 1.385.281 1.885.781l2.162 2.162c.5.5.78 1.178.781 1.885v8.505A2.667 2.667 0 0 1 13.333 16v-6a2 2 0 0 0-2-2H4.667c-1.103 0-2 .892-2 1.997V16A2.667 2.667 0 0 1 0 13.333V2.667A2.667 2.667 0 0 1 2.667 0H4Zm1.333 0v3.333A.667.667 0 0 0 6 4h2.667a.667.667 0 0 0 .666-.667V0h-4ZM4 16h8v-6a.666.666 0 0 0-.667-.667H4.667A.665.665 0 0 0 4 9.997V16Z"
    />
  </svg>
)

export default Save
