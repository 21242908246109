import * as THREE from 'three'

function computeGlobalBoundingBox(object) {
  const boundingBox = new THREE.Box3()

  object.traverse(child => {
    if (child instanceof THREE.Mesh) {
      child.geometry.computeBoundingBox()
      boundingBox.expandByObject(child, true)
    }
  })

  return boundingBox
}

function computeGlobalBoundingSphere(object) {
  const boundingSphere = new THREE.Sphere()
  computeGlobalBoundingBox(object).getBoundingSphere(boundingSphere)
  return boundingSphere
}

function getCameraDistanceFromSphere(sphere, fillRatio, halfFov) {
  var distance = sphere.radius / Math.sin(halfFov)
  const distToTangentPoint = sphere.radius / Math.tan(halfFov)
  const projectionOfDistToTangentPoint = distToTangentPoint * Math.cos(halfFov)

  distance += ((1 - fillRatio) / fillRatio) * projectionOfDistToTangentPoint

  return distance
}

function getViewFillData(root, camera, horizontalFillRatio, verticalFillRatio) {
  var verticalStartDistance = 0
  var horizontalStartDistance = 0
  var startDistance

  root.updateWorldMatrix(true, true)
  const minimalSphere = computeGlobalBoundingSphere(root)

  // Calculation of camera distance to fit the object vertically in the view with some space
  // around.
  const verticalHalfFovInRadians = THREE.MathUtils.degToRad(camera.fov / 2)
  verticalStartDistance = getCameraDistanceFromSphere(
    minimalSphere,
    verticalFillRatio,
    verticalHalfFovInRadians,
  )

  // Calculation of camera distance to fit the object horizontally in the view with some
  // space around for the hotspots.
  const horizontalHalfFovInRadians = Math.atan(Math.tan(verticalHalfFovInRadians) * camera.aspect)
  horizontalStartDistance = getCameraDistanceFromSphere(
    minimalSphere,
    horizontalFillRatio,
    horizontalHalfFovInRadians,
  )

  startDistance = Math.max(verticalStartDistance, horizontalStartDistance)

  return { boundingSphere: minimalSphere, startDistance }
}

export {
  computeGlobalBoundingBox,
  computeGlobalBoundingSphere,
  getCameraDistanceFromSphere,
  getViewFillData,
}
