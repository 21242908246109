import { Pagination } from "@mui/material"
import { css, StyleSheet } from "aphrodite"
import React, { useCallback, useLayoutEffect, useMemo, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { useLocation, useNavigate, useOutletContext, useSearchParams } from "react-router-dom"
import { useEvent } from "react-use"
import { ChevronDynamique } from "../../assets/svgr"
import { useTheme } from "../../components/Provider/ThemeProvider"
import { UIButton } from "../../components/UI"
import { LogoutHeader } from "../../components/Widget/LogoutHeader"
import { useManualsPage } from "../../hooks/useManualsPage"
import { NEUTRAL_LIGHT, WHITE } from "../../styles/colors"
import { ManualCard } from "./ManualCard"
import { ManualModal } from "./ManualModal"

export const ManualsPage = () => {
  // states --------------------------------------------------------------------
  const [brandSelected, setBrandSelected] = useState({ value: "", label: "" })
  const [openSelect, setOpenSelect] = useState(false)

  // others hooks --------------------------------------------------------------
  const { t } = useTranslation("manualsPage")
  const { themeStyles } = useTheme()
  const navigate = useNavigate()
  const location = useLocation()
  const [params, setSearchParams] = useSearchParams()
  const divMenu = useRef()
  const { modalHidden } = useOutletContext()

  const {
    brandsDb,
    manuals,
    fetchBrandsByLabel,
    brandsByLabelDb,
    createdNewManual,
    paginationState,
    changeCurrentPage,
    first,
    showPopIn,
    handleModal,
    loadingCreate,
    loadingFethData,
  } = useManualsPage()

  // init style ----------------------------------------------------------------
  const styles = useMemo(() => getStyles({ theme: themeStyles }), [themeStyles])

  const handleClickOutside = useCallback(event => {
    if (divMenu.current && !divMenu.current.contains(event.target)) {
      setOpenSelect(false)
    }
  }, [])

  useEvent("mousedown", handleClickOutside)

  useLayoutEffect(() => {
    if (params.get("brand") && !brandSelected.value && brandsDb) {
      const item = brandsDb.find(brand => brand.value === params.get("brand"))
      if (!item) {
        return
      }
      setBrandSelected({
        label: item.label,
        value: item.value,
      })
    }
  }, [location, brandsDb])

  return (
    <section className={css(styles.container)}>
      <header className={css(styles.header)}>
        <div className={css(styles.titleContainer)}>
          <div ref={divMenu} className={css(styles.flex)}>
            <h1 className={css(styles.title)}>{t("title")}</h1>

            <div style={{ position: "relative" }}>
              <button
                onClick={() => setOpenSelect(prev => !prev)}
                type="button"
                aria-label="open"
                className={css(styles.select)}>
                {brandSelected?.label || t("all_brands")}
                <ChevronDynamique state={openSelect ? "up" : "down"} color={themeStyles?.PRIMARY_COLOR} />
              </button>
              {openSelect && !modalHidden && (
                <div className={css(styles.selectContainer)}>
                  {params.get("brand") && (
                    <button
                      type="button"
                      aria-label="all brands"
                      className={css(styles.oneItemSelect)}
                      onClick={() => {
                        setBrandSelected({ label: "", value: "" })
                        navigate("/manuals?page=1")
                        setOpenSelect(false)
                      }}>
                      <p style={{ margin: 0 }}>{t("all_brands")}</p>
                    </button>
                  )}
                  {brandsDb?.map(brand => (
                    <button
                      type="button"
                      aria-label="brand's name"
                      className={css(
                        styles.oneItemSelect,
                        brand?.value === brandSelected?.value && styles.brandSelectedItem,
                      )}
                      key={brand?.value}
                      onClick={() => {
                        setBrandSelected({ label: brand?.label, value: brand?.value })
                        setSearchParams({ brand: brand?.value, page: 1 })
                        setOpenSelect(false)
                      }}>
                      <p style={{ margin: 0 }}>
                        {brand?.label}
                        {brand?.nbManuals > 0 && (
                          <span
                            style={{
                              fontSize: 12,
                              fontWeight: "800",
                              fontFamily: "Roboto",
                            }}>{` (${brand?.nbManuals})`}</span>
                        )}
                      </p>
                    </button>
                  ))}
                </div>
              )}
            </div>
          </div>
          <UIButton
            dynamicStyle={[styles.addBtn]}
            kind="primary"
            label={t("add_manual")}
            themeStyle={themeStyles}
            onClick={() => handleModal()}
          />
        </div>

        <LogoutHeader themeStyles={themeStyles} />
      </header>
      <main>
        <div className={css(styles.grid)}>
          {manuals[0] &&
            paginationState.totalCount > 0 &&
            manuals?.map((manual, index) => (
              <ManualCard
                key={index}
                onClick={() => navigate(`/manuals/manager?manual=${manual.id}`)}
                title={manual?.productName ?? "-"}
                img={manual?.imgSrc}
                theme={themeStyles}
              />
            ))}
        </div>

        {!loadingFethData && paginationState.totalCount === 0 && (
          <div className={css(styles.containerNotFound)}>
            {!brandSelected?.label ? (
              <>
                <p className={css(styles.textNotFound)}>{t("manuals_all_brands_not_found")}</p>
                <p className={css(styles.textNotFound)}>{t("manuals_all_brands_not_found_info")}</p>
              </>
            ) : (
              <>
                <p className={css(styles.textNotFound)}>{t("manuals_brand_not_found")}</p>
                <p className={css(styles.textNotFound)}>{t("manuals_brand_not_found_info")}</p>
              </>
            )}
          </div>
        )}
      </main>

      <ManualModal
        themeStyles={themeStyles}
        fetchBrandsByLabel={fetchBrandsByLabel}
        brandsDb={brandsByLabelDb}
        closeModal={() => handleModal()}
        isOpen={showPopIn}
        loadingCreate={loadingCreate}
        onSave={data => createdNewManual(data)}
      />

      <footer className={css(styles.paginationContainer)}>
        {paginationState.totalCount > 0 && brandsDb?.length > 0 && (
          <Pagination
            size="small"
            page={paginationState?.currentPage}
            count={Math.ceil(paginationState?.totalCount / first)}
            onChange={(e, value) => changeCurrentPage(value)}
            color="primary"
            shape="rounded"
          />
        )}
      </footer>
    </section>
  )
}

const getStyles = ({ theme }) =>
  StyleSheet.create({
    container: {
      background: theme.BACKGROUND_COLOR,
      width: "100%",
      padding: "30px",
      fontFamily: "Roboto",
      boxSizing: "border-box",
      height: "100dvh",
      overflow: "auto",
      "::-webkit-scrollbar": {
        width: 4,
      },
    },
    title: {
      fontWeight: "600",
      fontSize: 20,
      color: theme.PRIMARY_COLOR,
      margin: "0px 5px 0 0",
      "@media (max-width: 1125px)": {
        fontSize: 16,
      },
    },
    titleContainer: {
      display: "flex",
      gap: 20,
      justifyContent: "space-between",
      alignItems: "center",
    },
    header: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      marginBottom: 40,
      gap: 30,
    },
    oneItemSelect: {
      color: theme.PRIMARY_COLOR,
      background: "none",
      border: "none",
      textAlign: "left",
      fontSize: 14,
      boxSizing: "border-box",
      padding: "5px 10px",
      cursor: "pointer",
      width: "-webkit-fill-available",
      textTransform: "capitalize",
      ":hover": {
        background: NEUTRAL_LIGHT,
      },
    },
    selectContainer: {
      position: "absolute",
      left: 0,
      right: "auto",
      top: 35,
      background: WHITE,
      padding: "10px 0px",
      borderRadius: 3,
      boxSizing: "border-box",
      border: `2px solid ${NEUTRAL_LIGHT}`,
      minWidth: "-webkit-fill-available",
      width: "max-content",
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      maxHeight: "30dvh",
      overflow: "auto",
      zIndex: 100,
      "::-webkit-scrollbar": {
        width: 0,
      },
      "@media (max-width: 1125px)": {
        top: 30,
      },
    },
    brandSelectedItem: {
      textDecorationLine: "underline",
      fontWeight: "800",
    },
    grid: {
      display: "grid",
      gridAutoRows: "minmax(200px, auto)",
      gridTemplateColumns: "repeat(auto-fill, minmax(200px, 1fr))",
      gap: 15,
      boxSizing: "border-box",
    },
    flex: { display: "flex", gap: 5, alignItems: "center", position: "relative" },
    addBtn: {
      minWidth: 145,
      fontSize: 13,
      height: 35,
      padding: "0px 10px",
      fontFamily: "Roboto",
    },
    select: {
      fontWeight: "600",
      fontSize: 20,
      color: theme.PRIMARY_COLOR,
      margin: 0,
      background: "none",
      border: "none",
      textDecorationLine: "underline",
      textTransform: "uppercase",
      display: "flex",
      gap: 10,
      alignItems: "center",
      cursor: "pointer",
      fontFamily: "Roboto",
      "@media (max-width: 1125px)": {
        fontSize: 16,
      },
      ":disabled": {
        opacity: "0.6",
        cursor: "default",
      },
    },
    containerNotFound: {
      padding: "20px 10px 10px 0",
      lineHeight: 1.5,
    },
    textNotFound: {
      margin: 0,
      fontSize: 14,
      fontStyle: "Roboto",
      fontWeight: "400",
      color: theme.PRIMARY_COLOR,
    },
    paginationContainer: {
      display: "flex",
      justifyContent: "flex-end",
      paddingLeft: "10px",
      paddingTop: 15,
    },
    elementNumber: {
      fontSize: "12px",
      color: "rgba(0, 0, 0, 0.5)",
      margin: 0,
    },
  })
