import { useEffect, useRef } from "react"
import { TransformControls } from "three/addons/controls/TransformControls.js"

export const useTransformControls = (viewerRef, isEditMode) => {
  const transformControls = useRef()
  useEffect(addTransformControls, [viewerRef, isEditMode])

  return transformControls

  function addTransformControls() {
    if (isEditMode) {
      transformControls.current = new TransformControls(
        viewerRef.current.world.camera,
        viewerRef.current.canvas,
      )
      viewerRef.current.scene.add(transformControls.current)
    }

    return cleanControls

    function cleanControls() {
      if (transformControls.current) {
        transformControls.current.dispose()
        transformControls.current = null
      }
    }
  }
}
