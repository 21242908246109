import PropTypes from "prop-types"
import * as React from "react"

const ChevronDynamique = ({ state, color = "#000" }) =>
  state === "down" ? (
    <svg xmlns="http://www.w3.org/2000/svg" width={15} height={12} fill="none">
      <path fill={color} d="m.5.5 7 11 7-11H.5Z" />
    </svg>
  ) : (
    <svg xmlns="http://www.w3.org/2000/svg" width={15} height={12} fill="none">
      <path fill={color} d="m14.5 11.5-7-11-7 11h14Z" />
    </svg>
  )

ChevronDynamique.propTypes = {
  state: PropTypes.string,
  color: PropTypes.string,
}

export default ChevronDynamique
