import * as Sentry from "@sentry/react"
import { checkEnv } from "../lib"
const config = checkEnv()

// fetch params -----------------------------------------------------------------------
const handleError = (error, request) => {
  Sentry.withScope(scope => {
    scope.setExtra("request", request)
    scope.setExtra("component", "src/api/index.js")
    Sentry.captureException(error)
  })
  throw error
}

const withHeaderAccessToken = async (method, path, fetchName, body, headers) => {
  const url = `${config.BASE_URL}${path}`
  const response = await fetch(url, {
    method,
    headers: new Headers({
      Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      ...(headers && { ...headers }),
    }),
    body,
  })

  if (!response.ok) {
    if (response.status !== 404) {
      const error = await response.json()
      handleError(error, { request: fetchName, path })
    }
  }

  return response
}

const withHeaderRefreshToken = async (method, path, fetchName) => {
  const url = `${config.BASE_URL}${path}`
  const response = await fetch(url, {
    method,
    headers: new Headers({
      Authorization: `Bearer ${localStorage.getItem("refresh_token")}`,
    }),
  })

  if (!response.ok) {
    const error = await response.json()
    handleError(error, { request: fetchName, path })
  }

  return response.json()
}

// fetch func ------------------------------------------------------------------

const refreshAccessToken = async () => {
  return await withHeaderRefreshToken("GET", "/token", "refreshAccessToken")
}

// manual ----------------------------------------------------------------------
const postManualFile = async (idManual, file, filename) => {
  if (!file) return
  const formData = new FormData()
  formData.append("asset", file)
  formData.append("filename", filename)
  const url = `/manuals/${idManual}/image`
  return await withHeaderAccessToken("POST", url, "postManualFile", formData)
}

async function fetchManualFile(idManual) {
  if (idManual === undefined) return
  const url = `/manuals/${idManual}/image`
  return await withHeaderAccessToken("GET", url, "fetchManualFile")
}

// parts -----------------------------------------------------------------------
const postPart = async (idPart, file) => {
  if (!file) return
  const formData = new FormData()
  formData.append("asset", file)
  const url = `/parts/${idPart}/image?lang=en`
  return await withHeaderAccessToken("POST", url, "postPart", formData)
}

const postPartTranslation = async (idPart, translations) => {
  const url = `/parts/${idPart}/translations`
  const headers = { "Content-Type": "application/json" }
  const body = JSON.stringify(translations)
  return await withHeaderAccessToken("POST", url, "postPartTranslation", body, headers)
}

// tools -----------------------------------------------------------------------
const postTool = async (idTool, file) => {
  if (!file) return
  const formData = new FormData()
  formData.append("asset", file)

  const url = `/tools/${idTool}/image?lang=en`
  return await withHeaderAccessToken("POST", url, "postTool", formData)
}
const postToolTranslation = async (idTool, translations) => {
  const url = `/tools/${idTool}/translations`
  const headers = { "Content-Type": "application/json" }
  const body = JSON.stringify(translations)
  return await withHeaderAccessToken("POST", url, "postToolTranslation", body, headers)
}

// pois ------------------------------------------------------------------------
const postPicturePoi = async (idPoi, file) => {
  if (!file) return

  const formData = new FormData()
  formData.append("asset", file)

  const url = `/pois/${idPoi}/image?lang=en`
  return await withHeaderAccessToken("POST", url, "postPicturePoi", formData)
}

const postVideoPoi = async (idPoi, file) => {
  if (!file) return

  const formData = new FormData()
  formData.append("asset", file)

  const url = `/pois/${idPoi}/video?lang=en`
  return await withHeaderAccessToken("POST", url, "postVideoPoi", formData)
}

const postThumbnailPoi = async (idPoi, file) => {
  if (!file) return

  const formData = new FormData()
  formData.append("asset", file)
  const url = `/pois/${idPoi}/thumbnail?lang=en`
  return await withHeaderAccessToken("POST", url, "postThumbnailPoi", formData)
}

const postPoiTranslation = async (idPoi, translations) => {
  const url = `/pois/${idPoi}/translations`
  const headers = { "Content-Type": "application/json" }
  const body = JSON.stringify(translations)
  return await withHeaderAccessToken("POST", url, "postPoiTranslation", body, headers)
}

// 3d --------------------------------------------------------------------------
const postModel = async (idModel, file) => {
  if (!file) return

  const formData = new FormData()
  formData.append("asset", file)

  const url = `/models/${idModel}/file`
  return await withHeaderAccessToken("POST", url, "postModel", formData)
}

const postModelThumbnail = async (idModel, file) => {
  if (!file) return

  const formData = new FormData()
  formData.append("asset", file)
  const url = `/models/${idModel}/thumbnail`
  return await withHeaderAccessToken("POST", url, "postModelThumbnail", formData)
}

// translations ----------------------------------------------------------------
const fetchManualTranslation = async (idManual, lang, acceptType) => {
  const url = `/manuals/${idManual}/translationfiles?lang=${lang}`
  const headers = { Accept: acceptType }
  return await withHeaderAccessToken("GET", url, "fetchManualTranslation", null, headers)
}

const postManualTranslation = async (idManual, file, lang) => {
  if (!file) return

  const formData = new FormData()
  formData.append("translations", file)
  const url = `/manuals/${idManual}/translationfiles?lang=${lang}`
  return await withHeaderAccessToken("POST", url, "postManualTranslation", formData)
}

// childStepPoi ----------------------------------------------------------------
// if poi in model, then childStepPoi. Countain description, external link, ...
const postChildStepPoiTranslation = async (idStepPoi, translations) => {
  const url = `/step_poi/${idStepPoi}/translations`
  const headers = { "Content-Type": "application/json" }
  const body = JSON.stringify(translations)
  return await withHeaderAccessToken("POST", url, "postChildStepPoiTranslation", body, headers)
}

export default {
  refreshAccessToken,
  fetchManualFile,
  fetchManualTranslation,
  postManualTranslation,
  postManualFile,
  postChildStepPoiTranslation,
  postPart,
  postTool,
  postPicturePoi,
  postPoiTranslation,
  postPartTranslation,
  postVideoPoi,
  postThumbnailPoi,
  postToolTranslation,
  postModelThumbnail,
  postModel,
}
