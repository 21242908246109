import * as React from "react"

const AddStack = () => (
  <>
    <svg xmlns="http://www.w3.org/2000/svg" width={12} height={12} fill="none">
      <path
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="m1 8.47 5 2.5 5-2.5M1 5.97l5 2.5 5-2.5m-5-5-5 2.5 5 2.5 5-2.5-5-2.5Z"
      />
    </svg>
    <svg xmlns="http://www.w3.org/2000/svg" width={8} height={8} fill="none">
      <path
        fill="#fff"
        d="M4.668 7.53a.564.564 0 0 0 .039-.22V4.682h2.628a.564.564 0 0 0 0-1.128H4.707V.925a.564.564 0 0 0-1.129 0v2.627H.951a.564.564 0 0 0 0 1.129h2.627V7.31a.564.564 0 0 0 1.09.22Z"
      />
    </svg>
  </>
)

export default AddStack
