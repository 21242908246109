import { gql } from "@apollo/client"
import { ASSET_TYPE_DATA, PART_DATA, POI_DATA, TOOL_DATA } from "./fragments"

export const ASSETS_TYPES = gql`
  query assetTypes {
    assetTypes {
      nodes {
        id
        label
      }
    }
  }
`

export const LIBRARY_ASSETS = gql`
  query Libraries($id: UUID, $first: Int, $offset: Int) {
    libraries(filter: { idAssetType: { equalTo: $id } }, first: $first, offset: $offset) {
      nodes {
        id
        filename
        usage
      }
      totalCount
    }
  }
`

// parts -----------------------------------------------------------------------
export const ASSETS_PARTS = gql`
  ${PART_DATA}
  query UsageParts($id: UUID!, $first: Int, $offset: Int) {
    usageParts(filter: { idAssetType: { equalTo: $id } }, first: $first, offset: $offset) {
      nodes {
        id
        partByIdPart {
          ...PartData
        }
      }
      totalCount
    }
  }
`

export const USAGE_PART = gql`
  ${PART_DATA}
  query UsageParts($id: UUID!) {
    usageParts(filter: { idPart: { equalTo: $id } }) {
      nodes {
        id
        partByIdPart {
          ...PartData
        }
      }
    }
  }
`

export const CREATE_PART = gql`
  mutation CreatePart {
    createPart(input: { part: {} }) {
      part {
        id
      }
    }
  }
`

export const CREATE_USAGE_PART = gql`
  mutation CreateUsagePart($idAssetType: UUID!, $idPart: UUID!) {
    createUsagePart(input: { usagePart: { idAssetType: $idAssetType, idPart: $idPart } }) {
      clientMutationId
    }
  }
`

// tools -----------------------------------------------------------------------
export const ASSETS_TOOLS = gql`
  ${TOOL_DATA}
  query UsageTools($id: UUID!, $first: Int, $offset: Int) {
    usageTools(filter: { idAssetType: { equalTo: $id } }, first: $first, offset: $offset) {
      nodes {
        id
        toolByIdTool {
          ...ToolData
        }
      }
      totalCount
    }
  }
`

export const USAGE_TOOL = gql`
  ${TOOL_DATA}
  query UsageTools($id: UUID!) {
    usageTools(filter: { idTool: { equalTo: $id } }) {
      nodes {
        id
        toolByIdTool {
          ...ToolData
        }
      }
    }
  }
`

export const CREATE_TOOL = gql`
  mutation CreateTool {
    createTool(input: { tool: {} }) {
      tool {
        id
      }
    }
  }
`

export const CREATE_USAGE_TOOL = gql`
  mutation CreateUsageTool($idAssetType: UUID!, $idTool: UUID!) {
    createUsageTool(input: { usageTool: { idAssetType: $idAssetType, idTool: $idTool } }) {
      clientMutationId
    }
  }
`

// pois ------------------------------------------------------------------------
export const GET_DEFAULT_POI = gql`
  query DefaultPoi {
    getDefaultPoi
  }
`

export const ASSETS_POIS = gql`
  ${ASSET_TYPE_DATA}
  ${POI_DATA}
  query UsagePois($first: Int, $offset: Int) {
    usagePois(first: $first, offset: $offset) {
      nodes {
        id
        assetTypeByIdAssetType {
          ...AssetTypeData
        }
        poiByIdPoi {
          ...PoiData
        }
      }
      totalCount
    }
  }
`
export const CREATE_POI = gql`
  mutation CreatePoi($filename: String) {
    createPoi(input: { poi: { filename: $filename } }) {
      poi {
        id
      }
    }
  }
`
export const CREATE_USAGE_POI = gql`
  mutation CreateUsagePoi($idAssetType: UUID!, $idPoi: UUID!) {
    createUsagePoi(input: { usagePoi: { idAssetType: $idAssetType, idPoi: $idPoi } }) {
      clientMutationId
    }
  }
`

export const UPDATE_POI = gql`
  mutation UpdatePoi($filename: String, $id: UUID!) {
    updatePoi(input: { patch: { filename: $filename }, id: $id }) {
      clientMutationId
    }
  }
`
