import * as React from "react"

const WarningRoundYellow = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} fill="none">
    <path
      fill="#FFD666"
      fillRule="evenodd"
      d="M10 20c5.523 0 10-4.477 10-10S15.523 0 10 0 0 4.477 0 10s4.477 10 10 10Zm3.502-15.354a.5.5 0 0 0-.707 0l-1.482 1.482-5.926 5.926a.498.498 0 0 0-.13.225l-.738 2.584a.5.5 0 0 0 .618.618l2.584-.739a.497.497 0 0 0 .225-.13l5.926-5.925 1.482-1.482a.5.5 0 0 0 0-.707l-1.852-1.852Zm-6.864 9.366-.65-.65-.26.91.91-.26Zm-.19-1.605 1.145 1.145 5.218-5.219-1.144-1.144-5.22 5.218Zm5.926-5.926 1.144 1.145.775-.774-1.145-1.145-.774.774Z"
      clipRule="evenodd"
    />
  </svg>
)
export default WarningRoundYellow
