import * as React from "react"

const PoiType2 = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width={48} height={48} fill="none">
    <circle cx={24} cy={24} r={10} fill="#1A2A34" fillOpacity={0.02} />
    <g filter="url(#a)">
      <circle cx={24} cy={24} r={14} fill="#FFEA28" />
    </g>
    <path fill="#1A2A34" d="M23.242 28.102V20h1.612v8.102h-1.612ZM20 24.854v-1.612h8.102v1.612H20Z" />
    <defs>
      <filter
        id="a"
        width={42}
        height={42}
        x={3}
        y={3}
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse">
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset />
        <feGaussianBlur stdDeviation={3.5} />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0" />
        <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_4805_450" />
        <feBlend in="SourceGraphic" in2="effect1_dropShadow_4805_450" result="shape" />
      </filter>
    </defs>
  </svg>
)

export default PoiType2
