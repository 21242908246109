import { css, StyleSheet } from "aphrodite"
import { PropTypes } from "prop-types"
import React from "react"

const UICheckboxInput = ({
  label,
  checked,
  value,
  type,
  borderColor,
  onChange,
  dynamicStyle,
  labelDynamicStyle,
  containerDynamicStyle,
  name,
  ...props
}) => {
  const styles = getStyles({ borderColor })

  return (
    <div className={css(styles.checkboxContainer, containerDynamicStyle)}>
      <input
        {...props}
        name={name}
        value={value}
        checked={checked}
        onChange={onChange}
        className={css(styles.checkboxInput, dynamicStyle)}
        type={type}
      />

      <p className={css(styles.checkboxLabel, labelDynamicStyle)}>{label}</p>
    </div>
  )
}
const getStyles = ({ borderColor }) =>
  StyleSheet.create({
    checkboxInput: {
      width: "16px",
      height: "16px",
      borderColor,
      margin: 0,
    },
    checkboxLabel: {
      margin: 0,
      fontWeight: 600,
      fontSize: "12px",
    },
    checkboxContainer: {
      display: "flex",
      gap: 5,
      margin: 0,
      alignItems: "center",
    },
  })

UICheckboxInput.propTypes = {
  label: PropTypes.string,
  checked: PropTypes.bool,
  value: PropTypes.string,
  onChange: PropTypes.func,
  dynamicStyle: PropTypes.object,
  labelDynamicStyle: PropTypes.object,
  containerDynamicStyle: PropTypes.object,
  borderColor: PropTypes.string,
  type: PropTypes.string,
  name: PropTypes.string,
}

export { UICheckboxInput }
