import PropTypes from "prop-types"
import * as React from "react"

const Home = ({ color }) => (
  <svg viewBox="0 0 20 20" height={20} width={20} xmlns="http://www.w3.org/2000/svg" fill="none">
    <g transform="translate(2, 2)">
      <path
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        transform="matrix(0.689655,0,0,0.689655,0,0)"
        d="M9 22V12h6v10M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V9Z"
      />
    </g>
  </svg>
)

Home.propTypes = {
  color: PropTypes.string,
}

export default Home
