import * as TWEEN from '@tweenjs/tween.js'
import * as THREE from 'three'
import POI_INFOS_IMAGE from '../../assets/textures/poi_infos.png'
import POI_WARNING_IMAGE from '../../assets/textures/poi_warning.png'
import PULSE_IMAGE from '../../assets/textures/pulse.png'
import AssetManager from './AssetManager.mjs'
import Interactable from './Interactable.mjs'
import { createPixelSprite } from './UIUtils.mjs'

const POI_TYPES = {
  INFOS: 'Infos',
  WARNING: 'Warning',
}
Object.freeze(POI_TYPES)

const assetManager = new AssetManager()
assetManager.addLoaders({ Texture: THREE.TextureLoader })

class Poi extends Interactable {
  _type
  _poiUpdateTween
  _pulseUpdateTween
  _raycastTarget = null

  static get TYPES() {
    return POI_TYPES
  }

  get raycastTargets() {
    return [this._raycastTarget]
  }

  static parseType(typeStr) {
    switch (typeStr.toLowerCase()) {
      case 'info':
      case POI_TYPES.INFOS.toLowerCase():
        return POI_TYPES.INFOS
      case POI_TYPES.WARNING.toLowerCase():
        return POI_TYPES.WARNING
      default:
        throw new Error('POI_TYPES parsing error')
    }
  }

  constructor(type) {
    super()
    if (!Object.values(POI_TYPES).includes(type)) {
      throw new Error('The provided type ' + type + ' is not a valid type for an instance of POI')
    }

    this._type = type
  }

  async init() {
    const poiWidth = 28
    const poiHeight = 28
    var poiMapPath

    switch (this._type) {
      case POI_TYPES.INFOS:
        poiMapPath = POI_INFOS_IMAGE
        break
      case POI_TYPES.WARNING:
        poiMapPath = POI_WARNING_IMAGE
        break
    }

    const loadedAssets = await assetManager.loadMultiple([poiMapPath, PULSE_IMAGE], {
      type: 'Texture',
    })

    const poiMap = loadedAssets.get(poiMapPath)
    poiMap.colorSpace = THREE.SRGBColorSpace
    // poiMap.premultiplyAlpha = true
    poiMap.generateMipmaps = false
    poiMap.needsUpdate = true

    const pulseMap = loadedAssets.get(PULSE_IMAGE)
    pulseMap.colorSpace = THREE.SRGBColorSpace
    // pulseMap.premultiplyAlpha = true
    pulseMap.generateMipmaps = false
    pulseMap.needsUpdate = true

    const poiSprite = createPixelSprite(poiWidth, poiHeight, {
      transparent: true,
      // premultipliedAlpha: true,
      map: poiMap,
      depthWrite: false,
    })
    const pulseSprite = createPixelSprite(20, 20, {
      transparent: true,
      // premultipliedAlpha: true,
      map: pulseMap,
      opacity: 0.02,
      depthWrite: false,
    })

    this._root.add(poiSprite)
    this._root.add(pulseSprite)
    poiSprite.position.set(0, 0, 0)
    pulseSprite.position.set(0, 0, 0)

    poiSprite.renderOrder = 1
    pulseSprite.renderOrder = 0

    this._raycastTarget = poiSprite
    poiSprite.name = 'poi'
    pulseSprite.name = 'pulse'

    const poiTweenStep1 = new TWEEN.Tween(poiSprite).to(
      { userData: { width: 32, height: 32 } },
      100,
    )
    const poiTweenStep2 = new TWEEN.Tween(poiSprite).to(
      { userData: { width: 28, height: 28 } },
      500,
    )
    const poiTweenStep3 = new TWEEN.Tween(poiSprite).to(
      { userData: { width: 28, height: 28 } },
      300,
    )
    poiTweenStep1.chain(poiTweenStep2)
    poiTweenStep2.chain(poiTweenStep3)
    poiTweenStep3.chain(poiTweenStep1)
    this._poiUpdateTween = poiTweenStep1
    this._poiUpdateTween.delay(100)

    const pulseTweenStep1 = new TWEEN.Tween(pulseSprite).to(
      { userData: { width: 34, height: 34 }, material: { opacity: 0.4 } },
      100,
    )
    const pulseTweenStep2 = new TWEEN.Tween(pulseSprite).to(
      { userData: { width: 48, height: 48 }, material: { opacity: 0.4 } },
      500,
    )
    const pulseTweenStep3 = new TWEEN.Tween(pulseSprite).to(
      { userData: { width: 20, height: 20 }, material: { opacity: 0.02 } },
      300,
    )
    pulseTweenStep1.chain(pulseTweenStep2)
    pulseTweenStep2.chain(pulseTweenStep3)
    pulseTweenStep3.chain(pulseTweenStep1)
    this._pulseUpdateTween = pulseTweenStep1
    this._pulseUpdateTween.delay(100)
  }

  changeType(newType) {
    if (newType === this._type) {
      return
    }

    this._type = newType
    var poiMapPath

    switch (this._type) {
      case POI_TYPES.INFOS:
        poiMapPath = POI_INFOS_IMAGE
        break
      case POI_TYPES.WARNING:
        poiMapPath = POI_WARNING_IMAGE
        break
    }

    return assetManager.load(poiMapPath, { type: 'Texture' }).then(onMapReady.bind(this))

    function onMapReady(map) {
      if (this._type !== newType) {
        return
      }

      map.colorSpace = THREE.SRGBColorSpace
      // map.premultiplyAlpha = true
      map.generateMipmaps = false
      map.needsUpdate = true

      const poiSprite = this.root.getObjectByName('poi')
      poiSprite.material.map = map
      poiSprite.material.needsUpdate = true
    }
  }

  addToInterfaceManager(interfaceManager) {
    super.addToInterfaceManager(interfaceManager)

    const tweenGroup = interfaceManager.tweenGroup
    addChainedTweensToGroup.call(this, this._poiUpdateTween)
    addChainedTweensToGroup.call(this, this._pulseUpdateTween)

    this._poiUpdateTween.start()
    this._pulseUpdateTween.start()

    function addChainedTweensToGroup(tween) {
      if (tween._group === tweenGroup) {
        return
      }

      tween.group(tweenGroup)

      for (const chainedTween of tween._chainedTweens) {
        addChainedTweensToGroup.call(this, chainedTween)
      }
    }
  }

  removeFromInterfaceManager(interfaceManager) {
    super.removeFromInterfaceManager(interfaceManager)

    this._poiUpdateTween.stop()
    this._pulseUpdateTween.stop()
    this._poiUpdateTween.group(false)
    this._pulseUpdateTween.group(false)
  }
}

export default Poi
