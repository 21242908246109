import { useLazyQuery, useMutation, useQuery } from "@apollo/client"
import { useEffect, useMemo, useState } from "react"
import { useSearchParams } from "react-router-dom"
import api from "../api"
import { useTheme } from "../components/Provider/ThemeProvider"
import {
  ANIMATION_MODES,
  CREATE_CHILD_STEP_ANIMATION,
  CREATE_CHILD_STEP_CAMERA,
  UPDATE_CHILD_STEP_ANIMATION,
  UPDATE_CHILD_STEP_CAMERA,
} from "../graphql/animate"
import { GET_DEFAULT_POI } from "../graphql/assets"
import { CREATE_CHILD_STEP_POI, DELETE_CHILD_STEP_POI, UPDATE_CHILD_STEP_POI } from "../graphql/childStep"
import { CHILD_STEPS_BY_STEP } from "../graphql/step"
import { checkEnv } from "../lib"
import { captureErrorGraphql } from "../lib/sentry"

export const useModel3dPage = () => {
  // states ----------------------------------------------------------------
  const [childStepSelected, setChildStepSelected] = useState()
  const [drawerHighlight, setDrawerHighlight] = useState({
    type: null, // camera, target, poi
    index: null, // index of item
    idPoi: null, // id of item if type is poi
  })
  const { setOverlay } = useTheme()
  const [animatesAvailable, setAnimatesAvailable] = useState([])

  const [data, setData] = useState({
    pois: [],
    camera: {
      drawerOpen: false,
      x: undefined,
      y: undefined,
      z: undefined,
      minZoomDb: undefined,
      maxZoomDb: undefined,
      minZoomModel: undefined,
      maxZoomModel: undefined,
    },
    target: { drawerOpen: false, x: undefined, y: undefined, z: undefined },
    animate: {
      idAnimationMode: undefined,
      animationModeLabel: undefined,
      repeat: undefined,
      name: undefined,
    },
  })

  const [openSelect, setOpenSelect] = useState(false)
  const [loadingSave, setLoadingSave] = useState(false)
  const [saveSuccess, setSaveSuccess] = useState(false)

  // hooks ----------------------------------------------------------------
  const [searchParams, setSearchParams] = useSearchParams()
  const config = useMemo(() => checkEnv(), [])

  // gql query ----------------------------------------------------------------
  const [fetchChildSteps, { data: childsDb }] = useLazyQuery(CHILD_STEPS_BY_STEP, {
    fetchPolicy: "cache-and-network",
    onCompleted: () => {
      checkChildStepSelected()
    },
  })

  const { data: animationModes } = useQuery(ANIMATION_MODES, {
    fetchPolicy: "cache-and-network",
  }) // get animation modes (types)
  const [createChildStepCamera] = useMutation(CREATE_CHILD_STEP_CAMERA)
  const [updateChildStepCamera] = useMutation(UPDATE_CHILD_STEP_CAMERA)
  const [updateChildStepAnimation] = useMutation(UPDATE_CHILD_STEP_ANIMATION)
  const [createChildStepAnimation] = useMutation(CREATE_CHILD_STEP_ANIMATION)
  const [createChildStepPoi] = useMutation(CREATE_CHILD_STEP_POI)
  const [deleteChildStepPoi] = useMutation(DELETE_CHILD_STEP_POI)
  const [updateChildStepPoi] = useMutation(UPDATE_CHILD_STEP_POI)
  const { data: defaultPoiId } = useQuery(GET_DEFAULT_POI)

  // functions ----------------------------------------------------------------
  const fetchLabelsPoisTranslations = async pois => {
    const array = [...pois]
    array.forEach(async (item, index) => {
      const response = await fetch(`${config.BASE_URL}/step_poi/${item.id}/translations?lang=en`)
      if (response.ok) {
        const trads = await response.json()
        const itemUpdate = {
          ...item,
          externalLink: trads.externalLink.en || "",
          labelExternalLink: trads.labelExternalLink.en || "",
          description: trads.description.en || "",
        }
        array[index] = itemUpdate
        setData(prev => ({ ...prev, pois: [...array] }))
      }
    })
  }

  const checkChildStepSelected = () => {
    const item = childsDb?.childSteps?.nodes.find(child => child.id === searchParams.get("childstep"))

    if (sessionStorage.localDataCurrentModel) {
      setData(JSON.parse(sessionStorage.localDataCurrentModel))
      sessionStorage.removeItem("localDataCurrentModel")
    } else {
      const animate = item.childStepAnimationsByIdChildStep.nodes[0]
      const camera = item.childStepCamerasByIdChildStep.nodes[0]
      const pois = item.stepPoisByIdChildStep.nodes.map(childStepPoi => ({
        id: childStepPoi.id,
        onSave: "oldData",
        drawerOpen: false,
        type: childStepPoi.type,
        idPoi: childStepPoi.idPoi,
        assetType:
          childStepPoi?.poiByIdPoi?.usagePoisByIdPoi?.nodes[0]?.assetTypeByIdAssetType?.label || "default",
        defaultPoi: childStepPoi?.idPoi === defaultPoiId?.getDefaultPoi,
        description: "",
        externalLink: "",
        labelExternalLink: "",
        x: childStepPoi.x,
        y: childStepPoi.y,
        z: childStepPoi.z,
      }))

      const newData = {
        page: "model3d",
        childStepId: item.id,
        pois: pois || [],
        camera: {
          drawerOpen: false,
          childStepCameraId: camera?.id,
          x: camera?.camPositionX || 0,
          y: camera?.camPositionY || 0,
          z: camera?.camPositionZ || 0,
          minZoomDb: camera?.minZoom || undefined,
          maxZoomDb: camera?.maxZoom || undefined,
          onSave: camera?.id ? "oldData" : "create",
          minZoomModel: undefined,
          maxZoomModel: undefined,
        },
        target: {
          drawerOpen: false,
          x: camera?.targetPositionX || 0,
          y: camera?.targetPositionY || 0,
          z: camera?.targetPositionZ || 0,
          onSave: camera?.id ? "oldData" : "create",
        },
        animate: {
          childStepAnimationsId: animate?.id,
          idAnimationMode:
            animate?.animationModeByIdAnimationMode?.id || animationModes?.animationModes?.nodes[0]?.id,
          animationModeLabel:
            animate?.animationModeByIdAnimationMode?.label || animationModes?.animationModes?.nodes[0]?.label,
          repeat: animate?.repetitions || "",
          name: animate?.name || "",
          onSave: animate?.id ? "oldData" : "create",
        },
      }

      setData({ ...newData })
      fetchLabelsPoisTranslations(newData.pois)
    }

    setChildStepSelected(item)
  }

  useEffect(() => {
    if (searchParams.get("step") && !childsDb?.childSteps?.nodes) {
      fetchChildSteps({ variables: { id: searchParams.get("step") } })
    }
  }, [searchParams])

  const changeChildSelected = idChild => {
    searchParams.delete("childstep")
    searchParams.append("childstep", idChild)
    setSearchParams(searchParams)
    checkChildStepSelected()
    setOpenSelect(false)
  }

  const changeData = (key, field, value) => {
    //* change data -----------------

    let minZoomDbIsValid
    let maxZoomDbIsValid

    switch (field) {
      case "minZoomModel":
        minZoomDbIsValid = (data.camera.minZoomDb && data.camera.minZoomDb >= value) || true

        setData(prev => ({
          ...prev,
          camera: {
            ...prev.camera,
            [field]: value,
            minZoomDb: minZoomDbIsValid ? prev.camera.minZoomDb : value,
            onSave: prev[key].onSave !== "create" && !minZoomDbIsValid ? "update" : prev[key].onSave,
          },
        }))
        break
      case "maxZoomModel":
        maxZoomDbIsValid = (data.camera.maxZoomDb && data.camera.maxZoomDb <= value) || true
        minZoomDbIsValid =
          (data.camera.minZoomDb && data.camera.minZoomDb >= data.camera.minZoomModel) || true

        setData(prev => ({
          ...prev,
          camera: {
            ...prev.camera,
            [field]: value,
            minZoomDb: minZoomDbIsValid ? prev.camera.minZoomDb : prev.camera.minZoomModel,
            maxZoomDb: maxZoomDbIsValid ? prev.camera.maxZoomDb : value,
            onSave:
              prev[key].onSave !== "create" && (!minZoomDbIsValid || !maxZoomDbIsValid)
                ? "update"
                : prev[key].onSave,
          },
        }))
        break
      case "minZoomDb":
        maxZoomDbIsValid =
          data.camera.maxZoomDb > value && data.camera.maxZoomModel && value <= data.camera.maxZoomModel

        setData(prev => ({
          ...prev,
          camera: {
            ...prev.camera,
            [field]: value,
            maxZoomDb: maxZoomDbIsValid ? prev.camera.maxZoomDb : value + 0.001,
            onSave: prev[key].onSave !== "create" && field !== "drawerOpen" ? "update" : prev[key].onSave,
          },
        }))
        break
      default:
        setData(prev => ({
          ...prev,
          [key]: {
            ...prev[key],
            [field]: value,
            onSave: prev[key].onSave !== "create" && field !== "drawerOpen" ? "update" : prev[key].onSave,
          },
        }))
        break
    }
  }

  // pois functions ----------------------------------------------------------------
  const addNewPoi = newPoiData => {
    //* create new poi in local ------------------------------
    setData(prev => {
      const array = [...prev.pois]
      array.push({
        id: "",
        onSave: "create",
        drawerOpen: true,
        idPoi: defaultPoiId?.getDefaultPoi,
        defaultPoi: true,
        ...(newPoiData.type && { type: newPoiData.type }),
        ...(newPoiData.description && { description: newPoiData.description }),
        ...(newPoiData.externalLink && { externalLink: newPoiData.externalLink }),
        ...(newPoiData.labelExternalLink && { labelExternalLink: newPoiData.labelExternalLink }),
        ...(newPoiData.x && { x: newPoiData.x }),
        ...(newPoiData.y && { y: newPoiData.y }),
        ...(newPoiData.z && { z: newPoiData.z }),
      })
      return { ...prev, pois: [...array] }
    })
    setOverlay(false)
    setDrawerHighlight({ type: "", index: null, idPoi: "" })
  }

  const deleteLocalPoi = indexPoi => {
    //! delete (hidden) poi  ---------------------------
    const array = [...data.pois]
    if (array[indexPoi].onSave === "create") {
      //* poi not exist in DB, remove in array -----------------
      array.splice(indexPoi, 1)
      setData(prev => ({ ...prev, pois: [...array] }))
    } else {
      //* poi exist in DB, hidden component and change action onSave -----------
      array[indexPoi] = { ...array[indexPoi], onSave: "delete" }
      setData(prev => ({ ...prev, pois: [...array] }))
    }
  }

  const changeDataPoi = (index, field, value) => {
    setData(prev => {
      const array = [...prev.pois]
      array[index] = {
        ...array[index],
        onSave: array[index].onSave === "oldData" && field !== "drawerOpen" ? "update" : array[index].onSave,
        [field]: value,
      }
      return { ...prev, pois: [...array] }
    })
  }

  // save all data ----------------------------------------------------------------
  const saveAllData = () => {
    setLoadingSave(true)
    const promises = []
    if (data.pois.length > 0) {
      data.pois.forEach((poi, indexChildStepPoi) => {
        if (poi.onSave === "create") {
          //* create child step poi

          if (
            poi.defaultPoi &&
            ((!poi.description && !poi.externalLink) || (poi.labelExternalLink && !poi.externalLink))
          ) {
            //* if default poi and not all fields are filled,
            return
          }

          const variables = {
            idChildStep: childStepSelected.id,
            idPoi: poi.idPoi,
            x: poi.x,
            y: poi.y,
            z: poi.z,
            type: poi.type.toUpperCase(),
          }

          promises.push(
            createChildStepPoi({
              variables,
              onCompleted: async data => {
                if (poi.externalLink || poi.labelExternalLink || poi.description) {
                  // push trad
                  const json = {
                    description: { en: poi.description },
                    externalLink: { en: poi.externalLink },
                    labelExternalLink: { en: poi.labelExternalLink },
                  }

                  await api.postChildStepPoiTranslation(data?.createStepPoi?.stepPoi?.id, json)
                }
                setData(prev => ({
                  ...prev,
                  pois: prev.pois.map((item, index) => {
                    if (index === indexChildStepPoi) {
                      return {
                        ...item,
                        id: data?.createStepPoi?.stepPoi?.id,
                        onSave: "oldData",
                      }
                    }
                    return item
                  }),
                }))
              },
              onError: e => {
                captureErrorGraphql(e, "useModel3dPage", "CREATE_CHILD_STEP_POI", variables)
              },
            }),
          )
        } else if (poi.onSave === "update") {
          //* update child step poi ----------------------------

          if (
            poi.defaultPoi &&
            ((!poi.description && !poi.externalLink) || (poi.labelExternalLink && !poi.externalLink))
          ) {
            //* if default poi and not all fields are filled -----------------
            return
          }

          const variables = {
            id: poi.id,
            idPoi: poi.idPoi,
            x: poi.x,
            y: poi.y,
            z: poi.z,
            ...(poi.type && { type: poi.type.toUpperCase() }),
          }

          promises.push(
            updateChildStepPoi({
              variables,
              onCompleted: async () => {
                if (poi.externalLink || poi.labelExternalLink || poi.description) {
                  // push trad
                  const json = {
                    description: { en: poi.description },
                    externalLink: { en: poi.externalLink },
                    labelExternalLink: { en: poi.labelExternalLink },
                  }

                  await api.postChildStepPoiTranslation(poi?.id, json)
                }
                setData(prev => ({
                  ...prev,
                  pois: prev.pois.map((item, index) => {
                    if (index === indexChildStepPoi) {
                      return {
                        ...item,
                        onSave: "oldData",
                      }
                    }
                    return item
                  }),
                }))
              },
              onError: e => {
                captureErrorGraphql(e, "useModel3dPage", "UPDATE_CHILD_STEP_POI", variables)
              },
            }),
          )
        } else if (poi.onSave === "delete") {
          //! delete child step poi ----------------------------
          promises.push(
            deleteChildStepPoi({
              variables: { id: poi.id },
              onCompleted: () => {
                setData(prev => ({
                  ...prev,
                  pois: prev.pois.filter((item, index) => index !== indexChildStepPoi),
                }))
              },
              onError: e => {
                captureErrorGraphql(e, "useModel3dPage", "DELETE_CHILD_STEP_POI", { id: poi.id })
              },
            }),
          )
        }
      })
    }
    if (data.camera.onSave === "create" || data.target.onSave === "create") {
      const variables = {
        idChildStep: childStepSelected.id,
        camPositionX: data.camera.x,
        camPositionY: data.camera.y,
        camPositionZ: data.camera.z,
        targetPositionX: data.target.x,
        targetPositionY: data.target.y,
        targetPositionZ: data.target.z,
        minZoom: data.camera.minZoomDb,
        maxZoom: data.camera.maxZoomDb,
      }

      promises.push(
        createChildStepCamera({
          variables,
          onCompleted: data => {
            setData(prev => ({
              ...prev,
              camera: {
                ...prev.camera,
                childStepCameraId: data?.createChildStepCamera?.childStepCamera?.id,
                onSave: "oldData",
              },
              target: {
                ...prev.target,
                onSave: "oldData",
              },
            }))
          },
          onError: e => {
            captureErrorGraphql(e, "useModel3dPage", "CREATE_CHILD_STEP_CAMERA", variables)
          },
        }),
      )
    } else if (data.camera.onSave === "update" || data.target.onSave === "update") {
      const variables = {
        id: data.camera.childStepCameraId,
        camPositionX: data.camera.x,
        camPositionY: data.camera.y,
        camPositionZ: data.camera.z,
        targetPositionX: data.target.x,
        targetPositionY: data.target.y,
        targetPositionZ: data.target.z,
        minZoom: data.camera.minZoomDb,
        maxZoom: data.camera.maxZoomDb,
      }

      promises.push(
        updateChildStepCamera({
          variables,
          onCompleted: () => {
            setData(prev => ({
              ...prev,
              camera: {
                ...prev.camera,
                onSave: "oldData",
              },
              target: {
                ...prev.target,
                onSave: "oldData",
              },
            }))
          },
          onError: e => {
            captureErrorGraphql(e, "useModel3dPage", "UPDATE_CHILD_STEP_CAMERA", variables)
          },
        }),
      )
    }

    if (data.animate.onSave === "update" && data.animate.name) {
      const variables = {
        id: data.animate.childStepAnimationsId,
        ...(data.animate.idAnimationMode && { idAnimationMode: data.animate.idAnimationMode }),
        ...(data.animate.repeat && {
          repetitions: data.animate.repeat,
        }),
        ...(data.animate.name && { name: data.animate.name }),
        idChildStep: searchParams.get("childstep"),
      }

      promises.push(
        updateChildStepAnimation({
          variables,
          onCompleted: () => {
            setData(prev => ({
              ...prev,
              animate: {
                ...prev.animate,
                onSave: "oldData",
              },
            }))
          },
          onError: e => {
            captureErrorGraphql(e, "useModel3dPage", "UPDATE_CHILD_STEP_ANIMATION", variables)
          },
        }),
      )
    }
    if (data.animate.onSave === "create" && data.animate.name) {
      const variables = {
        idChildStep: childStepSelected.id,
        ...(data.animate.idAnimationMode && { idAnimationMode: data.animate.idAnimationMode }),
        ...(data.animate.repeat && {
          repetitions: data.animate.repeat,
        }),
        ...(data.animate.name && { name: data.animate.name }),
      }

      promises.push(
        createChildStepAnimation({
          variables,
          onCompleted: data => {
            setData(prev => ({
              ...prev,
              animate: {
                ...prev.animate,
                childStepAnimationsId: data?.createChildStepAnimation?.childStepAnimation?.id,
                onSave: "oldData",
              },
            }))
          },
          onError: e => {
            captureErrorGraphql(e, "useModel3dPage", "CREATE_CHILD_STEP_ANIMATION", variables)
          },
        }),
      )
    }

    Promise.all(promises)
      .then(() => {
        setSaveSuccess(true)
        setTimeout(() => {
          setSaveSuccess(false)
        }, 3000)
      })
      .catch(err => {
        console.log(err)
      })
      .finally(() => {
        setLoadingSave(false)
      })
  }

  return {
    childSteps: childsDb?.childSteps?.nodes || [],
    childStepSelected,
    openSelect,
    setOpenSelect,
    changeChildSelected,
    addNewPoi,
    deleteLocalPoi,
    setAnimatesAvailable,
    animatesAvailable,
    data: data ?? {},
    changeDataPoi,
    changeData,
    animationModes: animationModes?.animationModes?.nodes || [],
    saveAllData,
    drawerHighlight,
    setDrawerHighlight,
    loadingSave,
    saveSuccess,
  }
}
