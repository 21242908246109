import Skeleton from "@mui/material/Skeleton"
import { css, StyleSheet } from "aphrodite"
import PropTypes from "prop-types"
import React, { useCallback, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate, useSearchParams } from "react-router-dom"
import { Eye } from "../../assets/svgr"
import { BLACK_COLOR, WHITE } from "../../styles/colors"

export const WidgetSmallCard = ({ onClick, img, urlTrad, id, filename, themeStyles, isSelectable }) => {
  // states  ------------------------------------------------------------------
  const [charging, setCharging] = useState(false)
  const [isHovered, setIsHovered] = useState(false)
  const [errorImg, setErrorImg] = useState(false)
  const [title, setTitle] = useState("")
  const [params] = useSearchParams()
  const { t } = useTranslation("libraryPage")
  const navigate = useNavigate()

  // init style ----------------------------------------------------------------
  const styles = getStyles({ theme: themeStyles, title, isSelectable })

  const fetchLabels = useCallback(async () => {
    const response = await fetch(urlTrad)
    if (response.ok) {
      const trads = await response.json()
      setTitle(trads.label.en)
    } else {
      setTitle("-")
    }
  }, [urlTrad])

  useEffect(() => {
    if (params.get("type") !== "3d" && params.get("type") !== "video") {
      fetchLabels()
    } else {
      setTitle(filename || "-")
    }
  }, [fetchLabels, urlTrad, filename])

  // func ----------------------------------------------------------------------
  useEffect(() => {
    setTimeout(() => {
      if (!charging) {
        setErrorImg(true)
      }
    }, 4000)
  }, [])

  const handleMouseEnter = useCallback(() => {
    setIsHovered(true)
  }, [])

  const handleMouseLeave = useCallback(() => {
    setIsHovered(false)
  }, [])

  const saveLocalData = useCallback(() => {
    let json = {}
    const type = params.get("type")
    const filter = params.get("filter")

    if (type === "picture") {
      if (filter === "parts") {
        //* if filter by parts , choice part for child step --
        if (sessionStorage.getItem("newParts")) {
          json = JSON.parse(sessionStorage.getItem("newParts"))
          if (!json) json = []
          json.push({
            new: true,
            id,
            label: title,
            childstep: sessionStorage.getItem("idCurrentChildStep"),
            quantity: null,
          })
        } else {
          json = [
            {
              new: true,
              id,
              label: title,
              childstep: sessionStorage.getItem("idCurrentChildStep"),
              quantity: null,
            },
          ]
        }
        sessionStorage.setItem("newParts", JSON.stringify(json))
        sessionStorage.removeItem("idCurrentChildStep")
      } else if (filter === "tools") {
        //* if filter by tools , choice tool for child step --
        if (sessionStorage.getItem("newTools")) {
          json = JSON.parse(sessionStorage.getItem("newTools"))
          if (!json) json = []
          json.push({
            new: true,
            id,
            label: title,
            childstep: sessionStorage.getItem("idCurrentChildStep"),
          })
        } else {
          json = [
            {
              new: true,
              id,
              label: title,
              childstep: sessionStorage.getItem("idCurrentChildStep"),
            },
          ]
        }
        sessionStorage.setItem("newTools", JSON.stringify(json))
        sessionStorage.removeItem("idCurrentChildStep")
      }
    } else if (type === "video") {
      //
    } else if (type === "3d") {
      //* save model id for step page -------
      sessionStorage.setItem("model3d", id)
    } else if (type === "all" && filter === "pois") {
      //* save poi choice for model 3d page ----
      const localData = JSON.parse(sessionStorage.getItem("localDataCurrentModel"))
      const pois = [...localData.pois]
      pois.forEach((item, index) => {
        if (index === localData.indexSelected) {
          item.idPoi = id
          item.defaultPoi = false
          item.assetType = img.includes("thumbnail") ? "video" : "image"
        }
      })
      sessionStorage.setItem(
        "localDataCurrentModel",
        JSON.stringify({
          ...localData,
          pois: [...pois],
        }),
      )
    }

    if (sessionStorage.getItem("redirectModelFromLibraryUrl")) {
      // from model page *****
      const redirect = sessionStorage.getItem("redirectModelFromLibraryUrl")
      sessionStorage.removeItem("redirectModelFromLibraryUrl")
      navigate(redirect)
    } else if (sessionStorage.getItem("redirectStepFromLibraryUrl")) {
      // from step page ******
      const redirect = sessionStorage.getItem("redirectStepFromLibraryUrl")
      sessionStorage.removeItem("redirectStepFromLibraryUrl")
      navigate(redirect)
    }
  }, [id, title])

  return (
    <div onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} className={css(styles.card)}>
      <button
        aria-label="see"
        onClick={() => onClick(title)}
        type="button"
        className={css(styles.eyeContainer)}>
        <Eye color={themeStyles?.PRIMARY_COLOR || BLACK_COLOR} />
      </button>
      {img && (
        <div
          style={{
            height: "100%",
            padding: !charging || !title ? 0 : "25px 20px 15px",
            boxSizing: "border-box",
            flexDirection: "column",
            gap: 15,
            justifyContent: "space-between",
            display: !charging || !title ? "none" : "flex",
            overflow: "hidden",
          }}>
          <div className={css(styles.containerImg)}>
            <img
              alt={`cover${title}`}
              onLoad={() => setCharging(true)}
              style={{ display: !charging || !title ? "none" : "block" }}
              className={css(styles.img)}
              src={img}
            />
          </div>
          {charging && title && (
            <div className={css(styles.containerTitle)}>
              <p className={css(styles.title)}>{title}</p>
            </div>
          )}
        </div>
      )}
      {((!charging && !errorImg) || !title) && (
        <div style={{ height: "100%", width: "100%", borderRadius: 4 }}>
          <Skeleton
            variant="rectangular"
            sx={{ bgcolor: WHITE, height: "100%", width: "100%" }}
            animation="wave"
            width={"100%"}
            height={"100%"}
          />
        </div>
      )}
      {errorImg && !charging && (
        <p className={css(styles.titleError)}>{`${t(params.get("type"))} "${title}" ${t("not_found")}`}</p>
      )}
      {(sessionStorage.getItem("redirectStepFromModelUrl") ||
        sessionStorage.getItem("redirectStepFromLibraryUrl")) &&
        isHovered && (
          <div className={css(styles.containerHidden)}>
            <button
              aria-label={filename}
              onClick={saveLocalData}
              className={css(styles.hiddenBtn)}
              type="button">
              {t("select")}
            </button>
          </div>
        )}
    </div>
  )
}

const getStyles = ({ theme, isSelectable }) =>
  StyleSheet.create({
    card: {
      background: WHITE,
      display: "flex",
      alignItems: "center",
      borderRadius: 4,
      justifyContent: "center",
      padding: 0,
      boxSizing: "border-box",
      flexDirection: "column",

      width: 200,
      height: 200,
      border: "none",
      position: "relative",
      overflow: "hidden",
      ":hover": {
        opacity: isSelectable ? 0.6 : 1,
      },
    },
    isHovered: {
      background: "rgba(255, 255, 255, 0.70)",
    },
    eyeContainer: {
      position: "absolute",
      top: 8,
      padding: 0,
      right: 8,
      border: "none",
      background: "none",
      zIndex: 5,
      cursor: "pointer",
      ":hover": {
        opacity: 0.6,
      },
    },
    containerTitle: {
      display: "flex",
      justifyContent: "center",
      alignItems: "flex-end",
      maxWidth: "160px",
    },
    title: {
      fontFamily: "Roboto",
      fontWeight: "700",
      fontSize: 14,
      color: theme.PRIMARY_COLOR || "black",
      margin: 0,
      textAlign: "center",
    },
    titleError: {
      fontFamily: "Roboto",
      fontWeight: "400",
      fontSize: "0.8rem",
      margin: "3px 0 0",
      fontStyle: "italic",
      maxWidth: "50%",
      color: theme.PRIMARY_COLOR || "black",
    },
    containerImg: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      overflow: "hidden",
      flexGrow: 1,
      aspectRatio: "1/1",
    },
    img: {
      height: "auto",
      width: "auto",
      maxWidth: "100%",
      maxHeight: "100%",
      display: "block",
      objectFit: "contain",
    },
    containerHidden: {
      position: "absolute",
      top: 0,
      bottom: 0,
      right: 0,
      left: 0,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      zIndex: 2,
      background: "rgba(255, 255, 255, 0.70)",
    },
    hiddenBtn: {
      backgroundColor: theme.PRIMARY_COLOR || WHITE,
      color: theme.PRIMARY_COLOR ? WHITE : BLACK_COLOR,
      border: "none",
      padding: "0 10px",
      height: 40,
      borderRadius: 4,
      fontSize: 15,
      fontFamily: "Roboto",
      fontWeight: "400",
      cursor: "pointer",
      ":hover": {
        fontWeight: "600",
      },
    },
  })

WidgetSmallCard.propTypes = {
  onClick: PropTypes.func,
  img: PropTypes.string,
  urlTrad: PropTypes.string,
  id: PropTypes.string,
  filename: PropTypes.string,
  themeStyles: PropTypes.object,
  isSelectable: PropTypes.bool,
}
