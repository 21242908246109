import * as THREE from 'three'
import ADD_POI_CURSOR_INVALID from '../../assets/img/poiAddInvalidCursor.png'
import ADD_POI_CURSOR_VALID from '../../assets/img/poiAddValidCursor.png'
import { convertOffsetToNDCCoordinates, getObjectsToRaycast } from './UIUtils.mjs'

class PoiCreationHelper {
  _viewer
  _distanceFromMesh
  _raycaster
  _isRaycastEnabled = true
  _objectsToRaycast
  _intersects = []
  _isActive = false
  _pointerNDCCoords = new THREE.Vector2()
  _listeners = new Map()
  _currentHit = null
  _pendingPromises = new Set()
  _onValidPositionActions = new Set()
  _onStoppedActions = new Set()
  _previousCursorValue
  _postRenderAction = this.update.bind(this)

  constructor(viewer, distanceFromMesh = 0.005) {
    this._viewer = viewer
    this._distanceFromMesh = distanceFromMesh

    this._raycaster = new THREE.Raycaster()

    this._listeners.set('pointerenter', () => (this._isRaycastEnabled = true))
    this._listeners.set('pointermove', onPointerMove.bind(this))
    this._listeners.set('pointerleave', () => (this._isRaycastEnabled = false))
    this._listeners.set('click', onClick.bind(this))

    function onPointerMove(event) {
      const { ndcX, ndcY } = convertOffsetToNDCCoordinates(
        event.offsetX,
        event.offsetY,
        this._viewer.canvas.clientWidth,
        this._viewer.canvas.clientHeight,
      )

      this._pointerNDCCoords.set(ndcX, ndcY)

      this._raycaster.setFromCamera(this._pointerNDCCoords, this._viewer.world.camera)
    }

    function onClick(event) {
      if (this._currentHit) {
        let validPosition = this._currentHit.point
        validPosition.addScaledVector(this._currentHit.normal, this._distanceFromMesh)
        for (const action of this._onValidPositionActions.values()) {
          action(validPosition)
        }
      }
    }
  }

  start() {
    if (this._isActive) {
      return
    }

    this._previousCursorValue = this._viewer.canvas.style.cursor
    this._objectsToRaycast = getObjectsToRaycast(this._viewer.modelRoot, false)
    this._isRaycastEnabled = true
    this._isActive = true
    this._viewer.addPostRenderAction(this._postRenderAction)

    this.addListeners()
  }

  stop() {
    if (!this._isActive) {
      return
    }

    this._isRaycastEnabled = false
    this._isActive = false

    for (const action of this._onStoppedActions.values()) {
      action()
    }

    this._viewer.removePostRenderAction(this._postRenderAction)
    this._viewer.canvas.style.cursor = this._previousCursorValue

    this.removeListeners()
  }

  async *getValidClickedPositions() {
    let { promise, resolve, reject } = Promise.withResolvers()
    let validPositionListener = position => resolve(position)
    let stoppedListener = () => reject('stopped')

    while (this._isActive) {
      let validPosition
      this._onValidPositionActions.add(validPositionListener)
      this._onStoppedActions.add(stoppedListener)

      try {
        validPosition = await promise
      } catch (error) {
        clean.call(this)
        return
      }

      clean.call(this)
      yield validPosition
      ;({ promise, resolve, reject } = Promise.withResolvers())
    }

    return

    function clean() {
      this._onValidPositionActions.delete(validPositionListener)
      this._onStoppedActions.delete(stoppedListener)
    }
  }

  addListeners() {
    for (const [k, v] of this._listeners.entries()) {
      this._viewer.canvas.addEventListener(k, v)
    }
  }

  removeListeners() {
    for (const [k, v] of this._listeners.entries()) {
      this._viewer.canvas.removeEventListener(k, v)
    }
  }

  update() {
    if (!this._isRaycastEnabled) {
      return
    }

    this._intersects.length = 0
    this._raycaster.intersectObjects(this._objectsToRaycast, false, this._intersects)

    this._currentHit = this._intersects.length > 0 ? this._intersects[0] : null
    this._viewer.canvas.style.cursor = this._currentHit
      ? 'url(' + ADD_POI_CURSOR_VALID + ') 21 8, pointer'
      : 'url(' + ADD_POI_CURSOR_INVALID + ') 21 8, no-drop'
  }
}

export default PoiCreationHelper
