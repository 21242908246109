import { css, StyleSheet } from "aphrodite"
import PropTypes from "prop-types"
import React, { useMemo } from "react"
import { useTranslation } from "react-i18next"
import Modal from "react-modal"
import { UIButton } from "../../components/UI"

const staticStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "80dvw",
    maxWidth: 650,
    maxHeight: "80dvh",
    border: "none",
    padding: 40,
    borderRadius: 0,
    boxSizing: "border-box",
  },
  overlay: { background: "rgba(0, 22, 33, 0.70)" },
}

export const WarningBackModal = ({ isOpen, closeModal, closeAndBack, themeStyles }) => {
  const { t } = useTranslation("model3DPage")

  // init style ----------------------------------------------------------------
  const styles = useMemo(() => getStyles({ theme: themeStyles }), [themeStyles])
  const customStyles = useMemo(() => ({ ...staticStyles, ...styles }), [styles])

  return (
    <Modal
      onRequestClose={closeModal}
      appElement={document.getElementById("root")}
      isOpen={isOpen}
      style={customStyles}>
      <h2 className={css(styles.title)}>{t("modal.title")}</h2>
      <p className={css(styles.subTitle)}>{t("modal.desc")}</p>
      <div className={css(styles.containerBtn)}>
        <UIButton
          dynamicStyle={[styles.btn]}
          label={t("modal.no")}
          themeStyle={themeStyles}
          kind="secondary"
          type="button"
          onClick={closeModal}
        />
        <UIButton
          dynamicStyle={[styles.btn]}
          label={t("modal.yes")}
          themeStyle={themeStyles}
          kind="primary"
          onClick={closeAndBack}
          type="submit"
        />
      </div>
    </Modal>
  )
}

const getStyles = ({ theme }) =>
  StyleSheet.create({
    title: {
      fontWeight: "600",
      color: theme.PRIMARY_COLOR,
      fontSize: 20,
      fontFamily: "Roboto",
      textAlign: "center",
      flexShrink: 0,
      margin: 0,
    },
    subTitle: {
      fontWeight: "400",
      color: theme.PRIMARY_COLOR,
      fontSize: 16,
      fontFamily: "Roboto",
      textAlign: "center",
    },
    containerBtn: {
      display: "flex",
      gap: 10,
      marginTop: 30,
      width: "100%",
      justifyContent: "center",
    },
    btn: {
      fontWeight: "400",
      fontFamily: "Roboto",
      fontSize: 15,
      height: 40,
      padding: "0px 20px",
      marginTop: 8,
      minWidth: 138,
    },
  })

WarningBackModal.propTypes = {
  isOpen: PropTypes.bool,
  closeModal: PropTypes.func,
  closeAndBack: PropTypes.func,
  themeStyles: PropTypes.object,
}
