import { css, StyleSheet } from "aphrodite"
import PropTypes from "prop-types"
import React, { useLayoutEffect, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import Modal from "react-modal"
import ResizeDevice from "../../assets/svgr/ResizeDevice"
import { useTheme } from "../../components/Provider/ThemeProvider"
import { checkEnv } from "../../lib"
import { BLACK_COLOR } from "../../styles/colors"

export const WarningSmallPage = ({ show }) => {
  const { t } = useTranslation("resizePage")
  const [logo, setLogo] = useState()

  const { themeStyles } = useTheme()

  // init style ----------------------------------------------------------------
  const styles = useMemo(() => getStyles({ theme: themeStyles }), [themeStyles])

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      width: 450,
      height: 450,
      maxWidth: "90dvw",
      maxHeight: "90dvh",
      border: "none",
      padding: "60px 50px",
      boxSizing: "border-box",
      lineHeight: "20px",
      borderRadius: 20,
      display: "flex",
      justifyContent: "center",
      flexDirection: "column",
      alignItems: "center",
      gap: 25,
    },
    overlay: {
      background: "#464646",
    },
  }

  useLayoutEffect(() => {
    if (themeStyles?.organization_id) {
      const config = checkEnv()
      const apiLogo = `${config.BASE_URL}/organizations/${themeStyles?.organization_id}/logo-cms`
      setLogo(apiLogo)
    }
  }, [themeStyles])

  return (
    <Modal appElement={document.getElementById("root")} isOpen={show} style={customStyles}>
      {logo && (
        <div>
          <img height={"100%"} width={"100%"} alt="logo" src={logo} />
        </div>
      )}
      <p className={css(styles.baseText)}>{t("infos")}</p>
      <div>
        <ResizeDevice />
      </div>
    </Modal>
  )
}

const getStyles = ({ theme }) =>
  StyleSheet.create({
    baseText: {
      color: theme?.PRIMARY_COLOR || BLACK_COLOR,
      fontFamily: "Roboto",
      fontSize: 14,
      textAlign: "center",
      margin: 0,
    },
    titleText: {
      fontSize: 18,
    },
  })

WarningSmallPage.propTypes = {
  show: PropTypes.bool,
}
