import { css, StyleSheet } from "aphrodite"
import PropTypes from "prop-types"
import { useEffect, useRef } from "react"
import { MOUSE } from "three"
import { use3DCameraOrbitConfiguration } from "../../hooks/use3DCameraOrbitConfiguration"
import { use3DPoi } from "../../hooks/use3DPoi"
import { use3DViewer } from "../../hooks/use3DViewer"
import { useTransformControls } from "../../hooks/useTransformControls"
import PoiCreationHelper from "../../lib/graphics/PoiCreationHelper.mjs"

export const ManualEdition3DViewer = ({
  modelURL,
  wireframe = true,
  orbitData,
  poisData = [],
  changeData,
  changeDataPoi,
  addPoiMode = false,
  addNewPoi,
  changeAnimatesAvailable,
  highlightData,
}) => {
  const styles = getStyles()
  const canvasRef = useRef(null)
  const { viewerRef, animationsData, modelRoot } = use3DViewer(canvasRef, modelURL, wireframe)
  const transformControlsRef = useTransformControls(viewerRef, true)
  const { selectPoi } = use3DPoi(viewerRef, poisData, true, changeDataPoi, transformControlsRef)
  const { selectCamera, selectTarget } = use3DCameraOrbitConfiguration(
    viewerRef,
    transformControlsRef,
    orbitData,
    changeData,
  )

  useEffect(setupEditorCamera, [modelRoot])
  useEffect(updateAnimationNames, [animationsData])
  useEffect(handlePoiCreation, [addPoiMode, addNewPoi])
  useEffect(updateHighlighted, [highlightData])

  return (
    <div className={css(styles.viewerContainer)}>
      <canvas
        ref={canvasRef}
        className={css(styles.viewerCanvas)}
        // style={addPoiMode ? { cursor: 'url(' + ADD_POI_CURSOR_VALID + ') 21 8, pointer' } : {}}
      ></canvas>
    </div>
  )

  function setupEditorCamera() {
    viewerRef.current.cameraController.setMouseControls({ MIDDLE: MOUSE.PAN, RIGHT: MOUSE.ROTATE })

    if (!modelRoot) {
      return
    }

    viewerRef.current.cameraController.setDistanceLimits({
      maxDistance: viewerRef.current.cameraController.maxDistance * 4,
    })
  }

  function handlePoiCreation() {
    let poiCreator = null

    if (!viewerRef.current || viewerRef.current.modelRoot == null) {
      return
    }

    viewerRef.current.viewerInterfaceManager?.setEnabled(!addPoiMode)

    if (addPoiMode) {
      viewerRef.current.selectionManager?.unselect()

      poiCreator = new PoiCreationHelper(viewerRef.current)
      beginPoiCreation()
    }

    return clean

    async function beginPoiCreation() {
      poiCreator.start()
      const poiPositionGenerator = poiCreator.getValidClickedPositions()

      const newPoiPosition = await poiPositionGenerator.next()
      if (newPoiPosition.value) {
        addNewPoi({
          type: "INFO",
          x: newPoiPosition.value.x,
          y: newPoiPosition.value.y,
          z: newPoiPosition.value.z,
        })
      }
      poiCreator.stop()
    }

    function clean() {
      if (poiCreator) {
        poiCreator.stop()
      }
    }
  }

  function updateAnimationNames() {
    const animationNames = []
    for (const animData of animationsData) {
      animationNames.push(animData.name)
    }
    changeAnimatesAvailable([...animationNames])
  }

  function updateHighlighted() {
    if (!viewerRef.current.selectionManager) {
      console.warn("Cannot highlight. Missing selection manager")
      return
    }

    switch (highlightData.type) {
      case "camera":
        selectCamera()
        break
      case "target":
        selectTarget()
        break
      case "poi":
        selectPoi(highlightData.index)
        break
      default:
        return
    }

    const newTarget = viewerRef.current.selectionManager?.selected?.interactable.root.position
    if (!newTarget) {
      return
    }

    viewerRef.current.cameraController.setTarget(newTarget)
  }
}

const getStyles = () =>
  StyleSheet.create({
    viewerContainer: {
      width: "100%",
      height: "100%",
    },
    viewerCanvas: {
      width: "100%",
      height: "100%",
    },
  })

ManualEdition3DViewer.propTypes = {
  modelURL: PropTypes.string,
  wireframe: PropTypes.bool,
  orbitData: PropTypes.object,
  poisData: PropTypes.array,
  changeData: PropTypes.func,
  changeDataPoi: PropTypes.func,
  addPoiMode: PropTypes.bool,
  addNewPoi: PropTypes.func,
  changeAnimatesAvailable: PropTypes.func,
  highlightData: PropTypes.object,
}
