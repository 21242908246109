import { useCallback, useEffect, useRef } from "react"
import InteractableTransformManipulator from "../lib/graphics/InteractableTransformManipulator.mjs"
import Poi from "../lib/graphics/Poi.mjs"

export const use3DPoi = (
  viewerRef,
  poisData,
  isEditMode = false,
  changeDataPoi,
  transformControlsRef,
  onPoiClick,
) => {
  const createdPois = useRef([])
  const poiManipulators = useRef(new Map())
  const poiTransformChangedAction = useCallback(onPoiTransformChanged, [changeDataPoi])
  const poiManipulatorSelectAction = useCallback(onPoiManipulatorSelect, [changeDataPoi])
  const poiClickAction = useCallback(onPoiInteractableClick, [onPoiClick])

  useEffect(configuraViewer, [viewerRef])
  useEffect(handleEditMode, [isEditMode, transformControlsRef])
  useEffect(updateCreatedPois, [poisData])
  useEffect(updateTransformChangedActions, [poiTransformChangedAction, poiManipulatorSelectAction])
  useEffect(updatePoiClickActions, [poiClickAction])

  return { selectPoi }

  function configuraViewer() {
    viewerRef.current.addInterfaceManager()
    if (isEditMode) {
      viewerRef.current.addSelectionManager()
    }

    addPoisToViewer(createdPois.current)

    return cleanViewer

    function cleanViewer() {
      removePoisFromViewer(createdPois.current)
    }
  }

  function handleEditMode() {
    if (!isEditMode) {
      for (const poi of createdPois.current) {
        poi.addClickAction(poiClickAction)
      }

      return cleanPreviewMode
    }

    for (const poi of createdPois.current) {
      addManipulatorToPoi(poi)
    }

    return cleanEditMode

    function cleanEditMode() {
      for (const manipulator of poiManipulators.current.values()) {
        manipulator.dispose()
      }

      poiManipulators.current.clear()
    }

    function cleanPreviewMode() {
      for (const poi of createdPois.current) {
        poi.removeClickAction(poiClickAction)
      }
    }
  }

  function updateCreatedPois() {
    let isDataValid = true
    // console.warn('POIS')
    // console.warn(poisData)

    const visiblePoisIndices = []
    for (let i = 0; i < poisData.length; i++) {
      if (poisData[i].onSave !== "delete") {
        visiblePoisIndices.push(i)
      }
    }

    if (createdPois.current.length < visiblePoisIndices.length) {
      // Create missing POIs
      const newCreatedPoisArray = []
      const poiInitCalls = []

      for (let i = createdPois.current.length; i < visiblePoisIndices.length; i++) {
        const poiType = Poi.parseType(poisData[visiblePoisIndices[i]].type)
        const newPoi = new Poi(poiType)

        poiInitCalls.push(newPoi.init())
        newCreatedPoisArray.push(newPoi)
      }

      try {
        Promise.all(poiInitCalls).then(() => onNewPoisReady(newCreatedPoisArray))
      } catch (e) {
        console.error("poi init promise all failed")
        isDataValid = false
      }
    } else {
      if (createdPois.current.length > visiblePoisIndices.length) {
        removePoisFromViewer(createdPois.current.slice(visiblePoisIndices.length))
        createdPois.current.length = visiblePoisIndices.length
      }
      updateCreatedPoisData()
    }

    return cleanUpdateCreatedPois

    function onNewPoisReady(newPois) {
      if (isDataValid) {
        addPoisToViewer(newPois)
        createdPois.current.push(...newPois)
        updateCreatedPoisData()
      }
    }

    function cleanUpdateCreatedPois() {
      isDataValid = false
    }

    function updateCreatedPoisData() {
      let poi, poiIndex, x, y, z
      for (let i = 0; i < createdPois.current.length; i++) {
        poi = createdPois.current[i]
        poiIndex = visiblePoisIndices[i]
        x = poisData[poiIndex].x === undefined ? 0 : parseFloat(poisData[poiIndex].x)
        y = poisData[poiIndex].y === undefined ? 0 : parseFloat(poisData[poiIndex].y)
        z = poisData[poiIndex].z === undefined ? 0 : parseFloat(poisData[poiIndex].z)

        poi.root.position.set(x, y, z)
        poi.root.userData.poiIndex = poiIndex

        if (poisData[poiIndex].type) {
          poi.changeType(Poi.parseType(poisData[poiIndex].type))
        }
      }
    }
  }

  function updateTransformChangedActions() {
    if (!isEditMode) {
      return
    }

    for (const manipulator of poiManipulators.current.values()) {
      manipulator.addTransformChangedAction(poiTransformChangedAction)
      manipulator.addSelectAction(poiManipulatorSelectAction)
    }

    return () => {
      for (const manipulator of poiManipulators.current.values()) {
        manipulator.removeTransformChangedAction(poiTransformChangedAction)
        manipulator.removeSelectAction(poiManipulatorSelectAction)
      }
    }
  }

  function updatePoiClickActions() {
    if (isEditMode) {
      return
    }

    for (const poi of createdPois.current) {
      poi.addClickAction(poiClickAction)
    }

    return () => {
      for (const poi of createdPois.current) {
        poi.removeClickAction(poiClickAction)
      }
    }
  }

  function addPoisToViewer(newPois) {
    for (let i = 0; i < newPois.length; i++) {
      viewerRef.current.viewerInterfaceManager.addInteractable(newPois[i])
      if (isEditMode) {
        addManipulatorToPoi(newPois[i])
      } else {
        newPois[i].addClickAction(poiClickAction)
      }
    }

    viewerRef.current.viewerInterfaceManager.updateInterface()
  }

  function removePoisFromViewer(poisToRemove) {
    for (let i = 0; i < poisToRemove.length; i++) {
      viewerRef.current?.viewerInterfaceManager?.removeInteractable(poisToRemove[i])
      if (isEditMode) {
        const manipulator = poiManipulators.current.get(poisToRemove[i])
        if (manipulator) {
          manipulator.dispose()
          poiManipulators.current.delete(poisToRemove[i])
        }
      } else {
        poisToRemove[i].removeClickAction(poiClickAction)
      }
    }

    viewerRef.current?.selectionManager?.unselect()
    viewerRef.current?.viewerInterfaceManager?.updateInterface()
  }

  function addManipulatorToPoi(poi) {
    const preExistingManipulator = poiManipulators.current.get(poi)
    if (preExistingManipulator) {
      preExistingManipulator.dispose()
      poiManipulators.current.delete(poi)
    }

    const manipulator = new InteractableTransformManipulator(poi, transformControlsRef.current)
    manipulator.addTransformChangedAction(poiTransformChangedAction)
    manipulator.addSelectAction(poiManipulatorSelectAction)

    poiManipulators.current.set(poi, manipulator)
  }

  function onPoiTransformChanged(manipulator) {
    changeDataPoi(
      manipulator.interactable.root.userData.poiIndex,
      "x",
      manipulator.interactable.root.position.x,
    )
    changeDataPoi(
      manipulator.interactable.root.userData.poiIndex,
      "y",
      manipulator.interactable.root.position.y,
    )
    changeDataPoi(
      manipulator.interactable.root.userData.poiIndex,
      "z",
      manipulator.interactable.root.position.z,
    )
  }

  function onPoiManipulatorSelect(manipulator) {
    changeDataPoi(manipulator.interactable.root.userData.poiIndex, "drawerOpen", true)
  }

  function onPoiInteractableClick(interactable) {
    onPoiClick(interactable.root.userData.poiIndex)
  }

  function selectPoi(poiIndex) {
    for (const [k, v] of poiManipulators.current.entries()) {
      if (k.root.userData.poiIndex === poiIndex) {
        viewerRef.current.selectionManager.select(v)
      }
    }
  }
}
