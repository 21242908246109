import { useEffect } from "react"

export const use3DCameraParameters = (
  viewerRef,
  camPositionX,
  camPositionY,
  camPositionZ,
  minZoom,
  maxZoom,
  targetPositionX,
  targetPositionY,
  targetPositionZ,
) => {
  useEffect(setCameraParameters, [
    viewerRef,
    camPositionX,
    camPositionY,
    camPositionZ,
    minZoom,
    maxZoom,
    targetPositionX,
    targetPositionY,
    targetPositionZ,
  ])

  function setCameraParameters() {
    viewerRef.current.cameraController.setTarget({
      x: isNaN(Number(targetPositionX)) ? 0 : targetPositionX,
      y: isNaN(Number(targetPositionY)) ? 0 : targetPositionY,
      z: isNaN(Number(targetPositionZ)) ? 0 : targetPositionZ,
    })

    const parsedMinZoom = Number(minZoom)
    const parsedMaxZoom = Number(maxZoom)

    viewerRef.current.cameraController.setDistanceLimits({
      minDistance: isNaN(parsedMinZoom) || parsedMinZoom <= 0 ? 0.001 : minZoom,
      maxDistance: isNaN(parsedMaxZoom) || parsedMaxZoom <= 0 ? Infinity : maxZoom,
    })

    viewerRef.current.cameraController.camera.position.set(
      isNaN(camPositionX) ? 0 : camPositionX,
      isNaN(camPositionY) ? 0 : camPositionY,
      isNaN(camPositionZ) ? -1 : camPositionZ,
    )
  }
}
