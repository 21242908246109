import { gql } from "@apollo/client"
import { MODEL_DATA } from "./fragments"

export const MODELS = gql`
  ${MODEL_DATA}
  query models($first: Int, $offset: Int) {
    models(orderBy: NATURAL, first: $first, offset: $offset) {
      nodes {
        ...ModelData
      }
      totalCount
    }
  }
`

export const MODEL_BY_ID = gql`
  ${MODEL_DATA}
  query modelsById($id: UUID!) {
    models(filter: { id: { equalTo: $id } }) {
      nodes {
        ...ModelData
      }
    }
  }
`

export const CREATE_MODEL = gql`
  mutation CreateModel($filename: String!) {
    createModel(input: { model: { filename: $filename } }) {
      model {
        id
      }
    }
  }
`

export const UPDATE_MODEL = gql`
  mutation UpdateModel($filename: String!, $id: UUID!) {
    updateModel(input: { patch: { filename: $filename }, id: $id }) {
      clientMutationId
    }
  }
`
