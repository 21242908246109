import { css, StyleSheet } from "aphrodite"
import React, { Suspense, useCallback, useLayoutEffect, useMemo, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import {
  Link,
  useLocation,
  useNavigate,
  useOutletContext,
  useParams,
  useSearchParams,
} from "react-router-dom"
import { useEvent } from "react-use"
import { Add, ChevronDynamique, Stack, Trash } from "../../assets/svgr"
import { useTheme } from "../../components/Provider/ThemeProvider"
import { UIButton, UIButtonIcon } from "../../components/UI"
import { LogoutHeader } from "../../components/Widget/LogoutHeader"
import { Model3DViewer } from "../../components/Widget/Model3DViewer"
import { useStepPage } from "../../hooks/useStepPage"
import { checkEnv } from "../../lib"
import { NEUTRAL_DARK, NEUTRAL_LIGHT, WHITE } from "../../styles/colors"
import { ConfirmDeleteStepModal } from "./ConfirmDeleteStepModal"
import { ConfirmNewModelModal } from "./ConfirmNewModelModal"
import { WidgetChildStep } from "./WidgetChildStep"

export const StepPage = () => {
  // states --------------------------------------------------------------------
  const [openSelect, setOpenSelect] = useState(false)
  const [showConfirmRedirect, setShowConfirmRedirect] = useState(false)
  const [showConfirmDelete, setShowConfirmDelete] = useState(false)

  // others hooks --------------------------------------------------------------
  const { t } = useTranslation("stepPage")
  const { themeStyles } = useTheme()
  const config = useMemo(() => checkEnv(), [])
  const navigate = useNavigate()
  const stepDivMenu = useRef()
  const [params] = useSearchParams()
  const location = useLocation()
  const { idstep } = useParams()
  const { modalHidden } = useOutletContext()

  const {
    stepsByManual,
    childsStep,
    childStepSelected,
    setStepSelected,
    stepSelected,
    stepModel,
    modelIsPresent,
    saveSuccess,
    setChildStepSelected,
    setChildsStep,
    deleteChildStep,
    deleteStep,
    manual,
    saveChildStepData,
    addNewStepFunc,
    addChildStep,
  } = useStepPage()

  // init style ----------------------------------------------------------------
  const styles = useMemo(() => getStyles({ theme: themeStyles }), [themeStyles])

  // func ----------------------------------------------------------------------
  const handleClickOutside = event => {
    if (stepDivMenu.current && !stepDivMenu.current.contains(event.target)) {
      setOpenSelect(false)
    }
  }
  useEvent("mousedown", handleClickOutside)

  const checkUrl = () => {
    const item = stepsByManual?.find(step => step?.id === idstep)
    if (item?.id) setStepSelected({ label: item?.text, id: item?.id, pos: item?.pos })
    if (location.pathname === "/manuals/step/new") {
      //* reset childstep -------
      addNewStepFunc()
    }
  }

  useLayoutEffect(() => {
    checkUrl()
  }, [location])

  const checkChevron = childStepId => {
    const array = [...childsStep]
    for (const childStep of array) {
      if (childStep.id === childStepId) {
        if (childStep.expand) {
          setChildStepSelected(null)
        } else {
          setChildStepSelected(childStep)
          navigate(`?manual=${params.get("manual")}&childstep=${childStepId}`)
        }
        childStep.expand = !childStep.expand
      } else {
        childStep.expand = false
      }
    }
    setChildsStep([...array])
  }

  const redirectModel = () => {
    setShowConfirmRedirect(false)
    const currentUrl = `${location.pathname}?manual=${params.get("manual")}`
    sessionStorage.setItem("redirectStepFromLibraryUrl", currentUrl)
    navigate(`/library?type=3d&manual=${params.get("manual")}&page=1`)
  }

  const removeLocalStorage = useCallback(() => {
    //* if from edit step clean storage
    sessionStorage.removeItem("newParts")
    sessionStorage.removeItem("newTools")
    sessionStorage.removeItem("redirectStepFromLibraryUrl")
    sessionStorage.removeItem("redirectStepFromModelUrl")
    sessionStorage.removeItem("redirectModelFromLibraryUrl")
    sessionStorage.removeItem("idCurrentChildStep")
    sessionStorage.removeItem("model3d")
  }, [])

  const handleSelect = useCallback(() => {
    setOpenSelect(prev => !prev)
  }, [])

  const changeStep = step => {
    removeLocalStorage()
    if (step?.id) {
      setStepSelected({ label: step.text, id: step.id, pos: step.pos })
      navigate(`/manuals/step/${step.id}?manual=${params.get("manual")}`)
    } else {
      navigate(`/manuals/manager?manual=${params.get("manual")}`)
    }
    setOpenSelect(false)
  }

  return (
    <section className={css(styles.container)}>
      <header className={css(styles.header)}>
        <div className={css(styles.titleContainer)}>
          <div ref={stepDivMenu} className={css(styles.flex)}>
            <h1 className={css(styles.headerTitle)}>{`${t("title")} ${manual?.name}  -`}</h1>
            <div style={{ position: "relative" }}>
              <button
                aria-label={
                  stepSelected.id
                    ? `${t("step")} ${
                        stepsByManual.findIndex(step => step.id === stepSelected.id) + 1 || "-"
                      }`
                    : `${t("step")} ${stepsByManual?.length + 1 || "-"}`
                }
                onClick={handleSelect}
                type="button"
                className={css(styles.select)}>
                {stepSelected.id
                  ? `${t("step")} ${stepsByManual.findIndex(step => step.id === stepSelected.id) + 1 || "-"}`
                  : `${t("step")} ${stepsByManual?.length + 1 || "-"}`}
                <ChevronDynamique state={openSelect ? "up" : "down"} color={themeStyles?.PRIMARY_COLOR} />
              </button>
              {openSelect && !modalHidden && (
                <Suspense>
                  <div className={css(styles.selectContainer)}>
                    <button
                      aria-label="manager-button"
                      type="button"
                      className={css(styles.oneItemSelect)}
                      onClick={changeStep}>
                      <p style={{ margin: 0 }}> {t("manager")}</p>
                    </button>

                    {stepsByManual[0] &&
                      stepsByManual?.map((step, index) => (
                        <button
                          type="button"
                          className={css(
                            styles.oneItemSelect,
                            step?.id === stepSelected?.id && styles.stepSelectedItem,
                          )}
                          key={index}
                          aria-label="step-button"
                          onClick={() => changeStep(step)}>
                          <p style={{ margin: 0 }}> {`${t("step")} ${index + 1}`} </p>
                        </button>
                      ))}
                  </div>
                </Suspense>
              )}
            </div>
          </div>

          <div className={css(styles.flex)}>
            {location?.pathname !== "/manuals/step/new" && (
              <UIButton
                dynamicStyle={[styles.addBtn]}
                kind="primary"
                label={t("add_new_step")}
                themeStyle={themeStyles}
                onClick={() => navigate(`/manuals/step/new?manual=${params.get("manual")}`)}
              />
            )}

            {location?.pathname !== "/manuals/step/new" && (
              <UIButtonIcon
                onClick={() => setShowConfirmDelete(true)}
                dynamicStyle={[styles.styleBtn]}
                icon={<Trash />}
              />
            )}
          </div>
        </div>
        <LogoutHeader themeStyles={themeStyles} />
      </header>
      <Link
        to={`${config.BASE_URL_APP}/en/${params.get("manual")}`}
        target="_blank"
        className={css(styles.link)}>{`${config?.BASE_URL_APP}/en/${params.get("manual")}`}</Link>
      <Suspense>
        <main className={css(styles.mainContainer)}>
          <div className={css(styles.assetContainer)}>
            <button
              aria-label="edit-model-button"
              onClick={() => {
                if (stepModel) {
                  setShowConfirmRedirect(true)
                } else {
                  redirectModel()
                }
              }}
              className={css(styles.editModelBtn)}
              type="button">
              {stepModel ? t("editstep.edit_scene3d") : t("editstep.add_scene3d")}

              <Stack color={WHITE} />
            </button>
            {stepModel && (
              <Model3DViewer
                modelURL={`${config.BASE_URL}/models/${stepModel.idModel}/file`}
                playerSize={"small"}
              />
            )}
          </div>
          <div className={css(styles.detailsContainer)}>
            <p className={css(styles.desc)}>{t("editstep.desc")}</p>
            {!idstep && <p className={css(styles.descNewStep)}>{t("editstep.desc_new_step")}</p>}
            {childsStep[0] &&
              childsStep.map((child, index) => (
                <WidgetChildStep
                  key={index}
                  deleteChildStep={deleteChildStep}
                  modelIsPresent={modelIsPresent}
                  data={child}
                  index={index}
                  saveChildStepData={saveChildStepData}
                  saveSuccess={saveSuccess}
                  checkChevron={checkChevron}
                  childStepSelected={childStepSelected}
                  last={childsStep[childsStep.length - 1].id === child.id}
                />
              ))}
            <button
              disabled={!modelIsPresent}
              type="button"
              aria-label="create-new-child-step-button"
              onClick={addChildStep}
              className={css(styles.newChildStepCard)}>
              <p className={css(styles.titleText)}>{t("editstep.create_new_child_step")}</p>
              <Add />
            </button>
          </div>
        </main>
      </Suspense>

      <ConfirmNewModelModal
        isOpen={showConfirmRedirect}
        confirm={() => redirectModel()}
        cancel={() => setShowConfirmRedirect(false)}
        theme={themeStyles}
      />
      <ConfirmDeleteStepModal
        isOpen={showConfirmDelete}
        confirm={() => deleteStep()}
        cancel={() => setShowConfirmDelete(false)}
        theme={themeStyles}
      />
    </section>
  )
}

const getStyles = ({ theme }) =>
  StyleSheet.create({
    container: {
      background: theme.BACKGROUND_COLOR,
      width: "100%",
      padding: "30px",
      fontFamily: "Roboto",
      height: "100dvh",
      boxSizing: "border-box",
      overflow: "auto",
      "::-webkit-scrollbar": {
        width: 4,
      },
    },
    headerTitle: {
      fontWeight: "600",
      fontSize: 20,
      color: theme.PRIMARY_COLOR,
      margin: 0,
      textTransform: "uppercase",
      "@media (max-width: 1125px)": {
        fontSize: 16,
      },
    },
    titleContainer: {
      display: "flex",
      gap: 20,
      justifyContent: "space-between",
      alignItems: "center",
      fontFamily: "Roboto",
      "@media (max-width: 1125px)": {
        gap: 5,
      },
    },
    header: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      marginBottom: 10,
      gap: 30,
    },
    oneItemSelect: {
      color: theme.PRIMARY_COLOR,
      background: "none",
      border: "none",
      textAlign: "left",
      width: "-webkit-fill-available",
      fontSize: 14,
      boxSizing: "border-box",
      padding: "5px 10px",
      cursor: "pointer",
      ":hover": {
        background: NEUTRAL_LIGHT,
      },
    },
    selectContainer: {
      position: "absolute",
      left: 0,
      top: 35,
      right: "auto",
      background: WHITE,
      padding: "10px 0",
      borderRadius: 3,
      boxSizing: "border-box",
      border: `2px solid ${NEUTRAL_LIGHT}`,
      minWidth: "-webkit-fill-available",
      width: "max-content",
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      maxHeight: "30dvh",
      overflow: "auto",
      zIndex: 100,
      "::-webkit-scrollbar": {
        width: 4,
      },
      "@media (max-width: 1125px)": {
        top: 30,
      },
    },
    stepSelectedItem: {
      textDecorationLine: "underline",
      fontWeight: "800",
    },
    flex: { display: "flex", gap: 5, alignItems: "center", position: "relative" },
    addBtn: {
      fontSize: 13,
      height: 35,
      fontWeight: "400",
      fontFamily: "Roboto",
      padding: "0 10px",
    },
    descNewStep: {
      fontSize: 16,
      fontFamily: "Roboto",
      fontWeight: "700",
      color: theme.PRIMARY_COLOR,
      margin: "0px 0px 15px",
    },
    styleBtn: {
      background: theme?.SECONDARY_COLOR,
      height: 35,
      minWidth: 40,
      borderRadius: 4,
    },
    select: {
      fontWeight: "600",
      fontSize: 20,
      color: theme.PRIMARY_COLOR,
      margin: 0,
      background: "none",
      border: "none",
      textDecorationLine: "underline",
      textTransform: "uppercase",
      display: "flex",
      gap: 10,
      alignItems: "center",
      cursor: "pointer",
      fontFamily: "Roboto",
      "@media (max-width: 1125px)": {
        fontSize: 16,
      },
      ":disabled": {
        opacity: "0.6",
        cursor: "default",
      },
    },
    smallText: {
      fontWeight: "400",
      fontFamily: "Roboto",
      fontSize: 12,
      color: theme.PRIMARY_COLOR,
      margin: "8px 0 0",
    },
    assetContainer: {
      width: 550,
      border: `4px solid ${theme.PRIMARY_COLOR}`,
      background: NEUTRAL_DARK,
      height: "80dvh",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      position: "relative",
      boxSizing: "border-box",
      overflow: "hidden",
    },
    detailsContainer: {
      "@media (min-width: 820px)": {
        width: "-webkit-fill-available",
      },
    },
    mainContainer: {
      display: "flex",
      paddingTop: 30,
      gap: 30,
      alignItems: "flex-start",
      boxSizing: "border-box",
    },
    footerCard: {
      position: "absolute",
      bottom: 0,
      height: 60,
      left: 0,
      right: 0,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      gap: 20,
    },
    newChildStepCard: {
      height: 40,
      background: theme.PRIMARY_COLOR || NEUTRAL_DARK,
      width: "100%",
      color: WHITE,
      display: "flex",
      alignItems: "center",
      padding: "0 15px",
      boxSizing: "border-box",
      marginTop: 15,
      fontWeight: "600",
      fontFamily: "Roboto",
      border: "none",
      borderRadius: 1,
      justifyContent: "space-between",
      cursor: "pointer",
      ":disabled": {
        background: NEUTRAL_DARK,
        cursor: "default",
      },
    },
    desc: {
      fontSize: 16,
      fontFamily: "Roboto",
      fontWeight: "500",
      color: theme.PRIMARY_COLOR,
      margin: "0px 0px 15px",
    },
    paginateText: {
      color: WHITE,
      fontSize: 14,
      fontFamily: "Roboto",
      fontWeight: "600",
      textTransform: "uppercase",
      textDecorationLine: "underline",
      margin: 0,
    },
    editModelBtn: {
      position: "absolute",
      borderTopLeftRadius: 0,
      left: 0,
      top: 0,
      background: theme.PRIMARY_COLOR,
      border: "none",
      color: WHITE,
      padding: "8px 10px",
      fontFamily: "Roboto",
      fontWeight: "400",
      fontSize: 12,
      borderBottomRightRadius: 4,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      gap: 5,
      cursor: "pointer",
      ":hover": {
        opacity: 0.6,
      },
    },
    titleText: {
      fontSize: 14,
      textTransform: "uppercase",
    },
    img: { flex: 1 },
    link: {
      color: theme.PRIMARY_COLOR,
      fontSize: 12,
      fontFamily: "Roboto",
      fontWeight: "400",
    },
  })
