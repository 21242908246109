import { useLazyQuery, useMutation } from "@apollo/client"
import { useCallback, useEffect, useLayoutEffect, useState } from "react"
import { useLocation, useNavigate, useSearchParams } from "react-router-dom"
import api from "../api"
import { BRANDS, BRANDS_BY_LABEL, BRAND_BY_ID_BRAND } from "../graphql/brand"
import { ALL_MANUALS, CREATE_MANUAL } from "../graphql/manuals"
import { captureErrorGraphql } from "../lib/sentry"
const environment = process.env.REACT_APP_ENV || "development"
const configEnv = require(`../config/config.${environment}.js`)
const config = configEnv?.default

export const useManualsPage = () => {
  const navigate = useNavigate()
  const [params, setSearchParams] = useSearchParams()
  const location = useLocation()

  // states --------------------------------------------------------------------
  const [manuals, setManuals] = useState([])
  const [brands, setBrands] = useState([])
  const [loading, setLoading] = useState(false)
  const [brandsByLabel, setBrandsByLabel] = useState([])
  const [showPopIn, setShowPopIn] = useState(false)

  const first = 15
  const [paginationState, setPaginationState] = useState({
    currentPage: 1,
    totalCount: null,
    offset: 0,
  })

  // graphql  --------------------------------------------------------------------
  const [fetchBrands] = useLazyQuery(BRANDS, {
    onCompleted: data => {
      const array = []
      for (const brand of data.brands.nodes) {
        array.push({ value: brand.id, label: brand.label, nbManuals: brand.nbManuals })
      }
      setBrands([...array])
    },
  })
  const [fetchBrandsByLabel] = useLazyQuery(BRANDS_BY_LABEL, {
    onCompleted: data => {
      const array = []
      for (const brand of data.brands.nodes) {
        array.push({ value: brand.id, label: brand.label })
      }
      setBrandsByLabel([...array])
    },
  })
  const [fetchAllManualsDb, { loading: loadingAllManuals }] = useLazyQuery(ALL_MANUALS, {
    fetchPolicy: "network-only",
    onCompleted: data => {
      if (data.manuals) {
        const array = []
        for (const manual of data.manuals.nodes) {
          array.push({
            ...manual,
            productName: manual.productByIdProduct.name,
            imgSrc: `${config.BASE_URL}/manuals/${manual.id}/image`,
          })
        }
        setManuals(array)
        setPaginationState(prev => ({ ...prev, totalCount: data?.manuals?.totalCount || 0 }))
      }
    },
  })
  const [fetchManualsByIdBrand, { loading: loadingManualsByBrand }] = useLazyQuery(BRAND_BY_ID_BRAND, {
    fetchPolicy: "network-only",
    onCompleted: data => {
      if (data.brands) {
        const array = []
        for (const product of data.brands.nodes[0].productsByIdBrand.nodes) {
          for (const manual of product.manualsByIdProduct.nodes) {
            array.push({
              ...manual,
              productName: product.name,
              imgSrc: `${config.BASE_URL}/manuals/${manual.id}/image`,
            })
          }
        }
        setManuals(array)
        setPaginationState(prev => ({
          ...prev,
          totalCount: data?.brands.nodes[0].productsByIdBrand?.totalCount || 0,
        }))
      }
    },
  })
  const [createManual] = useMutation(CREATE_MANUAL, {
    refetchQueries: [{ query: BRAND_BY_ID_BRAND }],
  })

  // func ----------------------------------------------------------------------
  useEffect(() => {
    fetchBrands()
  }, [])

  useLayoutEffect(() => {
    if (!params.get("page")) {
      setSearchParams({ page: 1 })
    } else {
      const newPagination = { ...paginationState }
      newPagination.currentPage = Number(params.get("page"))
      newPagination.offset = (Number(params.get("page")) - 1) * first
      newPagination.totalCount = null

      if (params.get("brand")) {
        fetchManualsByIdBrand({ variables: { id: params.get("brand") } })
      } else {
        fetchAllManualsDb({
          variables: { offset: (Number(params.get("page")) - 1) * first, first },
        })
      }
      setPaginationState(newPagination)
    }
  }, [location])

  const changeCurrentPage = page => {
    setSearchParams({ page })
  }

  const uploadFile = async (idManual, file, filename) => {
    try {
      await api.postManualFile(idManual, file, filename)

      navigate(-1)
      if (params.get("brand")) {
        fetchManualsByIdBrand({ variables: { id: params.get("brand") } })
      } else {
        fetchAllManualsDb({
          variables: { offset: paginationState.offset, first },
        })
      }
      setLoading(false)
      setShowPopIn(false)
    } catch {
      // error upload file
      setLoading(false)
    }
  }

  const createdNewManual = data => {
    setLoading(true)
    const variables = {
      ...(data.brandId && { pIdBrand: data.brandId }),
      ...(data.time && { minutes: Number(data.time) }),
      ...(data.nbUsers && { pNumberOfPeopleNeeded: Number(data.nbUsers) }),
      ...(data.productName && { pProductName: data.productName }),
    }
    createManual({
      variables: { ...variables },
      onError: e => {
        captureErrorGraphql(e, "useManualsPage", "CREATE_MANUAL", variables)
        setLoading(false)
      },
      onCompleted: newManuals => {
        uploadFile(newManuals?.createManualHelper?.manual?.id, data?.file?.data, data?.file?.filename)
      },
    })
  }

  const handleModal = useCallback(() => {
    setShowPopIn(prev => !prev)
  }, [])

  return {
    brandsDb: brands ?? [],
    brandsByLabelDb: brandsByLabel ?? [],
    manuals: !loadingAllManuals && !loadingManualsByBrand && manuals.length > 0 ? manuals : [],
    fetchBrandsByLabel,
    createdNewManual,
    changeCurrentPage,
    paginationState,
    first,
    showPopIn,
    handleModal,
    loadingCreate: loading,
    loadingFethData: loadingAllManuals || loadingManualsByBrand,
  }
}
