import { useLazyQuery, useMutation } from "@apollo/client"
import { useCallback, useEffect, useState } from "react"
import { useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom"
import {
  CREATE_CHILD_STEP,
  CREATE_MANUAL_STEP,
  CREATE_STEP_MODEL,
  CREATE_STEP_PART,
  CREATE_STEP_TOOL,
  DELETE_CHILD_STEP,
  DELETE_STEP,
  DELETE_STEP_PART,
  DELETE_STEP_TOOL,
  FETCH_STEP_MODEL,
  MANUAL_STEP,
  STEPS_BY_MANUAL,
  UPDATE_CHILD_STEP,
  UPDATE_STEP_MODEL,
  UPDATE_STEP_PART_QUANTITY,
} from "../graphql/step"
import { captureErrorGraphql } from "../lib/sentry"

export const useStepPage = () => {
  // states --------------------------------------------------------------------
  const [step, setStep] = useState([])
  const [childsStep, setChildsStep] = useState([])
  const [childStepSelected, setChildStepSelected] = useState(null)
  const [steps, setSteps] = useState([])
  const [manualData, setManualData] = useState(null)
  const [stepSelected, setStepSelected] = useState({ id: "", label: "", pos: null })
  const [model, setModel] = useState()
  const [saveSuccess, setSaveSuccess] = useState(false)

  // hooks ---------------------------------------------------------------------
  const { idstep } = useParams()
  const [params, setSearchParams] = useSearchParams()
  const navigate = useNavigate()
  const location = useLocation()

  // graphql  --------------------------------------------------------------------
  const [fetchStep] = useLazyQuery(MANUAL_STEP, {
    fetchPolicy: "cache-and-network",
    variables: { id: idstep },
    onCompleted: data => {
      const array = []
      if (data?.manualStep?.childStepsByIdManualStep?.nodes) {
        for (const childStep of data.manualStep.childStepsByIdManualStep.nodes) {
          array.push({ ...childStep, expand: params.get("childstep") === childStep.id })
          if (params.get("childstep") === childStep.id) {
            setChildStepSelected({ ...childStep, expand: true })
          }
        }

        setStepSelected({
          label: data?.manualStep?.text,
          id: data?.manualStep?.id,
          pos: data?.manualStep?.pos,
        })

        setChildsStep([...array])
      }
      setStep(data?.manualStep)
      if (sessionStorage.getItem("model3d")) {
        //* you come from the page allowing you to choose a new model ------
        if (data?.manualStep?.stepModelsByIdManualStep?.nodes[0]) {
          //* a model exists for this step, so you update ------------------
          saveUpdateStepModel(
            data?.manualStep?.stepModelsByIdManualStep?.nodes[0]?.id,
            array,
            data?.manualStep?.id,
          )
        } else {
          //* else, create a new step model, you create a first sub-step  ----
          saveNewModelData()
        }
      } else {
        fetchModel()
      }
    },
  })
  const [fetchStepsByManuals] = useLazyQuery(STEPS_BY_MANUAL, {
    fetchPolicy: "cache-and-network",
    variables: { id: params.get("manual") },
    onCompleted: data => {
      setSteps(data?.manual?.manualStepsByIdManual?.nodes)
      setManualData(data?.manual?.productByIdProduct)
      if (location.pathname === "/manuals/step/new") {
        if (sessionStorage.getItem("model3d")) {
          const dataLength = data?.manual?.manualStepsByIdManual?.nodes?.length
          const lastPos = dataLength > 0 ? data?.manual?.manualStepsByIdManual?.nodes[dataLength - 1].pos : 0

          saveNewStepData({
            pos: lastPos + 1,
          })
        } else {
          setStepSelected({
            label: "",
            id: "",
            new: true,
            pos: data?.manual?.manualStepsByIdManual?.nodes?.length + 1,
          })
        }
      }
    },
  })
  const [fetchModel] = useLazyQuery(FETCH_STEP_MODEL, {
    fetchPolicy: "network-only",
    variables: { id: idstep },
    onCompleted: data => {
      if (data?.manualStep?.stepModelsByIdManualStep?.nodes[0]) {
        if (data?.manualStep?.stepModelsByIdManualStep?.nodes[0].id !== model?.id) {
          setModel(data?.manualStep?.stepModelsByIdManualStep?.nodes[0])
        }
      } else {
        setModel(null)
      }
    },
  })
  const [createStepPart] = useMutation(CREATE_STEP_PART)
  const [createStepTool] = useMutation(CREATE_STEP_TOOL)
  const [createChildStep] = useMutation(CREATE_CHILD_STEP)
  const [createModelStep] = useMutation(CREATE_STEP_MODEL)
  const [createManualStep] = useMutation(CREATE_MANUAL_STEP)

  const [deleteStepTool] = useMutation(DELETE_STEP_TOOL)
  const [deleteStepPart] = useMutation(DELETE_STEP_PART)
  const [deleteStep] = useMutation(DELETE_STEP)
  const [deleteChildStep] = useMutation(DELETE_CHILD_STEP)

  const [updateStepPartQuantity] = useMutation(UPDATE_STEP_PART_QUANTITY)
  const [updateStepModel] = useMutation(UPDATE_STEP_MODEL)
  const [updateChildStep] = useMutation(UPDATE_CHILD_STEP, {
    onCompleted: () => {
      sessionStorage.removeItem("newParts")
      sessionStorage.removeItem("newTools")
      fetchStep()
    },
  })

  // func ----------------------------------------------------------------------
  useEffect(() => {
    if (idstep && location.pathname !== "/manuals/step/new") {
      fetchStep()
    }
    fetchStepsByManuals()
  }, [location])

  const saveNewStepData = ({ pos }) => {
    //* create step
    createManualStep({
      variables: { idManual: params.get("manual"), pos },
      onCompleted: dataStep => {
        //* create model step
        createModelStep({
          variables: {
            idStep: dataStep?.createManualStep?.manualStep?.id,
            idModel: sessionStorage.getItem("model3d"),
          },
          onCompleted: () => {
            sessionStorage.removeItem("model3d")

            //* create first child step
            createChildStep({
              variables: {
                idManualStep: dataStep?.createManualStep?.manualStep?.id,
                pos: 1,
              },
              onCompleted: dataChildStep => {
                const newChildStep = {
                  id: dataChildStep?.createChildStep?.childStep?.id,
                  pos: 1,
                  expand: true,
                }
                setChildsStep([newChildStep])
                setChildStepSelected({ ...newChildStep })

                navigate(
                  `/manuals/step/${dataStep?.createManualStep?.manualStep?.id}?manual=${params.get(
                    "manual",
                  )}&childstep=${dataChildStep?.createChildStep?.childStep?.id}`,
                )
              },
            })
          },
          onError: e => {
            captureErrorGraphql(e, "useStepPage", "CREATE_STEP_MODEL", {
              idStep: dataStep?.createManualStep?.manualStep?.id,
              idModel: sessionStorage.getItem("model3d"),
            })
          },
        })
      },
      onError: e => {
        captureErrorGraphql(e, "useStepPage", "CREATE_MANUAL_STEP", {
          idManual: params.get("manual"),
          pos,
        })
      },
    })
  }

  const saveUpdateStepModel = (idStepModel, childs, idStep) => {
    const idModel = sessionStorage.getItem("model3d")

    //* update model -------
    updateStepModel({
      variables: { id: idStepModel, idModel },
      onCompleted: () => {
        sessionStorage.removeItem("model3d")
        //!  delete all childs step -----------------------------------------
        let count = 0
        for (const childStep of childs) {
          deleteChildStep({
            variables: { id: childStep.id },
            onCompleted: () => {
              count = count + 1
              if (count === childs.length) {
                //* create first child step ----------------------------------
                createChildStep({
                  variables: { idManualStep: idStep, pos: 1 },
                  onCompleted: data => {
                    const newChildStep = {
                      id: data?.createChildStep?.childStep?.id,
                      pos: 1,
                      expand: true,
                    }
                    setChildsStep([newChildStep])
                    setChildStepSelected({ ...newChildStep })
                    navigate(
                      `?manual=${params.get("manual")}&childstep=${data?.createChildStep?.childStep?.id}`,
                    )
                  },
                })
              }
            },
            onError: e => {
              captureErrorGraphql(e, "useStepPage", "DELETE_CHILD_STEP", { id: childStep.id })
            },
          })
        }
      },
      onError: e => {
        captureErrorGraphql(e, "useStepPage", "UPDATE_STEP_MODEL", {
          id: idStepModel,
          idModel,
        })
      },
    })
  }

  const deleteSelectedStep = () => {
    if (stepSelected?.id) {
      //! delete step ----------------------------------------------------------
      deleteStep({
        variables: { idStep: stepSelected.id },
        onCompleted: () => {
          setStepSelected(null)
          navigate(`/manuals/manager?manual=${params.get("manual")}`)
        },
        onError: e => {
          captureErrorGraphql(e, "useStepPage", "DELETE_STEP", { idStep: stepSelected.id })
        },
      })
    }
  }

  const deleteChildStepSelected = id => {
    if (id) {
      //! delete child step ----------------------------------------------------
      deleteChildStep({
        variables: { id },
        onCompleted: () => {
          setSearchParams({
            ...(params.get("manual") && { manual: params.get("manual") }),
          })
        },
        onError: e => {
          captureErrorGraphql(e, "useStepPage", "DELETE_CHILD_STEP", { id })
        },
      })
    }
  }

  const saveNewModelData = () => {
    //* create model 3d pictures if model is new
    createModelStep({
      variables: { idStep: idstep, idModel: sessionStorage.getItem("model3d") },
      onCompleted: () => {
        sessionStorage.removeItem("model3d")
        fetchModel()
      },
      onError: e => {
        captureErrorGraphql(e, "useStepPage", "CREATE_STEP_MODEL", {
          idStep: idstep,
          idModel: sessionStorage.getItem("model3d"),
        })
      },
    })
    // todo clean child step --------
  }

  const addNewStepFunc = useCallback(() => {
    sessionStorage.removeItem("newParts")
    sessionStorage.removeItem("newTools")
    setChildsStep([])
    setModel(null)
    setStepSelected({
      label: "",
      id: "",
      new: true,
      pos: steps.length + 1,
    })
  })

  const addChildStep = () => {
    const oldData = childsStep.map(child => ({ ...child, expand: false }))
    const pos = childsStep[childsStep.length - 1]?.pos ? childsStep[childsStep.length - 1].pos + 1 : 1
    createChildStep({
      variables: { idManualStep: step.id, pos },
      onCompleted: data => {
        const newChildStep = {
          id: data?.createChildStep?.childStep?.id,
          pos,
          expand: true,
        }
        setChildsStep([...oldData, newChildStep])
        setChildStepSelected({ ...newChildStep })
        navigate(`?manual=${params.get("manual")}&childstep=${data?.createChildStep?.childStep?.id}`)
      },
      onError: e => {
        captureErrorGraphql(e, "useStepPage", "CREATE_CHILD_STEP", {
          idManualStep: step.id,
          pos,
        })
      },
    })
  }

  const saveChildStepData = useCallback(async dataChildStep => {
    //* old child step
    for (const part of dataChildStep.parts) {
      if (part.new) {
        //* create part
        createStepPart({
          variables: {
            idChildStep: dataChildStep.childStepId,
            idPart: part.id,
            quantity: Number(part.quantity),
          },
          onError: e => {
            captureErrorGraphql(e, "useStepPage", "CREATE_STEP_PART", {
              idChildStep: dataChildStep.childStepId,
              idPart: part.id,
              quantity: Number(part.quantity),
            })
          },
        })
      } else if (part.isChange) {
        //* update part quantity
        await updateStepPartQuantity({
          variables: { id: part.id, quantity: Number(part.quantity) },
          onError: e => {
            captureErrorGraphql(e, "useStepPage", "UPDATE_STEP_PART_QUANTITY", {
              id: part.id,
              quantity: Number(part.quantity),
            })
          },
        })
      } else if (part.inTheTrash) {
        //* delete step part
        await deleteStepPart({
          variables: { id: part.id },
          onError: e => {
            captureErrorGraphql(e, "useStepPage", "DELETE_STEP_PART", { id: part.id })
          },
        })
      }
    }

    for (const tool of dataChildStep.tools) {
      if (tool.new) {
        //* create tool
        createStepTool({
          variables: { idChildStep: dataChildStep.childStepId, idTool: tool.id, quantity: 1 },
          onError: e => {
            captureErrorGraphql(e, "useStepPage", "CREATE_STEP_TOOL", {
              idChildStep: dataChildStep.childStepId,
              idTool: tool.id,
              quantity: 1,
            })
          },
        })
      } else if (tool.inTheTrash) {
        //* delete step tool
        await deleteStepTool({
          variables: { id: tool.id },
          onError: e => {
            captureErrorGraphql(e, "useStepPage", "DELETE_STEP_TOOL", { id: tool.id })
          },
        })
      }
    }

    //* update nb repeat
    await updateChildStep({
      variables: { nbRepeat: Number(dataChildStep.repeat), id: dataChildStep.childStepId },
      onError: e => {
        captureErrorGraphql(e, "useStepPage", "UPDATE_CHILD_STEP", {
          nbRepeat: Number(dataChildStep.repeat),
          id: dataChildStep.childStepId,
        })
      },
    })

    sessionStorage.removeItem("newParts")
    sessionStorage.removeItem("newTools")
    sessionStorage.removeItem("idCurrentChildStep")
    setSaveSuccess(true)
    setTimeout(() => {
      setSaveSuccess(false)
    }, 1800)
  })

  return {
    step,
    stepsByManual: steps || [],
    manual: manualData,
    childStepSelected,
    childsStep,
    setChildsStep,
    setChildStepSelected,
    setStepSelected,
    addNewStepFunc,
    stepSelected,
    addChildStep,
    deleteStep: () => deleteSelectedStep(),
    saveChildStepData,
    stepModel: model || null,
    modelIsPresent: !!model,
    deleteChildStep: deleteChildStepSelected,
    saveSuccess,
  }
}
