import { css, StyleSheet } from "aphrodite"
import PropTypes from "prop-types"
import React, { useEffect } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import { Logout } from "../../assets/svgr"
import { useAuth } from "../../hooks"
import { UIButtonIcon } from "../UI"

export const LogoutHeader = ({ themeStyles }) => {
  const { t } = useTranslation("ui")
  const navigate = useNavigate()
  const { userLog, refreshUserData } = useAuth()

  // init style ----------------------------------------------------------------
  const styles = getStyles({ theme: themeStyles })

  // func ----------------------------------------------------------------------
  const logOut = () => {
    navigate("/signin")
  }

  useEffect(() => {
    if (!userLog?.role || !userLog?.lastname) refreshUserData()
  }, [])

  return (
    <div className={css(styles.userContainer)}>
      <div className={css(styles.userFlex)}>
        <p style={{ margin: 0 }}>{t("hello")}</p>
        <p className={css(styles.user)}>{`${userLog?.firstname} ${userLog?.lastname}`}</p>
      </div>

      <UIButtonIcon
        hiddenBackgroundColor={true}
        onClick={logOut}
        icon={<Logout color={themeStyles?.PRIMARY_COLOR} />}
      />
    </div>
  )
}

const getStyles = ({ theme }) =>
  StyleSheet.create({
    userContainer: {
      fontSize: 14,
      display: "flex",
      alignItems: "center",
      gap: 5,
      color: theme?.PRIMARY_COLOR,
      fontFamily: "Roboto",
    },
    userFlex: {
      display: "flex",
      alignItems: "center",
      gap: 10,
    },
    user: {
      fontWeight: "800",
      fontFamily: "Roboto",
      margin: 0,
      fontSize: 14,
    },
  })

LogoutHeader.propTypes = {
  themeStyles: PropTypes.object,
}
