import * as Progress from "@radix-ui/react-progress"
import { StyleSheet, css } from "aphrodite"
import PropTypes from "prop-types"
import React, { useCallback, useEffect, useMemo, useState } from "react"
import { UIButtonIcon, UISelect } from "."
import Paused from "../../assets/svgr/Paused"
import Play from "../../assets/svgr/Play"
import { BLACK_COLOR, NEUTRAL_MEDIUM, WHITE } from "../../styles/colors"

const UIPlayerBlue = ({
  disabled,
  animations,
  onAnimationChanged,
  handlePlay,
  handleSeek,
  animationState,
  animationTime = 0,
  size = "medium",
  primaryColor = BLACK_COLOR,
  secondaryColor = NEUTRAL_MEDIUM,
}) => {
  const [animationSelected, setAnimationSelected] = useState({})

  // init style ----------------------------------------------------------------
  const styles = useMemo(
    () => getStyles({ animations: !!animations.length > 0, size, primaryColor }),
    [animations, size, primaryColor],
  )

  const handleClick = useCallback(
    e => {
      if (!animationSelected.duration) return
      const rect = e.currentTarget.getBoundingClientRect()
      const x = e.clientX - rect.left // x position within the element
      const width = rect.right - rect.left // width of the element
      const duration = animationSelected.duration
      const newAnimationTime = (x / width) * duration
      handleSeek(newAnimationTime)
    },
    [animationSelected, handleSeek],
  )

  const handleChangeAnimation = useCallback(
    option => {
      const itemSelected = animations.find(anim => anim.name === option.value) || {}
      onAnimationChanged(option.value)
      setAnimationSelected({
        ...itemSelected,
        value: itemSelected?.value,
        label: itemSelected?.name,
      })
    },
    [animations, onAnimationChanged],
  )

  useEffect(() => {
    if (animations?.length > 0 && !animationSelected?.value) {
      setAnimationSelected({
        duration: animations[0].duration,
        value: animations[0].value,
        label: animations[0].name,
      })
      onAnimationChanged(animations[0].name)
    }
  }, [animations])

  return (
    <div className={css(styles.player)}>
      <UIButtonIcon
        hiddenBackgroundColor={true}
        onClick={handlePlay}
        dynamicStyle={[styles.play]}
        disabled={disabled}
        icon={animationState === "paused" ? <Play /> : <Paused />}
      />
      <Progress.Root className={css(styles.progressBar)} value={animationTime} onClick={handleClick}>
        <Progress.Indicator
          className={css(styles.progressBarTimeline)}
          style={{
            width: animationSelected.label ? `${(animationTime / animationSelected.duration) * 100}%` : 0,
          }}
        />
      </Progress.Root>

      {animations?.length > 0 && (
        <div className={css(styles.select_container)}>
          <UISelect
            options={animations?.map(anim => ({ value: anim.name, label: anim.name }))}
            onOptionChanged={handleChangeAnimation}
            defaultValue={{ value: animationSelected?.label, label: animationSelected?.name }}
            value={animationSelected}
            primaryColor={primaryColor}
            secondaryColor={secondaryColor}
            isDisabled={!animations?.length > 0}
            outlined={true}
            menuPlacement="top"
          />
        </div>
      )}
    </div>
  )
}

UIPlayerBlue.propTypes = {
  animations: PropTypes.array,
  onAnimationChanged: PropTypes.func,
  handlePlay: PropTypes.func,
  handleSeek: PropTypes.func,
  animationState: PropTypes.string,
  animationTime: PropTypes.number,
  disabled: PropTypes.bool,
  size: PropTypes.string,
  primaryColor: PropTypes.string,
  secondaryColor: PropTypes.string,
}

const getStyles = ({ animations, size, primaryColor }) =>
  StyleSheet.create({
    player: {
      background: `linear-gradient(180deg, rgba(0, 125, 188, 0.00) 0%,${primaryColor} 100%)`,
      position: "sticky",
      bottom: 0,
      left: 0,
      height: 80,
      right: 0,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      gap: size === "small" ? 10 : 15,
      padding: size === "small" ? "0 10px" : "0 20px",
      boxSizing: "border-box",
      width: "100%",
    },
    play: {
      background: "transparent",
      padding: 0,
      width: 40,
    },
    progressBar: {
      height: 8,
      width: !animations ? "calc(100% - 40px)" : size === "small" ? "calc(64% - 40px)" : "calc(75% - 40px)",
      background: "rgb(255, 255, 255, 0.5)",
    },
    progressBarTimeline: {
      background: WHITE,
      height: 8,
      opacity: 1,
    },
    select_container: {
      width: size === "small" ? "36%" : "25%",
    },
  })

export default UIPlayerBlue
