import IconButton from "@mui/material/IconButton"
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip"
import { styled } from "@mui/material/styles"
import Info from "../../assets/svgr/Info"
import { WHITE } from "../../styles/colors"

const UIHtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }}>
    <IconButton style={{ background: "transparent", padding: 0, width: 30 }}>
      <Info />
    </IconButton>
  </Tooltip>
))(({ theme, primarycolor }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: primarycolor,
    color: WHITE,
    maxWidth: 330,
    textAlign: "center",
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
  },
}))

export default UIHtmlTooltip
