import { css, StyleSheet } from "aphrodite"
import PropTypes from "prop-types"
import React from "react"
import ClipLoader from "react-spinners/ClipLoader"
import { Check } from "../../assets/svgr"
import { NEUTRAL_DARK, WHITE } from "../../styles/colors"

export const UIButtonIcon = ({
  loading = false,
  animationSuccess = false,
  icon,
  dynamicStyle,
  hiddenBackgroundColor = false,
  label = "",
  ...props
}) => {
  // animation ---------------------------------------------------------------------------
  const translateKeyframes = {
    "0%": {
      transform: "rotate(180deg)",
    },
    "100%": {
      transform: "rotate(0deg)",
    },
  }

  const styles = getStyles({ translateKeyframes, disabled: props?.disabled, hiddenBackgroundColor })

  return (
    <>
      {animationSuccess ? (
        <button
          aria-label={`${label}-btn` || `button-icon`}
          type="button"
          {...props}
          className={css(styles.buttonContainer, dynamicStyle)}>
          {label && <p>{label}</p>}

          <div
            style={{
              border: "none",
              background: WHITE,
              height: 20,
              width: 20,
              borderRadius: "50%",
            }}
            className={css(styles.animationSuccess)}>
            <Check />
          </div>
        </button>
      ) : (
        <button
          aria-label={`${label}-btn` || `button-icon`}
          type="button"
          {...props}
          className={css(styles.buttonContainer, dynamicStyle)}>
          {label && <p>{label}</p>}
          {loading ? (
            <ClipLoader color={WHITE} loading={loading} speedMultiplier={1} size={15} margin={4} />
          ) : (
            icon
          )}
        </button>
      )}
    </>
  )
}

const getStyles = ({ translateKeyframes, disabled, hiddenBackgroundColor }) =>
  StyleSheet.create({
    buttonContainer: {
      borderStyle: "none",
      cursor: "pointer",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      background: "transparent",
      ":disabled": {
        background: hiddenBackgroundColor ? null : NEUTRAL_DARK,
        opacity: 0.6,
        cursor: "default",
      },
      ":hover": {
        opacity: disabled && !hiddenBackgroundColor ? "1" : "0.6",
      },
    },
    animationSuccess: {
      animationDuration: "600ms",
      animationFillMode: "forwards",
      animationIterationCount: "initial",
      animationName: [translateKeyframes],
    },
  })

UIButtonIcon.propTypes = {
  icon: PropTypes.node,
  dynamicStyle: PropTypes.array,
  animationSuccess: PropTypes.bool,
  loading: PropTypes.bool,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  hiddenBackgroundColor: PropTypes.bool,
}
