import { css, StyleSheet } from "aphrodite"
import PropTypes from "prop-types"
import React, { useLayoutEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import imagePictures from "../../assets/img/cyclePlaceholder.png"
import imageModels from "../../assets/img/modelPlaceholder.png"
import imageVideos from "../../assets/img/videoPlaceholder.png"
import { WHITE } from "../../styles/colors"

export const WidgetCard = ({ type, themeStyles }) => {
  const [isHovered, setIsHovered] = useState(false)
  const [img, setImg] = useState()

  const handleMouseEnter = () => {
    setIsHovered(true)
  }

  const handleMouseLeave = () => {
    setIsHovered(false)
  }

  // others hooks --------------------------------------------------------------
  const navigate = useNavigate()
  const { t } = useTranslation("libraryPage")

  // init style ----------------------------------------------------------------
  const styles = getStyles({ theme: themeStyles, url: img })

  useLayoutEffect(() => {
    if (type) {
      if (type.label === "picture") {
        setImg(imagePictures)
      } else if (type.label === "3d") {
        setImg(imageModels)
      } else {
        setImg(imageVideos)
      }
    }
  }, [type])

  return (
    <>
      {img && (
        <button
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          type="button"
          aria-label="item type"
          className={css(styles.card)}
          onClick={() => navigate(`?type=${type.label}&page=1`)}>
          <div className={css(styles.cardFooter, isHovered && styles.cardHover)}>
            <p className={css(styles.textFooter)}>{t(`selectType.${type.label}`)}</p>
          </div>
        </button>
      )}
    </>
  )
}

const getStyles = ({ theme, url }) =>
  StyleSheet.create({
    card: {
      position: "relative",
      cursor: "pointer",
      border: "none",
      height: "auto",
      width: "calc(33.33% - 20px)",
      maxWidth: 400,
      margin: 0,
      padding: 0,
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      background: `url(${url}), lightgray -208.644px -36.153px / 194.483% 100% no-repeat`,
    },
    cardHover: {
      height: 220,
      zIndex: "2",
      clipPath: "polygon(100% 0, 100% 100%, 0 100%, 0 30%)",
    },
    cardFooter: {
      position: "absolute",
      bottom: 0,
      right: 0,
      left: 0,
      background: theme.PRIMARY_COLOR,
      height: 114,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      zIndex: 1,
      transition: "height 0.3s ease-in-out",
    },
    textFooter: {
      color: WHITE,
      textDecoration: "underline",
      fontSize: 14,
      textTransform: "uppercase",
      fontWeight: "700",
      fontFamily: "Roboto",
    },
  })

WidgetCard.propTypes = {
  type: PropTypes.object,
  themeStyles: PropTypes.object,
}
