import { css, StyleSheet } from "aphrodite"
import PropTypes from "prop-types"
import React from "react"
import ImageUploading from "react-images-uploading"
import { NEUTRAL_LIGHT } from "../../styles/colors"
import { UIButton } from "./UIButton"

const UIImgUpload = ({
  uploadLabel,
  uploadBtnText,
  image = null,
  type,
  width,
  disabled = false,
  onChange,
  loading = false,
  removeImage,
  error = false,
  multiple = false,
  btnColor,
  height,
  theme,
  onError,
  labelDynamicStyle,
}) => {
  const styles = getStyles({ width, theme, btnColor, height })

  return (
    <ImageUploading
      acceptType={["jpg", "jpeg", "png"]}
      value={image}
      dataURLKey="data_url"
      onChange={onChange}
      multiple={multiple}
      onError={onError}>
      {({ onImageUpload, isDragging, dragProps }) => (
        <div>
          <p className={css(styles.label, labelDynamicStyle)}>{uploadLabel}</p>

          {!image && !error && !loading ? (
            <>
              <UIButton
                dynamicStyle={[styles.btn]}
                label={uploadBtnText}
                themeStyle={theme}
                kind="primary"
                type={type}
                disabled={disabled}
                onClick={onImageUpload}
                {...dragProps}
                style={isDragging ? { color: "red" } : undefined}
              />
            </>
          ) : (
            <div style={{ display: "flex", alignItems: "center" }}>
              {!error && (
                <UIButton
                  dynamicStyle={[styles.btn]}
                  label={uploadBtnText}
                  themeStyle={theme}
                  kind="primary"
                  type={type}
                  onClick={() => {
                    removeImage()
                    onImageUpload()
                  }}
                  {...dragProps}
                  disabled={disabled}
                  style={isDragging ? { color: NEUTRAL_LIGHT } : undefined}
                />
              )}
            </div>
          )}
        </div>
      )}
    </ImageUploading>
  )
}

const getStyles = ({ height, width, theme, btnColor }) =>
  StyleSheet.create({
    buttonUpload: {
      width,
      height,
      backgroundColor: "transparent",
      borderStyle: "dashed",
      borderRadius: "4px",
      boxSizing: "border-box",
      border: "none",
      fontWeight: "400",
      fontFamily: "Roboto",
      fontSize: 13,
    },
    uploadDescriptionContainer: {
      display: "flex",
      justifyContent: "center",
    },
    uploadDetail: {
      margin: 0,
      fontWeight: 300,
      fontwidth: "10px",
      opacity: 0.4,
      width: "85%",
      fontStyle: "italic",
      textAlign: "center",
    },
    btn: {
      fontWeight: "400",
      width,
      fontFamily: "Roboto",
      fontSize: 13,
      height,
      padding: "0px 10px",
      boxSizing: "border-box",
      backgroundColor: btnColor,
      marginTop: 0,
    },
    label: {
      fontWeight: "600",
      fontFamily: "Roboto",
      fontwidth: 12,
      color: theme.PRIMARY_COLOR,
      margin: 0,
    },
    warningBg: {
      backgroundColor: "#C4C4C4",
      height,
      width,
      borderRadius: 4,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
  })

UIImgUpload.propTypes = {
  uploadLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  uploadBtnText: PropTypes.string,
  image: PropTypes.any,
  onChange: PropTypes.func,
  removeImage: PropTypes.func,
  dynamicStyle: PropTypes.array,
  multiple: PropTypes.bool,
  type: PropTypes.string,
  height: PropTypes.number,
  width: PropTypes.number,
  loading: PropTypes.bool,
  theme: PropTypes.object,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  btnColor: PropTypes.string,
  disabled: PropTypes.bool,
  onError: PropTypes.func,
  labelDynamicStyle: PropTypes.object,
}

export { UIImgUpload }
