import { css, StyleSheet } from "aphrodite"
import React, { Suspense, useCallback, useEffect, useMemo, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { useLocation, useNavigate, useOutletContext, useSearchParams } from "react-router-dom"
import { useEvent } from "react-use"
import { ChevronDynamique } from "../../assets/svgr"
import { useTheme } from "../../components/Provider/ThemeProvider"
import { UIButton } from "../../components/UI"
import { LogoutHeader } from "../../components/Widget/LogoutHeader"
import { useManagerPage } from "../../hooks/useManagerPage"
import { NEUTRAL_LIGHT, WHITE } from "../../styles/colors"
import { WidgetManager } from "./WidgetManager"

export const ManagerPage = () => {
  // states --------------------------------------------------------------------
  const [stepSelected, setStepSelected] = useState({ id: "", label: "" })
  const [openSelect, setOpenSelect] = useState(false)

  // others hooks --------------------------------------------------------------
  const { t } = useTranslation("managerPage")
  const { themeStyles } = useTheme()
  const navigate = useNavigate()
  const stepDivMenu = useRef()
  const [params] = useSearchParams()
  const location = useLocation()
  const { modalHidden } = useOutletContext()

  const {
    manual,
    loading,
    setLoading,
    brandsByLabelDb,
    publishedManual,
    updateData,
    fetchBrandsByLabel,
    saveSuccess,
  } = useManagerPage()

  // init style ----------------------------------------------------------------
  const styles = useMemo(
    () => getStyles({ theme: themeStyles, title: manual?.productByIdProduct?.name }),
    [themeStyles, manual?.productByIdProduct?.name],
  )

  const handleClickOutside = useCallback(event => {
    if (stepDivMenu.current && !stepDivMenu.current.contains(event.target)) {
      setOpenSelect(false)
    }
  }, [])

  useEvent("mousedown", handleClickOutside)

  useEffect(() => {
    setStepSelected({ label: "", value: "" })
  }, [location])

  const addNewStep = useCallback(() => {
    navigate(`/manuals/step/new?manual=${params.get("manual")}`)
  }, [])

  return (
    <section className={css(styles.container)}>
      <Suspense>
        <header className={css(styles.header)}>
          <div className={css(styles.titleContainer)}>
            <div ref={stepDivMenu} className={css(styles.flex)}>
              <h1 className={css(styles.title)}>{`${t("title")} ${
                manual?.productByIdProduct?.name || "-"
              }  -`}</h1>
              <div style={{ position: "relative" }}>
                <button
                  aria-label="open"
                  onClick={() => setOpenSelect(prev => !prev)}
                  type="button"
                  className={css(styles.select)}>
                  {stepSelected?.label || t("manager")}
                  <ChevronDynamique state={openSelect ? "up" : "down"} color={themeStyles?.PRIMARY_COLOR} />
                </button>
                {openSelect && !modalHidden && (
                  <div className={css(styles.selectContainer)}>
                    <button
                      type="button"
                      aria-label="manager"
                      className={css(styles.oneItemSelect, styles.stepSelectedItem)}>
                      <p style={{ margin: 0 }}> {t("manager")}</p>
                    </button>
                    {manual?.stepsData[0] &&
                      manual?.stepsData?.map((step, index) => (
                        <button
                          type="button"
                          aria-label="step"
                          className={css(styles.oneItemSelect)}
                          key={index}
                          onClick={() => {
                            setStepSelected({ label: step.text, id: step.id })
                            navigate(`/manuals/step/${step.id}?manual=${params.get("manual")}`)
                            setOpenSelect(false)
                          }}>
                          <p style={{ margin: 0 }}>{`${t("step")} ${index + 1}`}</p>
                        </button>
                      ))}
                  </div>
                )}
              </div>
            </div>

            <UIButton
              dynamicStyle={[styles.addBtn]}
              kind="primary"
              label={t("add_step")}
              themeStyle={themeStyles}
              onClick={addNewStep}
            />
          </div>

          <LogoutHeader themeStyles={themeStyles} />
        </header>

        <div style={{ maxWidth: 1125 }}>
          <WidgetManager
            themeStyles={themeStyles}
            brandByIdBrand={brandsByLabelDb}
            manual={manual}
            updateData={updateData}
            setLoading={setLoading}
            loading={loading}
            fetchBrandsByLabel={fetchBrandsByLabel}
            publishedManual={publishedManual}
            saveSuccess={saveSuccess}
            addNewStep={addNewStep}
          />
        </div>
      </Suspense>
    </section>
  )
}

const getStyles = ({ theme, title }) =>
  StyleSheet.create({
    container: {
      background: theme.BACKGROUND_COLOR,
      width: "100%",
      padding: "30px",
      fontFamily: "Roboto",
      height: "100dvh",
      boxSizing: "border-box",
      overflow: "auto",
      "::-webkit-scrollbar": {
        width: 4,
      },
    },
    title: {
      fontWeight: "600",
      fontSize: title?.length > 40 ? 18 : 20,
      color: theme.PRIMARY_COLOR,
      margin: 0,
      textTransform: "uppercase",
      "@media (max-width: 1125px)": {
        fontSize: 16,
      },
    },
    titleContainer: {
      display: "flex",
      gap: 20,
      justifyContent: "space-between",
      alignItems: "center",
      fontFamily: "Roboto",
      "@media (max-width: 1125px)": {
        gap: 5,
      },
    },
    header: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      marginBottom: 40,
      gap: 30,
    },
    oneItemSelect: {
      color: theme.PRIMARY_COLOR,
      background: "none",
      border: "none",
      textAlign: "left",
      fontSize: 14,
      boxSizing: "border-box",
      width: "-webkit-fill-available",
      padding: "5px 10px",
      cursor: "pointer",
      ":hover": {
        background: NEUTRAL_LIGHT,
      },
    },
    selectContainer: {
      position: "absolute",
      left: 0,
      right: "auto",
      background: WHITE,
      padding: "10px 0",
      borderRadius: 3,
      boxSizing: "border-box",
      border: `2px solid ${NEUTRAL_LIGHT}`,
      minWidth: "-webkit-fill-available",
      width: "max-content",
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      maxHeight: "30dvh",
      overflow: "auto",
      zIndex: 100,
      "::-webkit-scrollbar": {
        width: 4,
      },
      top: 35,
      "@media (min-width: 1125px)": {
        top: 30,
      },
    },
    stepSelectedItem: {
      textDecorationLine: "underline",
      fontWeight: "800",
    },
    flex: { display: "flex", gap: 5, alignItems: "center", position: "relative" },
    addBtn: {
      minWidth: 130,
      fontSize: 13,
      height: 35,
      fontFamily: "Roboto",
      fontWeight: "400",
    },
    styleBtn: {
      background: theme?.SECONDARY_COLOR,
      height: 35,
      minWidth: 35,
      borderRadius: 4,
    },
    select: {
      fontWeight: "600",
      fontSize: 20,
      color: theme.PRIMARY_COLOR,
      margin: 0,
      background: "none",
      border: "none",
      textDecorationLine: "underline",
      textTransform: "uppercase",
      display: "flex",
      gap: 10,
      alignItems: "center",
      cursor: "pointer",
      fontFamily: "Roboto",
      "@media (max-width: 1125px)": {
        fontSize: 16,
      },
      ":disabled": {
        opacity: "0.6",
        cursor: "default",
      },
    },
    smallText: {
      fontWeight: "400",
      fontFamily: "Roboto",
      fontSize: 12,
      color: theme.PRIMARY_COLOR,
      margin: "8px 0 0",
    },
  })
