import * as THREE from 'three'
import Animator from './Animator.mjs'
import CameraController from './CameraController.mjs'
import World from './World.mjs'
// import InterfaceManager from "../../../../../../base/bower_components/vcult-3d/src/modules/interface/manager.mjs";
import { OutlineEffect } from './OutlineEffect.js'
import SelectionManager from './SelectionManager.mjs'
import ViewerInterfaceManager from './ViewerInterfaceManager.mjs'

class ViewerManager {
  _world
  _elapsedTime = 0
  _deltaTime = 0
  _cameraController
  _modelAsset = null
  _animator = null
  _viewerInterfaceManager = null
  _selectionManager = null
  _needsScreenshot = false
  _effect = null
  _postRenderActions = new Set()
  _outlineLayer = 2
  _animationPaused = false

  get world() {
    return this._world
  }
  get scene() {
    return this.world.scene
  }
  get canvas() {
    return this.world.renderer.domElement
  }
  get cameraController() {
    return this._cameraController
  }
  get modelRoot() {
    if (this._modelAsset !== null) {
      return this._modelAsset.scene
    }

    return null
  }
  get viewerInterfaceManager() {
    return this._viewerInterfaceManager
  }
  get selectionManager() {
    return this._selectionManager
  }
  get currentAnimationName() {
    if (!this._animator) {
      return null
    }

    return this._animator.currentAnimationName
  }

  constructor(canvas, width, height) {
    var renderOptions = {
      canvas: canvas,
      antialias: true,
      // stencil: false,
    }

    var worldOptions = {
      renderOptions,
      near: 0.001,
      far: 1000,
      // clearColor: new THREE.Color(0x22aadd).getHex(THREE.LinearSRGBColorSpace),
      // clearColor: 0x22aadd,
      clearColor: 0xffffff,
      ambientIntensity: 0.3,
      fov: 45,
      autoResize: true,
    }

    worldOptions.width = width
    worldOptions.height = height

    this._world = new World(worldOptions)
    // this.world.renderer.outputColorSpace = THREE.LinearSRGBColorSpace

    // const toneMappings = new Map([
    //   ['No', THREE.NoToneMapping],
    //   ['Linear', THREE.LinearToneMapping],
    //   ['Reinhard', THREE.ReinhardToneMapping],
    //   ['Cineon', THREE.CineonToneMapping],
    //   ['ACES', THREE.ACESFilmicToneMapping],
    //   ['Custom', THREE.CustomToneMapping],
    // ])

    // for (const [k, v] of toneMappings.entries()) {
    //   console.log(k + ' = ' + v)
    // }

    // console.warn(
    //   'TM: ' + this.world.renderer.toneMapping + ' CS: ' + this.world.renderer.outputColorSpace
    // )
    // this.world.addCopyPass()
    // this.world.addFXAAPass()
    // this.world.addDotScreenPass()
    // this.world.addCopypass()

    // var cube = new THREE.Mesh(
    //   new THREE.BoxGeometry(0.5, 0.5, 0.5),
    //   new THREE.MeshLambertMaterial({
    //     color: 0xffaa22,
    //     // color: 0xdddddd,
    //   })
    //   // new THREE.MeshBasicMaterial({
    //   //   color: 0xffffff,
    //   //   wireframe: true,
    //   // })
    // )
    // this.scene.add(cube)
    // this.transformControls = new TransformControls(this.world.camera, this.canvas)
    // this.transformControls.attach(cube)
    // this.scene.add(this.transformControls)

    // const outlines = Outlines({ color: new THREE.Color(0.0, 1.0, 0.0) })
    // cube.add(outlines.group)
    // outlines.generate()

    const mainLight = new THREE.DirectionalLight(0xffffff, 3.5)
    this.scene.add(mainLight)
    mainLight.position.set(-1, 1, 1)
    this.world.camera.position.set(0, 1.5, 2)

    this._cameraController = new CameraController(
      this._world.camera,
      this._world.renderer.domElement,
    )

    // this.canvas.addEventListener('renderResize', onResize.bind(this))

    // function onResize(event) {
    //   console.warn(event.detail.width + ' ' + event.detail.height)
    //   const renderSize = new THREE.Vector2()
    //   this.world.renderer.getSize(renderSize)
    //   console.warn('RENDER: ' + renderSize.x + ' ' + renderSize.y)
    // }
  }

  // start() {
  //     requestAnimationFrame(this.update.bind(this));
  // }

  pause() {
    //
  }

  resume() {
    //
  }

  dispose() {
    this.world.dispose()
    this._cameraController.dispose()
    if (this.viewerInterfaceManager !== null) {
      this.viewerInterfaceManager.dispose()
      this._viewerInterfaceManager = null
    }
  }

  setOutlineEnabled(enabled) {
    this._effect = enabled
      ? new OutlineEffect(this.world.renderer, this._outlineLayer, {
          defaultThickness: 0.005,
        })
      : null
  }

  addObjectToOutline(object, includeChildren = false) {
    if (includeChildren) {
      object.traverse(o => o.layers.enable(this._outlineLayer))
    } else {
      object.layers.enable(this._outlineLayer)
    }
  }

  addInterfaceManager() {
    if (this.viewerInterfaceManager !== null) {
      return
    }

    this._viewerInterfaceManager = new ViewerInterfaceManager(this)
    this._viewerInterfaceManager.updateInterface()
  }

  addSelectionManager() {
    if (this.selectionManager !== null) {
      return
    }

    this._selectionManager = new SelectionManager(this)
  }

  setModel(modelAsset, autoAdaptCamera = false) {
    if (this._animator !== null) {
      this._animator.dispose()
      this._animator = null
    }

    if (this._modelAsset !== null) {
      this.scene.remove(this._modelAsset.scene)
    }

    if (!modelAsset) {
      this._modelAsset = null
      if (this._viewerInterfaceManager !== null) {
        this._viewerInterfaceManager.updateInterface()
      }
      return
    }

    // modelAsset.scene.position.set(0,0,0);
    this.scene.add(modelAsset.scene)
    this._modelAsset = modelAsset

    if (autoAdaptCamera) {
      this._cameraController.fitViewToObjects(modelAsset.scene, 0.8, 0.8)
    }

    if (modelAsset.animations.length > 0) {
      this._animator = new Animator(modelAsset.scene, modelAsset.animations)
      this._animator.mixer.addEventListener('finished', this.onAnimationFinished.bind(this))
    }

    if (this._viewerInterfaceManager != null) {
      this._viewerInterfaceManager.updateInterface()
    }

    // const modelObjects = []
    // this.modelRoot.traverse(getModelMeshes)

    // // this.world.setOutlinedObjects(modelObjects)

    // function getModelMeshes(object) {
    //   if (!(object instanceof THREE.Mesh)) {
    //     return
    //   }

    //   modelObjects.push(object)
    // }
  }

  onAnimationFinished(event) {
    if (this._animator == null || this._animator.currentAction == null) {
      return
    }

    if (this._animator.currentAction == event.action) {
      const animationFinishedEvent = new CustomEvent('animationFinished', {
        detail: {
          name: this._animator.currentAction.getClip().name,
        },
      })

      this.canvas.dispatchEvent(animationFinishedEvent)
    }
  }

  playAnimation(name, loopMode = THREE.LoopRepeat, repetitions = Infinity) {
    if (this._animator === null) {
      console.error(
        'ERROR: Cannot play animation because there is no animator associated with the current model.',
      )
      return
    }

    this._animator.playAnimation(name)
    if (this._animator.currentAction) {
      this._animator.currentAction.setLoop(loopMode, repetitions)
    }
    this._animator.setAnimationPaused(this._animationPaused)
  }

  setAnimationPaused(paused) {
    if (typeof paused != 'boolean') {
      console.error('Bad parameter')
      return
    }

    this._animationPaused = paused
    this._animator?.setAnimationPaused(this._animationPaused)
  }

  setAnimationTime(time) {
    if (this._animator === null) {
      return
    }

    this._animator.setAnimationTime(time)
  }

  stopAnimation() {
    if (this._animator === null) {
      return
    }

    this._animator.stopAnimation()
  }

  getAnimationNames() {
    if (this._animator === null) {
      return []
    }

    return this._animator.animationNames
  }

  getAnimationsData() {
    if (this._animator === null) {
      return []
    }

    return this._animator.animationsData
  }

  getAnimationDuration() {
    if (this._animator === null) {
      return 0
    }

    return this._animator.currentAction.getClip().duration
  }

  addPostRenderAction(action) {
    if (typeof action !== 'function') {
      console.error('ERROR: The provided action is not a function')
      return
    }

    this._postRenderActions.add(action)
  }

  removePostRenderAction(action) {
    this._postRenderActions.delete(action)
  }

  update(timestamp) {
    this._deltaTime = timestamp - this._elapsedTime
    this._elapsedTime = timestamp

    // CAMERA
    this._cameraController.update(this._deltaTime, this._elapsedTime)

    // ANIMATION
    if (this._animator !== null) {
      this._animator.update(this._deltaTime, this._elapsedTime)
      if (this._animator.currentAction != null && !this._animationPaused) {
        const animationUpdatedEvent = new CustomEvent('animationUpdated', {
          detail: {
            name: this._animator.currentAction.getClip().name,
            time: this._animator.currentAction.time,
          },
        })

        this.canvas.dispatchEvent(animationUpdatedEvent)
      }
    }

    // RENDER
    if (this._effect !== null) {
      this._effect.render(this.scene, this.world.camera)
    } else {
      this._world.animate(this._deltaTime, this._elapsedTime)
    }

    // UI
    if (this._viewerInterfaceManager !== null) {
      this._viewerInterfaceManager.update(this._deltaTime, this._elapsedTime)
    }

    for (const action of this._postRenderActions.values()) {
      action(this._deltaTime, this._elapsedTime)
    }

    // SCREENSHOT
    if (this._needsScreenshot) {
      this._needsScreenshot = false
      const screenshotReadyEvent = new Event('screenshotReady')
      this.canvas.dispatchEvent(screenshotReadyEvent)
    }
  }

  takeScreenshot() {
    return new Promise(prepareScreenshot.bind(this))

    function prepareScreenshot(resolve /*, reject*/) {
      const eventListener = onScreenshotReady.bind(this)
      this.canvas.addEventListener('screenshotReady', eventListener)

      this._needsScreenshot = true

      function onScreenshotReady(/*event*/) {
        this.canvas.removeEventListener('screenshotReady', eventListener)
        this.canvas.toBlob(resolve, 'image/jpeg', 1)
      }
    }
  }
}

export default ViewerManager
