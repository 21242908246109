import { ApolloClient, ApolloProvider, createHttpLink, from, InMemoryCache } from "@apollo/client"
import { setContext } from "@apollo/client/link/context"
import React from "react"
import ReactDOM from "react-dom/client"
import { BrowserRouter, Navigate } from "react-router-dom"
import api from "./api"
import App from "./App"
import "./index.css"
import { isUserTokenExpired } from "./lib"
import reportWebVitals from "./reportWebVitals"

const environment = process.env.REACT_APP_ENV || "development"
const configEnv = require(`./config/config.${environment}.js`)
const config = configEnv?.default

// init apollo -----------------------------------------------------------------
const httpLink = createHttpLink({ uri: config.GRAPHQL_URL })

const authLink = setContext(async (_, { headers }) => {
  const accessToken = localStorage.getItem("access_token")
  const refreshToken = localStorage.getItem("refresh_token")
  if (!accessToken) {
    return {
      headers,
    }
  }
  if (accessToken && isUserTokenExpired(accessToken)) {
    if (!isUserTokenExpired(refreshToken)) {
      try {
        const response = await api.refreshAccessToken()
        localStorage.setItem("access_token", response.accessToken)
        localStorage.setItem("refresh_token", response.refreshToken)
        return {
          headers: {
            ...headers,
            authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        }
      } catch (error) {
        return <Navigate to="/signin" />
      }
    } else {
      return <Navigate to="/signin" replace state={{ from: location }} />
    }
  } else {
    return {
      headers: {
        ...headers,
        authorization: `Bearer ${accessToken}`,
      },
    }
  }
})

const cache = new InMemoryCache({
  typePolicies: {
    Manual: {
      fields: {
        assemblageDuration: {
          merge(existing, incoming) {
            // Si les données entrantes sont les mêmes que les données existantes, retournez les données existantes
            if (existing && incoming && existing.minutes === incoming.minutes) {
              return existing
            }
            // Sinon, retournez les données entrantes
            return incoming
          },
        },
      },
    },
  },
})

const client = new ApolloClient({
  connectToDevTools: process.env.NODE_ENV === "development",
  link: from([authLink.concat(httpLink)]),
  cache,
  name: "GuideMe-BackOffice",
  version: config?.CURRENT_VERSION || "1.0.0",
})

const root = ReactDOM.createRoot(document.getElementById("root"))
root.render(
  <BrowserRouter>
    <ApolloProvider client={client}>
      <App />
    </ApolloProvider>
  </BrowserRouter>,
)

// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
