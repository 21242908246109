import { StyleSheet, css } from "aphrodite"
import dayjs from "dayjs"
import PropTypes from "prop-types"
import React, { useCallback, useLayoutEffect, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import Modal from "react-modal"
import ReactPlayer from "react-player"
import { useOutletContext, useSearchParams } from "react-router-dom"
import { Close, Edit } from "../../assets/svgr"
import { UIButtonIcon } from "../../components/UI"
import { Model3DViewer } from "../../components/Widget/Model3DViewer"
import { BLACK_COLOR, NEUTRAL_DARK, NEUTRAL_LIGHT, WHITE } from "../../styles/colors"

const staticStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "90dvw",
    height: 418,
    maxWidth: 1100,
    maxHeight: "85dvh",
    border: "none",
    padding: 0,
    boxSizing: "border-box",
    display: "flex",
    borderRadius: 0,
  },
  overlay: {
    background: "rgba(0, 22, 33, 0.70)",
  },
}

export const WidgetDetailsItemModal = ({ closeModal, theme, data, showEditData }) => {
  const [fileData, setFileData] = useState({ fileSrc: "", size: "", animationCount: null })

  // hooks ---------------------------------------------------------------------
  const { t } = useTranslation("libraryPage")
  const [params] = useSearchParams()
  const [type, setType] = useState(params.get("type")?.toLowerCase())
  const { modalHidden } = useOutletContext()

  // init style ----------------------------------------------------------------
  const styles = useMemo(
    () => getStyles({ theme, file: data?.fileSrc, isVideo: type === "video" }),
    [theme, data?.fileSrc, type],
  )
  const customStyles = useMemo(() => ({ ...staticStyles, ...styles }), [styles])

  useLayoutEffect(() => {
    const type = params.get("type") === "all" ? data?.type?.toLowerCase() : params.get("type")
    setType(type)
    if (data?.fileSrc) {
      fetch(data.fileSrc)
        .then(response => {
          return response.blob()
        })
        .then(image => {
          const imageUrl = URL.createObjectURL(image)
          setFileData(prev => ({
            ...prev,
            fileSrc: imageUrl,
            size: (image.size / 1048576).toFixed(2),
          }))
        })
        .catch(error => {
          console.error("Erreur :", error)
        })
    }
  }, [data])

  const close = useCallback(() => {
    setFileData({ fileSrc: "", size: "" })
    closeModal()
  }, [])

  const handleAnimationCount = useCallback(count => {
    setFileData(prev => ({ ...prev, animationCount: count }))
  }, [])

  return (
    <Modal
      onRequestClose={close}
      appElement={document.getElementById("root")}
      isOpen={!modalHidden}
      style={customStyles}>
      <section className={css(styles.leftContainer)}>
        {fileData?.fileSrc && type !== "video" && type !== "3d" && (
          <div className={css(styles.containerImg)}>
            <img alt={`cover${data?.label}`} className={css(styles.img)} src={fileData?.fileSrc} />
          </div>
        )}
        {fileData?.fileSrc && type === "video" && (
          <>
            <ReactPlayer controls={true} url={fileData?.fileSrc} />
          </>
        )}
        {type === "3d" && (
          <Model3DViewer modelURL={data?.fileSrc} wireframe={true} setAnimationCount={handleAnimationCount} />
        )}
      </section>
      <section className={css(styles.rightContainer)}>
        <header>
          <UIButtonIcon
            onClick={close}
            dynamicStyle={[styles.closeBtn]}
            icon={<Close color={theme?.PRIMARY_COLOR || BLACK_COLOR} />}
          />
        </header>
        <main
          style={{
            display: "flex",
            flexDirection: "column",
            gap: (data?.filename?.length || data?.title?.length) > 30 ? 50 : 70,
          }}>
          <div className={css(styles.flexAbsolute)}>
            <h1 className={css(styles.title)}>
              {type === "3d" || type === "video" ? data?.filename : data?.title}
            </h1>
            <UIButtonIcon
              onClick={showEditData}
              dynamicStyle={[styles.updateButton]}
              icon={<Edit color={WHITE} />}
            />
          </div>

          <div>
            {type === "3d" && (
              <div className={css(styles.animationCount)}>
                <p className={css(styles.font400)}>
                  {fileData.animationCount
                    ? fileData.animationCount > 1
                      ? `${fileData?.animationCount} ${t("modal.multi_animation_count")}`
                      : `${fileData?.animationCount} ${t("modal.one_animation_count")}`
                    : `0 ${t("modal.one_animation_count")}`}
                </p>
              </div>
            )}
            <div className={css(styles.flexData)}>
              <div className={css(styles.oneData)}>
                <p className={css(styles.font600)}>{t("modal.file_name")}</p>
                <p className={css(styles.font400)}>
                  {type === "3d" || type === "video" ? data?.filename : data?.title}
                </p>
              </div>
              {type !== "3d" && (
                <div className={css(styles.oneData)}>
                  <p className={css(styles.font600)}>{t("modal.file_resolution")}</p>
                  <p className={css(styles.font400)}>-</p>
                </div>
              )}
              <div className={css(styles.oneData)}>
                <p className={css(styles.font600)}>{t("modal.file_createdAt")}</p>
                <p className={css(styles.font400)}>{dayjs(data?.createdAt).format("DD/MM/YYYY")}</p>
              </div>

              {type === "picture" && (
                <div className={css(styles.oneData)}>
                  <p className={css(styles.font600)}>{t("modal.usage")}</p>
                  <p className={css(styles.font400)}>{t(`modal.${data?.usage}`)}</p>
                </div>
              )}
              <div className={css(styles.oneData)}>
                <p className={css(styles.font600)}>{t("modal.file_size")}</p>
                <p className={css(styles.font400)}>{`${fileData?.size} Mo`}</p>
              </div>
              <div className={css(styles.oneData)}>
                <p className={css(styles.font600)}>{t("modal.file_updateAt")}</p>
                <p className={css(styles.font400)}>
                  {data?.updatedAt ? dayjs(data?.updatedAt).format("DD/MM/YYYY") : "-"}
                </p>
              </div>
            </div>
          </div>
        </main>
      </section>
    </Modal>
  )
}
const getStyles = ({ theme, file, isVideo }) =>
  StyleSheet.create({
    closeBtn: {
      position: "absolute",
      top: 15,
      right: 10,
      background: "transparent",
    },
    rightContainer: {
      width: "43%",
      padding: "40px",
      background: NEUTRAL_LIGHT,
      boxSizing: "border-box",
      overflow: "auto",
      "::-webkit-scrollbar": {
        width: 5,
      },
    },
    leftContainer: {
      width: "57%",
      background: isVideo ? BLACK_COLOR : file ? WHITE : NEUTRAL_DARK,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      overflow: "hidden",
      boxSizing: "border-box",
      position: "relative",
    },
    flexAbsolute: {
      display: "flex",
      gap: 15,
      alignItems: "flex-start",
    },
    title: {
      fontFamily: "Roboto",
      fontWeight: "600",
      fontSize: 24,
      textTransform: "uppercase",
      margin: 0,
      color: theme.PRIMARY_COLOR || BLACK_COLOR,
    },
    animationCount: {
      marginBottom: 20,
      fontFamily: "Roboto",
      fontWeight: "400",
      fontSize: 12,
      fontStyle: "italic",
    },
    updateButton: {
      background: theme.PRIMARY_COLOR || BLACK_COLOR,
      height: 27,
      width: 27,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      borderRadius: "50%",
    },
    font400: {
      fontFamily: "Roboto",
      fontWeight: "400",
      fontSize: 12,
      color: theme.PRIMARY_COLOR || BLACK_COLOR,
      margin: 0,
    },
    font600: {
      fontFamily: "Roboto",
      fontWeight: "600",
      fontSize: 12,
      color: theme.PRIMARY_COLOR || BLACK_COLOR,
      margin: 0,
      paddingBottom: 7,
    },
    containerImg: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "100%",
      width: "100%",
      boxSizing: "border-box",
    },
    img: {
      height: "auto",
      width: "auto",
      display: "block",
      objectFit: "contain",
      flexGrow: 1,
      maxHeight: "85%",
      maxWidth: "85%",
    },
    flexData: {
      display: "flex",
      gap: 25,
      justifyContent: "space-between",
      margin: 0,
      flexDirection: "row",
      flexWrap: "wrap",
      boxSizing: "border-box",
      alignItems: "flex-start",
    },
    oneData: {
      width: "40%",
    },
  })

WidgetDetailsItemModal.propTypes = {
  closeModal: PropTypes.func,
  theme: PropTypes.object,
  data: PropTypes.object,
  showEditData: PropTypes.func,
}
