import * as THREE from 'three'
import * as BufferGeometryUtils from 'three/addons/utils/BufferGeometryUtils.js'

class WireframeRendering {
  /**
   * @param {THREE.Mesh} mesh
   */
  static applyWireframeRendering(root) {
    applyWireframeRenderingToObjectAndChildren(root)

    function applyWireframeRenderingToObjectAndChildren(object) {
      for (const child of object.children) {
        applyWireframeRenderingToObjectAndChildren(child)
      }

      if (!(object instanceof THREE.Mesh)) {
        return
      }

      const lineGeometry = WireframeRendering.getLineGeometry(object.geometry)
      const lines = new THREE.LineSegments(
        lineGeometry,
        new THREE.LineBasicMaterial({ color: 0x000000 }),
      )
      object.add(lines)
      lines.position.set(0, 0, 0)
      lines.quaternion.identity()

      if (!object.material) {
        return
      }

      if (Array.isArray(object.material)) {
        const materials = []
        for (const originalMaterial of object.material) {
          materials.push(createWhiteMaterialEquivalent(originalMaterial))
        }
        object.material = materials
      } else {
        object.material = createWhiteMaterialEquivalent(object.material)
      }
    }

    function createWhiteMaterialEquivalent(originalMaterial) {
      return new THREE.MeshBasicMaterial({
        color: 0xffffff,
        side: originalMaterial.side,
        polygonOffset: true,
        polygonOffsetFactor: 1,
        polygonOffsetUnits: 1,
      })
    }
  }

  /**
   * @param {THREE.BufferGeometry} sourceGeometry
   * @returns {THREE.BufferGeometry}
   */
  static getLineGeometry(sourceGeometry) {
    var sourceGeometryClone = sourceGeometry.clone()
    const candidateEdgesMap = new Map()
    const edgesToRenderMap = new Map()
    const lineVertices = []

    {
      let attributeNamesToDelete = []
      for (const attributeName in sourceGeometryClone.attributes) {
        if (attributeName !== 'position') {
          attributeNamesToDelete.push(attributeName)
        }
      }

      for (const name of attributeNamesToDelete) {
        sourceGeometryClone.deleteAttribute(name)
      }
    }

    sourceGeometryClone.morphAttributes = {}
    sourceGeometryClone = BufferGeometryUtils.mergeVertices(sourceGeometryClone)
    if (sourceGeometryClone.groups.length != 0) {
      sourceGeometryClone = BufferGeometryUtils.mergeGroups(sourceGeometryClone)
    }

    var groupEnd, key
    const indices = sourceGeometryClone.getIndex()
    var edgeData
    var sortedIndices
    var groups =
      sourceGeometryClone.groups.length == 0
        ? [{ start: 0, count: indices.count, materialIndex: 0 }]
        : sourceGeometryClone.groups

    for (const group of groups) {
      groupEnd = group.start + group.count
      for (let faceIndex = group.start; faceIndex < groupEnd; faceIndex += 3) {
        for (let i = 0; i < 3; i++) {
          // Sorting indices to get a unique key regardless of the order of the indices in the face
          sortedIndices = [
            indices.getX(faceIndex + i),
            indices.getX(faceIndex + ((i + 1) % 3)),
          ].sort()
          key = sortedIndices[0] + ',' + sortedIndices[1]
          edgeData = candidateEdgesMap.get(key)
          if (edgeData !== undefined) {
            if (edgeData.materialIndex != group.materialIndex && !edgesToRenderMap.has(key)) {
              edgesToRenderMap.set(key, edgeData)
            }
            edgeData.isSharedBySeveralFaces = true
          } else {
            candidateEdgesMap.set(key, {
              materialIndex: group.materialIndex,
              isSharedBySeveralFaces: false,
            })
          }
        }
      }
    }

    edgesToRenderMap.forEach((v, k) => {
      pushVerticesWithKey(k)
    })

    candidateEdgesMap.forEach((v, k) => {
      if (v.isSharedBySeveralFaces) {
        return
      }
      pushVerticesWithKey(k)
    })

    const lineGeometry = new THREE.BufferGeometry()
    lineGeometry.setAttribute(
      'position',
      new THREE.BufferAttribute(new Float32Array(lineVertices), 3),
    )

    // console.warn(lineGeometry)

    return lineGeometry

    function pushVerticesWithKey(key) {
      const [index1, index2] = key.split(',', 2)
      // console.warn('Pushing ' + index1 + ' and ' + index2)
      // console.warn(lineVertices)
      lineVertices.push(
        sourceGeometryClone.attributes.position.getX(index1),
        sourceGeometryClone.attributes.position.getY(index1),
        sourceGeometryClone.attributes.position.getZ(index1),
        sourceGeometryClone.attributes.position.getX(index2),
        sourceGeometryClone.attributes.position.getY(index2),
        sourceGeometryClone.attributes.position.getZ(index2),
      )
    }
  }
}

export default WireframeRendering
