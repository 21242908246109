import { gql } from "@apollo/client"

export const ANIMATION_MODES = gql`
  query AnimationModes {
    animationModes {
      nodes {
        id
        label
      }
    }
  }
`

export const UPDATE_CHILD_STEP_CAMERA = gql`
  mutation UpdateChildStepCamera(
    $id: UUID!
    $camPositionX: Float
    $camPositionY: Float
    $camPositionZ: Float
    $maxZoom: Float
    $minZoom: Float
    $targetPositionX: Float
    $targetPositionY: Float
    $targetPositionZ: Float
  ) {
    updateChildStepCamera(
      input: {
        patch: {
          camPositionX: $camPositionX
          camPositionY: $camPositionY
          camPositionZ: $camPositionZ
          maxZoom: $maxZoom
          minZoom: $minZoom
          targetPositionX: $targetPositionX
          targetPositionY: $targetPositionY
          targetPositionZ: $targetPositionZ
        }
        id: $id
      }
    ) {
      clientMutationId
    }
  }
`

export const CREATE_CHILD_STEP_CAMERA = gql`
  mutation CreateChildStepCamera(
    $camPositionX: Float
    $camPositionY: Float
    $camPositionZ: Float
    $maxZoom: Float
    $minZoom: Float
    $targetPositionX: Float
    $targetPositionY: Float
    $targetPositionZ: Float
    $idChildStep: UUID!
  ) {
    createChildStepCamera(
      input: {
        childStepCamera: {
          camPositionX: $camPositionX
          camPositionY: $camPositionY
          camPositionZ: $camPositionZ
          maxZoom: $maxZoom
          minZoom: $minZoom
          targetPositionX: $targetPositionX
          targetPositionY: $targetPositionY
          targetPositionZ: $targetPositionZ
          idChildStep: $idChildStep
        }
      }
    ) {
      clientMutationId
    }
  }
`

export const CREATE_CHILD_STEP_ANIMATION = gql`
  mutation CreateChildStepAnimation(
    $idChildStep: UUID!
    $idAnimationMode: UUID!
    $repetitions: RepetitionRange!
    $name: String!
  ) {
    createChildStepAnimation(
      input: {
        childStepAnimation: {
          name: $name
          idAnimationMode: $idAnimationMode
          repetitions: $repetitions
          idChildStep: $idChildStep
        }
      }
    ) {
      childStepAnimation {
        id
      }
    }
  }
`

export const UPDATE_CHILD_STEP_ANIMATION = gql`
  mutation UpdateChildStepAnimation(
    $id: UUID!
    $idAnimationMode: UUID
    $repetitions: RepetitionRange
    $name: String
    $idChildStep: UUID
  ) {
    updateChildStepAnimation(
      input: {
        patch: {
          idAnimationMode: $idAnimationMode
          idChildStep: $idChildStep
          name: $name
          repetitions: $repetitions
        }
        id: $id
      }
    ) {
      clientMutationId
    }
  }
`
