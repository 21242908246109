import * as React from "react"

const CheckRoundGreen = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} fill="none">
    <path
      fill="#52C41A"
      d="M10 0a10 10 0 1 1 0 20 10 10 0 0 1 0-20ZM8.754 11.973 6.533 9.75a.858.858 0 1 0-1.213 1.213l2.829 2.828a.852.852 0 0 0 1.212 0l5.858-5.858a.857.857 0 1 0-1.212-1.213l-5.253 5.253Z"
    />
  </svg>
)
export default CheckRoundGreen
