import { css, StyleSheet } from "aphrodite"
import React, { useEffect, useState } from "react"
import { Outlet } from "react-router-dom"
import { WarningSmallPage } from "../../pages"
import { useTheme } from "../Provider/ThemeProvider"
import { NavBarSlide } from "../Widget"

const Layout = () => {
  const styles = getStyles()
  const [isDestopSize, setIsDestopSize] = useState()
  const { overlay } = useTheme()

  const updateMedia = () => {
    setIsDestopSize(window.innerWidth)
  }

  useEffect(() => {
    setIsDestopSize(window.innerWidth)
    window.addEventListener("resize", updateMedia)
    return () => {
      window.removeEventListener("resize", updateMedia)
    }
  }, [])

  return (
    <div className={css(styles.container)}>
      {overlay && !(isDestopSize && isDestopSize < 1024) && <div className={css(styles.overlay)} />}

      <NavBarSlide />
      <Outlet context={{ modalHidden: isDestopSize && isDestopSize < 1024 }} />
      <WarningSmallPage show={isDestopSize && isDestopSize < 1024} />
    </div>
  )
}

const getStyles = () =>
  StyleSheet.create({
    overlay: {
      background: "rgba(0, 22, 33, 0.70)",
      zIndex: 1000,
      position: "absolute",
      width: "100dvw",
      height: "100dvh",
    },
    container: {
      display: "flex",
      flexDirection: "row",
      minHeight: "100dvh",
      width: "100dvw",
    },
  })

export default Layout
