import * as React from "react"

const Add = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width={12} height={12} fill="none">
    <path
      fill="#fff"
      stroke="#fff"
      strokeWidth={0.5}
      d="M1.36 6.809a.8.8 0 0 0 .316.05l3.784-.058-.058 3.784a.787.787 0 0 0 .237.558c.149.145.351.225.563.221a.84.84 0 0 0 .825-.804l.058-3.784 3.784-.058a.84.84 0 0 0 .805-.825.787.787 0 0 0-.78-.8l-3.784.058.059-3.784a.787.787 0 0 0-.8-.779.84.84 0 0 0-.825.804l-.059 3.784-3.784.059a.826.826 0 0 0-.591.24.848.848 0 0 0-.255.585.799.799 0 0 0 .237.578.78.78 0 0 0 .268.17Z"
    />
  </svg>
)

export default Add
