import { gql } from "@apollo/client"
import { BRAND_DATA, CHILD_STEP_DATA, MANUAL_DATA, MANUAL_STEP_DATA, PART_DATA } from "./fragments"

export const GET_MANUAL_ITEMS = gql`
  query getManualItems($id: UUID!) {
    getManualItems(input: { idManual: $id }) {
      parts {
        id
        quantity
      }
      tools {
        id
        quantity
      }
    }
  }
`

export const MANUAL_BY_ID = gql`
  ${MANUAL_DATA}
  ${BRAND_DATA}
  ${CHILD_STEP_DATA}
  ${PART_DATA}
  query manual($id: UUID!) {
    manual(id: $id) {
      ...ManualData
      assemblageDuration {
        minutes
      }
      productByIdProduct {
        name
        id
        brandByIdBrand {
          ...BrandData
        }
      }
      manualLanguagesByIdManual {
        nodes {
          id
          langLocaleByIdLangLocale {
            localeCode
            id
            languageByIdLanguage {
              code
            }
          }
        }
      }
      manualStepsByIdManual(orderBy: POS_ASC) {
        nodes {
          id
          pos
          text
          childStepsByIdManualStep(orderBy: POS_ASC) {
            nodes {
              ...ChildStepData
              stepPartsByIdChildStep {
                nodes {
                  id
                  quantity
                  partByIdPart {
                    ...PartData
                  }
                }
              }
              stepToolsByIdChildStep {
                nodes {
                  id
                  quantity
                  toolByIdTool {
                    filename
                    id
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

export const CREATE_MANUAL = gql`
  mutation createManual(
    $pIdBrand: UUID!
    $minutes: Int
    $pProductName: String!
    $pNumberOfPeopleNeeded: Int
  ) {
    createManualHelper(
      input: {
        pProductName: $pProductName
        pIdBrand: $pIdBrand
        pAssemblageDuration: { minutes: $minutes }
        pNumberOfPeopleNeeded: $pNumberOfPeopleNeeded
      }
    ) {
      manual {
        id
      }
    }
  }
`

export const UPDATE_MANUAL_AND_PRODUCT = gql`
  mutation updateManualAndProduct(
    $minutes: Int
    $numberOfPeopleNeeded: Int
    $manualId: UUID!
    $brandId: UUID!
    $productName: String
    $productId: UUID!
  ) {
    updateManual(
      input: {
        patch: { assemblageDuration: { minutes: $minutes }, numberOfPeopleNeeded: $numberOfPeopleNeeded }
        id: $manualId
      }
    ) {
      clientMutationId
    }
    updateProduct(input: { patch: { idBrand: $brandId, name: $productName }, id: $productId }) {
      clientMutationId
    }
  }
`

export const ALL_MANUALS = gql`
  ${MANUAL_DATA}
  ${MANUAL_STEP_DATA}
  query manuals($first: Int, $offset: Int) {
    manuals(first: $first, offset: $offset) {
      nodes {
        ...ManualData
        assemblageDuration {
          days
          hours
          minutes
          months
          seconds
          years
        }
        manualStepsByIdManual {
          nodes {
            ...ManualStepData
          }
        }
        productByIdProduct {
          name
          id
        }
      }
      totalCount
    }
  }
`

export const PUBLISHED_MANUAL = gql`
  mutation PublishedManual($id: UUID!, $publishedDate: Datetime) {
    updateManual(input: { patch: { publishedAt: $publishedDate }, id: $id }) {
      clientMutationId
    }
  }
`
