import { useLazyQuery, useMutation, useQuery } from "@apollo/client"
import { useEffect, useMemo, useState } from "react"
import { useLocation, useSearchParams } from "react-router-dom"
import api from "../api"
import {
  ASSETS_PARTS,
  ASSETS_POIS,
  ASSETS_TOOLS,
  ASSETS_TYPES,
  CREATE_PART,
  CREATE_POI,
  CREATE_TOOL,
  CREATE_USAGE_PART,
  CREATE_USAGE_POI,
  CREATE_USAGE_TOOL,
  LIBRARY_ASSETS,
  UPDATE_POI,
} from "../graphql/assets"
import { CREATE_MODEL, MODELS, UPDATE_MODEL } from "../graphql/models"
import { checkEnv } from "../lib"
import { captureErrorGraphql } from "../lib/sentry"

export const useLibraryPage = () => {
  // states --------------------------------------------------------------------
  const [assets, setAssets] = useState([])
  const [itemSelected, setItemSelected] = useState()
  const first = 18
  const [totalCount, setTotalCount] = useState(0)
  const [errorCreate, setErrorCreate] = useState(null)
  const [loading, setLoading] = useState(false)

  // hooks ---------------------------------------------------------------------
  const location = useLocation()
  const [params, setSearchParams] = useSearchParams()
  const config = useMemo(() => checkEnv(), [])

  // graphql -------------------------------------------------------------------
  const { data: assetTypesDb } = useQuery(ASSETS_TYPES)
  const [fetchAssets] = useLazyQuery(LIBRARY_ASSETS, {
    fetchPolicy: "cache-and-network",
  })
  const [fetchAssetsParts] = useLazyQuery(ASSETS_PARTS, {
    fetchPolicy: "cache-and-network",
  })
  const [fetchAssetsTools] = useLazyQuery(ASSETS_TOOLS, {
    fetchPolicy: "cache-and-network",
  })
  const [fetchAssetsPois] = useLazyQuery(ASSETS_POIS, {
    fetchPolicy: "cache-and-network",
  })
  const [fetchModels] = useLazyQuery(MODELS, {
    fetchPolicy: "cache-and-network",
  })

  const [createPart] = useMutation(CREATE_PART)
  const [createTool] = useMutation(CREATE_TOOL)
  const [createPoi] = useMutation(CREATE_POI)
  const [createUsagePart] = useMutation(CREATE_USAGE_PART)
  const [createUsageTool] = useMutation(CREATE_USAGE_TOOL)
  const [createUsagePoi] = useMutation(CREATE_USAGE_POI)
  const [createModel] = useMutation(CREATE_MODEL)
  const [updateModel] = useMutation(UPDATE_MODEL)
  const [updatePoi] = useMutation(UPDATE_POI)

  // func ----------------------------------------------------------------------
  const refreshData = () => {
    const assetTypeChoice = assetTypesDb?.assetTypes?.nodes?.find(type => type.label === params.get("type"))
    let newOffset = (Number(params.get("page")) - 1) * first
    if (newOffset < 0) newOffset = 0

    if (assetTypeChoice?.id) {
      setAssets([])
      //* if type DB found ----------------
      if (params.get("filter") === "parts") {
        //* fetch parts assets
        fetchAssetsParts({
          variables: { id: assetTypeChoice.id, offset: newOffset, first },
          onCompleted: data => {
            if (data?.usageParts?.nodes[0]) {
              const array = []
              for (const part of data.usageParts.nodes) {
                array.push({
                  id: part?.partByIdPart?.id,
                  urlTrad: `${config.BASE_URL}/parts/${part?.partByIdPart?.id}/translations`,
                  usage: "parts",
                  fileSrc: `${config.BASE_URL}/parts/${part?.partByIdPart?.id}/image?lang=en`,
                })
              }
              setAssets([...array])
              setTotalCount(data?.usageParts?.totalCount || 0)
            }
          },
        })
      } else if (params.get("filter") === "tools") {
        //* fetch tools assets
        fetchAssetsTools({
          variables: { id: assetTypeChoice.id, offset: newOffset, first },
          onCompleted: data => {
            if (data?.usageTools?.nodes[0]) {
              const array = []
              for (const tool of data.usageTools.nodes) {
                array.push({
                  id: tool?.toolByIdTool?.id,
                  urlTrad: `${config.BASE_URL}/tools/${tool?.toolByIdTool?.id}/translations`,
                  usage: "tools",
                  fileSrc: `${config.BASE_URL}/tools/${tool?.toolByIdTool?.id}/image?lang=en`,
                })
              }
              setAssets([...array])
              setTotalCount(data?.usageTools?.totalCount || 0)
            }
          },
        })
      } else if (params.get("type") === "3d") {
        fetchModels({
          variables: { offset: newOffset, first },
          onCompleted: data => {
            if (data?.models?.nodes[0]) {
              const array = []
              for (const model of data.models.nodes) {
                array.push({
                  ...model,
                  usage: "3d",
                  thumbnailSrc: `${config.BASE_URL}/models/${model?.id}/thumbnail`,
                  fileSrc: `${config.BASE_URL}/models/${model?.id}/file`,
                })
              }
              setAssets([...array])
              setTotalCount(data?.models?.totalCount || 0)
            }
          },
        })
      } else if (params.get("type") === "video") {
        //* fetch video assets
        fetchAssets({
          variables: { id: assetTypeChoice.id, offset: newOffset, first },
          onCompleted: data => {
            if (data?.libraries?.nodes[0]) {
              const array = []
              for (const asset of data.libraries.nodes) {
                array.push({
                  ...asset,
                  usage: asset.usage,
                  thumbnailSrc: `${config.BASE_URL}/${asset.usage}/${asset.id}/thumbnail?lang=en`,
                  fileSrc: `${config.BASE_URL}/${asset.usage}/${asset?.id}/video?lang=en`,
                })
              }
              setAssets([...array])
              setTotalCount(data?.libraries?.totalCount || 0)
            }
          },
        })
      } else {
        //* fetch all assets
        fetchAssets({
          variables: { id: assetTypeChoice.id, offset: newOffset, first },
          onCompleted: data => {
            if (data?.libraries?.nodes[0]) {
              const array = []
              for (const asset of data.libraries.nodes) {
                array.push({
                  ...asset,
                  usage: asset.usage,
                  urlTrad: `${config.BASE_URL}/${asset.usage}/${asset.id}/translations`,
                  fileSrc: `${config.BASE_URL}/${asset.usage}/${asset.id}/image?lang=en`,
                })
              }
              setAssets([...array])
              setTotalCount(data?.libraries?.totalCount || 0)
            }
          },
        })
      }
    } else if (params.get("type") === "all") {
      if (params.get("filter") === "pois") {
        fetchAssetsPois({
          variables: { offset: newOffset, first },
          onCompleted: data => {
            if (data?.usagePois?.nodes[0]) {
              const array = []
              for (const asset of data.usagePois.nodes) {
                const type = asset?.assetTypeByIdAssetType?.label?.toLowerCase()
                array.push({
                  ...asset,
                  usage: asset.usage,
                  type,
                  filename: asset?.poiByIdPoi?.filename,
                  thumbnailSrc:
                    type === "video"
                      ? `${config.BASE_URL}/pois/${asset.poiByIdPoi.id}/thumbnail?lang=en`
                      : null,
                  fileSrc:
                    type === "video"
                      ? `${config.BASE_URL}/pois/${asset.poiByIdPoi?.id}/video?lang=en`
                      : `${config.BASE_URL}/pois/${asset.poiByIdPoi?.id}/image?lang=en`,
                  urlTrad: `${config.BASE_URL}/pois/${asset.poiByIdPoi.id}/translations`,
                })
              }
              setAssets([...array])
              setTotalCount(data?.usagePois?.totalCount || 0)
            }
          },
        })
      }
    }
  }

  const changeCurrentPage = page => {
    //* refresh url -----
    setSearchParams({
      type: params.get("type"),
      page,
      ...(params.get("item") && { item: params.get("item") }),
      ...(params.get("filter") && { filter: params.get("filter") }),
      ...(params.get("usage") && { usage: params.get("usage") }),
    })
  }

  useEffect(() => {
    if (assetTypesDb) {
      refreshData()
    }
    if (sessionStorage.getItem("newItem")) {
      setItemSelected({ action: "new" })
    }
  }, [location, assetTypesDb])

  const saveNewPart = data => {
    //* create data ----------------------------------------
    createPart({
      onCompleted: async newData => {
        const type = assetTypesDb?.assetTypes?.nodes.find(
          type => type?.label?.toLowerCase() === params.get("type"),
        )
        createUsagePart({
          variables: { idAssetType: type.id, idPart: newData?.createPart?.part?.id },
          onCompleted: async () => {
            //* push file
            await api.postPart(newData?.createPart?.part?.id, data?.file?.data)

            //* push trad
            const json = { label: { en: data.name.trim() } }
            await api.postPartTranslation(newData?.createPart?.part?.id, json)

            setItemSelected(null)
            if (sessionStorage.getItem("newItem")) {
              sessionStorage.removeItem("newItem")
            }
            changeCurrentPage(1)
            setLoading(false)
          },
          onError: e => {
            setLoading(false)
            captureErrorGraphql(e, "useLibraryPage", "CREATE_USAGE_PART", {
              idAssetType: type.id,
              idPart: newData?.createPart?.part?.id,
            })
          },
        })
      },
      onError: e => {
        setErrorCreate("duplicate")
        captureErrorGraphql(e, "useLibraryPage", "CREATE_PART", { filename: data.name.trim() })
      },
    })
  }

  const saveNewPoi = data => {
    setLoading(true)
    try {
      //* create data -------------------------
      createPoi({
        variables: { filename: data.name.trim() },
        onCompleted: async newData => {
          const type = assetTypesDb?.assetTypes?.nodes.find(
            type => type?.label?.toLowerCase() === params.get("type"),
          )

          createUsagePoi({
            variables: { idAssetType: type.id, idPoi: newData?.createPoi?.poi?.id },
            onCompleted: async () => {
              //* push file --------------------
              if (type?.label?.toLowerCase() === "picture") {
                await api.postPicturePoi(newData?.createPoi?.poi?.id, data?.file?.data)
                //* push trad -----------------
                const json = { label: { en: data.name.trim() } }
                await api.postPoiTranslation(newData?.createPoi?.poi?.id, json)
              } else if (type?.label?.toLowerCase() === "video") {
                await api.postVideoPoi(newData?.createPoi?.poi?.id, data?.file?.data)

                await api.postThumbnailPoi(newData?.createPoi?.poi?.id, data?.thumbnail)
              }

              setLoading(false)
              setItemSelected(null)
              if (sessionStorage.getItem("newItem")) {
                sessionStorage.removeItem("newItem")
              }
              changeCurrentPage(1)
            },
            onError: e => {
              setLoading(false)
              captureErrorGraphql(e, "useLibraryPage", "CREATE_USAGE_POI", {
                idAssetType: type.id,
                idPoi: newData?.createPoi?.poi?.id,
              })
            },
          })
        },
        onError: e => {
          setLoading(false)
          setErrorCreate("duplicate")
          captureErrorGraphql(e, "useLibraryPage", "CREATE_POI", { filename: data.name.trim() })
        },
      })
    } catch {
      setLoading(false)
    }
  }

  const saveNewTool = data => {
    //* create data
    createTool({
      onCompleted: async newData => {
        const type = assetTypesDb?.assetTypes?.nodes.find(
          type => type?.label?.toLowerCase() === params.get("type"),
        )

        createUsageTool({
          variables: { idAssetType: type.id, idTool: newData?.createTool?.tool?.id },
          onCompleted: async () => {
            //* push file
            await api.postTool(newData?.createTool?.tool?.id, data?.file?.data)

            //* push trad
            const json = { label: { en: data.name.trim() } }
            await api.postToolTranslation(newData?.createTool?.tool?.id, json)

            setItemSelected(null)
            if (sessionStorage.getItem("newItem")) {
              sessionStorage.removeItem("newItem")
            }
            changeCurrentPage(1)
            setLoading(false)
          },
          onError: e => {
            setLoading(false)
            captureErrorGraphql(e, "useLibraryPage", "CREATE_USAGE_TOOL", {
              idAssetType: type.id,
              idTool: newData?.createTool?.tool?.id,
            })
          },
        })
      },
      onError: e => {
        setErrorCreate("duplicate")
        captureErrorGraphql(e, "useLibraryPage", "CREATE_TOOL", { filename: data.name.trim() })
      },
    })
  }

  const saveNewModel = data => {
    createModel({
      variables: { filename: data.name.trim() },
      onCompleted: async newData => {
        if (newData?.createModel?.model?.id) {
          await api.postModel(newData?.createModel?.model?.id, data?.newModelFile?.data)
        }

        if (data?.thumbnail) {
          await api.postModelThumbnail(newData?.createModel?.model?.id, data?.thumbnail)
        }
        setLoading(false)
        setItemSelected(null)
        changeCurrentPage(1)
      },
      onError: e => {
        setErrorCreate("duplicate")
        captureErrorGraphql(e, "useLibraryPage", "CREATE_MODEL", { filename: data.name.trim() })
      },
    })
  }

  const saveNewItem = async (type, data) => {
    setLoading(true)
    if (type === "picture") {
      switch (data.usage) {
        case "parts":
          saveNewPart({ ...data, file: data.newFile })
          break
        case "tools":
          saveNewTool({ ...data, file: data.newFile })
          break
        case "pois":
          saveNewPoi({ ...data, file: data.newFile })
          break
        default:
          break
      }
    } else if (type === "video") {
      saveNewPoi({ ...data, file: data.newVideoFile, thumbnail: data.thumbnail, type })
    } else if (type === "3d") {
      saveNewModel({ ...data, file: data.newModelFile, thumbnail: data.thumbnail, type })
    } else {
      setLoading(false)
    }
  }

  const saveUpdateItem = async (type, data) => {
    setLoading(true)
    if (type === "picture") {
      switch (data.usage) {
        case "parts": {
          //* push new file
          if (data?.newFile?.data) {
            await api.postPart(itemSelected?.id, data?.newFile?.data)
          }

          //* push new trad
          const newJson2 = { label: { en: data.name.trim() } }
          await api.postPartTranslation(itemSelected?.id, newJson2)

          setItemSelected(null)
          changeCurrentPage(1)
          setLoading(false)
          break
        }
        case "tools": {
          //* push new file if exist

          if (data?.newFile?.data) {
            await api.postTool(itemSelected?.id, data?.newFile?.data)
          }

          //* push new trad
          const json = { label: { en: data.name.trim() } }
          await api.postToolTranslation(itemSelected?.id, json)

          setItemSelected(null)
          setLoading(false)
          changeCurrentPage(1)

          break
        }
        case "pois": {
          //* push new file if exist --------------------
          await api.postPicturePoi(itemSelected?.id, data?.newFile?.data, data?.newFile?.filename)

          //* push new trad -----------------
          const newJson = { label: { en: data.name.trim() } }
          await api.postPoiTranslation(itemSelected?.id, newJson)

          setLoading(false)
          setItemSelected(null)
          changeCurrentPage(1)

          break
        }
        default:
          break
      }
    } else if (type === "video") {
      await updatePoi({
        variables: { filename: data.name.trim(), id: itemSelected?.id },
      })

      //* push new file if exist --------------------
      if (data?.newVideoFile?.data) {
        await api.postVideoPoi(itemSelected?.id, data?.newVideoFile?.data)
      }
      if (data?.thumbnail) {
        await api.postThumbnailPoi(itemSelected?.id, data?.thumbnail)
      }

      setLoading(false)
      setItemSelected(null)
      changeCurrentPage(1)
    } else if (type === "3d") {
      await updateModel({
        variables: { filename: data.name.trim(), id: itemSelected?.id },
      })
      if (data?.newModelFile?.data) {
        await api.postModel(itemSelected?.id, data?.newModelFile?.data)
      }

      if (data?.thumbnail) {
        await api.postModelThumbnail(itemSelected?.id, data?.thumbnail)
      }
      setLoading(false)
      setItemSelected(null)
      changeCurrentPage(1)
    }
    setItemSelected(null)
    setLoading(false)
  }

  const handleModalNewUpload = () => {
    setItemSelected({ action: "new" })
  }

  const showEditData = () => {
    setItemSelected(prev => ({ ...prev, action: "edit" }))
  }

  return {
    assetTypes: assetTypesDb?.assetTypes?.nodes || [],
    assets: assets || [],
    totalCount,
    first,
    changeCurrentPage,
    itemSelected,
    setItemSelected,
    saveNewPart,
    saveNewTool,
    saveNewPoi,
    errorCreate,
    setErrorCreate,
    loading,
    saveNewItem,
    handleModalNewUpload,
    saveUpdateItem,
    showEditData,
  }
}
