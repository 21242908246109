import { css, StyleSheet } from "aphrodite"
import PropTypes from "prop-types"
import React from "react"
import { useTranslation } from "react-i18next"
import { CheveronDown, Eye } from "../../assets/svgr"
import { useTheme } from "../../components/Provider/ThemeProvider"
import { UIButtonIcon } from "../../components/UI"
import { UIInput } from "../../components/UI/UIInput"
import { UISlider } from "../../components/UI/UISlider"
import { BLACK_COLOR, NEUTRAL_DARK, WHITE } from "../../styles/colors"

export const WidgetCameraItem = ({ open, handleOpen, dataCamera, changeDataCamera, handleHighlight }) => {
  const { themeStyles } = useTheme()
  const { t } = useTranslation("model3DPage")

  // init style ----------------------------------------------------------------
  const styles = getStyles({ theme: themeStyles })

  return (
    <div className={css(styles.container)}>
      <div className={css(styles.header)}>
        <div className={css(styles.flex)}>
          <h1 className={css(styles.title)}>{t("camera")}</h1>
          <UIButtonIcon hiddenBackgroundColor={true} onClick={handleHighlight} icon={<Eye color={WHITE} />} />
        </div>
        <button
          type="button"
          aria-label="open"
          onClick={() => handleOpen()}
          className={css(styles.btn, !open && styles.chevronUp)}>
          <CheveronDown color={WHITE} />
        </button>
      </div>
      {open && (
        <div className={css(styles.body)}>
          <p className={css(styles.font600)}>{t("edit.position")}</p>
          <div className={css(styles.containerPos)}>
            <UIInput
              colorIcon={themeStyles?.PRIMARY_COLOR}
              camera="X"
              type="number"
              dynamicStyle={[styles.input]}
              value={dataCamera?.x}
              onChange={e => changeDataCamera("x", Number(e.target.value))}
            />
            <UIInput
              colorIcon={themeStyles?.PRIMARY_COLOR}
              camera="Y"
              type="number"
              dynamicStyle={[styles.input]}
              value={dataCamera?.y}
              onChange={e => changeDataCamera("y", Number(e.target.value))}
            />
            <UIInput
              colorIcon={themeStyles?.PRIMARY_COLOR}
              camera="Z"
              type="number"
              dynamicStyle={[styles.input]}
              value={dataCamera?.z}
              onChange={e => changeDataCamera("z", Number(e.target.value))}
            />
          </div>
          <p className={css(styles.font600)}>{t("edit.zoom")}</p>
          <div className={css(styles.containerZoom)}>
            <UIInput
              value={dataCamera?.minZoomDb?.toFixed(3)}
              type="number"
              min={dataCamera?.minZoomModel?.toFixed(3)}
              max={Number(dataCamera?.maxZoomModel?.toFixed(3)) - 0.001}
              onChange={e => changeDataCamera("minZoomDb", Number(e.target.value))}
              dynamicStyle={[styles.smallInput]}
              step={0.001}
              placeholder={dataCamera?.minZoomModel?.toFixed(3)}
            />
            <UISlider
              primaryColor={themeStyles.PRIMARY_COLOR}
              secondaryColor={themeStyles.SECONDARY_COLOR}
              min={Number(dataCamera?.minZoomModel?.toFixed(3))}
              max={Number(dataCamera?.maxZoomModel?.toFixed(3)) - 0.001}
              value={[Number(dataCamera?.minZoomDb?.toFixed(3)), Number(dataCamera?.maxZoomDb?.toFixed(3))]}
              handleChange={e => {
                changeDataCamera("maxZoomDb", e.target.value[1])
                changeDataCamera("minZoomDb", e.target.value[0])
              }}
            />

            <UIInput
              onChange={e => changeDataCamera("maxZoomDb", Number(e.target.value))}
              value={dataCamera?.maxZoomDb?.toFixed(3)}
              dynamicStyle={[styles.smallInput]}
              min={Number(dataCamera?.minZoomModel) + 0.001}
              max={dataCamera?.maxZoomModel}
              placeholder={dataCamera?.maxZoomModel?.toFixed(3)}
              type={"number"}
              step={0.001}
            />
          </div>
        </div>
      )}
    </div>
  )
}

WidgetCameraItem.propTypes = {
  open: PropTypes.bool,
  handleOpen: PropTypes.func,
  dataCamera: PropTypes.object,
  changeDataCamera: PropTypes.func,
  handleHighlight: PropTypes.func,
}

const getStyles = ({ theme }) =>
  StyleSheet.create({
    container: {
      width: "100%",
      height: "auto",
      marginBottom: 15,
    },
    sliderRoot: {
      position: "relative",
      display: "flex",
      alignItems: "center",
      userSelect: "none",
      touchAction: "none",
      width: 200,
      height: 20,
    },
    sliderTrack: {
      background: "rgba(217, 217, 217, 0.80)",
      position: "relative",
      flexGrow: 1,
      height: 4,
    },
    sliderRange: {
      position: "absolute",
      height: "100%",
      background: "rgba(217, 217, 217, 0.80)",
    },
    sliderThumb: {
      display: "block",
      width: 35,
      height: 10,
      backgroundColor: theme.PRIMARY_COLOR || BLACK_COLOR,
      cursor: "pointer",
      ":hover": {
        opacity: 0.8,
      },
    },
    containerZoom: {
      display: "flex",
      alignItems: "start",
      justifyContent: "space-between",
      gap: 5,
      padding: 0,
    },
    body: { background: WHITE, padding: "0 10px 5px", boxSizing: "border-box" },
    chevronUp: { transform: "rotate(180deg)" },
    btn: {
      border: "none",
      cursor: "pointer",
      background: theme.SECONDARY_COLOR,
      color: WHITE,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      width: 40,
    },
    containerPos: {
      display: "flex",
      gap: 10,
    },
    smallInput: {
      borderRadius: 0,
      fontStyle: "italic",
      fontSize: 12,
      height: 30,
      fontWeight: "400",
      width: 65,
      padding: 0,
      textAlign: "center",
      color: theme.PRIMARY_COLOR || BLACK_COLOR,
      "::placeholder": {
        color: theme.PRIMARY_COLOR || BLACK_COLOR,
        opacity: 0.7,
      },
    },
    input: {
      borderRadius: 0,
      fontStyle: "italic",
      fontSize: 12,
      height: 30,
      fontWeight: "400",
      color: theme.PRIMARY_COLOR || BLACK_COLOR,
      "::placeholder": {
        color: NEUTRAL_DARK,
        opacity: 0.7,
      },
    },
    header: {
      border: "none",
      height: 35,
      background: theme.SECONDARY_COLOR,
      color: WHITE,
      display: "flex",
      alignItems: "center",
      padding: "0 0 0 10px",
      textTransform: "uppercase",
      fontSize: 12,
      justifyContent: "space-between",
    },
    flex: {
      display: "flex",
      alignItems: "center",
    },
    title: {
      margin: 0,
      fontFamily: "Roboto",
      fontWeight: "600",
      fontSize: 14,
      minWidth: "60%",
    },
    item: {
      height: 25,
    },
    font600: {
      fontFamily: "Roboto",
      fontSize: 12,
      fontStyle: "normal",
      fontWeight: "600",
      lineHeight: "22px",
      padding: "5px 0 0",
      margin: 0,
      color: theme.PRIMARY_COLOR || BLACK_COLOR,
    },
  })
