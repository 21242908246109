import dayjs from "dayjs"
import { jwtDecode } from "jwt-decode"

export const isUserTokenExpired = token => {
  if (token) {
    const { exp } = jwtDecode(token)
    return !exp || dayjs().isAfter(dayjs(exp * 1000))
  }
  return true
}

export const checkEnv = () => {
  const environment = process.env.REACT_APP_ENV || "development"
  const configEnv = require(`../config/config.${environment}.js`)
  const config = configEnv?.default
  return config
}

export const dataURItoBlob = dataURI => {
  // convert base64 to raw binary data held in a string
  const byteString = atob(dataURI.split(",")[1])

  // separate out the mime component
  const mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0]

  // write the bytes of the string to an ArrayBuffer
  const arrayBuffer = new ArrayBuffer(byteString.length)
  const _ia = new Uint8Array(arrayBuffer)
  for (let i = 0; i < byteString.length; i++) {
    _ia[i] = byteString.charCodeAt(i)
  }

  const dataView = new DataView(arrayBuffer)
  const blob = new Blob([dataView], { type: mimeString })
  return blob
}
