import PropTypes from "prop-types"
import * as React from "react"

const CheveronDown = ({ color }) => (
  <svg width="12" height="7" viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1.49951 1.21875L5.99951 5.71875L10.4995 1.21875"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
CheveronDown.propTypes = {
  color: PropTypes.string.isRequired,
}

export default CheveronDown
