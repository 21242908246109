import * as THREE from 'three'
import { EffectComposer } from 'three/addons/postprocessing/EffectComposer.js'
// import { RenderPass } from 'three/addons/postprocessing/RenderPass.js'
import { OutputPass } from 'three/addons/postprocessing/OutputPass.js'
import { SMAAPass } from 'three/addons/postprocessing/SMAAPass.js'
import { ShaderPass } from 'three/addons/postprocessing/ShaderPass.js'
import { FXAAShader } from 'three/addons/shaders/FXAAShader.js'
import { DotScreenPass } from 'three/addons/postprocessing/DotScreenPass.js'
import { CopyShader } from 'three/addons/shaders/CopyShader.js'
import { SSAARenderPass } from 'three/addons/postprocessing/SSAARenderPass.js'
import { GammaCorrectionShader } from 'three/addons/shaders/GammaCorrectionShader.js'
// import { OutlinePass } from 'three/addons/postprocessing/OutlinePass.js'

/**
 * @name World
 * @class
 * @description Manage 3D world using THREE
 */
class World {
  data
  renderer
  camera
  scene
  width
  height
  canvasResizeObserver = null
  effectComposer = null
  outputPassIndex = -1
  // t = { time: 0, target: 1200, b: false }

  constructor(data = {}) {
    Object.assign(data, {
      width: data.width || window.innerWidth,
      height: data.height || window.innerHeight,
      clearAlpha: data.clearAlpha === undefined ? 1.0 : data.clearAlpha,
      clearColor: data.clearColor === undefined ? 0xff00ff : data.clearColor,
      autoResize: data.autoResize === undefined ? true : data.autoResize,
      renderOptions: data.renderOptions || { antialias: true },
      pixelRatio: data.pixelRatio || window.devicePixelRatio,
      sortObjects: data.sortObjects === undefined ? true : data.sortObjects,
      updateStyle: data.updateStyle === undefined ? true : data.updateStyle,
      fov: data.fov || 60,
      near: data.near || 0.01,
      far: data.far || 1000,
    })

    this.data = data
    this.width = data.width
    this.height = data.height

    /*--------SCENE--------*/
    this.scene = new THREE.Scene()
    if (this.data.ambientIntensity) {
      this.scene.add(
        new THREE.AmbientLight(this.data.ambientColor || 0xffffff, this.data.ambientIntensity),
      )
    }

    /*--------RENDERER--------*/
    this.renderer = this.data.renderer || new THREE.WebGLRenderer(this.data.renderOptions)
    this.renderer.setClearColor(this.data.clearColor, this.data.clearAlpha)
    this.renderer.setPixelRatio(this.data.pixelRatio)
    this.renderer.sortObjects = this.data.sortObjects
    this.renderer.setSize(this.width, this.height, this.data.updateStyle)

    // Append the canvas element created by the renderer to document body element or provided container.
    if (!this.data.renderOptions.canvas) {
      if (this.data.container) {
        this.data.container.appendChild(this.renderer.domElement)
      } else {
        document.body.appendChild(this.renderer.domElement)
      }
    }

    /*--------CAMERA--------*/
    this.camera =
      this.data.camera ||
      new THREE.PerspectiveCamera(
        this.data.fov,
        this.width / this.height,
        this.data.near,
        this.data.far,
      )
    this.scene.add(this.camera)
    this.camera.updateProjectionMatrix()

    if (this.data.autoResize) {
      this.canvasResizeObserver = new ResizeObserver(onCanvasResize.bind(this))
      this.canvasResizeObserver.observe(this.renderer.domElement)
    }

    function onCanvasResize(entries) {
      for (const entry of entries) {
        if (entry.target === this.renderer.domElement) {
          const width = entry.contentBoxSize[0].inlineSize
          const height = entry.contentBoxSize[0].blockSize

          this.renderer.setSize(width, height, false)
          if (this.effectComposer !== null) {
            this.effectComposer.setSize(width, height)
          }
          this.camera.aspect = width / height
          this.camera.updateProjectionMatrix()

          const resizeEvent = new CustomEvent('renderResize', {
            detail: {
              world: this,
              width,
              height,
            },
          })

          this.renderer.domElement.dispatchEvent(resizeEvent)
          return
        }
      }
    }
  }

  dispose() {
    if (this.canvasResizeObserver != null) {
      this.canvasResizeObserver.disconnect()
      this.canvasResizeObserver = null
    }

    if (this.effectComposer !== null) {
      this.effectComposer.dispose()
      this.effectComposer = null
    }
  }

  addPass(newPass, afterToneMapping = false) {
    if (this.effectComposer === null) {
      this.effectComposer = getNewPreconfiguredEffectComposer.call(this)
    }

    // console.log(newPass)
    if (afterToneMapping) {
      // this.effectComposer.addPass(newPass)
      this.effectComposer.insertPass(newPass, this.effectComposer.passes.length - 1)
    } else {
      this.effectComposer.insertPass(newPass, this.outputPassIndex)
      this.outputPassIndex++
    }
    // this.effectComposer.addPass(new OutputPass())
    // this.effectComposer.addPass(newPass)

    // this.effectComposer.removePass(this.effectComposer.passes[2])

    console.warn(this.effectComposer)
  }

  addSMAAPass() {
    const drawingSize = new THREE.Vector2()
    this.renderer.getDrawingBufferSize(drawingSize)

    const smaaPass = new SMAAPass(drawingSize.x, drawingSize.y)

    // Temporary fix: won't be necessary when three is upgraded to r158
    smaaPass.needsSwap = true

    // this.renderer.domElement.addEventListener('renderResize', onResize.bind(this))

    this.addPass(smaaPass, true)

    // function onResize(event) {
    //   if (event.detail.world === this) {
    //     const drawingSize = new THREE.Vector2()
    //     this.renderer.getDrawingBufferSize(drawingSize)
    //     console.warn('RESIZE SMAA ' + drawingSize.x + ' ' + drawingSize.y)
    //     smaaPass.setSize(drawingSize.x, drawingSize.y)
    //   }
    // }
  }

  addFXAAPass() {
    const fxaaPass = new ShaderPass(FXAAShader)
    const renderSize = new THREE.Vector2()

    this.renderer.getSize(renderSize)

    fxaaPass.uniforms['resolution'].value.set(1 / renderSize.x, 1 / renderSize.y)
    this.renderer.domElement.addEventListener('renderResize', onRenderResize.bind(this))

    this.addPass(fxaaPass, true)

    function onRenderResize(event) {
      if (event.detail.world === this) {
        console.warn('RESIZE FXAA ' + event.detail.width + ' ' + event.detail.height)
        fxaaPass.uniforms['resolution'].value.set(
          1.0 / event.detail.width,
          1.0 / event.detail.height,
        )
      }
    }
  }

  addDotScreenPass() {
    this.addPass(new DotScreenPass(undefined, undefined, 4))
  }

  addCopyPass() {
    this.addPass(new ShaderPass(CopyShader))
  }

  // setOutlinedObjects(objects = []) {
  //   if (this.effectComposer === null) {
  //     this.effectComposer = getNewPreconfiguredEffectComposer.call(this)
  //   }

  //   let outlinePass = this.effectComposer.passes.find((pass) => pass instanceof OutlinePass)
  //   if (outlinePass === undefined) {
  //     const renderSize = new THREE.Vector2()
  //     this.renderer.getSize(renderSize)

  //     outlinePass = new OutlinePass(renderSize, this.scene, this.camera)
  //     outlinePass.hiddenEdgeColor = new THREE.Color(1.0, 1.0, 1.0)
  //     outlinePass.visibleEdgeColor = new THREE.Color(1.0, 0.0, 0.0)
  //     outlinePass.overlayMaterial.blending = THREE.CustomBlending

  //     this.addPass(outlinePass, true)
  //   }

  //   outlinePass.selectedObjects = objects
  // }

  animate(deltaTime) {
    if (this.effectComposer != null) {
      // this.t.time += deltaTime
      // if (this.t.time > this.t.target) {
      //   this.t.target += 1200
      //   // this.effectComposer.passes[2].enabled = !this.effectComposer.passes[2].enabled
      //   this.t.b = !this.t.b

      //   if (this.t.b) {
      //     // let color = new THREE.Color(new THREE.Color(0x22aadd).getHex(THREE.LinearSRGBColorSpace))
      //     // this.renderer.setClearColor(color)
      //     // this.renderer.outputColorSpace = THREE.LinearSRGBColorSpace
      //   } else {
      //     // let color = new THREE.Color(0x22aadd)
      //     // this.renderer.setClearColor(color)
      //     // this.renderer.outputColorSpace = THREE.SRGBColorSpace
      //   }

      //   // const clearColor = new THREE.Color()
      //   // this.renderer.getClearColor(clearColor)
      //   // clearColor.setHex(clearColor.getHex(THREE.SRGBColorSpace))
      //   // console.log(clearColor.getHexString(THREE.SRGBColorSpace))
      // }
      // // console.warn(this.effectComposer.passes.map((e) => e.renderToScreen))

      // let doNotCompare = true
      // if (this.t.b || doNotCompare) {
      //   this.effectComposer.render()
      // } else {
      //   this.renderer.render(this.scene, this.camera)
      // }

      this.effectComposer.render(deltaTime)
    } else {
      this.renderer.render(this.scene, this.camera)
    }
  }
}

function getNewPreconfiguredEffectComposer() {
  const newEffectComposer = new EffectComposer(this.renderer)

  const clearColor = new THREE.Color()
  this.renderer.getClearColor(clearColor)
  newEffectComposer.setPixelRatio(this.renderer.getPixelRatio())

  // const taaPass = new TAARenderPass(this.scene, this.camera)
  // taaPass.unbiased = false
  // taaPass.accumulate = true
  // taaPass.sampleLevel = 4
  const ssaaPass = new SSAARenderPass(this.scene, this.camera, clearColor.getHex(), 1.0)
  ssaaPass.sampleLevel = 2

  // newEffectComposer.addPass(new RenderPass(this.scene, this.camera))
  // newEffectComposer.addPass(taaPass)
  newEffectComposer.addPass(ssaaPass)
  newEffectComposer.addPass(new OutputPass())
  newEffectComposer.addPass(new ShaderPass(GammaCorrectionShader))
  // newEffectComposer.passes[1].enabled = false
  // newEffectComposer.passes[0].needsSwap = true

  this.outputPassIndex = 1

  return newEffectComposer
}

export default World
