import * as THREE from 'three'

class SelectionManager {
  viewer
  _currentlySelected = null
  _raycaster
  // _clickListener
  _keyDownListener

  get selected() {
    return this._currentlySelected
  }

  constructor(viewer) {
    this.viewer = viewer

    this._raycaster = new THREE.Raycaster()
    // this._clickListener = onClick.bind(this)
    this._keyDownListener = onKeyDown.bind(this)

    function onKeyDown(event) {
      if (event.key === 'Escape' && this._currentlySelected.autoUnselect) {
        this.unselect()
      }
    }

    // function onClick(event) {
    //   const { ndcX, ndcY } = convertOffsetToNDCCoordinates(
    //     event.offsetX,
    //     event.offsetY,
    //     this.viewer.canvas.clientWidth,
    //     this.viewer.canvas.clientHeight
    //   )
    //   const intersects = []

    //   console.warn('CLICK SELECT')

    //   this._raycaster.setFromCamera(new THREE.Vector2(ndcX, ndcY), this.viewer.world.camera)
    //   this._raycaster.intersectObject(this.viewer.scene, true, intersects)
    //   if (intersects.length > 0) {
    //     // if (this._raycaster.intersectObject(this.viewer.scene, true).length > 0) {
    //     console.warn(intersects)
    //     return
    //   }

    //   console.warn(this._currentlySelected.autoUnselect)
    //   // The raycast did not hit anything
    //   if (this._currentlySelected.autoUnselect) {
    //     this.unselect()
    //   }
    // }
  }

  addListeners() {
    // this.viewer.canvas.addEventListener('click', this._clickListener)
    window.addEventListener('keydown', this._keyDownListener)
  }

  removeListeners() {
    // this.viewer.canvas.removeEventListener('click', this._clickListener)
    window.removeEventListener('keydown', this._keyDownListener)
  }

  dispose() {
    this.removeListeners()
  }

  select(selectable) {
    // TODO: check validity of selectable

    if (selectable === this._currentlySelected) {
      return
    }

    if (this._currentlySelected !== null) {
      this.unselect()
    }

    this._currentlySelected = selectable
    this._currentlySelected.select(this)

    this.addListeners()
  }

  unselect() {
    if (this._currentlySelected === null) {
      return
    }

    this._currentlySelected.unselect(this)
    this._currentlySelected = null

    this.removeListeners()
  }
}

export default SelectionManager
