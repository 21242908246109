import { css, StyleSheet } from "aphrodite"
import PropTypes from "prop-types"
import React, { useCallback, useEffect, useMemo, useState } from "react"
import Modal from "react-modal"
import { Link } from "react-router-dom"
import { Close } from "../../assets/svgr"
import { useTheme } from "../../components/Provider/ThemeProvider"
import { UIButtonIcon } from "../../components/UI"
import { checkEnv } from "../../lib"
import { BLACK_COLOR, WHITE } from "../../styles/colors"

const staticStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "80dvw",
    maxWidth: 550,
    maxHeight: "80dvh",
    border: "none",
    padding: "60px 40px 40px",
    borderRadius: 10,
    boxSizing: "border-box",
    display: "flex",
    flexDirection: "column",
    gap: 20,
  },
  overlay: { background: "rgba(0, 22, 33, 0.70)" },
}
export const WidgetPoiModal = ({ isOpen, closeModal, poi }) => {
  const { themeStyles } = useTheme()
  const [assetUrl, setAssetUrl] = useState(null)

  // init style ----------------------------------------------------------------
  const styles = useMemo(() => getStyles({ theme: themeStyles }), [themeStyles])
  const customStyles = useMemo(() => ({ ...staticStyles, ...styles }), [styles])
  const config = useMemo(() => checkEnv(), [poi])

  const close = useCallback(() => {
    closeModal()
  }, [])

  useEffect(() => {
    if (poi?.idPoi && poi?.assetType && config) {
      const usage = poi.assetType === "video" ? "video" : "image"
      setAssetUrl(`${config.BASE_URL}/pois/${poi.idPoi}/${usage}?lang=en`)
    }
  }, [poi, config])

  return (
    <Modal
      onRequestClose={close}
      appElement={document.getElementById("root")}
      isOpen={isOpen}
      style={customStyles}>
      <div className={css(styles.close_btn)}>
        <UIButtonIcon hiddenBackgroundColor={true} onClick={close} icon={<Close color={WHITE} />} />
      </div>
      {assetUrl && poi.assetType !== "default" && (
        <div>
          {poi.assetType === "video" ? (
            <video controls width="100%" height="100%">
              <source src={assetUrl} type="video/mp4" />
            </video>
          ) : (
            <img alt="image" src={assetUrl} width="100%" height="100%" />
          )}
        </div>
      )}
      {poi?.description && <p className={css(styles.info)}>{poi.description}</p>}
      {poi?.externalLink && (
        <Link className={css(styles.link)} to={poi.externalLink}>
          {poi.labelExternalLink ?? poi.externalLink}
        </Link>
      )}
    </Modal>
  )
}

const getStyles = ({ theme }) =>
  StyleSheet.create({
    info: {
      fontWeight: "600",
      color: theme.PRIMARY_COLOR,
      fontSize: 16,
      fontFamily: "Roboto",
      flexShrink: 0,
      margin: 0,
    },
    close_btn: {
      background: BLACK_COLOR,
      alignSelf: "flex-end",
      width: 25,
      height: 25,
      position: "absolute",
      top: 15,
      right: 15,
      borderRadius: "50%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    link: {
      color: theme.PRIMARY_COLOR,
      fontSize: 16,
      fontFamily: "Roboto",
      flexShrink: 0,
      fontWeight: "400",
      margin: 0,
      fontStyle: "italic",
      textDecoration: "none",
      ":hover": {
        color: theme.PRIMARY_COLOR,
        textDecoration: "underline",
      },
    },
  })

WidgetPoiModal.propTypes = {
  isOpen: PropTypes.bool,
  closeModal: PropTypes.func,
  poi: PropTypes.object,
}
