import { useEffect } from "react"
import * as THREE from "three"

export const use3DAnimation = (
  viewerRef,
  modelRoot,
  animationName,
  animationPlayMode = "normal",
  animationRepetitions = Infinity,
  isPaused = false,
) => {
  useEffect(playAnimation, [viewerRef, modelRoot, animationName, animationPlayMode, animationRepetitions])
  useEffect(updateAnimationPaused, [viewerRef, isPaused])

  function playAnimation() {
    const animationNames = viewerRef.current.getAnimationNames()
    if (animationName && animationNames.includes(animationName)) {
      let playMode
      switch (animationPlayMode) {
        case "ping-pong":
          playMode = THREE.LoopPingPong
          break
        case "normal":
        default:
          playMode = THREE.LoopRepeat
          break
      }

      viewerRef.current.playAnimation(animationName, playMode, animationRepetitions)
    } else {
      viewerRef.current.stopAnimation()
    }
  }

  function updateAnimationPaused() {
    viewerRef.current.setAnimationPaused(isPaused)
  }
}
