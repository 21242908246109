import { gql } from "@apollo/client"
import {
  ASSET_TYPE_DATA,
  CHILD_STEP_ANIMATION_DATA,
  CHILD_STEP_CAMERA_DATA,
  CHILD_STEP_DATA,
  MANUAL_DATA,
  MANUAL_STEP_DATA,
  PART_DATA,
  POI_DATA,
  TOOL_DATA,
} from "./fragments"

// step ------------------------------------------------------------------------
export const MANUAL_STEP = gql`
  ${MANUAL_STEP_DATA}
  ${MANUAL_DATA}
  ${CHILD_STEP_DATA}
  ${PART_DATA}
  ${TOOL_DATA}
  query ManualStep($id: UUID!) {
    manualStep(id: $id) {
      ...ManualStepData
      stepModelsByIdManualStep {
        nodes {
          id
          idModel
        }
      }
      manualByIdManual {
        ...ManualData
        productByIdProduct {
          id
          name
        }
      }
      childStepsByIdManualStep(orderBy: POS_ASC) {
        nodes {
          ...ChildStepData
          stepPartsByIdChildStep(orderBy: CREATED_AT_ASC) {
            nodes {
              quantity
              id
              partByIdPart {
                ...PartData
              }
            }
          }
          stepToolsByIdChildStep(orderBy: CREATED_AT_ASC) {
            nodes {
              id
              toolByIdTool {
                ...ToolData
              }
              quantity
            }
          }
        }
      }
    }
  }
`

export const STEPS_BY_MANUAL = gql`
  ${MANUAL_STEP_DATA}
  query ManualSteps($id: UUID!) {
    manual(id: $id) {
      productByIdProduct {
        id
        name
      }
      manualStepsByIdManual(orderBy: POS_ASC) {
        nodes {
          ...ManualStepData
        }
      }
    }
  }
`

export const CREATE_MANUAL_STEP = gql`
  ${MANUAL_STEP_DATA}
  mutation CreateManualStep($idManual: UUID!, $pos: Int!) {
    createManualStep(input: { manualStep: { idManual: $idManual, pos: $pos } }) {
      manualStep {
        ...ManualStepData
      }
    }
  }
`

export const DELETE_STEP = gql`
  mutation DeleteManualStep($idStep: UUID!) {
    deleteManualStep(input: { id: $idStep }) {
      clientMutationId
    }
  }
`

// child step ------------------------------------------------------------------
export const CHILD_STEPS_BY_STEP = gql`
  ${ASSET_TYPE_DATA}
  ${CHILD_STEP_DATA}
  ${CHILD_STEP_ANIMATION_DATA}
  ${CHILD_STEP_CAMERA_DATA}
  ${POI_DATA}
  query ChildSteps($id: UUID!) {
    childSteps(filter: { idManualStep: { equalTo: $id } }, orderBy: POS_ASC) {
      nodes {
        ...ChildStepData
        childStepAnimationsByIdChildStep {
          nodes {
            ...ChildStepAnimationData
            animationModeByIdAnimationMode {
              id
              label
            }
          }
        }
        childStepCamerasByIdChildStep {
          nodes {
            ...ChildStepCameraData
          }
        }
        stepPoisByIdChildStep {
          nodes {
            id
            idPoi
            x
            y
            z
            type
            poiByIdPoi {
              ...PoiData
              usagePoisByIdPoi {
                nodes {
                  assetTypeByIdAssetType {
                    ...AssetTypeData
                  }
                }
              }
            }
          }
        }
        manualStepByIdManualStep {
          stepModelsByIdManualStep {
            nodes {
              idModel
            }
          }
        }
      }
    }
  }
`
export const UPDATE_CHILD_STEP = gql`
  mutation UpdateChildStep($id: UUID!, $nbRepeat: Int) {
    updateChildStep(input: { patch: { nbRepeat: $nbRepeat }, id: $id }) {
      clientMutationId
    }
  }
`

export const CREATE_CHILD_STEP = gql`
  mutation CreateChildStep($idManualStep: UUID!, $pos: Int!) {
    createChildStep(input: { childStep: { idManualStep: $idManualStep, pos: $pos } }) {
      childStep {
        id
      }
    }
  }
`

export const DELETE_CHILD_STEP = gql`
  mutation DeleteChildStep($id: UUID!) {
    deleteChildStep(input: { id: $id }) {
      clientMutationId
    }
  }
`

// parts child step ------------------------------------------------------------
export const DELETE_STEP_PART = gql`
  mutation DeleteStepPart($id: UUID!) {
    deleteStepPart(input: { id: $id }) {
      clientMutationId
    }
  }
`

export const UPDATE_STEP_PART_QUANTITY = gql`
  mutation UpdateStepPart($id: UUID!, $quantity: Int) {
    updateStepPart(input: { patch: { quantity: $quantity }, id: $id }) {
      clientMutationId
    }
  }
`

export const CREATE_STEP_PART = gql`
  mutation CreateStepPart($idPart: UUID!, $idChildStep: UUID!, $quantity: Int) {
    createStepPart(input: { stepPart: { idPart: $idPart, idChildStep: $idChildStep, quantity: $quantity } }) {
      clientMutationId
    }
  }
`

// tools child step ------------------------------------------------------------
export const DELETE_STEP_TOOL = gql`
  mutation DeleteStepTool($id: UUID!) {
    deleteStepTool(input: { id: $id }) {
      clientMutationId
    }
  }
`

export const CREATE_STEP_TOOL = gql`
  mutation CreateStepTool($idTool: UUID!, $idChildStep: UUID!, $quantity: Int) {
    createStepTool(input: { stepTool: { idTool: $idTool, idChildStep: $idChildStep, quantity: $quantity } }) {
      clientMutationId
    }
  }
`

// models ----------------------------------------------------------------------

export const FETCH_STEP_MODEL = gql`
  query ManualStep($id: UUID!) {
    manualStep(id: $id) {
      id
      stepModelsByIdManualStep {
        nodes {
          id
          idModel
        }
      }
    }
  }
`
export const CREATE_STEP_MODEL = gql`
  mutation CreateStepModel($idStep: UUID!, $idModel: UUID!) {
    createStepModel(input: { stepModel: { idModel: $idModel, idManualStep: $idStep } }) {
      clientMutationId
    }
  }
`

export const UPDATE_STEP_MODEL = gql`
  mutation UpdateStepModel($idModel: UUID!, $id: UUID!) {
    updateStepModel(input: { patch: { idModel: $idModel }, id: $id }) {
      clientMutationId
    }
  }
`
