import * as React from "react"

const CloseSmall = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width={6} height={6} fill="none">
    <path
      fill="#fff"
      d="M.84 5.95a.6.6 0 0 0 .195-.137l1.976-1.975 1.976 1.975a.6.6 0 0 0 .848-.848L3.859 2.989l1.976-1.976a.6.6 0 0 0-.848-.848L3.01 2.141 1.035.165a.6.6 0 0 0-.848.848L2.162 2.99.187 4.965a.6.6 0 0 0 .653.985Z"
    />
  </svg>
)

export default CloseSmall
