import * as React from "react"

const Info = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="none">
    <path
      fill="#007DBC"
      d="M8.486 12.49c-.408 0-.72-.119-.936-.358-.216-.246-.323-.562-.323-.947V6.69h1.317v4.38c0 .176.027.296.08.358.055.061.136.092.243.092h.093a.43.43 0 0 0 .104-.012c.03 0 .065-.003.104-.011l.139.832a.902.902 0 0 1-.208.08c-.07.024-.158.043-.266.059-.1.015-.216.023-.347.023Zm-.578-8.366c.247 0 .436.074.567.22a.817.817 0 0 1 .196.566c0 .231-.07.424-.208.578-.13.154-.327.231-.59.231-.246 0-.438-.073-.577-.22a.828.828 0 0 1-.197-.554c0-.239.066-.435.197-.59.139-.154.343-.23.612-.23Z"
    />
    <path
      fill="#007DBC"
      fillRule="evenodd"
      d="M8 14.667A6.667 6.667 0 1 0 8 1.333a6.667 6.667 0 0 0 0 13.334ZM8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16Z"
      clipRule="evenodd"
    />
  </svg>
)
export default Info
