import { css, StyleSheet } from "aphrodite"
import React, { useCallback, useMemo, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate, useOutletContext } from "react-router-dom"
import { useEvent } from "react-use"
import { Add, ChevronDynamique, Save } from "../../assets/svgr"
import { useTheme } from "../../components/Provider/ThemeProvider"
import { UIButton, UIButtonIcon } from "../../components/UI"
import { LogoutHeader, ManualEdition3DViewer, ManualPreview3DViewer } from "../../components/Widget"
import { useModel3dPage } from "../../hooks/useModel3dPage"
import { checkEnv } from "../../lib"
import { NEUTRAL_LIGHT, NEUTRAL_MEDIUM, WHITE } from "../../styles/colors"
import { WarningBackModal } from "./WarningBackModal"
import WidgetAnimationItem from "./WidgetAnimationItem"
import { WidgetCameraItem } from "./WidgetCameraItem"
import { WidgetPOIItem } from "./WidgetPOIItem"
import { WidgetPoiModal } from "./WidgetPoiModal"
import { WidgetTargetItem } from "./WidgetTargetItem"

export const Model3DPage = () => {
  // others hooks --------------------------------------------------------------
  const { t } = useTranslation("model3DPage")
  const { themeStyles, setOverlay, overlay } = useTheme()
  const config = useMemo(() => checkEnv(), [])
  const navigate = useNavigate()
  const [showWarningSave, setShowWarningSave] = useState(false)
  const [showDetailPoi, setShowDetailPoi] = useState(false)
  const [poiSelected, setPoiSelected] = useState(null)
  const params = new URLSearchParams(window.location.search)

  const {
    // header page data --------
    childSteps,
    childStepSelected,
    changeChildSelected,
    openSelect,
    setOpenSelect,
    // data edit mode -------
    data,
    animationModes,
    setAnimatesAvailable,
    animatesAvailable,
    // functions -------------
    addNewPoi,
    deleteLocalPoi,
    changeDataPoi,
    changeData,
    saveAllData,
    // params ----------------
    drawerHighlight,
    setDrawerHighlight,
    saveSuccess,
    loadingSave,
  } = useModel3dPage()
  const divMenu = useRef()
  const hightlightRef = useRef()

  const { modalHidden } = useOutletContext()

  // init style ----------------------------------------------------------------
  const styles = useMemo(() => getStyles({ theme: themeStyles }), [themeStyles])

  const handleClickOutside = useCallback(
    event => {
      //* close select ------------------
      if (divMenu.current && !divMenu.current.contains(event.target)) {
        setOpenSelect(false)
      }
      //* close overlay ------------------
      if (hightlightRef.current && !hightlightRef.current.contains(event.target) && overlay) {
        setOverlay(false) // close overlay
        setDrawerHighlight({ type: null, index: null, idPoi: null }) // close all item
      }
    },
    [overlay],
  )

  useEvent("mousedown", handleClickOutside)

  const redirectBack = confirmForce => {
    if (confirmForce) {
      // force redirect to step page, not save data change
      setShowWarningSave(false)
      //* redirect to step page ---------------------------
      if (sessionStorage.getItem("redirectStepFromModelUrl")) {
        const redirectPath = `${sessionStorage.getItem("redirectStepFromModelUrl")}&childstep=${
          childStepSelected?.id
        }`
        sessionStorage.removeItem("redirectStepFromModelUrl")
        navigate(redirectPath)
      }
    } else {
      if (
        data?.pois?.some(item => item.onSave !== "oldData") ||
        data?.camera?.onSave !== "oldData" ||
        data?.target?.onSave !== "oldData" ||
        (data?.animate?.onSave !== "oldData" && data?.animate?.name)
      ) {
        // not save data, open modal warning
        setShowWarningSave(true)
      } else {
        //* redirect to step page ---------------------------
        if (sessionStorage.getItem("redirectStepFromModelUrl")) {
          const redirectPath = `${sessionStorage.getItem("redirectStepFromModelUrl")}&childstep=${
            childStepSelected?.id
          }`
          sessionStorage.removeItem("redirectStepFromModelUrl")
          navigate(redirectPath)
        }
      }
    }
  }

  const openChoiceTargetNewPoi = useCallback(() => {
    setOverlay(true)
    setDrawerHighlight({ type: "poi", index: data?.pois?.length, idPoi: null }) // open new poi after choice target
  }, [])

  const handleOpenItem = (type, index, value) => {
    // open drawer item
    if (type === "poi") {
      changeDataPoi(index, "drawerOpen", value)
    } else {
      changeData(type, "drawerOpen", value)
    }
  }

  const handleHighlight = (type, index, idPoi) => {
    // show highlight item
    setDrawerHighlight({ type, index, idPoi })
  }

  const animationRepetitions = useMemo(() => {
    if (typeof data?.animate?.repeat !== "string" || !data.animate.repeat.startsWith("_")) {
      return Infinity
    }

    const parsedValue = parseInt(data.animate.repeat.substring(1))

    return isNaN(parsedValue) ? Infinity : parsedValue
  }, [data.animate.repeat])

  return (
    <section className={css(styles.container)}>
      <header className={css(styles.header)}>
        <div className={css(styles.titleContainer)}>
          <div ref={divMenu} className={css(styles.flex)}>
            <h1 className={css(styles.title)}>{t("page_title")}</h1>
            <button
              onClick={() => setOpenSelect(prev => !prev)}
              type="button"
              className={css(styles.select)}
              aria-label="open"
              disabled={childSteps.length <= 1}>
              {childStepSelected && (
                <p
                  style={{
                    margin: 0,
                    textDecoration: "capitalise",
                  }}>{`${t("childStep")}-${childStepSelected.pos}`}</p>
              )}
              <ChevronDynamique state={openSelect ? "up" : "down"} color={themeStyles?.PRIMARY_COLOR} />
            </button>
            {openSelect && !modalHidden && childSteps.length > 1 && (
              <div className={css(styles.selectContainer)}>
                {childSteps?.map((childStep, index) => (
                  <React.Fragment key={index}>
                    {childStep.id !== childStepSelected?.id && (
                      <button
                        aria-label="select-child-step"
                        onClick={() => changeChildSelected(childStep.id)}
                        type="button"
                        className={css(styles.oneItemSelect)}>
                        <p style={{ margin: 0 }}>{`${t("childStep")}-${childStep?.pos}`}</p>
                      </button>
                    )}
                  </React.Fragment>
                ))}
              </div>
            )}
          </div>
        </div>
        <LogoutHeader themeStyles={themeStyles} />
      </header>
      <section style={{ maxWidth: 700, marginBottom: 15 }}>
        <p className={css(styles.font400)}>{t("desc")}</p>
        <div className={css(styles.containerSave)}>
          <UIButtonIcon
            icon={<Save />}
            dynamicStyle={[styles.btnSave, styles.btn]}
            label={t("save")}
            onClick={saveAllData}
            animationSuccess={saveSuccess}
            loading={loadingSave}
          />
          <UIButton
            dynamicStyle={[styles.btn]}
            kind="secondary"
            themeStyle={themeStyles}
            label={t("back_step")}
            onClick={() => redirectBack(false)}
          />
        </div>
      </section>
      <section className={css(styles.painting)}>
        <div className={css(styles.headerView)}>
          <button
            onClick={() => {
              params.set("tab", "preview")
              navigate(`${window.location.pathname}?${params.toString()}`)
            }}
            aria-label="preview_mode"
            type="button"
            className={css(styles.tab, params.get("tab") === "preview" && styles.selected)}>
            {t("preview_mode")}
          </button>
          <button
            onClick={() => {
              params.set("tab", "edit")
              navigate(`${window.location.pathname}?${params.toString()}`)
            }}
            type="button"
            aria-label="edit_mode"
            className={css(styles.tab, params.get("tab") === "edit" && styles.selected)}>
            {t("edit_mode")}
          </button>
        </div>
        <div style={{ display: "flex", justifyContent: "flex-end", height: "93%" }}>
          {params?.get("tab") === "edit" ? (
            <>
              <div
                ref={hightlightRef}
                style={{
                  zIndex: !modalHidden && !showWarningSave && !showDetailPoi ? 2000 : 1,
                  position: "relative",
                }}
                className={css(styles.editionScene)}>
                <ManualEdition3DViewer
                  modelURL={
                    childStepSelected?.manualStepByIdManualStep?.stepModelsByIdManualStep?.nodes[0].idModel
                      ? `${config.BASE_URL}/models/${childStepSelected?.manualStepByIdManualStep?.stepModelsByIdManualStep?.nodes[0].idModel}/file`
                      : null
                  }
                  poisData={data.pois}
                  animationName={data.animate?.name}
                  animationPlayMode={data.animate?.animationModeLabel}
                  animationRepetitions={data.animate?.repetitions}
                  orbitData={{ camera: data.camera, target: data.target }}
                  changeData={changeData}
                  changeDataPoi={changeDataPoi}
                  changeAnimatesAvailable={setAnimatesAvailable}
                  addNewPoi={addNewPoi}
                  addPoiMode={overlay}
                  highlightData={drawerHighlight}
                />
              </div>
              <div className={css(styles.actions)}>
                <h1 className={css(styles.label)}>{t("pois_property")}</h1>
                {childStepSelected &&
                  data?.pois?.map((poi, index) => (
                    <React.Fragment key={index}>
                      {poi?.onSave !== "delete" && (
                        <WidgetPOIItem
                          poi={poi}
                          dataChildStepSelected={data}
                          deletePoi={() => deleteLocalPoi(index)}
                          index={index}
                          changeDataPoi={changeDataPoi}
                          open={poi?.drawerOpen}
                          handleOpen={() => handleOpenItem("poi", index, !poi?.drawerOpen)}
                          handleHighlight={() => handleHighlight("poi", index, poi?.idPoi)}
                        />
                      )}
                    </React.Fragment>
                  ))}
                <div className={css(styles.containerAdd)}>
                  <h1 style={{ fontSize: 14, margin: 0 }}> {t("pois_create")}</h1>
                  <button
                    onClick={openChoiceTargetNewPoi}
                    className={css(styles.containerAddButton)}
                    type="button"
                    aria-label="add poi">
                    <Add />
                  </button>
                </div>
                <h1 className={css(styles.label)}>{t("pois_camera")}</h1>
                <WidgetCameraItem
                  open={data?.camera?.drawerOpen}
                  handleOpen={() => handleOpenItem("camera", 0, !data?.camera?.drawerOpen)}
                  changeDataCamera={(field, value) => changeData("camera", field, value)}
                  dataCamera={data?.camera ?? {}}
                  handleHighlight={() => handleHighlight("camera", 0, null)}
                />
                <WidgetTargetItem
                  open={data?.target?.drawerOpen}
                  handleOpen={() => handleOpenItem("target", 0, !data?.target?.drawerOpen)}
                  changeDataTarget={(field, value) => changeData("target", field, value)}
                  dataTarget={data?.target ?? {}}
                  handleHighlight={() => handleHighlight("target", 0, null)}
                />
                <WidgetAnimationItem
                  changeDataAnimate={(field, value) => changeData("animate", field, value)}
                  dataAnimate={data?.animate ?? {}}
                  animationModes={animationModes}
                  animatesAvailable={animatesAvailable}
                />
              </div>
            </>
          ) : (
            <ManualPreview3DViewer
              modelURL={
                childStepSelected?.manualStepByIdManualStep?.stepModelsByIdManualStep?.nodes[0].idModel
                  ? `${config.BASE_URL}/models/${childStepSelected?.manualStepByIdManualStep?.stepModelsByIdManualStep?.nodes[0].idModel}/file`
                  : null
              }
              poisData={data?.pois}
              animationName={data?.animate?.name}
              animationPlayMode={data?.animate?.animationModeLabel}
              animationRepetitions={animationRepetitions}
              orbitData={{ camera: data.camera, target: data.target }}
              onPoiClick={indexPoi => {
                setPoiSelected(data.pois[indexPoi])
                setShowDetailPoi(true)
              }}
            />
          )}
        </div>
      </section>
      {showWarningSave && !modalHidden && (
        <WarningBackModal
          isOpen={showWarningSave}
          themeStyles={themeStyles}
          closeModal={() => setShowWarningSave(false)}
          closeAndBack={() => redirectBack(true)}
        />
      )}
      {showDetailPoi && poiSelected && !modalHidden && (
        <WidgetPoiModal isOpen={showDetailPoi} closeModal={() => setShowDetailPoi(false)} poi={poiSelected} />
      )}
    </section>
  )
}

const getStyles = ({ theme }) =>
  StyleSheet.create({
    container: {
      background: theme.BACKGROUND_COLOR,
      width: "100%",
      padding: "30px",
      boxSizing: "border-box",
      fontFamily: "Roboto",
      height: "100dvh",
      overflow: "auto",
      "::-webkit-scrollbar": {
        width: 4,
      },
    },
    titleContainer: {
      display: "flex",
      gap: 30,
      justifyContent: "space-between",
      alignItems: "center",
      "@media (max-width: 1100px)": {
        gap: 5,
      },
    },
    btnSave: {
      background: theme?.PRIMARY_COLOR,
      color: WHITE,
      fontSize: 15,
      fontWeight: "500",
      borderRadius: 4,
      display: "flex",
      gap: 10,
    },
    actions: {
      width: "35%",
      background: NEUTRAL_LIGHT,
      maxWidth: 400,
      boxSizing: "border-box",
      padding: "15px 15px 25px",
      overflow: "auto",
      height: "100%",
      "::-webkit-scrollbar": {
        width: 4,
      },
    },
    editionScene: {
      flex: 1,
    },
    containerSave: {
      display: "flex",
      alignItems: "center",
      gap: 10,
    },
    title: {
      fontWeight: "600",
      fontSize: 20,
      color: theme.PRIMARY_COLOR,
      textTransform: "uppercase",
      margin: 0,
    },
    header: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      marginBottom: 30,
      boxSizing: "border-box",
      gap: 40,
    },
    label: {
      color: theme.PRIMARY_COLOR,
      fontFamily: "Roboto",
      fontSize: 16,
      fontStyle: "normal",
      fontWeight: "600",
      margin: 0,
      paddingBottom: 10,
    },
    font400: {
      color: theme.PRIMARY_COLOR,
      fontFamily: "Roboto",
      fontSize: 14,
      fontStyle: "normal",
      fontWeight: "400",
      lineHeight: "22px",
      marginTop: 0,
    },
    headerView: {
      width: "100%",
      display: "flex",
      height: "7%",
    },
    tab: {
      width: "50%",
      textAlign: "center",
      background: theme.SECONDARY_COLOR || WHITE,
      border: "none",
      cursor: "pointer",
      color: WHITE,
      textTransform: "uppercase",
      textDecoration: "underline",
      fontFamily: "Roboto",
      fontWeight: "600",
      fontSize: 16,
    },
    selected: {
      background: theme.PRIMARY_COLOR || NEUTRAL_MEDIUM,
    },
    painting: {
      width: "100%",
      border: `3px solid ${theme.PRIMARY_COLOR}`,
      boxSizing: "border-box",
      background: WHITE,
      height: 600,
    },
    btn: {
      height: 35,
      padding: "0 15px",
    },
    containerAdd: {
      width: "100%",
      height: 35,
      background: theme.SECONDARY_COLOR,
      color: WHITE,
      padding: "0 0 0 10px",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      fontFamily: "Roboto",
      fontWeight: "600",
      fontSize: 12,
      textTransform: "uppercase",
      boxSizing: "border-box",
      marginBottom: 10,
    },
    containerAddButton: {
      border: "none",
      cursor: "pointer",
      background: theme.SECONDARY_COLOR,
      color: WHITE,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      width: 40,
    },
    select: {
      fontWeight: "600",
      fontSize: 20,
      color: theme.PRIMARY_COLOR,
      margin: 0,
      background: "none",
      border: "none",
      textDecorationLine: "underline",
      textTransform: "uppercase",
      display: "flex",
      gap: 8,
      alignItems: "center",
      cursor: "pointer",
      fontFamily: "Roboto",
      ":disabled": {
        cursor: "default",
        opacity: 0.6,
      },
    },
    selectContainer: {
      position: "absolute",
      right: 0,
      left: "auto",
      top: 30,
      background: WHITE,
      padding: "10px 0",
      borderRadius: 3,
      boxSizing: "border-box",
      border: `2px solid ${NEUTRAL_LIGHT}`,
      minWidth: 160,
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      maxHeight: "30dvh",
      overflow: "auto",
      zIndex: 100,
      "::-webkit-scrollbar": {
        width: 4,
      },
    },
    oneItemSelect: {
      color: theme.PRIMARY_COLOR,
      background: "none",
      border: "none",
      textAlign: "left",
      width: "100%",
      fontSize: 14,
      boxSizing: "border-box",
      padding: "5px 10px",
      cursor: "pointer",
      textTransform: "uppercase",
      ":hover": {
        background: NEUTRAL_LIGHT,
      },
    },
    flex: { display: "flex", gap: 5, alignItems: "center", position: "relative" },
  })
