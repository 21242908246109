import PropTypes from "prop-types"
import * as React from "react"

const Manual = ({ color }) => (
  <svg viewBox="0 0 20 20" height={20} width={20} xmlns="http://www.w3.org/2000/svg" fill="none">
    <g transform="translate(2, 2)">
      <path
        fill={color}
        stroke={color}
        transform="matrix(0.689655,0,0,0.689655,0,0)"
        d="M15.179 8.965a2.359 2.359 0 0 1-1.218.671c-.459.095-.935.047-1.37-.137l-.347-.148-.237.294-3.154 3.91c.207-.43.138-.962-.216-1.321a1.119 1.119 0 0 0-1.324-.223l3.841-3.284.273-.233-.134-.333a2.526 2.526 0 0 1 .523-2.695 2.311 2.311 0 0 1 1.617-.71L12.26 5.963l-.339.348.339.349 1.79 1.84.358.37.359-.37 1.104-1.14c-.03.586-.26 1.16-.692 1.605Z"
      />
      <path
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        transform="matrix(0.689655,0,0,0.689655,0,0)"
        d="M19.307 1.5H3.288A2.287 2.287 0 0 0 1 3.791v11.456a2.293 2.293 0 0 0 2.288 2.29h3.63c.607.001 1.189.242 1.618.672l1.953 1.956a1.143 1.143 0 0 0 1.618 0l1.953-1.956a2.287 2.287 0 0 1 1.618-.671h3.629a2.287 2.287 0 0 0 2.288-2.291V3.79A2.293 2.293 0 0 0 19.307 1.5v0Z"
      />
    </g>
  </svg>
)

Manual.propTypes = {
  color: PropTypes.string,
}

export default Manual
