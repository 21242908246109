import PropTypes from "prop-types"
import * as React from "react"

const Edit = ({ color = " #000" }) => (
  <svg width={14} height={14} fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10.207 1.767A1.65 1.65 0 0 1 12.541 4.1l-7.875 7.875-3.208.875.875-3.208 7.874-7.875Z"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

Edit.propTypes = {
  color: PropTypes.string,
}

export default Edit
