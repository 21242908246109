import * as React from "react"

const Error = props => (
  <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} fill="none" {...props}>
    <path
      fill="#FA541C"
      d="M10 0C4.486 0 0 4.486 0 10s4.486 10 10 10 10-4.486 10-10S15.514 0 10 0Zm3.62 12.533a.77.77 0 1 1-1.087 1.088L10 11.088l-2.533 2.533a.77.77 0 0 1-1.088-1.088L8.912 10 6.379 7.467a.77.77 0 0 1 1.088-1.088L10 8.912l2.533-2.533a.77.77 0 0 1 1.088 1.088L11.088 10l2.533 2.533Z"
    />
  </svg>
)

export default Error
