import PropTypes from "prop-types"
import React, { useContext, useState } from "react"
import { ThemeContext } from "../../context"

const theme = process.env.REACT_APP_THEME
const currentTheme = await import(`../../styles/${theme}.json`)

export const useTheme = () => {
  return useContext(ThemeContext)
}

export const ThemeProvider = ({ children }) => {
  const themeStyles = currentTheme
  const [overlay, setOverlay] = useState(false)

  return (
    <ThemeContext.Provider value={{ themeStyles, overlay, setOverlay }}>{children}</ThemeContext.Provider>
  )
}

ThemeProvider.propTypes = {
  children: PropTypes.node,
  currentTheme: PropTypes.string,
  overlay: PropTypes.bool,
}
