import { Pagination } from "@mui/material"
import { css, StyleSheet } from "aphrodite"
import React, { useCallback, useMemo, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate, useOutletContext, useSearchParams } from "react-router-dom"
import { useEvent } from "react-use"
import { ChevronDynamique } from "../../assets/svgr"
import { useTheme } from "../../components/Provider/ThemeProvider"
import { UIButton } from "../../components/UI"
import { LogoutHeader } from "../../components/Widget/LogoutHeader"
import { useLibraryPage } from "../../hooks/useLibraryPage"
import { NEUTRAL_LIGHT, WHITE } from "../../styles/colors"
import { WidgetCard } from "./WidgetCard"
import { WidgetDetailsItemModal } from "./WidgetDetailsItemModal"
import { WidgetSmallCard } from "./WidgetSmallCard"
import { WidgetUploadModal } from "./WidgetUploadModal"

export const LibraryPage = () => {
  // states --------------------------------------------------------------------
  const [openSelect, setOpenSelect] = useState(false)

  // others hooks --------------------------------------------------------------
  const { t } = useTranslation("libraryPage")
  const { themeStyles } = useTheme()
  const navigate = useNavigate()
  const [params] = useSearchParams()
  const choiceDivMenu = useRef()
  const { modalHidden } = useOutletContext()

  const {
    assetTypes,
    first,
    changeCurrentPage,
    setItemSelected,
    assets,
    handleModalNewUpload,
    totalCount,
    itemSelected,
    showEditData,
    saveNewItem,
    saveUpdateItem,
    loading,
  } = useLibraryPage()

  // init style ----------------------------------------------------------------
  const styles = useMemo(() => getStyles({ theme: themeStyles }), [themeStyles])

  // func ----------------------------------------------------------------------

  const handleClickOutside = useCallback(event => {
    if (choiceDivMenu.current && !choiceDivMenu.current.contains(event.target)) {
      setOpenSelect(false)
    }
  }, [])

  useEvent("mousedown", handleClickOutside)

  const toggleOpenSelect = useCallback(() => {
    setOpenSelect(prev => !prev)
  }, [])

  return (
    <section className={css(styles.container)}>
      <header className={css(styles.header)}>
        <div className={css(styles.titleContainer)}>
          <div ref={choiceDivMenu} className={css(styles.flex)}>
            <h1 className={css(styles.title)}>{t("title")}</h1>
            {params?.get("type") && (
              <>
                <h1 className={css(styles.title)}>-</h1>
                <div style={{ position: "relative" }}>
                  <button
                    onClick={toggleOpenSelect}
                    type="button"
                    aria-label="select type"
                    className={css(styles.select)}
                    disabled={
                      sessionStorage.getItem("redirectModelFromLibraryUrl") ||
                      sessionStorage.getItem("redirectStepFromLibraryUrl")
                    }>
                    {t(`selectType.${params.get("type")}`)}
                    <ChevronDynamique state={openSelect ? "up" : "down"} color={themeStyles?.PRIMARY_COLOR} />
                  </button>
                  {openSelect && !modalHidden && (
                    <div className={css(styles.selectContainer)}>
                      {assetTypes?.map(tab => (
                        <React.Fragment key={tab?.label}>
                          {tab?.label !== "link" && tab?.label !== params.get("type") && (
                            <button
                              aria-label="select type"
                              className={css(styles.oneItemSelect, styles.smallText)}
                              type="button"
                              style={{ paddingLeft: 10 }}
                              onClick={() => {
                                navigate(`/library?type=${t(tab.label)}&page=1`)
                                setOpenSelect(false)
                              }}>
                              {t(`selectType.${tab.label}`)}
                            </button>
                          )}
                        </React.Fragment>
                      ))}
                    </div>
                  )}
                </div>
              </>
            )}
          </div>
          {params?.get("type") && (
            <UIButton
              dynamicStyle={[styles.addBtn]}
              kind="primary"
              label={t("upload_file")}
              themeStyle={themeStyles}
              onClick={() => handleModalNewUpload()}
            />
          )}
        </div>

        <LogoutHeader themeStyles={themeStyles} />
      </header>
      {params.get("type") ? (
        <div className={css(styles.grid)}>
          {assets[0] &&
            assets?.map((asset, index) => (
              <WidgetSmallCard
                key={index}
                themeStyles={themeStyles}
                img={asset.thumbnailSrc ? asset.thumbnailSrc : asset.fileSrc}
                urlTrad={asset.urlTrad}
                id={params.get("filter") === "pois" ? asset?.poiByIdPoi?.id : asset.id}
                filename={asset.filename}
                onClick={title => setItemSelected({ ...asset, title, action: "details" })}
                isSelectable={!!params.get("filter")}
              />
            ))}
        </div>
      ) : (
        <div className={css(styles.containerChoices)}>
          {assetTypes?.map((type, index) => (
            <React.Fragment key={index}>
              {type.label !== "link" && <WidgetCard themeStyles={themeStyles} type={type} />}
            </React.Fragment>
          ))}
        </div>
      )}
      <footer className={css(styles.paginationContainer)}>
        {params.get("type") && totalCount > 0 && assets?.length > 0 && (
          <Pagination
            size="small"
            page={Number(params.get("page"))}
            count={Math.ceil(totalCount / first)}
            onChange={(e, value) => changeCurrentPage(value)}
            color="primary"
            shape="rounded"
          />
        )}
      </footer>

      {(itemSelected?.action === "edit" || itemSelected?.action === "new") && (
        <WidgetUploadModal
          theme={themeStyles}
          closeModal={() => {
            setItemSelected(null)
            sessionStorage.removeItem("newItem")
          }}
          saveNewItem={saveNewItem}
          loading={loading}
          itemSelected={itemSelected}
          saveUpdateItem={saveUpdateItem}
        />
      )}

      {itemSelected && itemSelected?.action === "details" && (
        <WidgetDetailsItemModal
          data={itemSelected}
          theme={themeStyles}
          closeModal={() => setItemSelected(null)}
          showEditData={showEditData}
        />
      )}
    </section>
  )
}

const getStyles = ({ theme }) =>
  StyleSheet.create({
    container: {
      background: theme.BACKGROUND_COLOR,
      width: "100%",
      padding: "30px",
      fontFamily: "Roboto",
      height: "100dvh",
      boxSizing: "border-box",
      overflow: "auto",
      display: "flex",
      flexDirection: "column",
      "::-webkit-scrollbar": {
        width: 4,
      },
    },
    title: {
      fontWeight: "600",
      fontSize: 20,
      color: theme.PRIMARY_COLOR,
      margin: 0,
      "@media (max-width: 1125px)": {
        fontSize: 16,
      },
    },
    grid: {
      display: "grid",
      gridAutoRows: "minmax(200px, auto)",
      gridTemplateColumns: "repeat(auto-fill, minmax(200px, 1fr))",
      gap: 15,
      boxSizing: "border-box",
    },
    titleContainer: {
      display: "flex",
      gap: 20,
      justifyContent: "space-between",
      alignItems: "center",
      "@media (max-width: 1100px)": {
        gap: 5,
      },
    },
    header: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      marginBottom: 40,
      gap: 30,
    },
    addBtn: {
      fontSize: 13,
      minHeight: 35,
      padding: "0 20px",
      fontFamily: "Roboto",
      "@media (max-width: 1100px)": {
        padding: "0 5px",
      },
    },
    containerChoices: {
      maxHeight: "80dvh",
      flexDirection: "row",
      display: "flex",
      flexWrap: "wrap",
      overflowY: "auto",
      gap: 15,
      flex: 1,
      marginTop: 10,
      boxSizing: "border-box",
    },
    flex: { display: "flex", gap: 5, alignItems: "center", position: "relative" },
    select: {
      fontWeight: "600",
      fontSize: 20,
      color: theme.PRIMARY_COLOR,
      margin: 0,
      background: "none",
      border: "none",
      textDecorationLine: "underline",
      textTransform: "uppercase",
      display: "flex",
      gap: 10,
      alignItems: "center",
      cursor: "pointer",
      "@media (max-width: 1125px)": {
        fontSize: 16,
      },
      ":disabled": {
        opacity: "0.6",
        cursor: "default",
      },
    },
    selectContainer: {
      position: "absolute",
      left: 0,
      right: "auto",
      top: 35,
      background: WHITE,
      padding: "10px 0px",
      borderRadius: 3,
      boxSizing: "border-box",
      border: `2px solid ${NEUTRAL_LIGHT}`,
      minWidth: "-webkit-fill-available",
      width: "max-content",
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      maxHeight: "30dvh",
      overflow: "auto",
      zIndex: 100,
      "::-webkit-scrollbar": {
        width: 0,
      },
      "@media (min-width: 1125px)": {
        top: 30,
      },
    },
    smallText: {
      fontWeight: "400",
      fontFamily: "Roboto",
      fontSize: 12,
      color: theme.PRIMARY_COLOR,
      margin: "8px 0 0",
    },
    oneItemSelect: {
      color: theme.PRIMARY_COLOR,
      background: "none",
      border: "none",
      textAlign: "left",
      width: "-webkit-fill-available",
      fontSize: 14,
      boxSizing: "border-box",
      padding: "5px 10px",
      cursor: "pointer",
      textTransform: "uppercase",
      ":hover": {
        background: NEUTRAL_LIGHT,
      },
    },
    paginationContainer: {
      display: "flex",
      justifyContent: "flex-end",
      paddingLeft: "10px",
      paddingTop: 15,
    },
    elementNumber: {
      fontSize: "12px",
      color: "rgba(0, 0, 0, 0.5)",
      margin: 0,
    },
  })
