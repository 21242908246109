import { css, StyleSheet } from "aphrodite"
import PropTypes from "prop-types"
import React, { useCallback, useEffect, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate, useSearchParams } from "react-router-dom"
import { CheveronDown, Eye, PoiType1, PoiType2, Stack, Trash } from "../../assets/svgr"
import { useTheme } from "../../components/Provider/ThemeProvider"
import { UIButtonIcon } from "../../components/UI"
import { UIInput } from "../../components/UI/UIInput"
import { checkEnv } from "../../lib"
import { BLACK_COLOR, NEUTRAL_DARK, WHITE } from "../../styles/colors"

export const WidgetPOIItem = ({
  index,
  deletePoi,
  poi,
  changeDataPoi,
  open,
  handleOpen,
  handleHighlight,
  dataChildStepSelected,
}) => {
  const [labelPoi, setLabelPoi] = useState(null)
  const { themeStyles } = useTheme()
  const { t } = useTranslation("model3DPage")
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const config = useMemo(() => checkEnv(), [])

  // init style ----------------------------------------------------------------
  const styles = getStyles({ theme: themeStyles })

  const fetchLabelsPoiTranslation = useCallback(async () => {
    if (poi && !poi.defaultPoi) {
      const response = await fetch(`${config.BASE_URL}/pois/${poi.idPoi}/translations`)
      if (response.ok) {
        const trads = await response.json()
        setLabelPoi(trads.label.en)
      }
    }
  }, [poi])

  const fetchPoi = () => {
    sessionStorage.setItem(
      "localDataCurrentModel",
      JSON.stringify({
        ...dataChildStepSelected,
        indexSelected: index,
      }),
    )
    const currentUrl = `/manuals/model3d?step=${searchParams.get(
      "step",
    )}&childstep=${searchParams.get("childstep")}&tab=${searchParams.get("tab")}`
    sessionStorage.setItem("redirectModelFromLibraryUrl", currentUrl)
    navigate("/library?type=all&filter=pois&page=1")
  }

  useEffect(() => {
    if (poi?.idPoi && !poi.defaultPoi) {
      fetchLabelsPoiTranslation()
    }
  }, [poi])

  return (
    <div className={css(styles.container)}>
      <div className={css(styles.header)}>
        <div className={css(styles.flex)}>
          <div>
            <h1 className={css(styles.title)}>{`POI ${index + 1}`}</h1>
          </div>
          <UIButtonIcon
            onClick={handleHighlight}
            dynamicStyle={[styles.btn]}
            icon={<Eye color={WHITE} />}
            hiddenBackgroundColor={true}
          />
        </div>
        <div className={css(styles.containerTrash)}>
          <UIButtonIcon
            onClick={deletePoi}
            dynamicStyle={[styles.btn]}
            icon={<Trash color={WHITE} />}
            hiddenBackgroundColor={true}
          />
          <UIButtonIcon
            onClick={handleOpen}
            dynamicStyle={[styles.btn, !open && styles.chevronUp]}
            icon={<CheveronDown color={WHITE} />}
            hiddenBackgroundColor={true}
          />
        </div>
      </div>
      {open && (
        <div className={css(styles.body)}>
          <p className={css(styles.font600)}>{t("edit.desc")}</p>
          <UIInput
            placeholder={t("edit.placeholder_write_here")}
            type="textarea"
            dynamicStyle={[styles.input]}
            value={poi?.description}
            onChange={e => changeDataPoi(index, "description", e.target.value)}
          />
          <p className={css(styles.font600)}>{t("edit.urlExternal")}</p>
          <UIInput
            placeholder={t("edit.placeholder_http")}
            type="text"
            dynamicStyle={[styles.input]}
            value={poi?.externalLink}
            onChange={e => changeDataPoi(index, "externalLink", e.target.value)}
          />
          <p className={css(styles.font600)}>{t("edit.labelUrlExternal")}</p>
          <UIInput
            placeholder={t("edit.placeholder_write_here")}
            type="text"
            dynamicStyle={[styles.input]}
            value={poi?.labelExternalLink}
            onChange={e => changeDataPoi(index, "labelExternalLink", e.target.value)}
          />
          <p className={css(styles.font600)}>{t("edit.media")}</p>
          <div style={{ gap: 10 }} className={css(styles.flex)}>
            <UIButtonIcon
              icon={<Stack color={"#FFF"} />}
              label={t("edit.import_media")}
              dynamicStyle={[styles.btnMedia]}
              onClick={() => fetchPoi()}
            />
            {labelPoi && <p className={css(styles.font400)}>{labelPoi}</p>}
          </div>

          <p className={css(styles.font600)}>{t("edit.position")}</p>
          <div className={css(styles.containerPos)}>
            <UIInput
              value={poi?.x}
              camera="X"
              type="number"
              dynamicStyle={[styles.input]}
              onChange={e => changeDataPoi(index, "x", e.target.value)}
              colorIcon={themeStyles?.PRIMARY_COLOR}
            />
            <UIInput
              value={poi?.y}
              camera="Y"
              type="number"
              dynamicStyle={[styles.input]}
              onChange={e => changeDataPoi(index, "y", e.target.value)}
              colorIcon={themeStyles?.PRIMARY_COLOR}
            />
            <UIInput
              value={poi?.z}
              onChange={e => changeDataPoi(index, "z", e.target.value)}
              colorIcon={themeStyles?.PRIMARY_COLOR}
              camera="Z"
              type="number"
              dynamicStyle={[styles.input]}
            />
          </div>
          <p className={css(styles.font600)}>{t("edit.type")}</p>
          <div className={css(styles.containerPOI)}>
            <UIButtonIcon
              dynamicStyle={[
                styles.poiItem,
                poi?.type?.toLowerCase() !== "warning" && styles.typeNotSelected,
              ]}
              onClick={() => changeDataPoi(index, "type", "warning")}
              icon={<PoiType1 />}
              hiddenBackgroundColor={true}
            />
            <UIButtonIcon
              onClick={() => changeDataPoi(index, "type", "info")}
              dynamicStyle={[styles.poiItem, poi?.type?.toLowerCase() !== "info" && styles.typeNotSelected]}
              icon={<PoiType2 />}
              hiddenBackgroundColor={true}
            />
          </div>
        </div>
      )}
    </div>
  )
}

const getStyles = ({ theme }) =>
  StyleSheet.create({
    container: {
      width: "100%",
      height: "auto",
      marginBottom: 15,
    },
    body: { background: WHITE, padding: "0 10px 5px", boxSizing: "border-box" },
    chevronUp: { transform: "rotate(180deg)" },
    btn: {
      border: "none",
      cursor: "pointer",
      background: theme.SECONDARY_COLOR,
      color: WHITE,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    containerPos: {
      display: "flex",
      gap: 10,
    },
    btnMedia: {
      background: theme.PRIMARY_COLOR,
      borderRadius: 4,
      fontSize: 12,
      display: "flex",
      gap: 5,
      color: WHITE,
      padding: "0 10px",
      width: "60%",
    },
    typeNotSelected: { background: "transparent", border: "none" },
    containerPOI: { display: "flex" },
    input: {
      borderRadius: 0,
      fontSize: 12,
      height: 35,
      fontWeight: "400",
      color: theme.PRIMARY_COLOR || BLACK_COLOR,
      "::placeholder": {
        color: theme.PRIMARY_COLOR || BLACK_COLOR,
        opacity: 0.7,
        fontStyle: "italic",
      },
    },
    containerTrash: {
      display: "flex",
      paddingRight: 5,
    },
    header: {
      border: "none",
      height: 35,
      background: theme.SECONDARY_COLOR,
      color: WHITE,
      display: "flex",
      alignItems: "center",
      padding: "0 0 0 10px",
      textTransform: "uppercase",
      fontSize: 12,
      justifyContent: "space-between",
    },
    poiItem: { padding: 0, margin: 0, border: `1px solid ${NEUTRAL_DARK}` },
    flex: {
      display: "flex",
      alignItems: "center",
    },
    title: {
      margin: 0,
      fontFamily: "Roboto",
      fontWeight: "600",
      fontSize: 14,
      minWidth: "60%",
    },
    item: {
      height: 25,
    },
    font600: {
      fontFamily: "Roboto",
      fontSize: 12,
      fontStyle: "normal",
      fontWeight: "600",
      lineHeight: "22px",
      padding: "5px 0 0",
      margin: 0,
      color: theme.PRIMARY_COLOR || BLACK_COLOR,
    },
    font400: {
      fontFamily: "Roboto",
      fontSize: 10,
      fontStyle: "italic",
      fontWeight: "400",
      lineHeight: "22px",
      padding: "5px 0 0",
      margin: 0,
      color: theme.PRIMARY_COLOR || BLACK_COLOR,
    },
  })

WidgetPOIItem.propTypes = {
  index: PropTypes.number,
  deletePoi: PropTypes.func,
  poi: PropTypes.object,
  changeDataPoi: PropTypes.func,
  open: PropTypes.bool,
  handleOpen: PropTypes.func,
  handleHighlight: PropTypes.func,
  dataChildStepSelected: PropTypes.object,
}
