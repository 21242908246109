import PropTypes from "prop-types"
import React, { useMemo } from "react"
import Select from "react-select"
import { NEUTRAL_DARK, NEUTRAL_LIGHT, NEUTRAL_MEDIUM, WHITE } from "../../styles/colors"

const dot = () => ({
  alignItems: "center",
  display: "flex",
})

const getStyles = ({ primaryColor, outlined, inputHeight }) => ({
  indicatorSeparator: styles => ({
    ...styles,
    display: "none",
  }),
  dropdownIndicator: (styles, { isFocused }) => ({
    ...styles,
    color: outlined && !isFocused ? WHITE : primaryColor,
  }),
  control: (styles, { isFocused }) => ({
    ...styles,
    backgroundColor: outlined ? (isFocused ? WHITE : "transparent") : WHITE,
    height: inputHeight,
    boxSizing: "border-box",
    color: !outlined || isFocused ? primaryColor : WHITE,
    cursor: "pointer",
    fontSize: outlined && !isFocused ? 15 : 13,
    border: outlined ? "transparent" : `1px solid ${NEUTRAL_MEDIUM}`,
  }),
  option: (styles, { isDisabled, isSelected, isFocused }) => ({
    ...styles,
    color: primaryColor,
    fontSize: 13,
    fontWeight: isSelected ? 700 : 400,
    backgroundColor: isFocused ? NEUTRAL_LIGHT : WHITE,
    cursor: isDisabled ? "not-allowed" : "pointer",
    fontFamily: "Roboto",
    ":active": {
      ...styles[":active"],
    },
  }),
  input: (styles, { isFocused }) => ({
    ...styles,
    backgroundColor: isFocused ? WHITE : "transparent",
    color: primaryColor,
    fontSize: outlined && !isFocused ? 15 : 13,
    fontFamily: "Roboto",
    ...dot(),
  }),
  placeholder: styles => ({
    ...styles,
    color: primaryColor,
    fontSize: 13,
    fontFamily: "Roboto",
    ...dot(),
  }),
  singleValue: styles => ({
    ...styles,
    fontSize: outlined ? 15 : 13,
    fontFamily: "Roboto",
    color: "inherite",
    ...dot(),
    ":hover": {
      border: "none",
    },
  }),
  menu: styles => ({
    ...styles,
    outline: `1.5px solid ${primaryColor}`,
    borderRadius: 5,
    overflow: "hidden",
  }),
  menuList: styles => ({
    ...styles,
    "::-webkit-scrollbar": {
      width: "3px",
    },
    "::-webkit-scrollbar-thumb": {
      background: NEUTRAL_DARK,
    },
  }),
  noOptionsMessage: base => ({
    ...base,
    fontSize: 13,
  }),
})

export const UISelect = ({
  options,
  onOptionChanged,
  placeholder = "",
  defaultValue,
  primaryColor,
  isDisabled = false,
  secondaryColor,
  value,
  outlined = false,
  menuPlacement = "auto",
  menuPortalTarget = null,
  inputHeight = 35,
  noOptionsMessage = () => "No options",
}) => {
  const colourStyles = useMemo(
    () => getStyles({ primaryColor, secondaryColor, outlined, inputHeight }),
    [primaryColor, secondaryColor, outlined, inputHeight],
  )

  return (
    <Select
      aria-label="select"
      placeholder={placeholder}
      styles={colourStyles}
      onChange={onOptionChanged}
      options={options}
      defaultValue={defaultValue}
      menuPlacement={menuPlacement}
      value={value}
      isDisabled={isDisabled}
      menuPortalTarget={menuPortalTarget}
      noOptionsMessage={noOptionsMessage}
    />
  )
}

UISelect.propTypes = {
  options: PropTypes.array,
  onOptionChanged: PropTypes.func,
  placeholder: PropTypes.string,
  primaryColor: PropTypes.string,
  secondaryColor: PropTypes.string,
  defaultValue: PropTypes.object,
  value: PropTypes.object,
  isDisabled: PropTypes.bool,
  outlined: PropTypes.bool,
  menuPortalTarget: PropTypes.object,
  noOptionsMessage: PropTypes.func,
  menuPlacement: PropTypes.string,
  inputHeight: PropTypes.number,
}
