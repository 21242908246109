import { css, StyleSheet } from "aphrodite"
import PropTypes from "prop-types"
import React, { useCallback, useEffect, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { Link, useNavigate, useSearchParams } from "react-router-dom"
import { CheveronDown, Eye } from "../../assets/svgr"
import CheckRoundGreen from "../../assets/svgr/CheckRoundGreen"
import WarningRoundYellow from "../../assets/svgr/WarningRoundYellow"
import { useTheme } from "../../components/Provider/ThemeProvider"
import { UIButtonIcon } from "../../components/UI"
import { WHITE } from "../../styles/colors"

export const CardStepItem = ({ step, title }) => {
  const { themeStyles } = useTheme()
  const [open, setOpen] = useState(false)
  const { t } = useTranslation("managerPage")
  const navigate = useNavigate()
  const [params] = useSearchParams()

  // init style ----------------------------------------------------------------
  const styles = useMemo(() => getStyles({ theme: themeStyles }), [themeStyles])

  const redirect = useCallback(() => {
    navigate(`/manuals/step/${step.id}?manual=${params.get("manual")}`)
  })

  useEffect(() => {
    if (step?.childStepsByIdManualStep?.nodes.filter(child => child.status !== "ok").length > 0) {
      setOpen(true)
    }
  }, [step])

  return (
    <div className={css(styles.containerStep)}>
      <div className={css(styles.containerbtn)}>
        <div className={css(styles.flex)}>
          <h1 className={css(styles.title)}>{title}</h1>
          <UIButtonIcon
            onClick={redirect}
            dynamicStyle={[styles.btn]}
            icon={<Eye color={WHITE} />}
            hiddenBackgroundColor={true}
          />
        </div>
        <button
          type="button"
          aria-label="open"
          onClick={() => setOpen(prev => !prev)}
          className={css(styles.btn, !open && styles.chevronUp)}>
          <CheveronDown color={WHITE} />
        </button>
      </div>
      {open && (
        <div className={css(styles.body)}>
          {step?.childStepsByIdManualStep?.nodes[0] &&
            step?.childStepsByIdManualStep?.nodes?.map((childStep, index) => (
              <div className={css(styles.item)} key={index}>
                <Link
                  to={`/manuals/step/${step.id}?manual=${params.get("manual")}&childstep=${childStep.id}`}
                  className={css(styles.link)}>
                  <p className={css(styles.linkText)}>{t("child_step", { number: childStep.pos })}</p>
                  {childStep?.status?.toLowerCase() === "ok" ? <CheckRoundGreen /> : <WarningRoundYellow />}
                </Link>
              </div>
            ))}
        </div>
      )}
    </div>
  )
}

CardStepItem.propTypes = {
  step: PropTypes.object,
  title: PropTypes.string,
}

const getStyles = ({ theme }) =>
  StyleSheet.create({
    containerStep: {
      width: 170,
      height: "auto",
      marginBottom: 20,
    },
    body: {
      background: WHITE,
      padding: "10px",
      boxSizing: "border-box",
      width: 170,
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
    },
    chevronUp: { transform: "rotate(180deg)" },
    btn: {
      border: "none",
      cursor: "pointer",
      background: theme.SECONDARY_COLOR,
      color: WHITE,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      width: 40,
    },
    containerbtn: {
      border: "none",
      height: 40,
      background: theme.SECONDARY_COLOR,
      color: WHITE,
      display: "flex",
      alignItems: "center",
      padding: "0 0 0 10px",
      textTransform: "uppercase",
      fontSize: 15,
      justifyContent: "space-between",
    },
    flex: {
      display: "flex",
      alignItems: "center",
    },
    title: {
      margin: 0,
      fontFamily: "Roboto",
      fontWeight: "600",
      fontSize: 15,
      minWidth: "60%",
    },
    item: {
      height: 30,
      display: "flex",
      alignItems: "center",
    },
    link: {
      color: theme.PRIMARY_COLOR,
      display: "flex",
      alignItems: "center",
      gap: 10,
      ":hover": {
        opacity: 0.7,
      },
    },
    linkText: {
      fontFamily: "Roboto",
      fontWeight: "600",
      fontSize: 14,
      margin: 0,
    },
  })
