import PropTypes from "prop-types"
import { Navigate, useLocation } from "react-router-dom"
import { useAuth } from "../../hooks"
import { isUserTokenExpired } from "../../lib"

export const ProtectedRoute = ({ children }) => {
  const { refreshTokenFunc } = useAuth()

  const location = useLocation()
  const token = localStorage.getItem("access_token")
  const refreshToken = localStorage.getItem("refresh_token")

  if (!token) {
    return <Navigate to="/signin" state={{ from: location }} />
  }
  if (token) {
    if (isUserTokenExpired(token)) {
      if (!isUserTokenExpired(refreshToken)) {
        refreshTokenFunc()
      } else {
        return <Navigate to="/signin" state={{ from: location }} />
      }
    }
  }

  return children
}

ProtectedRoute.propTypes = {
  children: PropTypes.node,
}
