import PropTypes from "prop-types"
import * as React from "react"

const Eye = ({ color }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={16} height={13} fill="none">
    <path fill={color} d="M7.83 8.63a2.182 2.182 0 1 0 0-4.364 2.182 2.182 0 0 0 0 4.364Z" />
    <path
      fill={color}
      fillRule="evenodd"
      d="M.065 6.046a8.186 8.186 0 0 1 15.53-.005c.087.263.087.547 0 .81a8.185 8.185 0 0 1-15.53.005 1.281 1.281 0 0 1 0-.81Zm11.583.402a3.818 3.818 0 1 1-7.636 0 3.818 3.818 0 0 1 7.636 0Z"
      clipRule="evenodd"
    />
  </svg>
)

Eye.propTypes = { color: PropTypes.string }

export default Eye
