import { yupResolver } from "@hookform/resolvers/yup"
import { StyleSheet, css } from "aphrodite"
import * as GLTFValidator from "gltf-validator"
import PropTypes from "prop-types"
import React, { useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from "react"
import { useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import Modal from "react-modal"
import ReactPlayer from "react-player"
import { Link, useOutletContext, useSearchParams } from "react-router-dom"
import * as yup from "yup"
import { Close } from "../../assets/svgr"

import {
  UIButton,
  UIButtonIcon,
  UICheckboxInput,
  UIImgUpload,
  UIInputWithRegister,
  UIModelUpload,
  UIVideoUpload,
} from "../../components/UI"
import { Model3DViewer } from "../../components/Widget/Model3DViewer"
import { useLibraryPage } from "../../hooks/useLibraryPage"
import { BLACK_COLOR, ERROR_COLOR, NEUTRAL_DARK, NEUTRAL_LIGHT, WHITE } from "../../styles/colors"

const staticStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "90dvw",
    height: 418,
    maxWidth: 1100,
    maxHeight: "85dvh",
    border: "none",
    padding: 0,
    boxSizing: "border-box",
    display: "flex",
    borderRadius: 0,
  },
  overlay: {
    background: "rgba(0, 22, 33, 0.70)",
  },
}

export const WidgetUploadModal = ({
  theme,
  closeModal,
  saveNewItem,
  saveUpdateItem,
  loading,
  itemSelected,
}) => {
  //* for type picture --
  const [newFile, setNewFile] = useState(null)
  const [file, setFile] = useState(null)

  //* for type video --
  const [newVideoFile, setNewVideoFile] = useState(null)
  const [thumbnail, setThumbnail] = useState(null)

  //* for type 3d -------
  const [newModelFile, setNewModelFile] = useState(null)

  //* state params ------
  const [disableSave, setDisabledSave] = useState(null)

  // hooks ---------------------------------------------------------------------
  const { t } = useTranslation("libraryPage")
  const [params] = useSearchParams()
  const type = params.get("type")?.toLowerCase()
  const { errorCreate, setErrorCreate } = useLibraryPage()
  const playerRef = useRef(null)
  const viewerRef = useRef(null)
  const { modalHidden } = useOutletContext()

  const schema = yup.object().shape({
    name: yup.string().required(),
    usage: yup.string(),
  })

  const {
    handleSubmit,
    register,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    reValidateMode: "onChange",
    mode: "onSubmit",
    resolver: yupResolver(schema),
  })

  // init style ----------------------------------------------------------------
  const styles = useMemo(
    () => getStyles({ theme, video: newVideoFile?.data || newVideoFile?.url, img: file?.url }),
    [theme, newVideoFile?.data, newVideoFile?.url, file?.url],
  )
  const customStyles = useMemo(() => ({ ...staticStyles, ...styles }), [styles])

  useLayoutEffect(() => {
    if (params.get("filter")) {
      setValue("usage", params.get("filter"))
    }
    return () => close()
  }, [])

  useLayoutEffect(() => {
    if (itemSelected?.action === "edit") {
      setValue("name", itemSelected.title)
      if (itemSelected?.usage) setValue("usage", itemSelected.usage)

      if (type === "picture") setFile({ url: itemSelected?.fileSrc })
      if (type === "video") {
        fetch(itemSelected?.fileSrc)
          .then(response => {
            return response.blob()
          })
          .then(blob => {
            const url = URL.createObjectURL(blob)
            setNewVideoFile({ url })
          })

        fetch(itemSelected?.thumbnailSrc)
          .then(response => {
            return response.blob()
          })
          .then(blob => {
            const url = URL.createObjectURL(blob)
            setThumbnail({ url, data: null })
          })
      } else if (type === "3d") {
        setValue("name", itemSelected?.filename)
        setNewModelFile(prev => ({
          ...prev,
          url: itemSelected?.fileSrc,
          filename: itemSelected?.filename,
        }))
        setThumbnail({ url: itemSelected?.thumbnailSrc, data: null })
      }
    }
  }, [itemSelected])

  useEffect(() => {
    setErrorCreate(false)
    if (!watch("name") || errors?.name) {
      setDisabledSave(true)
      return
    }

    switch (type) {
      case "video":
        if ((!newVideoFile?.data && !newVideoFile?.url) || !thumbnail) {
          setDisabledSave(true)
        } else {
          setDisabledSave(false)
        }
        break
      case "3d":
        if ((!newModelFile?.data && !newModelFile?.url) || (!thumbnail?.data && !thumbnail?.url)) {
          setDisabledSave(true)
        } else {
          setDisabledSave(false)
        }
        break
      case "picture":
        if ((!file?.data && !file?.url) || !watch("usage") || errors?.usage) {
          setDisabledSave(true)
        } else {
          setDisabledSave(false)
        }
        break
      default:
        break
    }
  }, [watch("usage"), watch("name"), errors, thumbnail, file, newVideoFile, newFile, newModelFile])

  const close = () => {
    setNewVideoFile(null)
    setNewFile(null)
    setNewModelFile(null)
    setThumbnail(null)
    setFile(null)
    setValue("name", "")
    setValue("usage", "")
    closeModal()
  }

  const handleImage = async imageList => {
    setNewFile({
      filename: null,
      data: null,
      loading: true,
      url: null,
      error: false,
      showError: false,
    })

    const file = imageList[0].file

    if (file?.type?.startsWith("image/")) {
      // test size
      const img = new Image()
      img.src = imageList[0].data_url

      img.onload = () => {
        if (img.width !== img.height && (img.height || img.width) < 150) {
          setNewFile(prev => ({ ...prev, error: "error_file_format", showError: true }))
        } else {
          setNewFile(prev => ({
            ...prev,
            data: file,
            url: imageList[0].data_url,
            error: false,
          }))
          setFile({ data: file, url: imageList[0].data_url })
        }
      }

      setNewFile(prev => ({ ...prev, loading: false }))
    } else {
      setNewFile(prev => ({
        ...prev,
        loading: false,
        error: "error_file_format",
        showError: true,
      }))
    }
  }

  const handleModel = async file => {
    setNewModelFile({
      filename: null,
      data: null,
      loading: true,
      error: false,
      showError: false,
    })

    const isModelValid = await validateModel(file)
    if (isModelValid) {
      const title = file.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
      setNewModelFile(prev => ({
        ...prev,
        data: file,
        error: false,
        url: URL.createObjectURL(file),
        filename: title,
        loading: false,
      }))
      setValue("name", title)
      setThumbnail(null)
    } else {
      setNewModelFile(prev => ({
        ...prev,
        loading: false,
        error: "error_invalid_model",
        showError: true,
      }))
      setThumbnail(null)
    }
  }

  const validateModel = async file => {
    if (!file) {
      return false
    }

    try {
      const modelArrayBuffer = await file.arrayBuffer()
      const binaryData = new Uint8Array(modelArrayBuffer)
      const validationResult = await GLTFValidator.validateBytes(binaryData)

      return validationResult.issues.numErrors === 0 && validationResult.mimeType === "model/gltf-binary"
    } catch (e) {
      return false
    }
  }

  const handleVideo = async file => {
    setNewVideoFile({
      filename: null,
      data: null,
      loading: true,
      url: null,
      error: false,
      showError: false,
    })

    if (file && file.type.startsWith("video/")) {
      const title = file.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
      setNewVideoFile(prev => ({ ...prev, filename: title }))

      setNewVideoFile(prev => ({
        ...prev,
        data: file,
        url: URL.createObjectURL(file),
        error: false,
        loading: false,
        filename: file.name,
      }))

      setValue("name", title)
      setThumbnail(null)
    } else {
      setNewVideoFile(prev => ({
        ...prev,
        loading: false,
        error: "error_video_format",
        showError: true,
      }))
      setThumbnail(null)
    }
  }

  const handleCapture = async () => {
    if (playerRef.current) {
      const canvas = document.createElement("canvas")
      canvas.width = playerRef.current.getInternalPlayer().videoWidth
      canvas.height = playerRef.current.getInternalPlayer().videoHeight
      const ctx = canvas.getContext("2d")
      ctx.drawImage(playerRef.current.getInternalPlayer(), 0, 0, canvas.width, canvas.height)

      canvas.toBlob(blob => {
        const url = URL.createObjectURL(blob)
        setThumbnail({ url, data: blob })
      })
    } else if (type === "3d" && viewerRef.current) {
      const data = await viewerRef.current.takeScreenshot()
      const url = URL.createObjectURL(data)
      setThumbnail({ url, data })
    }
  }
  const onSubmit = data => {
    const newData = {
      ...data,
      ...(newFile && { newFile }),
      ...(thumbnail && { thumbnail: thumbnail.data }),
      ...(newVideoFile && { newVideoFile }),
      ...(newModelFile && { newModelFile }),
    }

    if (itemSelected?.action === "edit") {
      saveUpdateItem(type, newData)
    } else {
      saveNewItem(type, newData)
    }
  }

  const onError = error => {
    if (error.acceptType || error.size) {
      switch (type) {
        case "picture":
          setNewFile(prev => ({ ...prev, error: "error_file_format", showError: true }))
          break
        case "video":
          setNewVideoFile(prev => ({ ...prev, error: "error_video_format", showError: true }))
          break
        default:
          break
      }
    }
  }

  const hiddenErrorContainer = () => {
    setNewFile(prev => ({ ...prev, showError: false }))
    setNewVideoFile(prev => ({ ...prev, showError: false }))
    setNewModelFile(prev => ({ ...prev, showError: false }))
  }

  const setAnimationCount = useCallback(
    count =>
      setNewModelFile(prev => {
        return { ...prev, animationCount: count }
      }),
    [setNewModelFile],
  )

  return (
    <Modal
      onRequestClose={close}
      appElement={document.getElementById("root")}
      isOpen={!modalHidden}
      style={customStyles}>
      <section id="scene-container" className={css(styles.leftContainer)}>
        {type === "picture" && (
          <>
            {file?.url ? (
              <div className={css(styles.containerImg)}>
                <img alt={`cover${file?.filename}`} className={css(styles.img)} src={file?.url} />
              </div>
            ) : (
              <UIImgUpload
                uploadBtnText={t("modal.file_add")}
                theme={theme}
                onChange={handleImage}
                image={newFile?.url}
                height={35}
                type="button"
                btnColor={theme?.PRIMARY_COLOR}
                removeImage={() => setNewFile(null)}
                disabled={false}
                labelDynamicStyle={styles.font600}
                width={130}
                multiple={false}
                onError={onError}
              />
            )}
            {newFile?.error && newFile?.showError && (
              <div className={css(styles.errorContainer)}>
                <div className={css(styles.errorContainerClose)}>
                  <UIButtonIcon
                    onClick={hiddenErrorContainer}
                    hiddenBackgroundColor={true}
                    icon={<Close color={WHITE} />}
                  />
                </div>
                <p className={css(styles.errorBulletText)}>{t(`modal.errors.${newFile.error}`)}</p>
              </div>
            )}
          </>
        )}
        {type === "video" && (
          <>
            {newVideoFile?.url ? (
              <ReactPlayer ref={playerRef} controls={true} url={newVideoFile?.url} />
            ) : (
              <UIVideoUpload
                uploadBtnText={t("modal.file_add")}
                theme={theme}
                onChange={handleVideo}
                height={35}
                type="button"
                btnColor={theme?.PRIMARY_COLOR}
                disabled={false}
                labelDynamicStyle={styles.font600}
                width={130}
                onError={onError}
              />
            )}
            {newVideoFile?.error && newVideoFile?.showError && (
              <div className={css(styles.errorContainer)}>
                <div className={css(styles.errorContainerClose)}>
                  <UIButtonIcon
                    onClick={hiddenErrorContainer}
                    hiddenBackgroundColor={true}
                    icon={<Close color={WHITE} />}
                  />
                </div>
                <p className={css(styles.errorBulletText)}>{t(`modal.errors.${newVideoFile.error}`)}</p>
              </div>
            )}
          </>
        )}
        {type === "3d" && (
          <>
            {!newModelFile?.url ? (
              <UIModelUpload
                uploadBtnText={t("modal.file_add")}
                theme={theme}
                onChange={handleModel}
                image={newModelFile?.url}
                height={35}
                type="button"
                btnColor={theme?.PRIMARY_COLOR}
                removeImage={() => setNewModelFile(null)}
                disabled={false}
                labelDynamicStyle={styles.font600}
                width={130}
                multiple={false}
              />
            ) : (
              <Model3DViewer
                ref={viewerRef}
                modelURL={newModelFile.url}
                wireframe={true}
                setAnimationCount={setAnimationCount}
              />
            )}
            {newModelFile?.error && newModelFile?.showError && (
              <div className={css(styles.errorContainer)}>
                <div className={css(styles.errorContainerClose)}>
                  <UIButtonIcon
                    onClick={hiddenErrorContainer}
                    hiddenBackgroundColor={true}
                    icon={<Close color={WHITE} />}
                  />
                </div>
                {newModelFile.error === "error_invalid_model" && (
                  <p className={css(styles.errorBulletText)}>
                    {t(`modal.errors.${newModelFile.error}1`)}
                    <span>
                      <Link
                        className={css(styles.errorBulletSpan)}
                        to="https://github.khronos.org/glTF-Validator/">
                        https://github.khronos.org/glTF-Validator/
                      </Link>
                    </span>
                    {t(`modal.errors.${newModelFile.error}2`)}
                  </p>
                )}
              </div>
            )}
          </>
        )}
      </section>
      <section className={css(styles.rightContainer)}>
        <header>
          <UIButtonIcon
            onClick={close}
            hiddenBackgroundColor={true}
            dynamicStyle={[styles.closeBtn]}
            icon={<Close color={theme?.PRIMARY_COLOR || BLACK_COLOR} />}
          />
        </header>
        <main>
          <h1 className={css(styles.title)}>
            {itemSelected?.action === "edit" ? itemSelected.title : t(`modal.${type}_upload`)}
          </h1>
          <p style={{ marginBottom: 40 }} className={css(styles.font400)}>
            {type === "video"
              ? t("modal.desc_video_upload")
              : type === "picture"
                ? t("modal.desc_picture_upload")
                : t("modal.desc_model_upload")}
          </p>
          <form onSubmit={handleSubmit(onSubmit)} className={css(styles.form)}>
            <div className={css(styles.dataContainer)}>
              <div style={{ width: "40%" }}>
                <div>
                  <UIInputWithRegister
                    theme={theme}
                    type="text"
                    maxLength={35}
                    labelDynamicStyle={[styles.font600]}
                    register={register}
                    setValue={setValue}
                    dynamicStyle={[styles.input]}
                    keyInput="name"
                    isError={errorCreate === "duplicate" || errors?.name}
                    label={t("modal.file_name")}
                  />
                  {errorCreate === "duplicate" && (
                    <p className={css(styles.errorText)}>{t(`modal.errors.duplicate`)}</p>
                  )}
                </div>
                <div style={{ marginTop: 25 }}>
                  {type === "video" ? (
                    <UIVideoUpload
                      uploadLabel={t("modal.file_upload")}
                      uploadBtnText={newVideoFile?.url ? t("modal.file_edit") : t("modal.file_add")}
                      theme={theme}
                      onChange={handleVideo}
                      height={35}
                      type="button"
                      btnColor={theme?.PRIMARY_COLOR}
                      disabled={false}
                      labelDynamicStyle={styles.font600}
                      width={130}
                      onError={onError}
                    />
                  ) : type === "3d" ? (
                    <UIModelUpload
                      uploadBtnText={newModelFile?.url ? t("modal.file_edit") : t("modal.file_add")}
                      uploadLabel={t("modal.file_upload")}
                      theme={theme}
                      onChange={handleModel}
                      image={newModelFile?.url}
                      height={35}
                      type="button"
                      btnColor={theme?.PRIMARY_COLOR}
                      removeImage={() => setNewModelFile(null)}
                      disabled={false}
                      labelDynamicStyle={styles.font600}
                      width={130}
                      multiple={false}
                    />
                  ) : (
                    <UIImgUpload
                      uploadLabel={t("modal.file_upload")}
                      uploadBtnText={file?.url ? t("modal.file_edit") : t("modal.file_add")}
                      theme={theme}
                      onChange={handleImage}
                      image={newFile?.url}
                      height={35}
                      type="button"
                      btnColor={theme?.PRIMARY_COLOR}
                      removeImage={() => setNewFile(null)}
                      disabled={false}
                      labelDynamicStyle={styles.font600}
                      width={130}
                      multiple={false}
                      onError={onError}
                    />
                  )}
                </div>
              </div>
              <div style={{ width: "40%" }}>
                {type !== "video" && type !== "3d" && (
                  <>
                    <p className={css(styles.font600)}>{t("modal.usage")}</p>
                    <div className={css(styles.containerCheckbox)}>
                      <UICheckboxInput
                        borderColor={theme?.PRIMARY_COLOR || BLACK_COLOR}
                        labelDynamicStyle={styles.font400}
                        label={t("modal.parts")}
                        type="radio"
                        name="type"
                        value="parts"
                        disabled={itemSelected?.action === "edit"}
                        checked={watch("usage") === "parts"}
                        onChange={() => setValue("usage", "parts")}
                      />
                      <UICheckboxInput
                        borderColor={theme?.PRIMARY_COLOR || BLACK_COLOR}
                        labelDynamicStyle={styles.font400}
                        label={t("modal.pois")}
                        type="radio"
                        name="type"
                        value="pois"
                        disabled={itemSelected?.action === "edit"}
                        checked={watch("usage") === "pois"}
                        onChange={() => setValue("usage", "pois")}
                      />
                    </div>
                    <UICheckboxInput
                      borderColor={theme?.PRIMARY_COLOR || BLACK_COLOR}
                      labelDynamicStyle={styles.font400}
                      label={t("modal.tools")}
                      type="radio"
                      name="type"
                      value="tools"
                      disabled={itemSelected?.action === "edit"}
                      checked={watch("usage") === "tools"}
                      onChange={() => setValue("usage", "tools")}
                    />
                  </>
                )}
                {(type === "video" || type === "3d") && (
                  <div>
                    <p className={css(styles.font600)}>{t("modal.thumbnail")}</p>
                    {thumbnail?.url && (
                      <div className={css(styles.thumbnail)}>
                        <img alt="thumbnail" width={"100%"} height={"100%"} src={thumbnail.url} />
                      </div>
                    )}

                    <UIButton
                      label={
                        type === "3d"
                          ? thumbnail
                            ? t("modal.change_3d_thumbnail")
                            : t("modal.save_3d_thumbnail")
                          : type === "video"
                            ? thumbnail
                              ? t("modal.change_video_thumbnail")
                              : t("modal.save_video_thumbnail")
                            : ""
                      }
                      themeStyle={theme}
                      dynamicStyle={[styles.thumbnailBtn]}
                      onClick={handleCapture}
                      kind="primary"
                      type="button"
                      disabled={
                        type === "3d"
                          ? !newModelFile || newModelFile?.error
                          : !newVideoFile || newVideoFile?.error
                      }
                    />
                  </div>
                )}
              </div>
            </div>

            <footer className={css(styles.footer)}>
              <UIButton
                themeStyle={theme}
                dynamicStyle={styles.btn}
                type="submit"
                label={t("modal.published")}
                kind="primary"
                disabled={disableSave}
                loading={loading}
              />
              {itemSelected?.action !== "edit" && (
                <UIButton
                  themeStyle={theme}
                  dynamicStyle={styles.btn}
                  type="button"
                  label={t("modal.cancel")}
                  kind="secondary"
                  onClick={close}
                />
              )}
            </footer>
          </form>
        </main>
      </section>
    </Modal>
  )
}

const getStyles = ({ theme, video, img }) =>
  StyleSheet.create({
    rightContainer: {
      width: "43%",
      height: "100%",
      padding: "40px",
      background: NEUTRAL_LIGHT,
      boxSizing: "border-box",
    },
    leftContainer: {
      width: "57%",
      background: video ? BLACK_COLOR : img ? WHITE : NEUTRAL_DARK,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      overflow: "hidden",
      position: "relative",
    },
    thumbnail: {
      minWidth: "-webkit-fill-available",
      height: "auto",
      width: "100%",
      maxWidth: 202,
      boxSizing: "border-box",
      border: "3px solid var(--secondary, #7EBEE0)",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: WHITE,
      marginBottom: 8,
    },
    input: {
      color: theme?.PRIMARY_COLOR || BLACK_COLOR,
      borderRadius: 4,
      height: 35,
      fontSize: 12,
      marginTop: 8,
    },
    containerImg: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "100%",
      width: "100%",
      boxSizing: "border-box",
    },
    img: {
      height: "auto",
      width: "auto",
      display: "block",
      objectFit: "contain",
      flexGrow: 1,
      maxHeight: "85%",
      maxWidth: "85%",
    },
    errorText: {
      color: theme?.ERROR_COLOR || ERROR_COLOR,
      fontSize: 10,
      margin: "3px 0 0",
    },
    closeBtn: {
      position: "absolute",
      top: 15,
      right: 10,
      background: "transparent",
    },
    title: {
      fontFamily: "Roboto",
      fontWeight: "600",
      fontSize: 24,
      textTransform: "uppercase",
      color: theme.PRIMARY_COLOR || BLACK_COLOR,
      margin: "0 0 15px",
    },
    font400: {
      fontFamily: "Roboto",
      fontWeight: "400",
      fontSize: 12,
      color: theme.PRIMARY_COLOR || BLACK_COLOR,
      lineHeight: "20px",
      margin: 0,
    },
    font600: {
      fontFamily: "Roboto",
      fontWeight: "600",
      fontSize: 12,
      margin: 0,
      paddingTop: 3,
      color: theme.PRIMARY_COLOR || BLACK_COLOR,
      paddingBottom: 8,
    },
    form: {
      marginTop: 15,
    },
    dataContainer: {
      display: "flex",
      alignItems: "flex-start",
      gap: 50,
    },
    footer: {
      marginTop: 30,
      display: "flex",
      alignItems: "center",
      gap: 10,
    },
    btn: {
      height: 35,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      fontSize: 12,
      padding: "0 15px",
      fontFamily: "Roboto",
      minWidth: 70,
      fontWeight: "400",
    },
    thumbnailBtn: {
      height: 35,
      display: "flex",
      width: "100%",
      justifyContent: "center",
      alignItems: "center",
      fontSize: 12,
      padding: "0 15px",
      fontFamily: "Roboto",
      fontWeight: "400",
      margin: 0,
    },
    errorContainer: {
      position: "absolute",
      bottom: 10,
      width: "70%",
      maxWidth: 400,
      margin: "0 auto",
      backgroundColor: theme?.ERROR_COLOR || ERROR_COLOR,
      color: WHITE,
      padding: "8px 30px",
      borderRadius: "15px",
      fontSize: 12,
      display: "flex",
      textAlign: "center",
      fontFamily: "Roboto",
      fontWeight: "400",
      lineHeight: "20px",
    },
    errorContainerClose: {
      position: "absolute",
      top: 8,
      right: 5,
      cursor: "pointer",
    },
    errorBulletText: {
      margin: 0,
      padding: 0,
    },
    errorBulletSpan: {
      color: theme?.SECONDARY_COLOR || BLACK_COLOR,
      textDecoration: "underline",
      cursor: "pointer",
    },
    containerCheckbox: {
      marginTop: 0,
      display: "flex",
      gap: 15,
      flexWrap: "wrap",
      alignItems: "center",
      marginBottom: 10,
    },
  })

WidgetUploadModal.propTypes = {
  closeModal: PropTypes.func,
  theme: PropTypes.object,
  saveNewItem: PropTypes.func,
  loading: PropTypes.bool,
  itemSelected: PropTypes.object,
  saveUpdateItem: PropTypes.func,
}
