import PropTypes from "prop-types"
import * as React from "react"

const Logout = ({ color }) => (
  <svg viewBox="0 0 20 20" height={20} width={20} xmlns="http://www.w3.org/2000/svg" fill="none">
    <path
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2.5}
      transform="matrix(0.689655,0,0,0.689655,0,0)"
      d="M 10.33125 27.001719 L 4.775938 27.001719 C 4.041875 27.001719 3.335 26.707188 2.813906 26.186094 C 2.292813 25.665 1.998281 24.958125 1.998281 24.224063 L 1.998281 4.775938 C 1.998281 4.041875 2.292813 3.335 2.813906 2.813906 C 3.335 2.292813 4.041875 1.998281 4.775938 1.998281 L 10.33125 1.998281 M 20.055313 21.446406 L 27.001719 14.5 M 27.001719 14.5 L 20.055313 7.553594 M 27.001719 14.5 L 10.33125 14.5 "
    />
  </svg>
)

Logout.propTypes = {
  color: PropTypes.string,
}

export default Logout
