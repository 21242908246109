import { css, StyleSheet } from "aphrodite"
import PropTypes from "prop-types"
import { useEffect, useRef } from "react"
import { use3DAnimation } from "../../hooks/use3DAnimation"
import { use3DCameraParameters } from "../../hooks/use3DCameraParameters"
import { use3DPoi } from "../../hooks/use3DPoi"
import { use3DViewer } from "../../hooks/use3DViewer"

export const ManualPreview3DViewer = ({
  modelURL,
  wireframe = true,
  animationName,
  animationPlayMode = "loop",
  animationRepetitions = Infinity,
  orbitData,
  poisData = [],
  onPoiClick,
}) => {
  const styles = getStyles()
  const canvasRef = useRef(null)
  const { viewerRef, modelRoot } = use3DViewer(canvasRef, modelURL, wireframe, false)
  use3DAnimation(viewerRef, modelRoot, animationName, animationPlayMode, animationRepetitions)
  use3DPoi(viewerRef, poisData, false, undefined, undefined, onPoiClick)
  use3DCameraParameters(
    viewerRef,
    orbitData.camera.x,
    orbitData.camera.y,
    orbitData.camera.z,
    orbitData.camera.minZoomDb,
    orbitData.camera.maxZoomDb,
    orbitData.target.x,
    orbitData.target.y,
    orbitData.target.z,
  )

  useEffect(setupPreviewCamera, [viewerRef])

  return (
    <div className={css(styles.viewerContainer)}>
      <canvas ref={canvasRef} className={css(styles.viewerCanvas)}></canvas>
    </div>
  )

  function setupPreviewCamera() {
    viewerRef.current.cameraController.panEnabled = false
  }
}

const getStyles = () =>
  StyleSheet.create({
    viewerContainer: {
      width: "100%",
      height: "100%",
    },
    viewerCanvas: {
      width: "100%",
      height: "100%",
    },
  })

ManualPreview3DViewer.propTypes = {
  modelURL: PropTypes.string,
  wireframe: PropTypes.bool,
  animationName: PropTypes.string,
  animationPlayMode: PropTypes.string,
  animationRepetitions: PropTypes.number,
  orbitData: PropTypes.object,
  poisData: PropTypes.array,
  onPoiClick: PropTypes.func,
}
