import * as React from "react"

const ResizeDevice = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width={305} height={89} fill="none">
    <path
      stroke="#007DBC"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={8}
      d="M280.709 84.875h-50.417M255.5 84.875V64.708M255.5 64.708h-45.375v-60.5h90.75v60.5H255.5Z"
    />
    <path
      fill="#007DBC"
      d="M178.667 80.375c4.766 0 8.623-3.881 8.623-8.625l.043-43.125c0-4.744-3.9-8.625-8.666-8.625h-69.334c-4.766 0-8.666 3.881-8.666 8.625V71.75c0 4.744 3.9 8.625 8.666 8.625H92V89h104v-8.625h-17.333Zm-69.334-51.75h69.334V71.75h-69.334V28.625ZM0 40.818v43.364C0 86.832 2.194 89 4.875 89h68.25C75.806 89 78 86.832 78 84.182V40.818C78 38.168 75.806 36 73.125 36H4.875C2.194 36 0 38.168 0 40.818ZM74.48 62.5a3.707 3.707 0 0 1-.248 1.475c-.183.47-.459.9-.813 1.262-.353.363-.777.65-1.246.848a3.83 3.83 0 0 1-4.213-.848 3.71 3.71 0 0 1 .096-5.3 3.816 3.816 0 0 1 2.634-1.051c.982 0 1.926.377 2.633 1.05A3.729 3.729 0 0 1 74.48 62.5ZM63.375 40.818v43.364H9.75V40.818h53.625Z"
    />
  </svg>
)
export default ResizeDevice
