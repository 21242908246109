import { css, StyleSheet } from "aphrodite"
import PropTypes from "prop-types"
import React from "react"
import { useTranslation } from "react-i18next"
import { CheveronDown, Eye } from "../../assets/svgr"
import { useTheme } from "../../components/Provider/ThemeProvider"
import { UIButtonIcon, UIInput } from "../../components/UI"
import { BLACK_COLOR, WHITE } from "../../styles/colors"

export const WidgetTargetItem = ({ open, handleOpen, dataTarget, changeDataTarget, handleHighlight }) => {
  const { themeStyles } = useTheme()
  const { t } = useTranslation("model3DPage")

  // init style ----------------------------------------------------------------
  const styles = getStyles({ theme: themeStyles })

  return (
    <div className={css(styles.container)}>
      <div className={css(styles.header)}>
        <div className={css(styles.flex)}>
          <h1 className={css(styles.title)}>{t("target")}</h1>
          <UIButtonIcon hiddenBackgroundColor={true} onClick={handleHighlight} icon={<Eye color={WHITE} />} />
        </div>
        <button
          type="button"
          aria-label="open"
          onClick={handleOpen}
          className={css(styles.btn, !open && styles.chevronUp)}>
          <CheveronDown color={WHITE} />
        </button>
      </div>
      {open && (
        <div className={css(styles.body)}>
          <p className={css(styles.font600)}>{t("edit.position")}</p>
          <div className={css(styles.containerPos)}>
            <UIInput
              colorIcon={themeStyles?.PRIMARY_COLOR}
              camera="X"
              type="number"
              dynamicStyle={[styles.input]}
              value={dataTarget?.x}
              onChange={e => changeDataTarget("x", Number(e.target.value))}
            />
            <UIInput
              colorIcon={themeStyles?.PRIMARY_COLOR}
              camera="Y"
              type="number"
              dynamicStyle={[styles.input]}
              value={dataTarget?.y}
              onChange={e => changeDataTarget("y", Number(e.target.value))}
            />
            <UIInput
              colorIcon={themeStyles?.PRIMARY_COLOR}
              camera="Z"
              type="number"
              dynamicStyle={[styles.input]}
              value={dataTarget?.z}
              onChange={e => changeDataTarget("z", Number(e.target.value))}
            />
          </div>
        </div>
      )}
    </div>
  )
}

WidgetTargetItem.propTypes = {
  open: PropTypes.bool,
  handleOpen: PropTypes.func,
  dataTarget: PropTypes.object,
  changeDataTarget: PropTypes.func,
  handleHighlight: PropTypes.func,
}

const getStyles = ({ theme }) =>
  StyleSheet.create({
    container: {
      width: "100%",
      height: "auto",
      marginBottom: 15,
    },
    body: { background: WHITE, padding: "0 10px 5px", boxSizing: "border-box" },
    chevronUp: { transform: "rotate(180deg)" },
    btn: {
      border: "none",
      cursor: "pointer",
      background: theme.SECONDARY_COLOR,
      color: WHITE,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      width: 40,
    },
    containerPos: {
      display: "flex",
      gap: 10,
    },
    input: {
      borderRadius: 0,
      fontStyle: "italic",
      fontSize: 12,
      height: 30,
      fontWeight: "400",
      color: theme.PRIMARY_COLOR || BLACK_COLOR,
      "::placeholder": {
        color: theme.PRIMARY_COLOR || BLACK_COLOR,
        opacity: 0.7,
      },
    },
    header: {
      border: "none",
      height: 35,
      background: theme.SECONDARY_COLOR,
      color: WHITE,
      display: "flex",
      alignItems: "center",
      padding: "0 0 0 10px",
      textTransform: "uppercase",
      fontSize: 12,
      justifyContent: "space-between",
    },
    flex: {
      display: "flex",
      alignItems: "center",
    },
    title: {
      margin: 0,
      fontFamily: "Roboto",
      fontWeight: "600",
      fontSize: 14,
      minWidth: "60%",
    },
    item: {
      height: 25,
    },
    font600: {
      fontFamily: "Roboto",
      fontSize: 12,
      fontStyle: "normal",
      fontWeight: "600",
      lineHeight: "22px",
      padding: "5px 0 0",
      margin: 0,
      color: theme.PRIMARY_COLOR || BLACK_COLOR,
    },
  })
