import { yupResolver } from "@hookform/resolvers/yup"
import { css, StyleSheet } from "aphrodite"
import React, { useLayoutEffect, useMemo, useState } from "react"
import { useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { useLocation } from "react-router-dom"
import * as yup from "yup"
import "../../App.css"
import { useTheme } from "../../components/Provider/ThemeProvider"
import { UIButton, UIInputWithRegister } from "../../components/UI"
import { useAuth } from "../../hooks"
import { checkEnv } from "../../lib"
import { ERROR_COLOR, WHITE } from "../../styles/colors"

export const LoginPage = () => {
  // states --------------------------------------------------------------------
  const [logo, setLogo] = useState()

  // hooks ---------------------------------------------------------------------
  const { t } = useTranslation("loginPage")
  const { themeStyles } = useTheme()
  const location = useLocation()
  const { signinUserFunc, errorLogin, loading, setLoading } = useAuth()
  // const navigate = useNavigate()

  // init style ----------------------------------------------------------------
  const styles = useMemo(() => getStyles({ themeStyle: themeStyles, errorLogin }), [themeStyles, errorLogin])

  const schema = yup.object().shape({
    email: yup.string().email(t("errors.error_format")).required(),
    password: yup.string().required(),
  })

  const {
    handleSubmit,
    register,
    setValue,
    formState: { errors },
  } = useForm({
    reValidateMode: "onChange",
    mode: "onSubmit",
    resolver: yupResolver(schema),
  })

  useLayoutEffect(() => {
    localStorage.clear()
    sessionStorage.clear()
  }, [])

  useLayoutEffect(() => {
    if (themeStyles?.organization_id) {
      const config = checkEnv()
      const apiLogo = `${config.BASE_URL}/organizations/${themeStyles?.organization_id}/logo-cms`
      setLogo(apiLogo)
    }
  }, [themeStyles])

  const onSubmit = data => {
    setLoading(true)
    const { email, password } = data
    if ((email, password)) {
      signinUserFunc(email, password)
    }
  }

  return (
    <div className={css(styles.container)}>
      <div className={css(styles.cardForm)}>
        <header className={css(styles.cardHeader)}>
          {logo && (
            <div>
              <img height={"100%"} width={"100%"} alt="logo" src={logo} />
            </div>
          )}
          {/* <div className={css(styles.tabs)}>
            <button
              className={css(styles.tab, location.pathname === '/signin' && styles.selected)}
              type="button"
              onClick={() => navigate('/signin')}
            >
              {t('logIn')}
            </button>
            <button
              disabled
              className={css(styles.tab, location.pathname === '/register' && styles.selected)}
              type="button"
              onClick={() => navigate('/register')}
            >
              {t('register')}
            </button>
          </div> */}
          <p className={css(styles.title)}>
            {location.pathname === "/signin" ? t("title_logIn") : t("title_register")}
          </p>
        </header>
        <form onSubmit={handleSubmit(onSubmit)} className={css(styles.containerForm)}>
          {errorLogin && (
            <p style={{ textAlign: "center", marginBottom: 5 }} className={css(styles.errorText)}>
              {t(`errors.${errorLogin}`)}
            </p>
          )}

          <div style={{ marginBottom: errors?.email ? 5 : 20 }}>
            <UIInputWithRegister
              theme={themeStyles}
              colorIcon={themeStyles?.PRIMARY_COLOR}
              iconDirection="left"
              icon="user"
              type="email"
              register={register}
              keyInput="email"
              setValue={setValue}
              placeholder={t("email_placeholder")}
              isError={!!errors?.email || errorLogin}
              dynamicStyle={[styles.input]}
              autoComplete={"email"}
            />
            {errors?.email?.message && (
              <>
                <p className={css(styles.errorText)}>{errors.email.message}</p>
              </>
            )}
          </div>
          <div>
            <UIInputWithRegister
              theme={themeStyles}
              colorIcon={themeStyles?.PRIMARY_COLOR}
              iconDirection="left"
              icon="lock"
              type="password"
              register={register}
              keyInput="password"
              isError={!!errors?.password || errorLogin}
              setValue={setValue}
              autoComplete={"password"}
              placeholder={t("password_placeholder")}
              dynamicStyle={[styles.input]}
            />
            {errors?.password?.message && (
              <>
                <p className={css(styles.errorText)}>{errors.password.message}</p>
              </>
            )}
          </div>
          {/* <button disabled type="button" className={css(styles.forgotPassword)}>
            {t('forgot_password')}
            </button> */}

          <UIButton
            loading={loading}
            themeStyle={themeStyles}
            dynamicStyle={styles.btn}
            type="submit"
            label={t("connexion")}
          />
        </form>
      </div>
      <footer className={css(styles.footer)}>
        <p style={{ margin: 0 }}>{t("copyright")}</p>
      </footer>
    </div>
  )
}

const getStyles = ({ themeStyle, errorLogin }) =>
  StyleSheet.create({
    container: {
      width: "100dvw",
      height: "100dvh",
      display: "grid",
      placeItems: "center",
      background: themeStyle.BACKGROUND_COLOR,
      fontFamily: "Roboto",
    },
    cardForm: {
      borderRadius: 20,
      display: "flex",
      justifyContent: "center",
      flexDirection: "column",
      boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.1)",
      background: WHITE,
      boxSizing: "border-box",
      "@media (max-width: 600px)": {
        height: "85dvh",
        width: "90dvw",
        padding: "30px 50px",
      },
      "@media (min-width: 600px)": {
        height: 450,
        width: 450,
        padding: "40px 70px",
      },
    },
    cardHeader: {
      display: "grid",
      placeItems: "center",
      color: themeStyle.PRIMARY_COLOR,
      fontWeight: "600",
    },
    title: {
      margin: errorLogin ? "30px 0 10px" : "30px 0 25px",
      fontSize: 18,
    },
    tabs: {
      marginTop: 20,
    },
    tab: {
      fontSize: 14,
      border: "none",
      background: "none",
      color: themeStyle.PRIMARY_COLOR,
      fontWeight: "600",
      cursor: "pointer",
      ":hover": {
        opacity: 0.7,
      },
      ":disabled": {
        opacity: "0.6",
        cursor: "default",
      },
    },
    footer: {
      position: "absolute",
      bottom: 40,
      fontSize: ".8rem",
      fontWeight: "400",
      opacity: 0.45,
      padding: "0 10px",
      "@media (max-width: 600px)": {
        bottom: 20,
        textAlign: "center",
      },
    },
    containerForm: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
    },
    selected: { textDecorationLine: "underline" },
    forgotPassword: {
      fontSize: 15,
      fontWeight: "400",
      color: themeStyle.PRIMARY_COLOR,
      margin: "20px 0 10px",
      textAlign: "center",
      textDecoration: "underline",
      background: "none",
      border: "none",
      cursor: "pointer",
      ":hover": {
        opacity: 0.7,
      },
      ":disabled": {
        opacity: "0.6",
        cursor: "default",
      },
    },
    btn: {
      fontSize: 15,
      fontWeight: "600",
      height: 42,
      width: "45%",
      marginLeft: "auto",
      marginRight: "auto",
      marginTop: 30,
      cursor: "pointer",
      fontFamily: "Roboto",
    },
    errorText: {
      color: ERROR_COLOR,
      fontSize: 11,
      fontWeight: "400",
      margin: "5px 0",
      marginBottom: 10,
    },
    input: { fontSize: 12 },
  })
