import PropTypes from "prop-types"
import * as React from "react"

const User = ({ color }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={16} height={17} fill="none">
    <path
      fill={color}
      d="M14.189 12.994a6.68 6.68 0 0 0-1.44-2.134 6.709 6.709 0 0 0-2.133-1.44c-.007-.003-.015-.005-.022-.009a4.428 4.428 0 0 0-2.593-8.018 4.428 4.428 0 0 0-2.592 8.02l-.022.01a6.65 6.65 0 0 0-2.134 1.438 6.708 6.708 0 0 0-1.966 4.6.143.143 0 0 0 .143.147h1.071a.143.143 0 0 0 .143-.14 5.324 5.324 0 0 1 1.568-3.648 5.322 5.322 0 0 1 3.79-1.57c1.432 0 2.776.558 3.789 1.57a5.324 5.324 0 0 1 1.568 3.649c.001.078.064.139.142.139h1.072a.142.142 0 0 0 .133-.09.142.142 0 0 0 .01-.057 6.652 6.652 0 0 0-.527-2.467ZM8 8.894c-.82 0-1.59-.32-2.171-.9-.58-.58-.9-1.352-.9-2.172 0-.82.32-1.59.9-2.171.58-.58 1.352-.9 2.171-.9.82 0 1.592.32 2.172.9.58.58.9 1.351.9 2.171 0 .82-.32 1.591-.9 2.172-.58.58-1.352.9-2.172.9Z"
    />
  </svg>
)

User.propTypes = {
  color: PropTypes.string,
}

export default User
