import { gql } from "@apollo/client"

export const CREATE_CHILD_STEP_POI = gql`
  mutation CreateChildStepPoi(
    $idChildStep: UUID!
    $idPoi: UUID!
    $x: Float!
    $y: Float!
    $z: Float!
    $type: PoiType!
  ) {
    createStepPoi(
      input: { stepPoi: { idPoi: $idPoi, idChildStep: $idChildStep, x: $x, y: $y, z: $z, type: $type } }
    ) {
      stepPoi {
        id
      }
    }
  }
`

export const DELETE_CHILD_STEP_POI = gql`
  mutation DeleteChildStepPoi($id: UUID!) {
    deleteStepPoi(input: { id: $id }) {
      clientMutationId
    }
  }
`

export const UPDATE_CHILD_STEP_POI = gql`
  mutation UpdateChildStepPoi($id: UUID!, $idPoi: UUID, $x: Float, $y: Float, $z: Float, $type: PoiType) {
    updateStepPoi(input: { patch: { type: $type, x: $x, y: $y, z: $z, idPoi: $idPoi }, id: $id }) {
      clientMutationId
    }
  }
`
