import * as THREE from 'three'

class Animator {
  clips
  mixer
  _actions
  _currentAnimationName = null

  get animationNames() {
    let names = []
    for (const clip of this.clips) {
      names.push(clip.name)
    }

    return names
  }
  get animationsData() {
    let data = []

    for (const clip of this.clips) {
      data.push({
        name: clip.name,
        duration: clip.duration,
      })
    }

    return data
  }

  get currentAction() {
    if (this._currentAnimationName === null) {
      return null
    }

    var currentAction = this._actions.get(this._currentAnimationName)
    if (currentAction === undefined) {
      return null
    }

    return currentAction
  }

  get currentAnimationName() {
    return this._currentAnimationName
  }

  constructor(root, animations) {
    // console.warn('--- GlAnimator creation ---')

    this.mixer = new THREE.AnimationMixer(root)
    this.clips = animations

    this._actions = new Map()

    // this.clips.splice(0, 1);
    for (const clip of this.clips) {
      // clip.optimize();
      // clip.tracks = [clip.tracks[0]];
      let newAction = this.mixer.clipAction(clip)
      // newAction.blendMode = THREE.AdditiveAnimationBlendMode;
      newAction.loop = THREE.LoopOnce
      newAction.clampWhenFinished = true
      this._actions.set(clip.name, newAction)
      // console.warn('Name: ' + clip.name + ' Duration: ' + clip.duration)
    }
    // console.warn([root, this.clips])
  }

  playAnimation(name) {
    var newAction = this._actions.get(name)

    if (newAction === undefined) {
      console.error('ERROR GlAnimator: There is no animation with the name ' + name + '.')
      return
    }

    this.stopAnimation()

    newAction.play()
    this._currentAnimationName = name
  }

  setAnimationPaused(paused) {
    if (typeof paused != 'boolean') {
      console.error('Bad parameter')
      return
    }
    if (this._currentAnimationName === null) {
      return
    }

    var currentAction = this.currentAction
    if (currentAction != null) {
      currentAction.paused = paused
    }
  }

  setAnimationTime(time) {
    var currentAction = this.currentAction
    if (currentAction != null) {
      currentAction.time = time
    }
  }

  stopAnimation() {
    if (this._currentAnimationName === null) {
      return
    }

    var currentAction = this.currentAction
    if (currentAction != null) {
      currentAction.stop()
      this._currentAnimationName = null
    }
  }

  update(deltaTime, timestamp) {
    if (!this.mixer) {
      return
    }

    this.mixer.update(deltaTime / 1000)
  }

  dispose() {
    for (const action of this._actions.values()) {
      action.stop()
    }

    this._actions.clear()
    this._actions = null
    this._currentAnimationName = null
    this.mixer = null
  }
}

export default Animator
