import { StyleSheet } from "aphrodite"
import PropTypes from "prop-types"
import React, { useRef } from "react"
import { UIButton } from "./UIButton"

const UIFileUpload = ({ uploadBtnText, width, disabled = false, onChange, btnColor, height, theme }) => {
  const styles = getStyles({ width, theme, btnColor, height })
  const hiddenFileInput = useRef()

  const handleChange = async event => {
    const file = event.target.files[0]

    if (!file) return
    onChange(file)
  }

  const handleClick = () => {
    hiddenFileInput.current.click()
  }

  return (
    <div>
      <UIButton
        dynamicStyle={[styles.btn]}
        label={uploadBtnText}
        themeStyle={theme}
        kind="primary"
        type="button"
        onClick={handleClick}
      />

      <input
        type="file"
        disabled={disabled}
        className="form-control form-control-sm"
        id="media-URL"
        style={{ display: "none" }}
        ref={hiddenFileInput}
        label={uploadBtnText}
        accept=".csv"
        onChange={event => {
          handleChange(event)
        }}
      />
    </div>
  )
}

const getStyles = ({ height, width, theme, btnColor }) =>
  StyleSheet.create({
    buttonUpload: {
      width,
      height,
      backgroundColor: "transparent",
      borderStyle: "dashed",
      borderRadius: "4px",
      boxSizing: "border-box",
      border: "none",
      fontWeight: "400",
      fontFamily: "Roboto",
      fontSize: 12,
    },
    uploadDescriptionContainer: {
      display: "flex",
      justifyContent: "center",
    },
    uploadDetail: {
      margin: 0,
      fontWeight: 300,
      fontwidth: "10px",
      opacity: 0.4,
      width: "85%",
      fontStyle: "italic",
      textAlign: "center",
    },
    btn: {
      fontWeight: "400",
      width,
      fontFamily: "Roboto",
      fontSize: 12,
      height,
      padding: "0px 10px",
      boxSizing: "border-box",
      backgroundColor: btnColor,
    },
    label: {
      fontWeight: "600",
      fontFamily: "Roboto",
      fontwidth: 12,
      color: theme.PRIMARY_COLOR,
      margin: 0,
    },
    warningBg: {
      backgroundColor: "#C4C4C4",
      height,
      width,
      borderRadius: 4,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
  })

UIFileUpload.propTypes = {
  uploadBtnText: PropTypes.string,
  file: PropTypes.any,
  onChange: PropTypes.func,
  dynamicStyle: PropTypes.array,
  multiple: PropTypes.bool,
  height: PropTypes.number,
  width: PropTypes.number,
  loading: PropTypes.bool,
  theme: PropTypes.object,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  btnColor: PropTypes.string,
  disabled: PropTypes.bool,
}

export { UIFileUpload }
