import * as THREE from 'three'

function createPixelSprite(width, height, customMaterialOptions = {}) {
  const materialOptions = {
    color: 0xffffff,
  }

  Object.assign(materialOptions, customMaterialOptions)
  materialOptions.sizeAttenuation = true

  const sprite = new THREE.Sprite(new THREE.SpriteMaterial(materialOptions))
  sprite.userData.width = width
  sprite.userData.height = height
  sprite.onBeforeRender = updateSpriteScale

  return sprite

  function updateSpriteScale(renderer, scene, camera) {
    const cameraWorldPosition = new THREE.Vector3()
    camera.getWorldPosition(cameraWorldPosition)
    const spriteWorldPosition = new THREE.Vector3()
    this.getWorldPosition(spriteWorldPosition)
    const renderSize = new THREE.Vector2()
    renderer.getSize(renderSize)
    const distanceToCam = cameraWorldPosition.distanceTo(spriteWorldPosition)
    const halfH = distanceToCam * Math.tan(THREE.MathUtils.degToRad(camera.fov) / 2)
    const wantedHeightInPixels = this.userData.height
    const spriteScale = 2 * ((wantedHeightInPixels * halfH) / renderSize.y)

    const wantedWidthInPixels = this.userData.width
    this.scale.set(spriteScale * (wantedWidthInPixels / wantedHeightInPixels), spriteScale, 1)
    this.updateMatrixWorld(true)
  }
}

function convertOffsetToNDCCoordinates(offsetX, offsetY, width, height) {
  let ndcX, ndcY

  ndcX = THREE.MathUtils.mapLinear(offsetX, 0, width, -1, 1)
  ndcY = THREE.MathUtils.mapLinear(offsetY, 0, height, 1, -1)

  return {
    ndcX,
    ndcY,
  }
}

function getObjectsToRaycast(root, includeSpriteChildren) {
  const raycastables = []
  root.traverse(object => {
    if (object.geometry && !object.isLine && (includeSpriteChildren || !object.isSprite)) {
      raycastables.push(object)
    }
  })

  return raycastables
}

export { convertOffsetToNDCCoordinates, createPixelSprite, getObjectsToRaycast }
