import { css, StyleSheet } from "aphrodite"
import React, { useLayoutEffect, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { Link, useLocation, useNavigate } from "react-router-dom"
import { Home, Manual, Stack } from "../../assets/svgr"
import { checkEnv } from "../../lib"
import { WHITE } from "../../styles/colors"
import { useTheme } from "../Provider/ThemeProvider"

export const NavBarSlide = () => {
  // states --------------------------------------------------------------------
  const [logo, setLogo] = useState("")

  // hooks ---------------------------------------------------------------------
  const { themeStyles } = useTheme()
  const { t } = useTranslation("navBar")
  const location = useLocation()
  const navigate = useNavigate()
  const config = useMemo(() => checkEnv(), [])

  // init style -----------------------------------------------------------------
  const styles = useMemo(() => getStyles({ theme: themeStyles }), [themeStyles])

  useLayoutEffect(() => {
    if (themeStyles?.organization_id && config) {
      const apiLogo = `${config.BASE_URL}/organizations/${themeStyles?.organization_id}/logo-cms`
      setLogo(apiLogo)
    }
  }, [themeStyles])

  const cleanLocalstorage = () => {
    //* if from edit step page
    sessionStorage.clear()
  }

  return (
    <nav className={css(styles.containerNavBar)}>
      <header>
        {logo && (
          <Link onClick={cleanLocalstorage} to="/brands">
            <img className={css(styles.img)} height={"100%"} width={"100%"} alt="logo" src={logo} />
          </Link>
        )}
      </header>
      <nav>
        <button
          aria-label={t("home")}
          type="button"
          onClick={() => {
            cleanLocalstorage()
            navigate("/brands")
          }}
          className={css(styles.linkContainer, location.pathname === "/brands" && styles.selected)}>
          <Home color={location.pathname === "/brands" ? WHITE : themeStyles?.PRIMARY_COLOR} />
          {t("home")}
        </button>
        <button
          aria-label={t("library")}
          type="button"
          onClick={() => {
            cleanLocalstorage()
            navigate("/library")
          }}
          className={css(styles.linkContainer, location.pathname === "/library" && styles.selected)}>
          <Stack color={location.pathname === "/library" ? WHITE : themeStyles?.PRIMARY_COLOR} />
          {t("library")}
        </button>

        <button
          aria-label={t("manuels")}
          type="button"
          onClick={() => {
            cleanLocalstorage()
            navigate("/manuals")
          }}
          className={css(styles.linkContainer, location.pathname.includes("/manuals") && styles.selected)}>
          <Manual color={location.pathname.includes("/manuals") ? WHITE : themeStyles?.PRIMARY_COLOR} />
          {t("manuels")}
        </button>
      </nav>
      <p className={css(styles.version)}>{`v${config?.CURRENT_VERSION}`}</p>
    </nav>
  )
}

const getStyles = ({ theme }) =>
  StyleSheet.create({
    containerNavBar: {
      display: "flex",
      flexDirection: "column",
      height: "100dvh",
      boxSizing: "border-box",
      background: WHITE,
      gap: 60,
      overflow: "hidden",
      padding: "30px 25px 30px",
      width: "25%",
      minWidth: 230,
      maxWidth: 400,
    },
    img: {
      maxWidth: "90%",
      height: "auto",
    },
    linkContainer: {
      display: "flex",
      alignItems: "center",
      gap: 10,
      color: theme.PRIMARY_COLOR,
      background: "none",
      border: "none",
      cursor: "pointer",
      fontWeight: 400,
      height: 35,
      margin: "0 0 20px 0",
      padding: "0px 16px 0 6px",
      fontFamily: "Roboto",
      ":disabled": {
        opacity: "0.6",
        cursor: "default",
      },
      "@media (max-width: 1125px)": {
        fontSize: 15,
      },
      "@media (min-width: 1126px)": {
        fontSize: 17,
      },
    },
    selected: {
      background: theme.PRIMARY_COLOR,
      color: WHITE,
      borderRadius: 10,
    },
    version: {
      fontSize: 12,
      paddingLeft: 10,
      color: theme.PRIMARY_COLOR,
      fontFamily: "Roboto",
      position: "absolute",
      bottom: 20,
      left: 20,
    },
  })
