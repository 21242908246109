import { css, StyleSheet } from "aphrodite"
import PropTypes from "prop-types"
import React from "react"
import { useTranslation } from "react-i18next"
import Modal from "react-modal"
import { useOutletContext } from "react-router-dom"
import { UIButton } from "../../components/UI"
import { BLACK_COLOR } from "../../styles/colors"

export const ConfirmDeleteStepModal = ({ confirm, cancel, isOpen, theme }) => {
  const { t } = useTranslation("stepPage")
  const { modalHidden } = useOutletContext()

  // init style ----------------------------------------------------------------
  const styles = getStyles({ theme })

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      width: "80dvw",
      maxWidth: 500,
      maxHeight: "80dvh",
      border: "none",
      padding: "40px 50px",
      boxSizing: "border-box",
      lineHeight: "20px",
      borderRadius: 0,
      display: "flex",
      justifyContents: "space-arround",
      flexDirection: "column",
      gap: 15,
    },
    overlay: {
      background: "rgba(0, 22, 33, 0.70)",
    },
  }
  return (
    <Modal
      onRequestClose={cancel}
      appElement={document.getElementById("root")}
      isOpen={isOpen && !modalHidden}
      style={customStyles}>
      <h1 className={css(styles.baseText, styles.titleText)}>{t("modal.confirm.delete_step_title")}</h1>
      <div className={css(styles.containerBtn)}>
        <UIButton
          dynamicStyle={styles.button}
          themeStyle={theme}
          kind="secondary"
          label={t("modal.confirm.no")}
          onClick={() => cancel()}
        />
        <UIButton
          dynamicStyle={styles.button}
          themeStyle={theme}
          kind="primary"
          label={t("modal.confirm.yes")}
          onClick={() => confirm()}
        />
      </div>
    </Modal>
  )
}

ConfirmDeleteStepModal.propTypes = {
  isOpen: PropTypes.bool,
  cancel: PropTypes.func,
  confirm: PropTypes.func,
  theme: PropTypes.object,
}

const getStyles = ({ theme }) =>
  StyleSheet.create({
    baseText: {
      color: theme?.PRIMARY_COLOR || BLACK_COLOR,
      fontFamily: "Roboto",
      fontSize: 14,
      textAlign: "center",
      margin: 0,
    },
    titleText: {
      fontSize: 18,
    },
    containerBtn: {
      display: "flex",
      alignItems: "center",
      gap: 15,
      justifyContent: "center",
      marginTop: 10,
    },
    button: {
      height: 35,
      width: 80,
    },
  })
