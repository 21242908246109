import { gql } from "@apollo/client"
import { BRAND_DATA, MANUAL_DATA, MANUAL_STEP_DATA } from "./fragments"

export const BRANDS = gql`
  ${BRAND_DATA}
  query Brands($first: Int, $offset: Int) {
    brands(first: $first, offset: $offset, orderBy: LABEL_ASC) {
      nodes {
        ...BrandData
      }
      totalCount
    }
  }
`

export const BRANDS_BY_LABEL = gql`
  ${BRAND_DATA}
  query BrandsByLabel($label: String) {
    brands(orderBy: LABEL_ASC, filter: { label: { includesInsensitive: $label } }) {
      nodes {
        ...BrandData
      }
      totalCount
    }
  }
`

export const BRAND_BY_ID_BRAND = gql`
  ${MANUAL_DATA}
  ${MANUAL_STEP_DATA}
  query Brands($id: UUID!, $first: Int, $offset: Int) {
    brands(filter: { id: { equalTo: $id } }, first: $first, offset: $offset, orderBy: LABEL_ASC) {
      nodes {
        productsByIdBrand {
          nodes {
            idBrand
            name
            manualsByIdProduct {
              nodes {
                ...ManualData
                assemblageDuration {
                  days
                  hours
                  minutes
                  months
                  seconds
                  years
                }
                manualStepsByIdManual {
                  nodes {
                    ...ManualStepData
                  }
                }
              }
            }
            id
            name
          }
          totalCount
        }
      }
    }
  }
`
