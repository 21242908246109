import Box from "@mui/material/Box"
import Slider, { SliderThumb } from "@mui/material/Slider"
import { styled } from "@mui/material/styles"
import PropTypes from "prop-types"
import * as React from "react"

const SliderCustom = styled(Slider)(({ theme, primaryColor, secondaryColor }) => ({
  color: primaryColor || "#007DBC",
  height: 3,
  padding: "13px 0",
  "& .MuiSlider-thumb": {
    height: 10,
    width: 10,
    backgroundColor: secondaryColor || "#7EBEE0",
    border: "none",
    borderRadius: "0%",
  },
  "& .MuiSlider-track": {
    height: 8,
  },
  "& .MuiSlider-rail": {
    color: theme.palette.mode === "dark" ? "#bfbfbf" : "#d8d8d8",
    opacity: theme.palette.mode === "dark" ? undefined : 1,
    height: 4,
    borderRadius: "0%",
  },
  "& .MuiSlider-thumb::after": {
    borderRadius: "0%",
    width: 10,
    height: 10,
  },
}))

const UISlider = ({ value, handleChange, min, max, primaryColor, secondaryColor }) => {
  function ThumbComponent(props) {
    const { children, ...other } = props
    return (
      <SliderThumb {...other}>
        {children}
        <span className="slider-bar" />
        <span className="slider-bar" />
        <span className="slider-bar" />
      </SliderThumb>
    )
  }

  return (
    <Box sx={{ width: "100%" }}>
      <SliderCustom
        value={value}
        onChange={handleChange}
        primaryColor={primaryColor}
        secondaryColor={secondaryColor}
        valueLabelDisplay="auto"
        min={min}
        max={max}
        step={0.001}
        slots={{ thumb: ThumbComponent }}
        getAriaLabel={index => (index === 0 ? "Minimum price" : "Maximum price")}
        defaultValue={[20, 40]}
      />
    </Box>
  )
}

UISlider.propTypes = {
  value: PropTypes.array,
  handleChange: PropTypes.func.isRequired,
  min: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired,
  children: PropTypes.node,
  primaryColor: PropTypes.string,
  secondaryColor: PropTypes.string,
}

export { UISlider }
