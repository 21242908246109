import { useQuery } from "@apollo/client"
import { useEffect, useState } from "react"
import { useLocation, useSearchParams } from "react-router-dom"
import { BRANDS } from "../graphql/brand"
const environment = process.env.REACT_APP_ENV || "development"
const configEnv = require(`../config/config.${environment}.js`)
const config = configEnv?.default

export const useHomePage = () => {
  // states --------------------------------------------------------------------
  const first = 20
  const [paginationState, setPaginationState] = useState({
    currentPage: 1,
    totalCount: 0,
    offset: 0,
  })
  const [brands, setBrands] = useState([])

  // hooks ---------------------------------------------------------------------
  const [params, setSearchParams] = useSearchParams()
  const location = useLocation()

  // graphql  --------------------------------------------------------------------
  const { loading: loadingBrand } = useQuery(BRANDS, {
    fetchPolicy: "cache-and-network",
    variables: { first, offset: paginationState.offset },
    onCompleted: data => {
      const array = data?.brands?.nodes.map(brand => ({
        ...brand,
        imgSrc: `${config.BASE_URL}/brands/${brand.id}/logo`,
      }))
      setBrands([...array])
      setPaginationState(prev => ({ ...prev, totalCount: data?.brands?.totalCount || 0 }))
    },
  })

  // func ----------------------------------------------------------------------
  useEffect(() => {
    if (!params.get("page")) {
      setSearchParams({ page: 1 })
    } else {
      const newPagination = { ...paginationState }
      newPagination.currentPage = Number(params.get("page"))
      newPagination.offset = (Number(params.get("page")) - 1) * first
      setPaginationState(newPagination)
    }
  }, [location])

  const changeCurrentPage = page => {
    setBrands([])
    setSearchParams({ page })
  }

  return {
    brandsDb: !loadingBrand && brands.length > 0 ? brands : [],
    first,
    paginationState,
    changeCurrentPage,
  }
}
