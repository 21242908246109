import * as React from "react"

const PoiType1 = props => (
  <svg xmlns="http://www.w3.org/2000/svg" width={48} height={48} fill="none" {...props}>
    <circle cx={24} cy={24} r={10} fill="#FFEA28" fillOpacity={0.02} />
    <g filter="url(#a)">
      <circle cx={24} cy={24} r={14} fill="#FFEA28" />
    </g>
    <path
      fill="#1A2A34"
      d="m25.181 18-.155 8.743h-1.66L23.21 18h1.971ZM24.2 30.387a1.17 1.17 0 0 1-.851-.348 1.14 1.14 0 0 1-.348-.85 1.113 1.113 0 0 1 .348-.84 1.16 1.16 0 0 1 .85-.353c.324 0 .604.117.84.353a1.155 1.155 0 0 1 .185 1.444 1.24 1.24 0 0 1-.431.432c-.18.108-.378.162-.593.162Z"
    />
    <defs>
      <filter
        id="a"
        width={42}
        height={42}
        x={3}
        y={3}
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse">
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset />
        <feGaussianBlur stdDeviation={3.5} />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0" />
        <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_4805_449" />
        <feBlend in="SourceGraphic" in2="effect1_dropShadow_4805_449" result="shape" />
      </filter>
    </defs>
  </svg>
)

export default PoiType1
