import * as THREE from 'three'
import { getObjectsToRaycast } from './UIUtils.mjs'

class Interactable {
  ignoreBlockers = false
  _root
  _interfaceManager = null
  _oldCursorValue
  _onClickActions = new Set()

  get root() {
    return this._root
  }

  get raycastTargets() {
    return getObjectsToRaycast(this._root, true)
  }

  get interfaceManager() {
    return this._interfaceManager
  }

  constructor() {
    this._root = new THREE.Group()
  }

  addToInterfaceManager(interfaceManager) {
    this._interfaceManager = interfaceManager
  }

  removeFromInterfaceManager(interfaceManager) {
    this._interfaceManager = null
  }

  setHovered(hovered) {
    if (hovered) {
      this._oldCursorValue = this._interfaceManager.viewer.canvas.style.cursor
      this._interfaceManager.viewer.canvas.style.cursor = 'pointer'
    } else {
      this._interfaceManager.viewer.canvas.style.cursor = this._oldCursorValue
      this._oldCursorValue = undefined
    }
  }

  addClickAction(action) {
    if (typeof action !== 'function') {
      console.error('ERROR: The provided action is not a function')
      return
    }

    this._onClickActions.add(action)
  }

  removeClickAction(action) {
    this._onClickActions.delete(action)
  }

  click() {
    var hasDoneSomething = false

    for (const action of this._onClickActions.values()) {
      action(this)
      hasDoneSomething = true
    }

    if (!hasDoneSomething) {
      console.log('CLICK ' + this.root.name)
    }
  }
}

export default Interactable
