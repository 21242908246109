import * as THREE from 'three'
import { GLTFLoader } from 'three/addons/loaders/GLTFLoader.js'
import CAMERA_MODEL from '../../assets/models/camera.glb'
import TARGET_MODEL from '../../assets/models/target.glb'
import { computeGlobalBoundingBox } from './3DUtils.mjs'
import AssetManager from './AssetManager.mjs'
import Interactable from './Interactable.mjs'
import InteractableTransformManipulator from './InteractableTransformManipulator.mjs'

const assetManager = new AssetManager()
assetManager.addLoaders({ GLTF: GLTFLoader })

class OrbitCameraConfigurator {
  _interfaceManager
  _cameraInteractable
  _cameraManipulator
  _targetInteractable
  _targetManipulator
  _minDistance = 0
  _maxDistance = Infinity
  _onCameraTransformChangedActions = new Set()
  _onTargetTransformChangedActions = new Set()
  _onCameraSelectActions = new Set()
  _onTargetSelectActions = new Set()
  _isDisposed = false

  get cameraGizmo() {
    return this._cameraInteractable.root
  }
  get targetGizmo() {
    return this._targetInteractable.root
  }
  get cameraManipulator() {
    return this._cameraManipulator
  }
  get targetManipulator() {
    return this._targetManipulator
  }

  get minDistance() {
    return this._minDistance
  }
  get maxDistance() {
    return this._maxDistance
  }

  constructor(interfaceManager, transformControls) {
    this._cameraInteractable = new Interactable()
    this._targetInteractable = new Interactable()
    this._targetInteractable.ignoreBlockers = true

    this._interfaceManager = interfaceManager

    this._cameraManipulator = new InteractableTransformManipulator(
      this._cameraInteractable,
      transformControls,
    )
    this._targetManipulator = new InteractableTransformManipulator(
      this._targetInteractable,
      transformControls,
    )
    this._cameraManipulator.addTransformChangedAction(onTransformChanged.bind(this))
    this._targetManipulator.addTransformChangedAction(onTransformChanged.bind(this))
    this._cameraManipulator.addSelectAction(onManipulatorSelect.bind(this))
    this._targetManipulator.addSelectAction(onManipulatorSelect.bind(this))

    this.init()

    function onTransformChanged(manipulator) {
      var actionsSet = null
      switch (manipulator) {
        case this._cameraManipulator:
          if (!this.validateConfiguration()) {
            this.updateCameraOrientation()
            return
          }
          actionsSet = this._onCameraTransformChangedActions
          break
        case this._targetManipulator:
          this.validateConfiguration()
          actionsSet = this._onTargetTransformChangedActions
          break
        default:
          return
      }

      this.updateCameraOrientation()
      for (const action of actionsSet.values()) {
        action()
      }
    }

    function onManipulatorSelect(manipulator) {
      var actionsSet = null
      switch (manipulator) {
        case this._cameraManipulator:
          actionsSet = this._onCameraSelectActions
          break
        case this._targetManipulator:
          actionsSet = this._onTargetSelectActions
          break
        default:
          return
      }

      for (const action of actionsSet.values()) {
        action()
      }
    }
  }

  async loadAndAddGizmoModels() {
    const cameraFallbackGizmo = new THREE.Mesh(
      new THREE.BoxGeometry(0.15, 0.25, 0.5),
      new THREE.MeshBasicMaterial({ color: 0xaa88ff }),
    )
    const targetFallbackGizmo = new THREE.Mesh(
      new THREE.BoxGeometry(0.05, 0.05, 0.05),
      new THREE.MeshBasicMaterial({ color: 0xffbb55, depthTest: false }),
    )
    let loadedMeshes

    try {
      loadedMeshes = await assetManager.loadMultiple([CAMERA_MODEL, TARGET_MODEL], {
        type: 'GLTF',
      })
    } catch (error) {
      console.error('ERROR: error while loading gizmo models')
      this.cameraGizmo.add(cameraFallbackGizmo)
      this.targetGizmo.add(targetFallbackGizmo)
      return
    }

    const cameraScene = assetManager.get(CAMERA_MODEL)?.scene?.clone()
    const targetScene = assetManager.get(TARGET_MODEL)?.scene?.clone()

    // PREPARE CAMERA
    if (cameraScene) {
      cameraScene.rotateY(Math.PI)
      this.cameraGizmo.add(cameraScene)

      const cameraRenderable = getFirstRenderable(cameraScene)
      if (cameraRenderable) {
        cameraRenderable.onBeforeRender = (renderer, scene, camera) => {
          const cameraWorldPosition = new THREE.Vector3()
          camera.getWorldPosition(cameraWorldPosition)
          const cameraGizmoWorldPosition = new THREE.Vector3()
          this.cameraGizmo.getWorldPosition(cameraGizmoWorldPosition)
          const distanceToCam = cameraWorldPosition.distanceTo(cameraGizmoWorldPosition)

          this.cameraGizmo.scale.setScalar(distanceToCam / 1.5)
          this.cameraGizmo.updateMatrixWorld(true)
        }
      }
    } else {
      this.cameraGizmo.add(cameraFallbackGizmo)
    }

    // PREPARE TARGET
    if (targetScene) {
      const targetBBox = computeGlobalBoundingBox(targetScene)
      const boxSize = new THREE.Vector3()
      const boxCenter = new THREE.Vector3()

      targetBBox.getSize(boxSize)
      targetBBox.getCenter(boxCenter)

      targetScene.traverse(child => {
        if (child.material) {
          child.material.depthTest = false
        }
      })

      const hitBox = new THREE.Mesh(new THREE.BoxGeometry(boxSize.x, boxSize.y, boxSize.z))
      hitBox.visible = false
      hitBox.position.copy(boxCenter)
      targetScene.add(hitBox)
      this.targetGizmo.add(targetScene)

      const targetRenderable = getFirstRenderable(targetScene)
      if (targetRenderable) {
        targetRenderable.onBeforeRender = (renderer, scene, camera) => {
          const cameraWorldPosition = new THREE.Vector3()
          camera.getWorldPosition(cameraWorldPosition)
          const targetWorldPosition = new THREE.Vector3()
          this.targetGizmo.getWorldPosition(targetWorldPosition)
          const distanceToCam = cameraWorldPosition.distanceTo(targetWorldPosition)

          this.targetGizmo.scale.setScalar(distanceToCam / 1.5)
          this.targetGizmo.updateMatrixWorld(true)
        }
      }
    } else {
      this.targetGizmo.add(targetFallbackGizmo)
    }

    this.targetGizmo.renderOrder = 99

    function getFirstRenderable(root) {
      let queue = []
      queue.push(root)
      while (queue.length > 0) {
        let current = queue.shift()
        if (current.geometry && current.visible) {
          return current
        }

        for (const child of current.children) {
          queue.push(child)
        }
      }

      return null
    }
  }

  async init() {
    await this.loadAndAddGizmoModels()
    if (this._isDisposed) {
      return
    }

    this._interfaceManager.addInteractable(this._cameraInteractable)
    this._interfaceManager.addInteractable(this._targetInteractable)
  }

  dispose() {
    this._cameraManipulator.dispose()
    this._targetManipulator.dispose()
    this._cameraManipulator = this._targetManipulator = null

    this._interfaceManager.removeInteractable(this._cameraInteractable)
    this._interfaceManager.removeInteractable(this._targetInteractable)
    this._cameraInteractable = this._targetInteractable = null
  }

  setCameraPosition(x, y, z, autoValidate = true) {
    this.cameraGizmo.position.set(x, y, z)
    if (autoValidate) {
      this.validateConfiguration()
    }
    this.updateCameraOrientation()
  }

  setTargetPosition(x, y, z, autoValidate = true) {
    this.targetGizmo.position.set(x, y, z)
    if (this.autoValidate) {
      this.validateConfiguration()
    }
    this.updateCameraOrientation()
  }

  setMinDistance(minDistance, autoValidate = true) {
    if (isNaN(minDistance)) {
      throw new Error('Bad parameter ' + minDistance)
    }

    this._minDistance = parseFloat(minDistance)
    if (autoValidate && !this.validateConfiguration()) {
      this.updateCameraOrientation()
    }
  }

  setMaxDistance(maxDistance, autoValidate = true) {
    if (isNaN(maxDistance)) {
      throw new Error('Bad parameter ' + maxDistance)
    }

    this._maxDistance = parseFloat(maxDistance)
    if (autoValidate && !this.validateConfiguration()) {
      this.updateCameraOrientation()
    }
  }

  updateCameraOrientation() {
    this.cameraGizmo.lookAt(this.targetGizmo.position)
  }

  validateConfiguration() {
    const targetToCamera = this.cameraGizmo.position.clone().sub(this.targetGizmo.position)
    const distance = targetToCamera.length()

    if (distance >= this.minDistance && distance <= this.maxDistance) {
      return true
    }

    if (distance == 0) {
      targetToCamera.z = Number.EPSILON
    }
    targetToCamera.clampLength(this.minDistance, this.maxDistance)
    this.cameraGizmo.position.copy(this.targetGizmo.position).add(targetToCamera)

    for (const action of this._onCameraTransformChangedActions.values()) {
      action()
    }

    return false
  }

  addCameraTransformChangedAction(action) {
    if (typeof action !== 'function') {
      console.error('ERROR: The provided action is not a function')
      return
    }

    this._onCameraTransformChangedActions.add(action)
  }

  removeCameraTransformChangedAction(action) {
    this._onCameraTransformChangedActions.delete(action)
  }

  addTargetTransformChangedAction(action) {
    if (typeof action !== 'function') {
      console.error('ERROR: The provided action is not a function')
      return
    }

    this._onTargetTransformChangedActions.add(action)
  }

  removeTargetTransformChangedAction(action) {
    this._onTargetTransformChangedActions.delete(action)
  }

  addCameraSelectAction(action) {
    if (typeof action !== 'function') {
      console.error('ERROR: The provided action is not a function')
      return
    }

    this._onCameraSelectActions.add(action)
  }

  removeCameraSelectAction(action) {
    this._onCameraSelectActions.delete(action)
  }

  addTargetSelectAction(action) {
    if (typeof action !== 'function') {
      console.error('ERROR: The provided action is not a function')
      return
    }

    this._onTargetSelectActions.add(action)
  }

  removeTargetSelectAction(action) {
    this._onTargetSelectActions.delete(action)
  }
}

export default OrbitCameraConfigurator
