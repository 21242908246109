import { css, StyleSheet } from "aphrodite"
import PropTypes from "prop-types"
import React, { Suspense, useCallback, useEffect, useMemo, useState } from "react"
import { CloseSmall } from "../../assets/svgr"
import { useTheme } from "../../components/Provider/ThemeProvider"
import { checkEnv } from "../../lib"
import { WHITE } from "../../styles/colors"

export const WidgetToolChildStep = ({ id, remove, imgSrc }) => {
  const [label, setLabel] = useState()

  // init style ----------------------------------------------------------------
  const { themeStyles } = useTheme()
  const styles = useMemo(() => getStyles({ theme: themeStyles }), [themeStyles])

  const config = useMemo(() => checkEnv(), [])

  const fetchTrad = useCallback(async () => {
    try {
      const response = await fetch(`${config.BASE_URL}/tools/${id}/translations`)
      if (response.ok) {
        const trads = await response.json()
        if (trads.label.en) {
          setLabel(trads.label.en)
        }
      }
    } catch (error) {
      console.error("An error occurred while fetching translations:", error)
    }
  }, [id, config])

  useEffect(() => {
    if (id) {
      fetchTrad()
    }
  }, [id, fetchTrad])

  return (
    <React.Fragment>
      <Suspense>
        {label && (
          <div className={css(styles.itemTool)}>
            <button
              aria-label="close-button"
              onClick={remove}
              className={css(styles.primaryRoundedBtn)}
              type="button">
              <CloseSmall color={WHITE} />
            </button>
            <img alt="image" height={"100%"} width={"100%"} className={css(styles.img)} src={imgSrc} />
            <p className={css(styles.font400)}>{label}</p>
          </div>
        )}
      </Suspense>
    </React.Fragment>
  )
}
const getStyles = ({ theme }) =>
  StyleSheet.create({
    itemTool: {
      display: "flex",
      alignItems: "center",
      gap: 5,
      margin: 0,
      paddingBottom: 5,
    },
    img: {
      maxWidth: 40,
      height: "auto",
    },
    primaryRoundedBtn: {
      background: theme.PRIMARY_COLOR,
      borderRadius: "50%",
      border: "none",
      minWidth: 16,
      minHeight: 16,
      gap: 3,
      cursor: "pointer",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      ":hover": {
        opacity: 0.6,
      },
    },
    font400: {
      fontSize: 12,
      fontWeight: "400",
      color: theme.PRIMARY_COLOR,
      textTransform: "capitalize",
      margin: 0,
    },
  })

WidgetToolChildStep.propTypes = {
  imgSrc: PropTypes.string,
  remove: PropTypes.func,
  id: PropTypes.string,
}
