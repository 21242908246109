import * as THREE from 'three'

/**
 * @description This class is used to handle the creation and the use of three.js loaders. It also maintains a cache of loaded assets.
 */
class AssetManager {
  loadingManager
  loaders
  percent
  maxConcurrentLoading

  constructor() {
    var that = this

    THREE.Cache.enabled = true

    this.loadingManager = new THREE.LoadingManager(onLoad, onProgress, onError)
    this.loaders = {}
    this.assets = {}
    this.assets = new Map()
    this.percent = 0
    this.maxConcurrentLoading = 10

    function onLoad() {
      // console.log('all asset loaded')
    }

    function onProgress(item, current, total) {
      that.percent = (current / total) * 100
    }

    function onError(xhr) {
      console.warn('loading error', xhr)
    }
  }

  /**
   * Instantiates and adds new loaders. Each property of the parameter types must be a constructor of
   * a class inheriting THREE.Loader. The name of the property will be used as key (lower-cased)
   * in this instance's loaders.
   * @param {object} types
   */
  addLoaders(types = {}) {
    for (const type in types) {
      let typekey = type?.toLowerCase()

      if (typeof types[type] !== 'function' || !(types[type].prototype instanceof THREE.Loader)) {
        console.error(
          'Could not create loader of type ' +
            type +
            '. ' +
            types[type] +
            ' is not a valid loader class.',
        )
        continue
      }

      this.loaders[typekey] = new types[type](this.loadingManager)
    }
  }

  /**
   * Tries to retrieve a previously loaded asset from the cached assets.
   * @see set
   * @param {string} name - the name/url used when loading the asset
   * @returns the asset if it is found, undefined otherwise
   */
  get(name = '') {
    var asset = this.assets.get(name)

    if (asset === undefined) {
      // console.warn('Asset ' + name + ' not found.')
    }

    return asset
  }

  /**
   * Caches an asset that can be retrieved later using the parameter name as key.
   * @see get
   * @param {string} name - the key used to reference this asset in the cache.
   * @param {*} asset - the asset to cache
   */
  set(name = '', asset = {}) {
    this.assets.set(name, asset)
  }

  /**
   * Clears the cache. (Not related to THREE.Cache)
   */
  clearLoadedAssets() {
    this.assets.clear()
  }

  /**
   * Looks at the data parameter and checks if it fulfills all the minimum requirements in order to be
   * used by this instance with {@link load} or {@link loadMultiple}. Eventually, it tries to make it valid.
   * (e.g.: applies toLowerCase to the type...)
   * @param {Object} data - the loading data to validate
   * @param {string} data.type - the type of the loader to be used. Must be a string and the loader must have been previously added to this instance.
   * @returns {boolean} true if the data could be made valid, false otherwise
   */
  validateLoadingData(data) {
    if (typeof data.type !== 'string') {
      console.error("Invalid loading data: Missing 'type' in loading data.")
      return false
    }

    data.type = data.type.toLowerCase()

    if (!(this.loaders[data.type] instanceof THREE.Loader)) {
      console.error(`Invalid loading data: loader ${data.type} not exists`)
      return false
    }

    return true
  }

  /**
   * Loads one asset and returns a promise which will return the asset when it is loaded.
   * @param {string} assetPath - path of the asset to load
   * @param {Object} loadingData - loading data
   * @param {string} data.type - the type of the loader to be used. Must be a string and the loader must have been previously added to this instance.
   * @param {function} data.onProgress - the onProgress callback passed to the loader
   * @returns a promise resolved with the loaded asset and rejected if an error occurs during the loading.
   */
  load(assetPath, loadingData = {}) {
    if (!this.validateLoadingData(loadingData)) {
      throw new Error('Invalid loading data: aborting load.')
    }

    if (this.assets.has(assetPath)) {
      return Promise.resolve(this.get(assetPath))
    }

    const loadPromise = this.loaders[loadingData.type].loadAsync(assetPath, loadingData.onProgress)
    return loadPromise.then(asset => {
      this.set(assetPath, asset)
      return asset
    })
  }

  /**
   * Loads multiple assets and returns a promise which will return a Map of the loaded assets with the path as key if it resolves.
   * If no asset could be loaded, the promise will reject with an AggregateError containing all the loading errors.
   * @param {string[]} assetPaths - paths of the assets to load
   * @param {Object} loadingData - loading data
   * @param {string} data.type - the type of the loader to be used. Must be a string and the loader must have been previously added to this instance.
   * @param {function} data.onProgress - the onProgress callback passed to the loader
   * @returns a promise resolved with a map of the loaded assets and rejected if all loads fail.
   */
  loadMultiple(assetPaths = [], loadingData = {}) {
    if (!this.validateLoadingData(loadingData)) {
      throw new Error('Invalid loading data: aborting load.')
    }

    return new Promise(loadAll.bind(this))

    function loadAll(resolve, reject) {
      var handledAssetsCount = 0
      var inProgressLoadCount = 0
      var loadedAssets = new Map()
      var errors = []
      var loader = this.loaders[loadingData.type]
      var alreadyLoadedAsset
      var pathsToLoad = []

      for (let path of assetPaths) {
        alreadyLoadedAsset = this.get(path)
        if (alreadyLoadedAsset !== undefined) {
          loadedAssets.set(path, alreadyLoadedAsset)
        } else {
          pathsToLoad.push(path)
        }
      }

      // If all assets were already loaded, return immediately.
      if (pathsToLoad.length == 0) {
        resolve(loadedAssets)
        return
      }

      for (let path of pathsToLoad) {
        if (handledAssetsCount >= this.maxConcurrentLoading) {
          break
        }

        handledAssetsCount++
        inProgressLoadCount++
        loader
          .loadAsync(path, loadingData.onProgress)
          .then(onSuccess.bind(this, path), onError.bind(this, path))
      }

      function onSuccess(path, asset) {
        this.set(path, asset)
        loadedAssets.set(path, asset)
        onLoadDone.call(this)
      }

      function onError(path, err) {
        console.error('loading error ', err, path)
        errors.push(err)
        onLoadDone.call(this)
      }

      function onLoadDone() {
        inProgressLoadCount--
        if (handledAssetsCount < pathsToLoad.length) {
          let assetPath = pathsToLoad[handledAssetsCount++]
          inProgressLoadCount++
          loader
            .loadAsync(assetPath, loadingData.onProgress)
            .then(onSuccess.bind(this, assetPath), onError.bind(this, assetPath))
        } else if (inProgressLoadCount == 0) {
          if (loadedAssets.size > 0) {
            resolve(loadedAssets)
          } else {
            reject(new AggregateError(errors))
          }
        }
      }
    }
  }
}

export default AssetManager
