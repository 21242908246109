import { useLazyQuery, useMutation, useQuery } from "@apollo/client"
import dayjs from "dayjs"
import { useMemo, useState } from "react"
import { useSearchParams } from "react-router-dom"
import api from "../api"
import { BRANDS_BY_LABEL } from "../graphql/brand"
import {
  GET_MANUAL_ITEMS,
  MANUAL_BY_ID,
  PUBLISHED_MANUAL,
  UPDATE_MANUAL_AND_PRODUCT,
} from "../graphql/manuals"
import { checkEnv } from "../lib"
import { captureErrorGraphql } from "../lib/sentry"

export const useManagerPage = () => {
  // states --------------------------------------------------------------------
  const [brandsByLabel, setBrandsByLabel] = useState([])
  const [loading, setLoading] = useState(false)
  const [saveSuccess, setSaveSuccess] = useState(false)
  const [manualByIdManual, setManualByIdManual] = useState({})

  // hooks ---------------------------------------------------------------------
  const [params] = useSearchParams()
  const config = useMemo(() => checkEnv(), [])

  // graphql  --------------------------------------------------------------------
  //* request to get the materials
  const [fetchMaterials] = useLazyQuery(GET_MANUAL_ITEMS, {
    fetchPolicy: "cache-and-network",
    variables: { id: params.get("manual") },
    onCompleted: data => {
      if (data) {
        //* request to get the translations of the materials
        fetchTranslationsMaterials(data.getManualItems.tools, data.getManualItems.parts)
      } else {
        setManualByIdManual(prev => ({
          ...prev,
          materialsData: { tools: [], parts: [], loading: false },
        }))
      }
    },
  })

  //*  request to get the manual
  useQuery(MANUAL_BY_ID, {
    fetchPolicy: "cache-and-network",
    variables: { id: params.get("manual") },
    onCompleted: data => {
      if (data.manual) {
        setManualByIdManual({
          ...data.manual,
          urlThumbnail: `${config.BASE_URL}/manuals/${data.manual.id}/image?lang=en`,
          stepsData: [...data.manual.manualStepsByIdManual.nodes],
          materialsData: { tools: [], parts: [], loading: true },
        })
        fetchMaterials()
      }
    },
  })
  const [fetchBrandsByLabel] = useLazyQuery(BRANDS_BY_LABEL, {
    onCompleted: data => {
      setBrandsByLabel([])
      const array = []
      for (const brand of data.brands.nodes) {
        array.push({ value: brand.id, label: brand.label })
      }
      setBrandsByLabel([...array])
    },
  })
  const [updateManualData] = useMutation(UPDATE_MANUAL_AND_PRODUCT)
  const [publishedManualNow] = useMutation(PUBLISHED_MANUAL)

  // func ----------------------------------------------------------------------
  const updateData = async data => {
    setLoading(true)
    const variables = {
      ...(data.nbUser && { numberOfPeopleNeeded: Number(data.nbUser) }),
      ...(data.brandId && { brandId: data.brandId }),
      ...(data.time && { minutes: Number(data.time) }),
      ...(data.manualId && { manualId: data.manualId }),
      ...(data.productName && { productName: data.productName }),
      ...(data.productId && { productId: data.productId }),
    }
    updateManualData({
      variables,
      onError: e => {
        captureErrorGraphql(e, "useManagerPage", "UPDATE_MANUAL_AND_PRODUCT", variables)
        setLoading(false)
      },
      onCompleted: async () => {
        if (data?.file?.data) {
          await api.postManualFile(data.manualId, data?.file?.data, data?.file?.filename)
        }
        setLoading(false)
        setSaveSuccess(true)
        setTimeout(() => {
          setSaveSuccess(false)
        }, 1800)
      },
    })
  }

  const fetchTranslationsMaterials = async (tools, parts) => {
    await api
      .fetchManualTranslation(params.get("manual"), "en", "application/json")
      .then(response => {
        if (response.ok) {
          return response.json()
        }
      })
      .then(data => {
        const toolsTranslations = data.tools || []
        const partsTranslations = data.parts || []
        const toolsWithTranslations = tools?.map(tool => {
          const translation = toolsTranslations.find(t => t.id === tool.id)
          return {
            ...tool,
            translation: translation?.label.en || "",
          }
        })
        const partsWithTranslations = parts?.map(part => {
          const translation = partsTranslations.find(t => t.id === part.id)
          return {
            ...part,
            translation: translation?.label.en || "",
          }
        })

        setManualByIdManual(prev => ({
          ...prev,
          materialsData: {
            tools: toolsWithTranslations,
            parts: partsWithTranslations,
            loading: false,
          },
        }))
      })
      .catch(error => {
        captureErrorGraphql(error, "useManagerPage", "fetchTranslationsTools", {
          manualId: params.get("manual"),
          lang: "en",
          format: "application/json",
        })
        setManualByIdManual(prev => ({
          ...prev,
          materialsData: { tools: [], parts: [], loading: false },
        }))
      })
  }

  const publishedManual = async () => {
    const variables = { id: params.get("manual"), publishedDate: dayjs() }

    await publishedManualNow({
      variables,
      awaitRefetchQueries: true,
      refetchQueries: [
        {
          query: MANUAL_BY_ID,
          variables: { id: params.get("manual") },
          fetchPolicy: "network-only",
        },
      ],
      onError: e => {
        captureErrorGraphql(e, "useManagerPage", "PUBLISHED_MANUAL", variables)
      },
    })
  }

  return {
    manual: manualByIdManual ?? {},
    brandsByLabelDb: brandsByLabel ?? [],
    updateData,
    fetchBrandsByLabel,
    loading,
    setLoading,
    publishedManual,
    saveSuccess,
  }
}
