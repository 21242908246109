import { css, StyleSheet } from "aphrodite"
import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useSearchParams } from "react-router-dom"
import api from "../../api"
import { Check, Error } from "../../assets/svgr"
import { UIButton, UIFileUpload } from "../../components/UI"
import { NEUTRAL_DARK } from "../../styles/colors"

export const CardLangItem = ({ lng, theme }) => {
  const { t } = useTranslation("managerPage")
  const [params] = useSearchParams()
  const [loading, setLoading] = useState(false)
  const [stateFile, setStateFile] = useState("valid")

  // init style ----------------------------------------------------------------
  const styles = getStyles({ theme })

  const dowloadFile = async () => {
    try {
      setLoading(true)
      const response = await api.fetchManualTranslation(
        params.get("manual"),
        lng?.langLocaleByIdLangLocale?.languageByIdLanguage?.code,
        "text/csv",
      )
      const blob = await response.blob()

      const fileName = `translations-${params.get("manual")}-${
        lng?.langLocaleByIdLangLocale?.languageByIdLanguage?.code
      }.csv`
      const fileURL = URL.createObjectURL(blob)

      const a = document.createElement("a")
      a.href = fileURL
      a.download = fileName
      document.body.appendChild(a)
      a.click()
    } catch (error) {
      console.error(error)
    } finally {
      setLoading(false)
    }
  }

  const onChangeTranslationFile = async newFile => {
    if (newFile) {
      await api.postManualTranslation(
        params.get("manual"),
        newFile,
        lng?.langLocaleByIdLangLocale?.languageByIdLanguage?.code,
      )
    }
  }

  useEffect(() => {
    if (lng?.langLocaleByIdLangLocale?.languageByIdLanguage?.code?.toLowerCase() === "fr") {
      setStateFile("invalid")
    }
  }, [lng])

  return (
    <div className={css(styles.container)}>
      <div className={css(styles.flexRow)}>
        {stateFile === "valid" ? <Check /> : <Error />}
        <p className={css(styles.text)}>
          {t(`lang.${lng?.langLocaleByIdLangLocale?.languageByIdLanguage?.code?.toLowerCase()}`) ?? "nc"}
        </p>
      </div>

      <div className={css(styles.flexBtn)}>
        <UIButton
          dynamicStyle={[styles.btnDowload]}
          kind="secondary"
          themeStyle={theme}
          label={t("download_file")}
          onClick={() => dowloadFile()}
          loading={loading}
        />
        <UIFileUpload
          dynamicStyle={[styles.btn]}
          kind="primary"
          theme={theme}
          height={30}
          onChange={onChangeTranslationFile}
          uploadBtnText={t("upload_file")}
        />
      </div>
    </div>
  )
}

const getStyles = ({ theme }) =>
  StyleSheet.create({
    container: {
      padding: "6px 10px",
      borderBottom: `1px solid ${NEUTRAL_DARK}`,
      fontSize: 14,
      fontWeight: "600",
      color: theme?.PRIMARY_COLOR,
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    text: {
      fontSize: 12,
    },
    flexRow: {
      display: "flex",
      gap: 10,
      alignItems: "center",
    },
    flexBtn: {
      display: "flex",
      gap: 10,
      alignItems: "center",
    },
    btn: {
      height: 30,
      cursor: "pointer",
      padding: "0 10px",
      fontSize: 12,
      fontFamily: "Roboto",
      ":hover": {
        opacity: 0.6,
      },
    },
    btnDowload: {
      height: 30,
      width: 130,
      cursor: "pointer",
      padding: "0 10px",
      fontSize: 12,
      fontFamily: "Roboto",
    },
  })

CardLangItem.propTypes = {
  lng: PropTypes.object,
  theme: PropTypes.object,
}
