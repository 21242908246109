import { css, StyleSheet } from "aphrodite"
import PropTypes from "prop-types"
import React, { useRef } from "react"
import { UIButton } from "./UIButton"

const UIVideoUpload = ({
  uploadLabel,
  uploadBtnText,
  width,
  disabled = false,
  onChange,
  btnColor,
  height,
  theme,
  labelDynamicStyle,
  onError,
}) => {
  const styles = getStyles({ width, theme, btnColor, height })
  const hiddenFileInput = useRef()

  const handleChange = event => {
    const file = event.target.files[0]

    if (!file) return

    const acceptedFileTypes = ["video/mp4"]

    if (!acceptedFileTypes.includes(file.type)) {
      onError({ acceptType: true })
      return
    }

    const url = URL.createObjectURL(file)
    const video = document.createElement("video")

    video.onloadedmetadata = function () {
      URL.revokeObjectURL(url)

      if (video.videoWidth > 1920 || video.videoHeight > 1920) {
        onError({ size: true })
        return
      }

      onChange(file)
    }

    video.src = url
  }

  const handleClick = () => {
    hiddenFileInput.current.click()
  }

  return (
    <div>
      <p className={css(styles.label, labelDynamicStyle)}>{uploadLabel}</p>
      <UIButton
        dynamicStyle={[styles.btn]}
        label={uploadBtnText}
        themeStyle={theme}
        kind="primary"
        type="button"
        onClick={handleClick}
      />

      <input
        type="file"
        disabled={disabled}
        className="form-control form-control-sm"
        id="media-URL"
        style={{ display: "none" }}
        ref={hiddenFileInput}
        label={uploadBtnText}
        accept="video/mp4,video/x-m4v,video/*"
        onChange={event => {
          handleChange(event)
        }}
      />
    </div>
  )
}

const getStyles = ({ height, width, theme, btnColor }) =>
  StyleSheet.create({
    buttonUpload: {
      width,
      height,
      backgroundColor: "transparent",
      borderStyle: "dashed",
      borderRadius: "4px",
      boxSizing: "border-box",
      border: "none",
      fontWeight: "400",
      fontFamily: "Roboto",
      fontSize: 12,
    },
    uploadDescriptionContainer: {
      display: "flex",
      justifyContent: "center",
    },
    uploadDetail: {
      margin: 0,
      fontWeight: 300,
      fontwidth: "10px",
      opacity: 0.4,
      width: "85%",
      fontStyle: "italic",
      textAlign: "center",
    },
    btn: {
      fontWeight: "400",
      width,
      fontFamily: "Roboto",
      fontSize: 12,
      height,
      padding: "0px 10px",
      margin: 0,
      boxSizing: "border-box",
      backgroundColor: btnColor,
    },
    label: {
      fontWeight: "600",
      fontFamily: "Roboto",
      fontwidth: 12,
      color: theme.PRIMARY_COLOR,
      margin: 0,
    },
    warningBg: {
      backgroundColor: "#C4C4C4",
      height,
      width,
      borderRadius: 4,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
  })

UIVideoUpload.propTypes = {
  uploadLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  uploadBtnText: PropTypes.string,
  video: PropTypes.any,
  onChange: PropTypes.func,
  removeVideo: PropTypes.func,
  dynamicStyle: PropTypes.object,
  multiple: PropTypes.bool,
  type: PropTypes.string,
  height: PropTypes.number,
  width: PropTypes.number,
  loading: PropTypes.bool,
  theme: PropTypes.object,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  btnColor: PropTypes.string,
  disabled: PropTypes.bool,
  labelDynamicStyle: PropTypes.object,
  onError: PropTypes.func,
}

export { UIVideoUpload }
