import { css, StyleSheet } from "aphrodite"
import PropTypes from "prop-types"
import React, { useCallback, useLayoutEffect, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom"
import { AddStack, CheveronDown, Edit, Save, Stack, Trash } from "../../assets/svgr"
import WarningRoundYellow from "../../assets/svgr/WarningRoundYellow"
import { useTheme } from "../../components/Provider/ThemeProvider"
import { UIButtonIcon } from "../../components/UI"
import { checkEnv } from "../../lib"
import { BLACK_COLOR, ERROR_COLOR, NEUTRAL_DARK, WHITE } from "../../styles/colors"
import { WidgetPartChildStep } from "./WidgetPartChildStep"
import { WidgetToolChildStep } from "./WidgetToolChildStep"

export const WidgetChildStep = ({
  checkChevron,
  childStepSelected,
  data,
  index,
  saveChildStepData,
  modelIsPresent,
  saveSuccess,
  last,
  loading,
  deleteChildStep,
}) => {
  // hooks ----------------------------------------------------------------
  const { t } = useTranslation("stepPage")
  const [dataTools, setDataTools] = useState({ tools: [], parts: [], repeat: 0 })
  const [error, setError] = useState({ parts: [], repeat: false })
  const location = useLocation()
  const navigate = useNavigate()
  const [params] = useSearchParams()
  const { idstep } = useParams()
  const config = useMemo(() => checkEnv(), [])

  // init style ----------------------------------------------------------------
  const { themeStyles } = useTheme()
  const styles = useMemo(() => getStyles({ theme: themeStyles, open }), [themeStyles, open])

  useLayoutEffect(() => {
    if (data) {
      setDataTools({
        pos: data.pos,
        idManualStep: data?.idManualStep,
        childStepId: data.id,
        repeat: data.nbRepeat || 0,
        parts: data?.stepPartsByIdChildStep?.nodes || [],
        tools: data?.stepToolsByIdChildStep?.nodes || [],
      })
      if (sessionStorage.getItem("newParts")) {
        const json = JSON.parse(sessionStorage.getItem("newParts"))
        if (json?.length > 0) {
          for (const part of json) {
            if (part.childstep === childStepSelected.id) {
              setDataTools(prev => ({ ...prev, parts: [...prev.parts, { ...part }] }))
            }
          }
        }
      }
      if (sessionStorage.getItem("newTools")) {
        const json = JSON.parse(sessionStorage.getItem("newTools"))
        if (json?.length > 0) {
          for (const tool of json) {
            if (tool.childstep === childStepSelected.id) {
              setDataTools(prev => ({ ...prev, tools: [...prev.tools, { ...tool }] }))
            }
          }
        }
      }
    }
    return () => {
      setDataTools({ tools: [], parts: [], repeat: 0 })
    }
  }, [data, location])

  useLayoutEffect(() => {
    if (dataTools) {
      let errorParts = false
      let errorRepeat = false
      for (const part of dataTools.parts) {
        if (!part.quantity || Number(part.quantity) <= 0) {
          setError(prev => ({ ...prev, parts: [...prev.parts, part] }))
          errorParts = true
        }
      }
      if (!errorParts) {
        setError(prev => ({ ...prev, parts: [] }))
      }

      if (dataTools.repeat === "" || Number(dataTools.repeat) < 0) {
        errorRepeat = true
      }
      setError(prev => ({ ...prev, repeat: errorRepeat }))
    }
  }, [dataTools])

  const changeQuantityPart = (value, index) => {
    const json = JSON.parse(sessionStorage.getItem("newParts"))
    json?.map(part => {
      if (part.id === dataTools.parts[index]?.id) {
        part.quantity = Number(value)
      }
      return part
    })
    sessionStorage.setItem("newParts", JSON.stringify(json))

    setDataTools(prevDataTools => {
      const updatedParts = [...prevDataTools.parts]
      updatedParts[index] = {
        ...updatedParts[index],
        quantity: Number(value),
        isChange: true,
      }
      return { ...prevDataTools, parts: updatedParts }
    })
  }

  const localRemovePart = useCallback((type, index) => {
    const obj = JSON.parse(JSON.stringify(dataTools))

    if (type === "part") {
      if (obj.parts[index].new) {
        const json = JSON.parse(sessionStorage.getItem("newParts"))
        const indexItem = json.findIndex(part => part.id === obj.parts[index]?.id)
        json.splice(indexItem, 1)
        sessionStorage.setItem("newParts", JSON.stringify(json))
        obj.parts.splice(index, 1)
      } else {
        obj.parts[index].inTheTrash = true
      }
    } else {
      if (obj.tools[index].new) {
        const json = JSON.parse(sessionStorage.getItem("newTools"))
        const indexItem = json.findIndex(tool => tool.id === obj.tools[index]?.id)
        json.splice(indexItem, 1)
        sessionStorage.setItem("newTools", JSON.stringify(json))
        obj.tools.splice(index, 1)
      } else {
        obj.tools[index].inTheTrash = true
      }
    }

    setDataTools({ ...obj })
  })

  const choiceItemRedirect = useCallback((type, forCreate) => {
    sessionStorage.setItem("idCurrentChildStep", childStepSelected.id)
    sessionStorage.setItem(
      "redirectStepFromLibraryUrl",
      `/manuals/step/${idstep}?manual=${params.get("manual")}&childstep=${params.get("childstep")}`,
    )
    if (forCreate) {
      sessionStorage.setItem("newItem", true)
    }
    navigate(`/library?type=picture&filter=${type}&page=1`)
  })

  return (
    <form className={css(styles.bodyStep)}>
      <div className={css(styles.childStepCard)}>
        <div style={{ display: "flex", alignItems: "center", gap: 5 }}>
          <p className={css(styles.titleText)}>{t("editstep.child_step", { number: index + 1 })}</p>
          {idstep && data?.stepPartsByIdChildStep?.nodes?.length === 0 && <WarningRoundYellow />}
          {last && dataTools?.pos > 1 && (
            <UIButtonIcon
              style={{ paddingTop: 3 }}
              dynamicStyle={[styles.styleBtn]}
              icon={<Trash color={WHITE} />}
              onClick={() => deleteChildStep(dataTools.childStepId)}
            />
          )}
        </div>

        <button
          type="button"
          aria-label="open"
          onClick={() => checkChevron(data?.id)}
          style={{
            transform: childStepSelected?.id === data?.id ? "rotate(180deg)" : "",
          }}
          className={css(styles.styleBtn, !open && styles.chevronUp)}>
          <CheveronDown color={WHITE} />
        </button>
      </div>
      {data.expand && (
        <div className={css(styles.containerDetailStep)}>
          <div className={css(styles.flexBetween)}>
            <section style={{ width: "calc(100%/3)" }}>
              <div className={css(styles.flex)}>
                <p className={css(styles.font600)}>{t("editstep.parts")}</p>
                <UIButtonIcon
                  onClick={() => choiceItemRedirect("parts", true)}
                  dynamicStyle={[styles.primaryAdddBtn]}
                  icon={<AddStack />}
                />
              </div>

              <div className={css(styles.detailsSection)}>
                {dataTools?.parts[0] &&
                  dataTools?.parts.map((part, index) => (
                    <React.Fragment key={index}>
                      {!part?.inTheTrash && (
                        <WidgetPartChildStep
                          id={part?.new ? part?.id : part?.partByIdPart?.id}
                          remove={() => localRemovePart("part", index)}
                          imgSrc={`${config.BASE_URL}/parts/${
                            part?.partByIdPart?.id || part?.id
                          }/image?lang=en`}
                          quantity={dataTools?.parts[index]?.quantity}
                          onChange={value => changeQuantityPart(value, index)}
                        />
                      )}
                    </React.Fragment>
                  ))}
                <button
                  aria-label="add-part-button"
                  onClick={() => choiceItemRedirect("parts", false)}
                  type="button"
                  className={css(styles.partsBtn)}>
                  <p style={{ margin: 0 }}>{t("editstep.select_part")}</p>
                  <Stack color={WHITE} />
                </button>
              </div>
            </section>
            <section style={{ width: "calc(100%/3)" }}>
              <div className={css(styles.flex)}>
                <p className={css(styles.font600)}>{t("editstep.tools")}</p>
                <UIButtonIcon
                  onClick={() => choiceItemRedirect("tools", true)}
                  dynamicStyle={[styles.primaryAdddBtn]}
                  icon={<AddStack />}
                />
              </div>
              <div className={css(styles.detailsSection)}>
                {dataTools?.tools[0] &&
                  dataTools.tools.map((tool, index) => (
                    <React.Fragment key={index}>
                      {!tool?.inTheTrash && (
                        <WidgetToolChildStep
                          id={tool?.new ? tool?.id : tool?.toolByIdTool?.id}
                          imgSrc={`${config.BASE_URL}/tools/${
                            tool?.toolByIdTool?.id || tool?.id
                          }/image?lang=en`}
                          remove={() => localRemovePart("tool", index)}
                        />
                      )}
                    </React.Fragment>
                  ))}

                <button
                  onClick={() => choiceItemRedirect("tools", false)}
                  type="button"
                  aria-label="add-tool-button"
                  className={css(styles.partsBtn)}>
                  <p style={{ margin: 0 }}>{t("editstep.select_part")}</p>
                  <Stack color={WHITE} />
                </button>
              </div>
            </section>
            <section style={{ width: "calc(100%/3.7)" }}>
              <p className={css(styles.font600)}>{t("editstep.repeat")}</p>

              <div className={css(styles.detailsSection)}>
                <input
                  className={css(styles.input, error.repeat && styles.errorInput)}
                  type="number"
                  onChange={e => {
                    setDataTools(prev => ({ ...prev, repeat: e.target.value }))
                  }}
                  value={dataTools?.repeat}
                />
              </div>
            </section>
          </div>
          <div>
            {error?.parts?.length > 0 && (
              <p className={css(styles.errorText)}>{t("errors.error_part_quantity")}</p>
            )}
            {error?.repeat && <p className={css(styles.errorText)}>{t("errors.error_childstep_repeat")}</p>}
          </div>
          <div className={css(styles.flex)} style={{ margin: "20px 0 0px" }}>
            <UIButtonIcon
              onClick={() => saveChildStepData(dataTools)}
              dynamicStyle={[styles.trashBtn]}
              icon={<Save />}
              disabled={
                !modelIsPresent ||
                error?.parts?.length > 0 ||
                error?.repeat ||
                dataTools?.parts?.length === 0 ||
                dataTools?.parts?.some(tool => tool?.quantity < 1)
              }
              animationSuccess={saveSuccess}
              loading={loading}
            />
            <button
              aria-label="edit-3d-scene-button"
              onClick={() => {
                sessionStorage.setItem(
                  "redirectStepFromModelUrl",
                  `/manuals/step/${idstep}?manual=${params.get("manual")}`,
                )
                navigate(`/manuals/model3d?step=${idstep}&childstep=${params.get("childstep")}&tab=edit`)
              }}
              type="button"
              className={css(styles.primaryBtn)}
              disabled={!modelIsPresent}>
              <Edit color={WHITE} />
              {t("editstep.scene3d")}
            </button>
          </div>
        </div>
      )}
    </form>
  )
}

const getStyles = ({ theme, open }) =>
  StyleSheet.create({
    bodyStep: {
      background: WHITE,
      marginBottom: 15,
    },
    input: {
      width: 35,
      height: 30,
      fontSize: 12,
      fontWeight: "400",
      borderRadius: 4,
      color: theme.PRIMARY_COLOR || BLACK_COLOR,
      boxSizing: "border-box",
      textAlign: "center",
      border: `1px solid ${NEUTRAL_DARK}`,
      "::placeholder": {
        color: NEUTRAL_DARK,
      },
    },
    childStepCard: {
      height: 40,
      background: theme.SECONDARY_COLOR,
      width: "100%",
      color: WHITE,
      display: "flex",
      alignItems: "center",
      padding: "0 15px",
      boxSizing: "border-box",
      marginBottom: open ? 0 : 15,
      fontWeight: "600",
      fontFamily: "Roboto",
      border: "none",
      borderRadius: 1,
      justifyContent: "space-between",
    },
    errorInput: {
      borderColor: ERROR_COLOR,
    },
    titleText: {
      fontSize: 14,
      textTransform: "uppercase",
      margin: 0,
    },
    containerDetailStep: {
      padding: "15px 0 15px 15px",
    },
    flexBetween: {
      display: "flex",
      justifyContent: "space-between",
      gap: 40,
    },
    flex: { display: "flex", gap: 10, alignItems: "center", marginBottom: 10 },
    font600: {
      fontSize: 12,
      fontFamily: "Roboto",
      fontWeight: "600",
      color: theme.PRIMARY_COLOR,
      margin: 0,
    },
    detailsSection: {
      marginTop: 5,
      display: "flex",
      flexDirection: "column",
    },
    partsBtn: {
      height: 30,
      padding: "0 10px",
      display: "flex",
      justifyContent: "space-between",
      gap: 10,
      fontSize: 12,
      background: theme.SECONDARY_COLOR,
      border: "none",
      borderRadius: 4,
      color: WHITE,
      alignItems: "center",
      marginTop: 15,
      width: "100%",
      cursor: "pointer",
      marginBottom: 5,
      ":hover": {
        opacity: 0.6,
      },
    },
    errorText: {
      color: ERROR_COLOR,
      fontSize: 13,
      fontWeight: "400",
      fontFamily: "Roboto",
      margin: 2,
    },
    trashBtn: {
      background: theme.PRIMARY_COLOR,
      height: 35,
      minWidth: 35,
      borderRadius: 4,
      cursor: "pointer",
    },
    primaryBtn: {
      background: theme.PRIMARY_COLOR,
      fontSize: 12,
      borderRadius: 4,
      border: "none",
      height: 35,
      color: WHITE,
      fontFamily: "Roboto",
      padding: "0 10px",
      cursor: "pointer",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      gap: 5,
      ":hover": {
        opacity: 0.6,
      },
      ":disabled": {
        background: NEUTRAL_DARK,
        cursor: "default",
      },
    },
    font400: {
      fontSize: 12,
      fontWeight: "400",
      color: theme.PRIMARY_COLOR,
      textTransform: "capitalize",
      margin: 0,
    },
    styleBtn: {
      background: theme?.SECONDARY_COLOR,
      height: "100%",
      display: "block",
      border: "none",
    },
    primaryAdddBtn: {
      background: theme.PRIMARY_COLOR,
      borderRadius: "50px",
      border: "none",
      minWidth: 18,
      minHeight: 19,
      gap: 3,
      cursor: "pointer",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
  })

WidgetChildStep.propTypes = {
  checkChevron: PropTypes.func,
  childStepSelected: PropTypes.object,
  data: PropTypes.object,
  index: PropTypes.number,
  saveChildStepData: PropTypes.func,
  modelIsPresent: PropTypes.bool,
  last: PropTypes.bool,
  deleteChildStep: PropTypes.func,
  saveSuccess: PropTypes.bool,
  loading: PropTypes.bool,
}
