import PropTypes from "prop-types"
import * as React from "react"

const Stack = ({ color }) => (
  <svg viewBox="0 0 20 20" height={20} width={20} xmlns="http://www.w3.org/2000/svg" fill="none">
    <g transform="translate(2, 2)">
      <path
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        transform="matrix(0.689655,0,0,0.689655,0,0)"
        d="m2 17 10 5 10-5M2 12l10 5 10-5M12 2 2 7l10 5 10-5-10-5Z"
      />
    </g>
  </svg>
)

Stack.propTypes = {
  color: PropTypes.string,
}

export default Stack
