import { gql } from "@apollo/client"

export const GET_TOKEN_USER = gql`
  query getTokenWithCredentials($login: String!, $password: String!) {
    getTokenWithCredentials(input: { login: $login, password: $password }) {
      accessToken
      refreshToken
    }
  }
`

export const REFRESH_TOKEN = gql`
  query refreshToken($refreshToken: String!) {
    refreshToken(input: { refreshToken: $refreshToken }) {
      accessToken
      refreshToken
    }
  }
`
