import { useEffect, useRef, useState } from "react"
import { DRACOLoader } from "three/addons/loaders/DRACOLoader.js"
import { GLTFLoader } from "three/addons/loaders/GLTFLoader.js"
import AssetManager from "../lib/graphics/AssetManager.mjs"
import ViewerManager from "../lib/graphics/ViewerManager.mjs"
import WireframeRendering from "../lib/graphics/WireframeRendering.mjs"

export const use3DViewer = (canvasRef, modelURL, wireframe = true, autoAdaptCameraToModel = true) => {
  const [animationsData, setAnimationsData] = useState([])
  const [modelRoot, setModelRoot] = useState(null)
  const viewerRef = useRef(null)
  const assetManagerRef = useRef(null)
  const animationFrameRef = useRef(null)

  useEffect(initViewer, [])
  useEffect(loadAndShowModel, [modelURL, wireframe, autoAdaptCameraToModel])

  return {
    viewerRef,
    modelRoot,
    animationsData,
  }

  function initViewer() {
    // Init Viewer
    const width = canvasRef.current.clientWidth
    const height = canvasRef.current.clientHeight

    viewerRef.current = new ViewerManager(canvasRef.current, width, height)

    animationFrameRef.current = requestAnimationFrame(update)

    // Init AssetManager
    if (assetManagerRef.current === null) {
      assetManagerRef.current = new AssetManager()
      assetManagerRef.current.addLoaders({
        GLTF: GLTFLoader,
      })

      const dracoLoader = new DRACOLoader()
      dracoLoader.setDecoderPath("three/addons/libs/draco/")
      assetManagerRef.current.loaders.gltf.setDRACOLoader(dracoLoader)
    }

    return clean

    function update(timestamp) {
      viewerRef.current.update(timestamp)
      animationFrameRef.current = requestAnimationFrame(update)
    }

    function clean() {
      viewerRef.current.dispose()
      cancelAnimationFrame(animationFrameRef.current)
    }
  }

  function loadAndShowModel() {
    let isDataValid = true

    loadModelAsync(modelURL)

    return clean

    async function loadModelAsync(url) {
      let model = null
      if (url) {
        model = await assetManagerRef.current.load(url, { type: "GLTF" })
      }

      if (isDataValid) {
        viewerRef.current.setModel(model, autoAdaptCameraToModel)
        setModelRoot(viewerRef.current.modelRoot)
        setAnimationsData(viewerRef.current.getAnimationsData())

        if (wireframe && viewerRef.current.modelRoot !== null) {
          WireframeRendering.applyWireframeRendering(viewerRef.current.modelRoot)
          viewerRef.current.addObjectToOutline(viewerRef.current.modelRoot, true)
        }
        viewerRef.current.setOutlineEnabled(wireframe)
      }
    }

    function clean() {
      isDataValid = false
    }
  }
}
