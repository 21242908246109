import { TransformControls } from 'three/addons/controls/TransformControls.js'

class InteractableTransformManipulator {
  autoUnselect = true
  _interactable
  _transformControls
  _interactableClickAction
  _hasOwnControls = false
  _selectingManager = null
  _controlsListeners = new Map()
  _onTransformChangedActions = new Set()
  _onSelectActions = new Set()

  get interactable() {
    return this._interactable
  }

  constructor(interactable, controls) {
    this._interactable = interactable
    this._transformControls = controls

    if (this._transformControls && !(this._transformControls instanceof TransformControls)) {
      throw new Error('The provided controls must be an instance of TransformControls.')
    }

    if (!this._transformControls) {
      this._transformControls = new TransformControls(
        this._interactable.interfaceManager.viewer.world.camera,
        this._interactable.interfaceManager.viewer.canvas,
      )
      this._interactable.root.parent.add(this._transformControls)

      this._hasOwnControls = true
    }

    this._interactableClickAction = () =>
      this._interactable.interfaceManager.viewer.selectionManager.select(this)
    this._interactable.addClickAction(this._interactableClickAction)

    this._controlsListeners.set('mouseDown', onControlsPointerDown.bind(this))
    this._controlsListeners.set('mouseUp', onControlsPointerUp.bind(this))
    this._controlsListeners.set('change', onControlsChange.bind(this))

    function onControlsPointerDown(event) {
      this._interactable.interfaceManager.viewer.cameraController.setControlsEnabled(false)
    }

    function onControlsPointerUp(event) {
      this._interactable.interfaceManager.viewer.cameraController.setControlsEnabled(true)
    }

    function onControlsChange(event) {
      for (const action of this._onTransformChangedActions.values()) {
        action(this)
      }
    }
  }

  dispose() {
    this._interactable.removeClickAction(this._interactableClickAction)
    if (this._selectingManager) {
      this._selectingManager.unselect()
    }
    if (this._hasOwnControls) {
      this._transformControls.dispose()
    }
  }

  select(selectionManager) {
    this._selectingManager = selectionManager
    this._transformControls.attach(this._interactable.root)
    this.addControlsListeners()

    for (const action of this._onSelectActions.values()) {
      action(this)
    }
  }

  unselect(selectionManager) {
    this._selectingManager = null
    this._transformControls.detach()
    this.removeControlListeners()
  }

  addControlsListeners() {
    for (const [k, v] of this._controlsListeners.entries()) {
      this._transformControls.addEventListener(k, v)
    }
  }

  removeControlListeners() {
    for (const [k, v] of this._controlsListeners.entries()) {
      this._transformControls.removeEventListener(k, v)
    }
  }

  addTransformChangedAction(action) {
    if (typeof action !== 'function') {
      console.error('ERROR: The provided action is not a function')
      return
    }

    this._onTransformChangedActions.add(action)
  }

  removeTransformChangedAction(action) {
    this._onTransformChangedActions.delete(action)
  }

  addSelectAction(action) {
    if (typeof action !== 'function') {
      console.error('ERROR: The provided action is not a function')
      return
    }

    this._onSelectActions.add(action)
  }

  removeSelectAction(action) {
    this._onSelectActions.delete(action)
  }
}

export default InteractableTransformManipulator
