import Skeleton from "@mui/material/Skeleton"
import { css, StyleSheet } from "aphrodite"
import PropTypes from "prop-types"
import React, { useState } from "react"
import { WHITE } from "../../styles/colors"

export const WidgetBrandCard = ({ img, onClick, id }) => {
  // states  ------------------------------------------------------------------
  const [load, setLoad] = useState(false)

  // init style ----------------------------------------------------------------
  const styles = getStyles()

  return (
    <button aria-label="brand" onClick={onClick} type="button" className={css(styles.card)}>
      {img && (
        <img
          alt={`cover${id}`}
          height={"100%"}
          width={"100%"}
          id={id}
          style={{ display: load ? "block" : "none" }}
          className={css(styles.img)}
          src={img}
          onLoad={() => setLoad(true)}
        />
      )}
      {!load && (
        <div style={{ height: "100%", width: "100%", borderRadius: 4 }}>
          <Skeleton
            variant="rectangular"
            sx={{ bgcolor: WHITE, height: "100%", width: "100%" }}
            animation="wave"
            width={"100%"}
          />
        </div>
      )}
    </button>
  )
}

const getStyles = () =>
  StyleSheet.create({
    card: {
      background: WHITE,
      border: "none",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      borderRadius: 4,
      maxWidth: 311,
      position: "relative",
      width: "100%",
      height: "100%",
      padding: 0,
      maxHeight: 118,
      cursor: "pointer",
      overflow: "hidden",
      ":hover": {
        opacity: 0.6,
      },
    },
    img: {
      height: "auto",
      width: "auto",
      maxWidth: "50%",
      maxHeight: "100%",
      display: "block",
      objectFit: "contain",
    },
  })

WidgetBrandCard.propTypes = {
  img: PropTypes.string,
  onClick: PropTypes.func,
  id: PropTypes.string,
}
