import PropTypes from "prop-types"
import * as React from "react"

const Close = ({ color }) => (
  <svg width={11} height={12} fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10.589 1.887 9.53.829 5.34 5.022 1.146.829.09 1.887 4.28 6.079.09 10.272l1.057 1.057L5.34 7.137l4.192 4.192 1.058-1.057-4.193-4.193 4.193-4.192Z"
      fill={color}
    />
  </svg>
)

Close.propTypes = {
  color: PropTypes.string,
}

export default Close
