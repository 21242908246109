import { useEffect, useMemo, useRef } from "react"
import * as THREE from "three"
import { getViewFillData } from "../lib/graphics/3DUtils.mjs"
import OrbitCameraConfigurator from "../lib/graphics/OrbitCameraConfigurator.mjs"

export const use3DCameraOrbitConfiguration = (viewerRef, transformControlsRef, orbitData, changeData) => {
  const { zoomMin, zoomMax } = useMemo(calculateZoomRange, [viewerRef.current?.modelRoot])
  const configurator = useRef(null)

  useEffect(updateZoomRange, [zoomMin, zoomMax])
  useEffect(initConfigurator, [viewerRef, transformControlsRef])
  useEffect(addUpdateActions, [changeData, configurator.current])
  useEffect(updateConfigurationData, [
    orbitData.camera.minZoomDb,
    orbitData.camera.maxZoomDb,
    orbitData.target.x,
    orbitData.target.y,
    orbitData.target.z,
    orbitData.camera.x,
    orbitData.camera.y,
    orbitData.camera.z,
  ])

  return {
    selectCamera,
    selectTarget,
  }

  function updateZoomRange() {
    if (typeof changeData === "function") {
      changeData("camera", "minZoomModel", zoomMin)
      changeData("camera", "maxZoomModel", zoomMax)
    }
  }

  function calculateZoomRange() {
    if (viewerRef.current?.modelRoot != null) {
      const dummyCamera = new THREE.PerspectiveCamera(45, 9 / 16)
      const { boundingSphere, startDistance } = getViewFillData(
        viewerRef.current.modelRoot,
        dummyCamera,
        0.2,
        0.2,
      )
      return { zoomMin: boundingSphere.radius * 0.1, zoomMax: startDistance }
    } else {
      return { zoomMin: 0.001, zoomMax: 100 }
    }
  }

  function initConfigurator() {
    if (!viewerRef.current) {
      return
    }

    viewerRef.current.addInterfaceManager()
    configurator.current = new OrbitCameraConfigurator(
      viewerRef.current.viewerInterfaceManager,
      transformControlsRef.current,
    )

    return clean

    function clean() {
      configurator.current.dispose()
    }
  }

  function addUpdateActions() {
    const cameraUpdateAction = () => {
      changeData("camera", "x", configurator.current.cameraGizmo.position.x)
      changeData("camera", "y", configurator.current.cameraGizmo.position.y)
      changeData("camera", "z", configurator.current.cameraGizmo.position.z)
    }
    const targetUpdateAction = () => {
      changeData("target", "x", configurator.current.targetGizmo.position.x)
      changeData("target", "y", configurator.current.targetGizmo.position.y)
      changeData("target", "z", configurator.current.targetGizmo.position.z)
    }

    const cameraSelectAction = () => changeData("camera", "drawerOpen", true)
    const targetSelectAction = () => changeData("target", "drawerOpen", true)

    configurator.current.addCameraTransformChangedAction(cameraUpdateAction)
    configurator.current.addTargetTransformChangedAction(targetUpdateAction)
    configurator.current.addCameraSelectAction(cameraSelectAction)
    configurator.current.addTargetSelectAction(targetSelectAction)

    return clean

    function clean() {
      configurator.current.removeCameraTransformChangedAction(cameraUpdateAction)
      configurator.current.removeTargetTransformChangedAction(targetUpdateAction)
      configurator.current.removeCameraSelectAction(cameraSelectAction)
      configurator.current.removeTargetSelectAction(targetSelectAction)
    }
  }

  function updateConfigurationData() {
    if (!configurator.current) {
      return
    }

    if (orbitData.camera.minZoomDb) {
      configurator.current.setMinDistance(orbitData.camera.minZoomDb, false)
    }
    if (orbitData.camera.maxZoomDb) {
      configurator.current.setMaxDistance(orbitData.camera.maxZoomDb, false)
    }

    if (
      orbitData.target.x !== undefined &&
      orbitData.target.y !== undefined &&
      orbitData.target.z !== undefined
    ) {
      configurator.current.setTargetPosition(
        isNaN(orbitData.target.x) ? 0 : orbitData.target.x,
        isNaN(orbitData.target.y) ? 0 : orbitData.target.y,
        isNaN(orbitData.target.z) ? 0 : orbitData.target.z,
        false,
      )
    }

    if (
      orbitData.camera.x !== undefined &&
      orbitData.camera.y !== undefined &&
      orbitData.camera.z !== undefined
    ) {
      configurator.current.setCameraPosition(
        isNaN(orbitData.camera.x) ? 0 : orbitData.camera.x,
        isNaN(orbitData.camera.y) ? 0 : orbitData.camera.y,
        isNaN(orbitData.camera.z) ? 0 : orbitData.camera.z,
        true,
      )
    }
  }

  function selectCamera() {
    if (!configurator.current || !viewerRef.current?.selectionManager) {
      return
    }

    viewerRef.current.selectionManager.select(configurator.current.cameraManipulator)
  }

  function selectTarget() {
    if (!configurator.current || !viewerRef.current?.selectionManager) {
      return
    }

    viewerRef.current.selectionManager.select(configurator.current.targetManipulator)
  }
}
