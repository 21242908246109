import Skeleton from "@mui/material/Skeleton"
import { css, StyleSheet } from "aphrodite"
import PropTypes from "prop-types"
import React, { useCallback, useMemo, useState } from "react"
import { BLACK_COLOR, WHITE } from "../../styles/colors"

export const ManualCard = ({ title, img, onClick, theme }) => {
  const [load, setLoad] = useState(false)

  const handleLoad = useCallback(() => {
    setLoad(true)
  }, [])

  // init style ----------------------------------------------------------------
  const styles = useMemo(() => getStyles({ load, theme, title }), [load, theme, title])

  return (
    <button aria-label={`item ${title}`} onClick={onClick} type="button" className={css(styles.card)}>
      {img && (
        <div
          style={{
            height: "100%",
            boxSizing: "border-box",
            flexDirection: "column",
            justifyContent: "space-between",
            gap: 15,
            display: !load || !title ? "none" : "flex",
            overflow: "hidden",
          }}>
          <div className={css(styles.containerImg)}>
            <img alt={`cover${title}`} onLoad={handleLoad} className={css(styles.img)} src={img} />
          </div>

          {load && (
            <div className={css(styles.containerTitle)}>
              <p className={css(styles.title)}>{title}</p>
            </div>
          )}
        </div>
      )}
      {(!load || !title) && (
        <div style={{ height: "100%", width: "100%", borderRadius: 4 }}>
          <Skeleton
            variant="rectangular"
            sx={{ bgcolor: WHITE, height: "100%", width: "100%" }}
            animation="wave"
            width={"100%"}
          />
        </div>
      )}
    </button>
  )
}

const getStyles = ({ load, theme, title }) =>
  StyleSheet.create({
    card: {
      background: WHITE,
      display: "flex",
      alignItems: "center",
      borderRadius: 4,
      justifyContent: "space-between",
      padding: load && title ? "20px 20px 15px" : 0,
      boxSizing: "border-box",
      flexDirection: "column",
      width: 200,
      height: 200,
      border: "none",
      position: "relative",
      overflow: "hidden",
      cursor: "pointer",
      ":hover": {
        opacity: 0.6,
      },
    },
    containerTitle: {
      display: "flex",
      justifyContent: "center",
      alignItems: "flex-end",
    },
    title: {
      fontFamily: "Roboto",
      fontWeight: "700",
      fontSize: title.length > 34 ? 13 : 14,
      textTransform: "uppercase",
      margin: 0,
      color: theme?.PRIMARY_COLOR || BLACK_COLOR,
    },
    img: {
      height: "auto",
      width: "auto",
      maxWidth: "100%",
      maxHeight: "100%",
      display: "block",
      objectFit: "contain",
    },
    containerImg: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      overflow: "hidden",
      flexGrow: 1,
      aspectRatio: "1/1",
    },
  })

ManualCard.propTypes = {
  title: PropTypes.string,
  img: PropTypes.string,
  onClick: PropTypes.func,
  theme: PropTypes.object,
}
