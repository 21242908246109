import { contextLinesIntegration } from "@sentry/integrations"
import * as Sentry from "@sentry/react"
const environment = process.env.REACT_APP_ENV || "development"
const configEnv = require(`../config/config.${environment}.js`)
const config = configEnv?.default
const isLocal = window.location.hostname === "localhost" || window.location.hostname === "127.0.0.1"

export const initSentry = () => {
  if (environment !== "development" && !isLocal) {
    Sentry.init({
      dsn: "https://0ba593ca27b0d93f36d6c3a972696473@o530648.ingest.sentry.io/4505901842038784",
      integrations: [Sentry.browserTracingIntegration(), contextLinesIntegration()],
      release: config.CURRENT_VERSION,
      enableAutoSessionTracking: true,
      environment,

      // browser tracing configuration
      autoSessionTracking: true,
      sessionTrackingIntervalMillis: 10000,
      tracesSampleRate: 0.5,

      ignoreErrors: [
        "Network Error",
        "Request failed with status code 404", // no data
        "Request failed with status code 403", // no authorized
      ],
      initialScope: scope => {
        scope.setTag("plateforme", "guide-me-cms")
        return scope
      },
    })
  }
}

export const captureErrorGraphql = (error, component, request, variables) => {
  if (environment !== "development" && !isLocal) {
    Sentry.withScope(scope => {
      scope.setExtra("request", request)
      scope.setExtra("component", component)
      scope.setExtra("variables", variables)
      Sentry.captureException(error)
    })
  }
}
